import React, { Component } from 'react';
import ApiURL from "../../../api/ApiURL";

class InclusionExclusion extends Component {

    render() {
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/call-button-sonali-adorable-vacation.webp";
        return (
            <>
                <h4 className='tomato'><u>INCLUSION: <strong>PUNE TO BHUTAN PACKAGE</strong></u></h4>
                <ul>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Accommodation in TCB listed Hotels [Double/Twin Sharing Room]
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Meal Plan MAP (Breakfast and Dinner)
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis)
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Parking, Toll Tax, e-Permits
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Thimphu Local Sightseeing
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Paro City Tour
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;English / Hindi Speaking Tour Guide
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;All transfer will be on point to point basis
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;SDF Charges [Sustainable Development Fee]
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;01 Bottle Packages Drinking Water Per Day on Vehicle
                    </li>
                    <li>
                        <span className="green-dot">
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Guest Service Support during the trip from Bhutan & India Office 24/7
                    </li>
                </ul><br /><br />
                <h4 className='tomato'><u>EXCLUSION: <strong>BHUTAN TOUR FROM PUNE VIA MUMBAI AIRPORT</strong></u></h4>
                <ul>
                    <li>
                        <span className="red-dot">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Air Fare
                    </li>
                    <li>
                        <span className="red-dot">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Snacks, Lunch, or any other meal
                    </li>
                    <li>
                        <span className="red-dot">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Monument Entrance Fees
                    </li>
                    <li>
                        <span className="red-dot">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Laundry, Tips, Phone Call, or any such Personal Expense
                    </li>
                    <li>
                        <span className="red-dot">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                        &nbsp;&nbsp;Any item which is not mentioned in the "Inclusion" Section
                    </li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion;