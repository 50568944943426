import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Vietnam_7N_8D_Kolkata_Package from '../../../components/package/Vietnam/Vietnam_7N_8D_Kolkata_Package';
import { Helmet } from "react-helmet";
class Vietnam_7N_8D_Kolkata_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ReviewersData: [],
            ThemePackagesData: [],

        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "29,900/-",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "35,100/-",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            },
            {
                Title: "Explore Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "40,200/-",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            },
            {
                Title: "Excape Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "48,200/-",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ];

        const reviewersDataList = [
            {
                Name: "Sheikh Imran",
                ImageName: "Seikh-Imran.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Dear Akash/Team Adorable,I hope this message finds you well. I am writing to extend my heartfelt gratitude for the exceptional planning and execution of our recent trip to Vietnam. Your meticulous attention to detail and dedication to ensuring a seamless travel experience made this journey truly unforgettable.From the moment we arrived, everything was perfectly arranged—from the comfortable accommodations to the well-organized tours. Your recommendations for local attractions and Lunch/dining experiences on few days added a special touch, allowing us to immerse ourselves in the rich culture and beauty of Vietnam.We particularly appreciated your prompt responses to our queries and the personalized touches you included, which made us feel valued and cared for. Your professionalism and expertise shone through every aspect of the trip, and we are grateful for the peace of mind you provided.Thank you once again for your outstanding service and for making our trip to Vietnam a memorable adventure. We look forward to planning our next journey with you and will gladly recommend your services to friends and family.Best Regards Sheikh Imran and Family",
            },
            {
                Name: "Papri Das",
                ImageName: "Papri-Das.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We had booked a conducted private tour of Cambodia and Vietnam with Adorable vacations. We had very nice experience, the itinerary was well curated and implemented. We did not face any problems there regarding our transportation, tour,hotels etc.i would strongly recommend them .",
            },
            {
                Name: "Dr. Agniva Chakraborty",
                ImageName: "Agniva-Chakraborty.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Our recent trip to Bali with Adorable Vacation was nothing short of extraordinary. My wife and I were blown away by how well-organized and stress-free everything was. From the moment we set foot on the island, we felt taken care of. The itinerary perfectly balanced relaxation and adventure, allowing us to explore Bali’s breathtaking temples, enjoy scenic rice terraces, and savor romantic dinners by the beach. A special thanks to Mr. Akash Dutta and Ms. Anushka Majumdar for their impeccable planning and personalized service. Their attention to detail ensured our stay was comfortable and memorable. The seamless transfers, beautiful accommodations, and thoughtful touches made our experience even more special. This journey was everything we dreamed of and more. Thank you, Adorable Vacation, for making our Bali getaway one we’ll cherish forever",
            },
            {
                Name: "Swarnali Chakraborty",
                ImageName: "Swarnali- Chakraborty.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Adorable vacation arranged our bali tour according to our budget and meeting up the criterias very well. Thanks for the information guide and arrangements. It was an enjoyable experience.",
            },
        ];

        this.setState({
            HotPackagesData: hotPackageDataList,
            ReviewersData: reviewersDataList,
            ThemePackagesData: themePackageDataList,
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Vietnam Package Tour from Kolkata, Vietnam Package Tour from Kolkata Airport</title>
                    <meta name="description" content="Book Vietnam Package Tour from Kolkata Airport at the most Affordable Price. FOR BOOKING ☎ CALL +91-91636-76037" />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Vietnam Tour Package From Kolkata Reviews",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.8",
                                "bestRating": "5",
                                "ratingCount": "352"
                            }
                        })}
                    </script>
                </Helmet>
                <MainHeader />
                <Vietnam_7N_8D_Kolkata_Package
                    HotPackagesData={this.state.HotPackagesData}
                    ReviewersData={this.state.ReviewersData}
                    ThemePackagesData={this.state.ThemePackagesData}
                />
                <FooterDesktop />
                <div>
                    <h3 className="dropdown-item2">Vietnam Package Tour From Kolkata Reviews</h3>
                    <div>
                        <span className="dropdown-item2">4.8</span> <span className="dropdown-item2">Stars Rating, out of</span> <span className="dropdown-item2">5</span><br />
                        <span className="dropdown-item2">based on</span> <span className="dropdown-item2">352</span> <span className="dropdown-item2">Reviews</span>
                    </div>
                </div>
            </>
        );
    }
}

export default Vietnam_7N_8D_Kolkata_Page;