import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS: <strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation Stay in the listed hotels or homestays
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Meals All meals included (breakfast, lunch, evening snacks, and dinner)
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Transfers Exclusive non-AC vehicle for all transfers (NJP-NJP).</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Additional Costs Covered Driver allowances, fuel, toll taxes.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Permits and Taxes All necessary permits and applicable taxes included.</li>
                    
                </ul><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS: <strong>NORTH SIKKIM TOUR PACKAGE WITH SILK ROUTE & GANGTOK</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All items which are not included in the inclusion list.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Sunrise from Lungthung, Entry Fees for Pangolakha Wildlife Sanctuary.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Room Heater Charges for room heaters are not included.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Syndicate Charge Additional charge of Rs 200/- at NJP/Siliguri.</li>
                    
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;