import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import KolkataToBhutanPackage from '../../../components/package/Bhutan/KolkataToBhutanPackage';
import { Helmet } from "react-helmet";

class KolkataToBhutanPage extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ThemePackagesData: [],
            HotelsData: [],
            TrendingBhutanDataList: [],
            ReviewersData: [],

        }
    }


    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "4N-5D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro | Free Sim Card | E-Permit",
                PricePerNight: "22,599",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/4N-5D-FIFO-package'
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "5N-6D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "27,899",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/5N-6D-FIFO-package'

            },
            {
                Title: "Explore Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,999",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/6N-7D-FIFO-package'
            },

            {
                Title: "Excape Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,999",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/7N-8D-FIFO-package'
            },
            {
                Title: "Pleasing Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "43,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/8N-9D-FIFO-package'
            },
            {
                Title: "Fascinating Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Bumthang...more | Free Sim Card | E-Permit",
                PricePerNight: "53,899",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/10N-11D-FIFO-package'
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ]

        const reviewersDataList = [
            {
                Name: "Pallab Banerjee",
                ImageName: "pallab-banerjee.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Excellent service provided during my trip to Bhutan. The entire team in Kolkata and Bhutan is commendable. Care extended for senior citizens is very good.I strongly recommend to tie up with Adorable Vacation for memorable excursion. Great service.",
            },
            {
                Name: "Rikhiya Basu",
                ImageName: "rikiya-basu.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Had the most comfortable stay in Bhutan, thanks to adorable vacation LLp. Our guide Mr. Gyeltshen and our driver were extremely helpful and nice. They were always ready to help us out with everything. I would highly recommend Adorable Vacation LLP to everyone and would love to book our next trip with them too. Thank you team for taking care of us.",
            },
            {
                Name: "Kakoli Roy",
                ImageName: "Kakoli-Roy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Heartiest congratulations to Adorable Vacation LLP. This was my second trip with them.. first being Nepal. My recent family trip to Bhutan with with them was simply amazing. All arrangements... vehicle, transport, hotel booking, food, guide and all other services were excellent and our satisfaction. Thank to Soumalya, Anushka and their ever helping team. Looking forward for next trip with them. Wish you all very best. God bless you all.",
            },
            {
                Name: "Ritwik Sarkar",
                ImageName: "ritik-sarker.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Our vacation at Bhutan was booked through Rewa Adorable Vacation LLP. All the logistics like hotel booking, transport, guide etc. were managed quite professionally by Adorable Vacation. It was really a luxurious arrangement at an affordable rate. Our trip covered Thimphu, Punakha and Paro in Bhutan and Jaldapara in West Bengal. The locations of the hotels at all the places were simply superb, foods and hospitality at the hotels were excellent. The driver and guide were very much polite and experienced. Thanks Adorable Vacations for making our vacation very comfortable and helping us to create a lifetime memory of the picturesque Bhutan.",
            },
        ];

        const hotelDataList = [
            {
                HotelName: 'Hotel Ugyen, Thimpu',
                ImageName: "hotel-ugyen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Amodhara, Thimphu',
                ImageName: "amodhara-2.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'White Dragon, Punakha',
                ImageName: "white-dragon.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Pema karpo, Punakha',
                ImageName: "pema-karpo.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Layhuel, Paro',
                ImageName: "lehul.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Tshringma, Paro',
                ImageName: "Tsherim-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '43 Reviews'
            },
            {
                HotelName: 'Tsheringma, Phuntsholing',
                ImageName: "tsheringma.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '44 Reviews'
            },
            {
                HotelName: 'Palm, Phuntsholing',
                ImageName: "palm.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '40 Reviews'
            }
        ];

        const trendingBhutanDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "31,899",
                NoOfTours: 43,
                NoOfDeparture: 38
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour1.webp ",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,599",
                NoOfTours: 47,
                NoOfDeparture: 40
            },
            {
                Title: "Explore Bhutan",
                ImageName: "9N-10D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "48,899",
                NoOfTours: 44,
                NoOfDeparture: 37
            },
            {
                Title: "Excape Bhutan",
                ImageName: "12N-13D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "76,500",
                NoOfTours: 46,
                NoOfDeparture: 39
            },


        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            ThemePackagesData: themePackageDataList,
            HotelsData: hotelDataList,
            TrendingBhutanDataList: trendingBhutanDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bhutan package tour from Kolkata, Bhutan Tour Package from Kolkata</title>
                    <meta name="description" content="Book amazing Bhutan package tour from Kolkata at the most Affordable Price. FOR BOOKING ☎ CALL +91-98833-59713"/>
                </Helmet>

                <MainHeader />

                <KolkataToBhutanPackage
                    HotPackagesData={this.state.HotPackagesData}
                    ThemePackagesData={this.state.ThemePackagesData}
                    HotelsData={this.state.HotelsData}
                    TrendingBhutanDataList={this.state.TrendingBhutanDataList}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        )
    }
}

export default KolkataToBhutanPage;