import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Meghalaya/InclusionExclusion_KazirangaShillongMeghalaya_7n_8d";

class KazirangaShillongMeghalayaTour_7N_8D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,
     
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919830889567&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
 

  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Package-from-Guwahati.webp";
    const packageTopBanner2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Tour-Package-from-Guwahati.webp";
    const sightseen1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/KAZIRANGA-Cherrapunjee-JUNGLE-SAFARI.webp";
    const sightseen2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunji-package-tour.webp";
    const sightseen3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/ASSAM-CULTURE.webp";
    const sightseen4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CULTURE.webp";

    const navDay1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Indian-one-horned-rhinoceroses-KAZIRANGA.webp";
    const navDay2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Western-Zone-of Kaziranga-National-Park-Jungle-Safari.webp";
    const navDay3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Sholling-Barapani-Lake-Umiam-Lake.webp";
    const navDay4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/JOWAI-SHILLONG-Krang-Suri-Waterfalls.webp";
    const navDay5ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/MAWLYNNONG-DAWKI–CHERRAPUNJEE.webp";
    const navDay6ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CHERRAPUNJEE–NONGRIAT-TREK-DOUBLE-DECKER-BRIDGE.webp";
    const navDay7ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/SEVEN-SISTER-WATERFALLS-CHERAPUNJEE.webp";
    const navDay8ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kamakhya-Temple-Guwahati.webp";



    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Kaziranga Shillong Cherrapunjee Package Tour from Guwahati</strong></center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4.5vw" }}><center>Book <strong>Kaziranga Shillong Cherrapunjee Package Tour from Guwahati</strong></center>
                      <hr />
                    </h3>

                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                       Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Guwahati
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          kaziranga
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Shillong
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Dawki
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Cherrapunjee
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Shillong Cherrapunjee Tour Package</strong>, you have come to the right place!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}> FOR BOOKING <strong>KAZIRANGA SHILLONG CHERAPUNJEE TOUR PACKAGE</strong> <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480</a></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> FOR BOOKING <strong>KAZIRANGA SHILLONG CHERAPUNJEE TOUR PACKAGE</strong> <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480</a></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> INDIA'S LARGEST <b>NORTH EAST TOUR OPERATOR</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> INDIA'S LARGEST <b>NORTH EAST TOUR OPERATOR</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Welcome to the ultimate adventure in Northeast India with our <strong>Kaziranga Shillong Cherrapunjee Tour Packages from Guwahati</strong>. Dive into a vibrant mix of culture, nature, and adventure on a journey that will leave you amazed.</p>
                      <p>Discover the hidden natural treasures of Northeast India with our <strong>Kaziranga Shillong Cherrapunjee Package</strong>. Explore the stunning Kaziranga National Park in Assam and the beautiful landscapes of Meghalaya, which feature gorgeous waterfalls, sacred forests, towering hills, numerous lakes, and dark caves unlike any other in India.</p>
                      <p>Experience an unforgettable adventure with our Kaziranga elephant safari, where you’ll roam through dense forests and lush grasslands, seeing wildlife in their natural environment. Feel the excitement of a Jeep Safari in Kaziranga National Park, navigating diverse terrain and spotting predators like tigers and leopards.</p>
                      <p>Kaziranga National Park is a wildlife paradise, home to the rare Great Indian One-Horned Rhinoceros, Royal Bengal Tigers, Water Buffalos, Jungle Cats, Indian Gaurs, Indian Leopards, Indian Elephants, and Fishing Cats. Enjoy the beauty of tall grass, marshes, and shallow pools on our <strong>Kaziranga Shillong Cherrapunjee Package Tour</strong>.
                      </p>
                      <p>Next, enjoy a peaceful river cruise on the Brahmaputra River. Watch a stunning sunset over the horizon, feel the gentle breeze, and relax on deck, soaking in the enchanting landscape.</p>
                      <p>Meghalaya, known as the "Scotland of the East," offers lush green forests, beautiful waterfalls, long caves, and man-made marvels like the Living Root Bridges. Our <strong>Shillong Cherrapunjee Tour Package</strong> will offer tranquillity and panoramic views of terraced slopes, foggy hills, meandering rivers, and cascading waterfalls. Explore picture-perfect locations where nature's secrets are preserved from human greed and encroachment. Experience heaven from a new perspective with Adorable Vacation’s special <strong>North East Tour Packages from Guwahati</strong>.</p>
                      <p>No trip to <strong>Shillong Cherrapunjee Trip with Kaziranga</strong> is complete without exploring the mystical caves. Embark on a thrilling adventure through intricate caverns and passageways, marveling at stunning rock formations and underground rivers shaped by nature over millennia.</p>
                      <p>Visit the enchanting village of Cherrapunjee, famous for its lush greenery and record-breaking rainfall. Discover the incredible living root bridges, masterpieces of indigenous engineering. Trek through dense forests and across suspension bridges, witnessing the ingenuity of the local Khasi tribes.</p>
                      <p>Immerse yourself in the vibrant culture of <strong>North East Package Tour</strong>, where traditions are celebrated with fervor and hospitality is abundant. Savor local cuisine like Jadoh (rice and meat dish) and Doh Khlieh (spicy pork salad). Enjoy traditional festivals filled with music, dance, and laughter.</p>
                      <p>Join us on a <strong>Shillong Cherrapunjee Kaziranga Package Tour from Guwahati</strong> for an unforgettable adventure. Discover the true essence of Northeast India, explore its diverse landscapes, immerse yourself in its rich culture, and form lifelong connections with its warm and welcoming people.</p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}><strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR PACKAGE DURATION :</strong> <b>7N | 8D</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR PACKAGE DURATION :</strong> <b>7N | 8D</b></h4>
                </div>
                <div className="card-body">
                  <p>Places to cover in <strong>Shillong Cherrapunjee Package Tour from Guwahati </strong> : Kaziranga 2N | Shillong 2N | Cherrapunjee 2N | Guwahati 1N</p>
                  <p>Pickup & Drop Point for <strong>Kaziranga Shillong Tour Package</strong> : Lokpriya Gopinath Bordoloi International Airport, Guwahati</p>
                  <br /><br />
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR ITINERARY</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                GUWAHATI TO KAZIRANGA [APPROX. 4HRS]
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>JUNGLE SAFARI AT KAZIRANGA</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>KAZIRANGA TO SHILLONG [APPROX. 5HRS]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>SHILLONG – JOWAI – SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>SHILLONG – MAWLYNNONG – DAWKI – CHERRAPUNJEE</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>CHERRAPUNJEE – NONGRIAT TREK DOUBLE DECKER BRIDGE</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>CHERRAPUNJEE LOCAL SIGHTSEEING AND TRANSFER TO GUWAHATI [APPROX. 2HRS]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>DEPARTURE AT GUWAHATI AIRPORT</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES TO VISIT IN <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES TO VISIT IN <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                <p>This <strong>Kaziranga Shillong Cherrapunjee Package Tour</strong> includes many attractions in Assam and Meghalaya. Here are some of the places we'll explore :</p>
                <p>Kaziranga National Park, Shillong City, Umium Lake, Elephanta Falls, Shillong Peak, Spread Eagle Falls, Ward's Lake, Cathedral Catholic Church, Cherrapunjee, Seven Sister Falls, Mawsmai Cave, Nohkalikai Falls, Eco Park, Double Decker Living Root Bridge, Mawsynram, Jowai, Krang Suri Falls, Thadlaskein Lake, Stone Bridge, Tura, Mawlynnong, Dawki, Balpakram National Park, Kyllang Rock.</p>
                <br /><br />
                
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td><strong>Kaziranga Shillong Cherrapunjee Tour </strong> Duration</td>
                          <td>07 Nights | 08 Days</td>
                        </tr>
                        <tr>
                          <td><strong>Kaziranga Shillong Cherrapunjee Tour Package </strong> Pickup & Drop</td>
                          <td>Lokpriya Gopinath Bordoloi International Airport, Guwahati </td>
                        </tr>
                        <tr>
                          <td><strong>Kaziranga Shillong Cherrapunjee Package Tour Cost from Guwahati</strong></td>
                          <td>Rs. 28,999/- (Based on 6 Pax)</td>
                        </tr>
                        <tr>
                          <td>Altitude of Shillong</td>
                          <td>1,525 m [25.5788Â° N, 91.8933Â° E]</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Cherapunjee
                          </td>
                          <td>1,430 m [25.2702Â° N, 91.7323Â° E]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHAT IS THE BEST TIME TO BOOK <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR PACKAGE?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHAT IS THE BEST TIME TO BOOK <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR PACKAGE?</strong></h4>
                </div>

                <div className="card-body">
                  <p>Winter is the best time to visit Kaziranga National Park. You can travel <strong>Shillong Cherrapunjee Trip with Kaziranga</strong> anytime between the end of November and the first week of April. Winter in the Northeast starts in early November and lasts until the end of March. The weather during this season is pleasant and ideal for traveling. In winter, temperatures in Kaziranga range from a high of 24 degrees Celsius to a low of 6 degrees Celsius.</p>
                  <p>The vast swampy land of Kaziranga looks majestic in the winter mist, especially when you explore it on an elephant safari. Elephant safaris are great because they don't follow fixed paths, taking you to places where jeeps can't reach. Jeep safaris during winter are also wonderful. Book an open-top jeep to explore the park's best areas and see Kaziranga's wildlife up close. Although jeep safaris can be a bit dusty, they are still enjoyable.</p>
                  <p>Summer is also a good time to visit Kaziranga, but the hot and humid weather might be uncomfortable. Monsoon is not a good time to visit Kaziranga, as the park receives heavy rains and often gets flooded by the Brahmaputra River. Both humans and wildlife come to a standstill during the monsoon. Therefore, it is best to avoid planning a <strong>Shillong Tour</strong> between June and the end of September.</p>

                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen2ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DISTANCES TO NOTE FOR <strong>SHILLONG KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DISTANCES TO NOTE FOR <strong>SHILLONG KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></h4>
                </div>
                {/* <br />
                  <div className="content-desktop">
                    <center><img src={sightseen4ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br/> */}
                <br />
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>PLACES</th>
                          <th>DISTANCE / TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Guwahati to Cherrapunjee Distance - By Road</td>
                          <td>147.4 Kms</td>
                        </tr>
                        <tr>
                          <td>Guwahati to Shillong Distance - By Road</td>
                          <td>98.2 km</td>
                        </tr>
                        <tr>
                          <td>Guwahati to Kaziranga Distance - By Road</td>
                          <td>193.1 Kms</td>
                        </tr>
                        <tr>
                          <td>Shillong to Dawki Distance - By Road</td>
                          <td>81.8 Kms</td>
                        </tr>
                        <tr>
                          <td>Shillong to Mawlynnong Distance - By Road</td>
                          <td>73 Kms</td>
                        </tr>
                        <tr>
                          <td>Shillong to Cherrapunjee Distance - By Road</td>
                          <td>54 Kms</td>
                        </tr>
                        <tr>
                          <td>Kaziranga to Cherrapunjee Distance - By Road</td>
                          <td>300.8 Kms</td>
                        </tr>
                        <tr>
                          <td>Kaziranga to Shillong Distance - By Road</td>
                          <td>252.2 Kms</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>PRICE RANGE FOR <strong>BOOKING KAZIRANGA JEEP SAFARI BOOKING</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PRICE RANGE FOR <strong>BOOKING KAZIRANGA JEEP SAFARI BOOKING</strong></h4>
                </div>

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>KAZIRANGA SAFARI ZONES</th>
                          <th>KOHORA</th>
                          <th>BAGORI</th>
                          <th>AGARATOLI (EASTERN ZONE)</th>
                          <th>BURAPAHAR</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>INDIANS</td>
                          <td>INR 4000</td>
                          <td>INR 4000</td>
                          <td>INR 4600</td>
                          <td>INR 5400</td>
                        </tr>
                        <tr>
                          <td>FOREIGNERS</td>
                          <td>INR 7700</td>
                          <td>INR 7700</td>
                          <td>INR 8600</td>
                          <td>INR 9700</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p><b>Note:</b></p>
                  <ul>
                    <li>•	Maximum 6 persons are allowed in Jeep safari.</li>
                    <li>•	Child below 4 years is complementary.</li>
                    <li>•	Child age above 4 years will be treated as an adult.</li>
                    <li>•	Safari duration is 2 hours.</li>
                  </ul>
                  <br />   <br />   <br />
                  <h4><strong>COST FOR KAZIRANGA ELEPHANT SAFARI </strong></h4>
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>CITIZEN</th>
                          <th>RANGES</th>
                          <th>RATE/HOUR</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>INDIANS</td>
                          <td>Western Range (Bagori)</td>
                          <td>INR 1500</td>
                        </tr>
                        <tr>
                          <td>FOREIGNERS</td>
                          <td>Central Range (Kohora)</td>
                          <td>INR 3500</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>TIPS FOR KAZIRANGA NATIONAL PARK JEEP SAFARI / ELEPHANT SAFARI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>TIPS FOR KAZIRANGA NATIONAL PARK JEEP SAFARI / ELEPHANT SAFARI</strong></h4>
                </div>

                <div className="card-body">
                  <p>Here are some simple tips you must remember for your Kaziranga National Park Safari :</p>
                  <ul>
                    <li><b>•	Bring ID:</b> Carry a valid ID like a passport, driver's license, or Aadhar card. You'll need to show it at the park entrance.</li><br />
                    <li><b>• Follow Rules:</b> Obey the park's rules, such as no littering, no smoking, and no weapons.</li><br />
                    <li><b>• Visit in the Right Season:</b> The best time to visit is from November to April when the weather is good and animals are easier to see. Avoid the monsoon season as the park is closed due to heavy rain.</li><br />
                    <li><b>• Wear Suitable Clothing:</b> Dress in comfortable, lightweight clothes in earthy colors like brown, green, or khaki. Wear comfortable shoes as you may need to walk during the Elephant Safari.</li><br />
                    <li><b>• Bring Essentials:</b> Pack sunscreen, mosquito repellent, a hat or cap, sunglasses, and a water bottle. Also, bring a camera with a zoom lens for capturing wildlife and scenery.</li><br />
                    <li><b>• Hire a Guide:</b> A local guide can enhance your experience by helping you spot animals and explaining the park's flora and fauna.</li><br />
                    <li><b>• Be Patient:</b> Wildlife sightings are not guaranteed, so be patient and respect the animals and their habitat without disturbing them.</li><br />
                  </ul>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>

              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                    <h2>
                        DAY WISE <strong><span>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR PACKAGE FROM GUWAHATI</span></strong>
                      </h2>
                      <h4>7 Nights 8 Days <strong>Kaziranga Shillong Tour Itinerary</strong></h4>
                      
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <h4>DAY 1 : GUWAHATI TO KAZIRANGA [APPROX. 4HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Kaziranga National Park is a UNESCO World Heritage Site located in the Indian state of Assam. It is renowned for its population of Indian one-horned rhinoceroses, which are one of the main attractions of the park. Welcome on arrival at Guwahati airport/ Railway station and transfer to Kaziranga National Park, the home of the “One Horned Rhinoceros”. Check-in at your hotel/Lodge/resort. Evening free for leisure.Overnight stay at Kaziranga Hotel.</p>
                          
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <h4>DAY 2 : JUNGLE SAFARI AT KAZIRANGA</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>Early morning explores the Western Zone of Kaziranga National Park on the back of an elephant. Apart from world endangered One-horned Rhinoceros, the Park sustains half the world’s population of genetically pure Wild Water Buffaloes, over 1000 Wild elephants, and perhaps the densest population of Royal Bengal Tigers anywhere. Kaziranga is also a bird watchers paradise and home to some 500 species of birds. The Crested Serpent Eagle, Pallas Fishing Eagle, Grey-headed Fishing Eagle, Swamp Partridge, Bar-headed goose, whistling Teal, Bengal Florican, Storks, Herons, and Pelicans are some of the species found here.</p>
                          <p>We will return to the resort for breakfast. Afternoon experience jeep safari through the Central Zone of the National Park. In the evening enjoy the cultural program in Orchid Park.Overnight stay at Kaziranga.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 3 : KAZIRANGA TO SHILLONG [APPROX. 5HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Early morning explores Western Zone of Kaziranga National Park on back of elephant. After breakfast check out from hotel and transfer to Shillong. Often called the ‘Scotland of the East’, Shillong has lots to discover from lakes to hills to the jaw-dropping waterfalls. En route, stop at Barapani Lake (Umiam Lake)  for some amazing view & water activities. On arrival at Shillong, if Time Permits visit Don Bosco Musuem, Wards lake, Cathedral church, Lady Hydari Park</p>
                          <p>check in to the hotel. Afternoon time free for personal activity.Overnight stay at Shillong. </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          <h4>DAY 4 : SHILLONG – JOWAI - SHILLONG</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, start for Jowai to visit India's most magical waterfall: </p>
                          <ul>
                            <li>● Krang Suri Waterfalls</li>
                            <li>● Laitlum Canyon</li>
                            <li>● Thadlaskein Lake</li>
                            <li>● Stone Bridge.</li>
                          </ul>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                          <p>After getting there, get drenched and swim in the natural pools. Evening returns & free for Leisure.<b> Overnight at Shillong Stay. </b></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          <h4>DAY 5 : SHILLONG - MAWLYNNONG - DAWKI – CHERRAPUNJEE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Morning after breakfast we will proceed to visit the cleanest village of Asia - Mawlynnong. You will also get a chance to explore the nearby villages and witness the amazing living root bridges. Later on, you will be transferred to the international border of Bangladesh, wherein you will be visiting Dawki Village. This village is known for having some of the cleanest rivers, a classic example of which is the enchanting Umngot River.</p>
                          <p>Later on, you will go to Cherrapunjee for a relaxing overnight stay.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          <h4>DAY 6 : CHERRAPUNJEE – NONGRIAT TREK DOUBLE DECKER BRIDGE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, we proceed for a full day trekking to the Double Decker Living Root Bridge at Nongriat Village. We have to trek down 3200 steps approximately each way to the Double Decker Living Root Bridge (7-hour trek). Evening returns to Cherrapunjee. </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          <h4>DAY 7 : CHERRAPUNJEE LOCAL SIGHTSEEING AND TRANSFER TO GUWAHATI [APPROX. 2HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Entire Day Cherrapunjee Local, Includes, Today post breakfast, you will proceed to Shillong. En-route visit </p>
                          <ul>
                            <li>●  Wei Sawdong Falls</li>
                            <li>●  Seven Sisters Waterfalls</li>
                            <li>●  Arwah Cave</li>
                            <li>●  Nohkalikai Falls</li>
                            <li>●  Mawsmai Cave</li>
                          </ul>
                          <br/>
                          <p><b>Overnight stay at Hotel Guwahati.</b></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          <h4>DAY 8 : DEPARTURE AT GUWAHATI AIRPORT</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After breakfast check out from the hotel and drop at Guwahati Airport as per timings. If time permits, visits to Basistha Ashram, Srimanta Sankaradeva Kalakshetra, ‘Jagran’ store for authentic Assamese silks & handicrafts, trip ends with memories.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              {/* inclusion End  */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP SIGHTSEEING PLACES TO EXPLORE IN <strong>KAZIRANGA SHILLONG TOUR PACKAGE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP SIGHTSEEING PLACES TO EXPLORE IN <strong>KAZIRANGA SHILLONG TOUR PACKAGE</strong></h4>
                </div>
                <div className="card-body">
                  <p>Here are some must-visit palces to explore during <strong>Kaziranga Shillong Cherrapunjee Tour Package from Guwahati</strong> :</p>
                  <br/>
                  <h4>KAZIRANGA </h4>
                  <ul>
                    <li><b>•	Kaziranga National Park:</b> Famous for its one-horned rhinoceroses and home to many endangered species.</li><br />
                    <li><b>• Kaziranga Orchid and Biodiversity Park:</b> A paradise for nature lovers with over 500 species of orchids and many rare plants.</li><br />
                    <li><b>• Panbari Reserve Forest:</b> Perfect for birdwatching and nature walks.</li><br />
                    <li><b>• Deoparbat Ruins:</b> Ancient ruins dating back to the 9th century, great for history enthusiasts.</li><br />
                    <li><b>• Hoollongapar Gibbon Sanctuary:</b> Home to the only ape species in India, the Hoolock Gibbon.</li><br />
                  </ul>
                  <br />
                  <h4>SHILLONG</h4>
                  <ul>
                    <li><b>•	Umiam Lake:</b> Popular for picnics, boating, and kayaking, surrounded by lush green hills.</li><br />
                    <li><b>• Shillong Peak:</b> The highest point in Shillong, offering breathtaking views of the city and hills.</li><br />
                    <li><b>• Ward's Lake:</b> A beautiful lake in the city center, perfect for relaxing strolls and boating.</li><br />
                    <li><b>• Don Bosco Museum:</b> Showcasing the culture and heritage of North East India.</li><br />
                    <li><b>• Elephant Falls:</b> A stunning waterfall cascading over 100 feet, a favorite among tourists.</li><br />
                  </ul>
                  <br />
                  <h4>CHERRAPUNJEE</h4>
                  <li><b>• Nohkalikai Falls:</b> One of the tallest waterfalls in India, ideal for nature lovers and adventure enthusiasts.</li><br />
                  <li><b>• Mawsmai Cave:</b> Limestone caves offering a unique underground experience.</li><br />
                  <li><b>• Seven Sisters Falls:</b> Named after the seven northeastern states, a mesmerizing waterfall for nature lovers.</li><br />
                  <li><b>• Thangkharang Park:</b> Offers stunning views of the Bangladesh plains and Khasi hills.</li><br />
                  <li><b>• Dainthlen Falls:</b> Surrounded by lush green forests, perfect for nature lovers and photographers.</li><br />
                  <p>These are some of the top sightseeing places to include in your itinerary for an unforgettable trip to <strong>Shillong Cherrapunjee Tour Package</strong>.</p>
                </div>
              </div>
              <br />  <br />  <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>OFFBEAT PLACES TO EXPLORE IN <strong>SHILLONG CHERRAPUNJEE PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>OFFBEAT PLACES TO EXPLORE IN <strong>SHILLONG CHERRAPUNJEE PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <p>Here are the must-visit spots in Kaziranga, Shillong, and Cherrapunjee for a memorable trip to Shillong Cherrapunjee Tour Package :</p><br/>
                  <h4>SHILLONG </h4>
                  <ul>
                    <li><b>• Mawphlang Sacred Forest:</b> A peaceful forest considered sacred by the Khasi tribe, great for exploring local culture and biodiversity.</li>      <br />
                    <li><b>• Laitlum Canyons:</b> Hidden canyons with panoramic views of hills and valleys.</li>      <br />
                    <li><b>• David Scott Trail:</b> A scenic trek through forests, waterfalls, and Khasi villages.</li>      <br />
                    <li><b>• Wah Kaba Falls:</b> A secluded waterfall, perfect for picnics and relaxation.</li>      <br />
                    <li><b>• Mawlynnong Village:</b> Known as the cleanest village in Asia, famous for eco-tourism and living root bridges.</li>      <br />
                  </ul>
                  <br />
                  <h4>KAZIRANGA </h4>
                  <ul>
                    <li><b>• Biswanath Chariali:</b> A small town near Kaziranga, known for tea gardens and wildlife sanctuaries.</li>      <br />
                    <li><b>• Pobitora Wildlife Sanctuary:</b> A lesser-known sanctuary home to the Indian one-horned rhinoceros, a great alternative to Kaziranga.</li>      <br />
                    <li><b>• Diphlu River Lodge:</b> An eco-friendly lodge near Kaziranga National Park, offering a unique wildlife experience.</li>      <br />
                    <li><b>• Kakochang Waterfall:</b> A picturesque waterfall near Kaziranga, perfect for a relaxing picnic.</li>      <br />
                    <li><b>• Gibbon Wildlife Sanctuary:</b> A lesser-known sanctuary home to the hoolock gibbon and other endangered species.</li><br />
                  </ul>
                  <br />
                  <h4>CHERRAPUNJEE </h4>
                  <li><b>Mawmluh Cave:</b> A lesser-known cave near Cherrapunjee, offering a unique underground experience.</li><br />
                  <li><b>Nongriat Village:</b> A remote village famous for its living root bridges and scenic trekking trails.</li><br />
                  <li><b>Mawkdok Dympep Valley:</b> A scenic valley between Cherrapunjee and Shillong, with stunning views of hills and valleys.</li><br />
                  <li><b>Nartiang Monoliths:</b> Ancient monoliths from the 15th century, a must-visit for history buffs.</li><br />
                  <li><b>Rainbow Falls:</b> A beautiful waterfall named for the rainbows that form over it, perfect for nature lovers and photographers.</li><br />
                </div>
              </div>
              <br /><br />


              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <strong>Frequently Ask <span className="tomato">Questions</span></strong>
                      </h3>
                      <h4><strong>GUWAHATI SHILLONG CHERRAPUNJEE KAZIRANGA PACKAGE</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the Bihu Festival in North East Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Bihu is a famous festival in Assam, celebrated every year on April 14th to welcome the Assamese New Year, called Bohag Bihu. The festival features music, dance, local food, and drinks. It's celebrated across Assam and nearby states like Meghalaya and Arunachal Pradesh. The celebration lasts for about a month, with traditional Bihu dance performances held in various parts of the state.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How to reach the Single-Decker Living Root Bridge in Riwai?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The Single-Decker Living Root Bridge in Riwai is spectacular. To get there, take the Shillong-Mawlynnong road, about 75 km and a 2.5-hour drive from Shillong. After reaching Mawlynnong, trek 1 km through the forest to reach the bridge. You can stay in local homestays and enjoy the serene environment. Remember to pack essentials like a backpack, warm clothes, a raincoat, mosquito repellent, a torch, and a power bank.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Is Kaziranga National Park worth visiting?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, Kaziranga National Park is a UNESCO World Heritage site and is famous for its one-horned rhinoceroses. An elephant safari offers a close view of various animals like water buffalo, wild hogs, deer, wild elephants, and many bird species. It's a must-visit for wildlife enthusiasts.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          What food should I try during my Meghalaya Trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try local Assamese dishes like Laksa, Khar, Tenga, and Mati Dal. Popular dining spots include Anurag Dhaba on the way to Kaziranga, Punjab Da Dhaba on the way to Meghalaya, and street food at Police Bazaar in Shillong. In Cherrapunjee, try Jiva Veg Restaurant or Orange Roots Restaurant.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What is the best family dining place in Kaziranga Tour Plan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try the Brahmaputra River Cruise for a unique dining experience. Book in advance or ask your tour operator to arrange it. Other options include Naga Kitchen and Paradise, or street food in Paltan Bazar for authentic Assamese cuisine.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          How can I book a car for a full day Mawlynnong, Dawki Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can book a car from Guwahati to Shillong and from Shillong to Mawlynnong and Dawki. For a hassle-free experience, book through a specialized Northeast India tour operator.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Are there any government transport facilities for Shillong sightseeing?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, you can book through MTDC (Meghalaya Tourism Development Corporation), which offers coach services for sightseeing. Contact their Tourist Information Centre at Police Bazaar, Ph: 0364-2226220.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          When is the best time to visit Nohkalikai Falls?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to visit Nohkalikai Falls is during the monsoon (May to September) for the fullest view of the falls or from October to January for trekking and enjoying the scenery.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          How many Root Bridges are found in Meghalaya?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Meghalaya has about 11 living root bridges, including the famous Double Decker Root Bridge in Cherrapunjee, Ummunoi Root Bridge, Ritymmen Root Bridge, Mawsaw Root Bridge, and Mawlynnong Single-Decker Root Bridge.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          What are the best things to do in Cherrapunjee?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>• Capture the scenic beauty<br />
                            • Bird watching<br />
                            • Walks through beautiful streets<br />
                            • Shopping for handmade and bamboo items<br />
                            • Enjoy local delicacies<br />
                            • Hike the Double Decker Root Bridge</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          What food should I try in Cherrapunjee during the Kaziranga Shillong Cherrapunjee Tour Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try local dishes like Pork Rice, Sohra Pulao, Pork Momos, various pork delicacies, Thukpa, Rice Beer, and Jadoh (rice cooked in pork blood).</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Do we need a permit to visit Cherrapunjee?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>No, Indian citizens do not need a permit, but you must carry a valid ID card.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          What kind of clothes should we pack for a North East Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>In summer, bring comfortable clothes and rain gear (raincoat, umbrella, rain shoes). In winter, pack heavy woolen garments as it gets very cold.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          How can I offer Puja at Kamakhya Temple avoiding rush?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Get a VIP Pass for Rs. 500 per person from the counter, which opens at 7:00 A.M. This will reduce waiting time, and you can wait in the VIP room until it's your turn.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          What food should I taste on a Shillong trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try the local pineapple and corn. Enjoy street food at Police Bazaar, including barbeque chicken, fish, pork, momos, and more.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Can I enjoy solo kayaking in Umiam Lake and Dawki River?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, solo kayaking is available. You can book tickets yourself. Dawki River offers two types of boat services: government and private, costing around Rs. 6,000 per boat for one hour.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeventeen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeventeen"
                        >
                          Can I enter Bangladesh via Dawki border at Shillong?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeventeen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeventeen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>No, crossing the border is prohibited and monitored closely by the BSF.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEighteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEighteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseEighteen"
                        >
                          What is the best festive season to visit Assam Meghalaya Trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEighteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEighteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to visit Assam is from October to mid-March and Meghalaya from October to May. Enjoy festivals like:</p>
                          <p>• Khasi Festival in April<br />
                            • Jaintia Festival in July<br />
                            • Garo Festival in November<br />
                            • Autumn Festival in October</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Kaziranga Shillong Cherrapunjee Package Tour <span className="tomato"> Reviews</span>
                        </b>
                      </h3>
                      <p>
                        <i>"Your Happiness is our Priority"</i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-96747-38480">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div >
      </>
    );
  }
}
export default KazirangaShillongMeghalayaTour_7N_8D_Package;
