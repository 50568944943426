import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import Bhutan_Nashik_6N_7D_Package from '../../../components/package/Bhutan/Bhutan_Nashik_6N_7D_Package';
import { Helmet } from "react-helmet";
class Bhutan_Nashik_6N_7D_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "29,900/-",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "35,100/-",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            },
            {
                Title: "Explore Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "40,200/-",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            },
            {
                Title: "Excape Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "48,200/-",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            }

        ];

        const reviewersDataList = [
            {
                Name: "Krishna K",
                ImageName: "Krishna-K.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "Our Bhutan trip from Bagdogra was organised by Soumalya , Rewa Addorable LLP. Simply great and exotic hotels. Stay was very luxurious, food was great. Traveller bus was very smooth and luxurious. No issues with anything. The team was constantly in touch with us during the tour on WhatsApp to check everything is fine. They are at their best to provide best services to their guests. Very cooperative staff. Our guide Mr Harka and Mr Donzy were so friendly and awesome guys. Mr Soumalya was prompt , approchable , and provided a great deal and comfortable plan for us Once again would like to thank personally Mr Soumalya & their team members for arranging the best tour of Bhutan Will definitely recommend them to everyone for a memorable trip.",
            },
            {
                Name: "Sumit Mahata",
                ImageName: "sumit_meheta.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "What an awesome trip! It helps that Bhutan is probably the most beautiful place on the planet,but more importantly, our travel agency arranged our access to the country’s wonders so seamlessly. All thanks to Soumlya , sneheta and sonali, Keep up the good work.",
            },
            {
                Name: "Ramesh Nair",
                ImageName: "ramesh-nair.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "My wife and I traveled to Bhutan from 7th October to 12th October and the trip was arranged by Adorable Vacation. We are pretty satisfied with the arrangements and will recommend Adorable Vacation to people who are planning on travelling to Bhutan.The fact that we had a great team made our experience even more memorable.",
            },
            {
                Name: "Ruchir Mehta",
                ImageName: "ruchir_mahata.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Had the most comfortable stay in Bhutan, thanks to adorable vacation LLP. Our guide Mr.Gyeltshen and our driver were extremely helpful and nice. They were always ready to help us out with everything. I would highly recommend Adorable Vacation LLP to everyone and would love to book our next trip with them too. Thank you team for taking care of us",
            },
        ]


        this.setState({
            HotPackagesData: hotPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
       
        return (
            <>
                <Helmet>
                    <title>Bhutan package tour from Nashik, Bhutan Tour Packages from Nashik </title>
                    <meta name="description" content="Book amazing Bhutan package tour from Nashik at the most Affordable Price. FOR BOOKING ☎ CALL +91-98833-59713" />
                   
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Bhutan Package Tour from Nashik Reviews",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.8",
                                "bestRating": "5",
                                "ratingCount": "352"
                            }
                        })}
                    </script>
                </Helmet>
                <MainHeader />
                <Bhutan_Nashik_6N_7D_Package
                    HotPackagesData={this.state.HotPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
                <div>
                    <h3 className="dropdown-item2">Bhutan Package Tour From Nashik Reviews</h3>
                    <div>
                        <span className="dropdown-item2">4.8</span> <span className="dropdown-item2">Stars Rating, out of</span> <span className="dropdown-item2">5</span><br />
                        <span className="dropdown-item2">based on</span> <span className="dropdown-item2">318</span> <span className="dropdown-item2">Reviews</span>
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_Nashik_6N_7D_Page;