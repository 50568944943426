import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import ArunachalTourGuwahati_15N_16D_Package from '../../../components/package/Arunachal_Pradesh/ArunachalTourGuwahati_15N_16D_Package';
import { Helmet } from 'react-helmet';

class ArunachalTourGuwahati_15N_16D_Page extends Component {
    constructor() {
        super();
        this.state = {
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const reviewersDataList = [
            {
                Name: "Ashutosh Mukherjee",
                ImageName: "Ashutosh-Mukherjee.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We had a great trip to Arunachal and North East just after Covid pendemic. Everything was as per itinerary. Also they shown us some places which was not included in the itinerary. The give there 100% to satisfy there clients, Special thanks to Mr Noorul and Ms Sneheta for prompt responses.Will definitely recommend them if you are visiting Northeast India. Kudos team Adorable.",
            },
            {
                Name: "Aloke Pandey",
                ImageName: "Aloke-Pandey.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Nice experience, Highly professional team and services are the best, Would like to recommend every person who wish to travel North East (India). Thank you team Adorable for making our trip memorable and wonderful.",
            },
            {
                Name: "Arunlal Modi",
                ImageName: "Arun-Lal-Modi.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "It was a wonderful experience for us … Thanks to Adorable Vacation especially to Mr Akash who managed the entire tour fabulously.. He is highly professional and his way of handling things is just next level..I have highest recommendation for this company and would prefer them for my future trips to Northeast.",
            },
            {
                Name: "Jagdeep Singh",
                ImageName: "Jagdeep-SIngh.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "I recently went on a trip to Arunachal Pradesh Tawang Special 6N 7D Private Package.The complete management was done by Mr Soumya....He is highly professional. We had an amazing experience. Would highly recommend this company if you are planning to visit Northeastern part of India... Keep up the good work team..",
            },
        ]

        this.setState({
            ReviewersData: reviewersDataList,
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Arunachal Pradesh package tour from Guwahati, Arunachal Pradesh tour package from Guwahati</title>
                    <meta name="description" content="Explore the majestic landscapes of Arunachal with our exclusive Arunachal Pradesh package tour from Guwahati. FOR BOOKING ☎ CALL +91-96747-38480"/>
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Arunachal Pradesh Package Tour from Guwahati Reviews",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.8",
                                "bestRating": "5",
                                "ratingCount": "352"
                            }
                        })}
                    </script>
                </Helmet>

                <MainHeader />

                <ArunachalTourGuwahati_15N_16D_Package
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
                <div>
                    <h3 className="dropdown-item2">Arunachal Pradesh package tour from Guwahati Reviews</h3>
                    <div>
                        <span className="dropdown-item2">4.8</span> <span className="dropdown-item2">Stars Rating, out of</span> <span className="dropdown-item2">5</span><br />
                        <span className="dropdown-item2">based on</span> <span className="dropdown-item2">352</span> <span className="dropdown-item2">Reviews</span>
                    </div>
                </div>
            </>
        );
    }
}

export default ArunachalTourGuwahati_15N_16D_Page;