import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_MeghalayaShillongCherrapunjee_7n_8d";

class Meghalaya_WariChora_Tura_7N_8D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/GUWAHATI.webp";

    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Meghalaya_WariChora_Tura_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Meghalaya Package Tour with Wari Chora, Tura, South Garo Hills</strong> - BEST DEAL | BOOK NOW!</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Meghalaya Package Tour with Wari Chora, Tura, South Garo Hills</strong> - BEST DEAL | BOOK NOW!</center>
                      <hr />
                    </h3>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> Looking for <strong>Meghalaya Tour Package with Wari Chora, Tura, South Garo Hills</strong>? <a href="tel:+91-9674738480">FOR BOOKING CALL +91-96747-38480</a><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Meghalaya Tour Package with Wari Chora, Tura, South Garo Hills</strong>? <a href="tel:+91-9674738480">FOR BOOKING CALL +91-96747-38480</a><br /></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Embark on an Enchanting Journey with <strong>Meghalaya Package Tour with South Garo Hills</strong></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Embark on an Enchanting Journey with <strong>Meghalaya Package Tour with South Garo Hills</strong></h4>
                    </div>
                    <div className="card-body">
                      <p>Meghalaya, often called the <b>Abode of the Clouds</b>, is a haven for nature enthusiasts. As one of the Seven Sisters of North East India, this charming state is renowned for its unspoiled landscapes, verdant meadows, magnificent waterfalls, pristine rivers, captivating limestone caves, dense and majestic forests, picturesque lakes, world-famous living root bridges, and the cleanest village, all enveloped in unadulterated natural splendor.<br /><br />
                        If you're a city dweller weary of the monotony of daily life, longing to escape the stress, workload, and urban hustle and bustle, Meghalaya is the perfect destination for rejuvenation and relaxation. During your <strong>Meghalaya Package Tour with Garo Hills,</strong> you'll discover complete peace of mind, surrounded by pure nature brimming with oxygen and freshness. Travel through this exquisite land, engage with the local people, immerse yourself in their culture, explore enchanting lakes, cascading waterfalls, living root bridges, trek the rolling hills, engage in a game of hide and seek with the clouds, and embrace the rain in one of the wettest places on Earth. Every nook and cranny of this beautifully adorned state is unique and offers its splendid beauty to travelers.<br /><br />
                        So, don't get late, pack your bag and book a <a href="/shillong-meghalaya-package-tour-from-guwahati" target="_blank"><strong>Shillong Meghalaya Tour Package</strong></a> with Cherapunji and <strong>Mawsynram Tour</strong> from Adorable - The specialist for <a href="/north-east-india-package-tour-from-guwahati-airport" target="_blank"><strong>North East Package Tour from Guwahati</strong></a>.<br /><br /></p>

                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />

                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong> Duration : 7 Nights | 8 Days</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong> DURATION : 7N | 8D </h4>
                </div>
                <div className="card-body">
                  <p>Places to cover in <strong>Meghalaya Package Tour With Garo Hills</strong> : Tura [2N] | Shillong [2N] | Cherrapunjee [2N] | Guwahati [1N]<br /><br /></p>
                  <p><b>Location of Tura</b> : Tura, situated in Meghalaya's West Garo Hills district, offers a tranquil escape to nature enthusiasts. At an elevation of approximately 657 meters (2,156 feet), it provides a serene retreat amidst pristine landscapes and lush greenery. Engaging with the local culture, exploring waterfalls, living root bridges, and scenic hills, and enjoying the region's natural beauty are highlights of a visit to Tura. It's a perfect destination for relaxation and rejuvenation in a peaceful environment.<br /><br /></p>
                  <p><b>Location of Cherapunjee</b> : 1,430 m | Coordinates - 25.2702&#176; N, 91.7323&#176; E <br /></p>
                  <p><b>Location of Mawsynram </b> : 1,400 m | Coordinates - 25.2975&#176; N, 91.5826&#176; E <br /></p>
                  <p><b>Pickup & Drop</b> : Lokpriya Gopinath Bordoloi International Airport, Guwahati [GAU] / Guwahati Railway Station <br /><br /><br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            Summary of <strong>Meghalaya Package Tour With Garo Hills</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                GUWAHATI TO TURA [216 KM / 5 HRS]
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>DAY EXCURSION TO WARI CHORA [3 HRS APPROX]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>TURA TO SHILLONG [303 KM / 7 HRS APPROX]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>EXCURSION TOWARDS MAWSYNRAM [THE WETTEST PLACE ON EARTH]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span> DAY EXCURSION TRIP TO MAWLYNNONG AND DAWKI & TRANSFER TO CHERRAPUNJEE</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span> CHERRAPUNJEE LOCAL SIGHTSEEING</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>CHERRAPUNJEE TO GUWAHATI [5 HRS]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span> CHERISH YOUR MEMORIES</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> How is the weather in Shillong ?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>How is the weather in Shillong ?</h4>
                </div>
                <div className="card-body">
                  <p>Shillong,enjoys a subtropical highland climate akin to an oceanic climate. It experiences cool, rainy summers and cold, dry winters. The following table provides a month-wise overview of Shillong's weather conditions.<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Travel Month</b></th><th><b>Min / Max Temperature</b></th><th><b>Season</b></th></tr></thead>
                      <tbody><tr><td>March to June</td><td>12&#176; - 24&#176;C</td><td>Pleasant</td></tr>
                        <tr><td>July to September</td><td>10&#176; - 22&#176;C</td><td>Rainy Season</td></tr>
                        <tr><td>October to February</td><td>5&#176; - 14&#176;C</td><td>Cold</td></tr>
                      </tbody></table>
                  </div>
                  <br />
                  <br />
                  <h4 style={{ color: 'tomato' }}><b>How is the weather in Cherrapunjee?</b></h4>
                  <br />
                  <br />
                  <p>Cherrapunjee, renowned for its year-round rainfall, maintains a pleasantly cool and muggy climate due to constant cloud cover, making it an attractive destination throughout the year. August stands out as the warmest and wettest month, while January tends to be the coolest. The table below provides a glimpse of Cherrapunjee's weather patterns.<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Travel Month</b></th><th><b>Min / Max Temperature</b></th><th><b>Season</b></th></tr></thead>
                      <tbody><tr><td>March to June</td><td>15&#176; - 25&#176;C</td><td>Warm but Pleasant</td></tr>
                        <tr><td>July to September</td><td>18&#176; - 28&#176;C</td><td>Warm and Wet</td></tr>
                        <tr><td>October to February</td><td>8&#176; - 15&#176;C</td><td>Cold & Pleasant</td></tr>
                      </tbody></table>
                  </div>
                  <br /><br />
                  <h4 style={{ color: 'tomato' }}><b>How to reach Shillong ?</b></h4>
                  <br /><br />
                  <p>Shillong can be reached by different ways. The nearest airport to reach Shillong is by landing at Guwahati Airport [GAU]. Daily lots of Domestic flights from all over India operates with few International flights landing at the airport. Guests also can travel to Shillong city by Landing at the Shillong Airport [SHL]. It is almost 1 hrs drive but often can lead to more time due to heavy traffic. More over Shillong also can be reached by Silchar Airport [IXS] of Assam. Apart from flight, guests also can travel to Shillong from Guwahati Station which is almost 100km drive for 3 hrs. Lets have a look at tabular data :<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>From</b></th><th><b>To</b></th><th><b>Distance/Time</b></th></tr></thead>
                      <tbody><tr><td>Guwahati Airport</td><td>Shillong</td><td>2 hr 59 min (115.1 km) via NH6</td></tr>
                        <tr><td>Shillong Airport</td><td>Shillong</td><td>55 min (30.9 km) via Shillong Bypass Rd and NH6</td></tr>
                        <tr><td>Silchar Airport</td><td>Shillong</td><td>4 hr 18 min (235.8 km) via NH6</td></tr>
                        <tr><td>Guwahati Station</td><td>Shillong</td><td>2 hr 27 min (96.3 km) via NH6</td></tr></tbody>
                    </table>
                  </div>
                  <br /><br />
                  <div className="content-desktop">
                    <center><img src={sightseen2ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> How to reach for Meghalaya Package Tour With Garo Hills ?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> How to reach for Meghalaya Package Tour With Garo Hills ?</h4>
                </div>
                <div className="card-body">
                  <p>Tura is conveniently accessible via air travel. The nearest airport Guwahati Airport (GAU) in Assam, which is much closer to Tura, with a distance of about 200 kilometers between them. This air route provides a convenient and efficient way to reach Tura for travelers. <br /><br /><br /></p>
                  <h4 style={{ color: 'tomato' }}>Places of Attractions in <strong>Meghalaya Package Tour With Garo Hills</strong></h4><br /><br />
                  <p>There are lots of places of attractions like - Pelga Falls, Wari Chora, Umiam Lake, Mawsynram, Elephanta Falls, Shillong Peak, Mawphlang Sacred Forest, Mawjymbuin Cave, Cathedral Catholic Church, Krang Suri Falls, Thadlaskein Lake, Stone Bridge, Nartiang Temple, Mawlynnong, Umngot River, Seven Sisters Falls etc.<br /><br /></p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DISTANCES TO REMEMBER IN <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY DISTANCES TO REMEMBER IN <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr><th><b>Places</b></th><th><b>Distance/Time</b></th></tr></thead>
                      <tbody><tr><td>Guwahati to Tura</td><td>4 hr 44 min (199 km) via NH 17 and NH217</td></tr>
                        <tr><td>Tura to Wari Chora</td><td>5 hr(144 km) via Paikan - Tura - Dalu Rd and NH217</td></tr>
                        <tr><td>Tura to Shillong</td><td>7 hr 40 min (303 km) via NH 127B</td></tr>
                        <tr><td>Sillong to Umiam Lake</td><td>37 min (16.5 km) via NH6</td></tr>
                        <tr><td>Shillong to Elephanta Falls</td><td>38 min (12.5 km) via NH106</td></tr>
                        <tr><td>Shillong to Shillong Peak</td><td>46 min (10.6 km) via NH6</td></tr>
                        <tr><td>Shillong to Mawlynnong</td><td>2 hr 35 min (73.0 km) via NH206</td></tr>
                        <tr><td>Shillong to Dawki</td><td>2 hr 43 min (81.8 km) via NH206</td></tr>
                        <tr><td>Mawlynnong to Dawki</td><td>1 hr 7 min (29.4 km) via NH206</td></tr>
                        <tr><td>Cherrapunjee to Nohkalikai Falls</td><td>17 min (7.1 km) via Sohra-Shella Rd</td></tr>
                        <tr><td>Cherrapunjee to Mawsmai Cave</td><td>10 min (3.6 km) via Sohra-Shella Rd</td></tr>
                        <tr><td>Cherrapunjee to Guwahati</td><td>4 hr 44 min (166 km) via SH3</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT ARE THE TOP ATTRACTIONS IN <strong>MEGHALAYA PACKAGE TOUR</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE TOP ATTRACTIONS IN <strong>MEGHALAYA PACKAGE TOUR</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Obviously, North East India is a mystry land and travelling this mystic paradise is always amazing. During <strong>Meghalaya Package Tour From Guwahati</strong> travellers must visit the following places :</p>
                  <ul><li><b>Shillong</b> : The Scotland of the East <br /><br /></li>
                    <li><b>Cherrapunji</b> : Known as Sohra or Churra or "The Land of Oranges" <br /><br /></li>
                    <li><b>Mawsynram</b> : One of the wettest places on the Earth with deep caves and waterfalls <br /><br /></li>
                    <li><b>Jowai</b> : Known as the Heart of Meghalaya <br /><br /></li>
                    <li><b>Tura</b> : A heaven for Adventure Lovers <br /><br /></li>
                    <li><b>Mawlynnong</b> : The Cleanest Village in India <br /><br /></li>
                    <li><b>Dawki</b> : The Stunning Crystal Clear River Waters <br /><br /></li>
                    <li><b>Garden of Caves</b> : A garden with unique rock formations and caves.<br /><br /></li>
                    <li><b>Seven Sisters Falls</b> : A group of seven waterfalls, a natural wonder. <br /><br /></li>
                    <li><b>Dinner Cruise on Brahmaputra</b> : An evening cruise on the mighty Brahmaputra River. <br /><br /></li>
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT ARE THE BEST THINGS TO DO IN <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHAT ARE THE BEST THINGS TO DO IN <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong>?</h4>
                </div>
                <div className="card-body">
                  <ul><li>Capturing the exquisite beauty of Shillong in the lenses <br /><br /></li>
                    <li>Birding or Bird Watching <br /><br /></li>
                    <li>Talking walks across the beautiful streets of Shillong <br /><br /></li>
                    <li>Shopping of handmade items at Shillong <br /><br /></li>
                    <li>Water sports like yachting, angling, and water skiing at Umiam Lake <br /><br /></li>
                    <li>Boating and Waterfall Rappelling at Umiam Lake <br /><br /></li>
                    <li>Tantalizing the taste buds with the loca
                      l delicacies of Shillong <br /><br /></li>
                    <li>Experience Double Decker Living Root Bridge Trail <br /><br /></li>
                    <li>Mawlongbna Village Trekking <br /><br /></li>
                    <li>Dawki River bed Trekking & Boating <br /><br /></li>
                    <li>Trekking David Scott's Trail <br /><br /></li>
                    <li>Krem Mawsmai, Krem Mawmluh, and Krem Umshyrpi Caves Trail <br /><br /></li>
                    <li>Enjoying the buzzing nightlife of Shillong <br /><br /></li>
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT ARE THE TOP THINGS TO KEEP IN MIND WHILE BOOKING <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY WHAT ARE THE TOP THINGS TO KEEP IN MIND WHILE BOOKING <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong>?</h4>
                </div>
                <div className="card-body">
                  <ul><li>Raincoat/Umbrella is a must to pack for <strong>Mawsynram Tour</strong> as it rains a lot there. <br /><br /></li>
                    <li>Booking a <strong>Shillong Tour</strong> or <strong>Shillong Cherrapunji Mawsynram Package Tour</strong> in monsoon is great choice if you want to experience the rain with beautiful ambience and waterfalls. <br /><br /></li>
                    <li>Adorable advice you to start any sightseeing tour early morning so that you can cover most of the attractions. <br /><br /></li>
                    <li>Carry valuable belongings (money, credit card, camera, mobile) with you during <strong>Shillong Cherrapunji Sightseeing Tour</strong>. <br /><br /></li>
                    <li>Like all hill areas, Shillong also shut down within 8 to 9pm. So complete your dinner before that time. <br /><br /></li>
                    <li>Please carry your photo identification like Passport, Voter ID Card or Driving License in original. <br /><br /></li>
                    <li>Don't forget to try local delicacy in <strong>Shillong Meghalaya Tour</strong>. <br /><br /></li>
                    <li>While on the way to Shillong, don't forget to taste fresh Pineapple from road side local vendors. It's taste is amazing. <br /><br /></li>
                    <li>Always carry bottled or packaged drinking water in hotel and sightseeing tour. <br /><br /></li>
                    <li>Don't forget to carry your id cards for Shillong Peak tour as the area belongs to armed forces. <br /><br /></li>
                    <li>Don't forget to pack power bank for mobile. <br /><br /></li>
                    <li>Pack important medicine and first aid box in your travel bag before start your <em>Shillong Meghalaya Cherapunji Tour</em>. <br /><br /></li>
                    <li>Carry a torch, candle and lighter for emergency purpose. <br /><br /></li>
                    <li>Try to carry at least two different mobile connections during your <strong>Meghalaya Trip</strong>. <br /><br /></li>
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> IS MONSOON A GOOD TIME TO BOOK A <strong>SHILLONG PACKAGE TOUR</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IS MONSOON A GOOD TIME TO BOOK A <strong>SHILLONG PACKAGE TOUR</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Certainly! Yes, indeed. Experiencing the monsoon in Cherrapunji and Mawsinram is a unique and unparalleled experience. Mawsinram, holding the Guinness World Record for the highest annual rainfall - approximately 11,871mm, creates a distinct monsoon atmosphere that you'll want to be a part of.<br /><br />
                    In monsoon Cherapunji and Mawsynrum has its own distinctive charm that you cannot behold in other season. During monsoon it turns into a paradise with fresh lush greenery, lakes with full brink, and live gorgeous waterfalls. It is best time for honeymooners, adventure lovers and for those who love rain, specially want to watch raining sitting by the window for whole day. Nothing can compare to the feeling that comes from sipping a cup of warm tea or coffee while listening to the songs of rains as rainfall in Mawsynram is deafeningly loud. After all rain has its own beauty and brings life in to active mode. So do the travellers. If you want to explore rain washed Shillong Cherapunji and Mawsynrum, book your <strong>Shillong Cherrapunjee Monsoon Package Tour</strong> at discounted price from Adorable Vacation between July to September. Believe me, it will be an amazing experience.<br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY <strong>MAWSYNRAM TOUR</strong> IS REALLY WORTH IN MONSOON?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY <strong>MAWSYNRAM TOUR</strong> IS REALLY WORTH IN MONSOON ?</h4>
                </div>
                <div className="card-body">
                  <p>Famously known as the wettest place on Earth, Mawsynram remains favorites among many who love to see the refreshing, green nature and the magical landscapes during the monsoon. Despite heavy rainfall, Mawsynram still manages to see a good amount of footfall of them who crave to spend some lone time with nature while cherishing the monsoon fantasy. Indeed, Mawsynram - the rain destination is an ideal spot to see the beauty of rain while getting wet in the fresh shower. During monsoon, the weather remains pleasant and perfect for the couple to get cozy while enjoying the rain with a cup of hot tea and pakoras. The drenched destination is not only for the happy lovers but also for the singles and the lazy fellas, who love to lie on the warm bed under a blanket and hear the beautiful sound of rain as it falls on windows and creates a musical affair. visiting Mawsynram and see the beauty of monsoon is immerging as a whole new concept with good popularity over time, while an increase in the number of visitors every year apart from the fact that monsoon travel might bring you some added discounts on your <strong>Mawsynram Monsoon Spl Package Tour</strong>. As the rain pours over Mawsynram, the destination becomes alive with clean, lush green nature which is extremely soothing to the eyes and helps to calm and rejuvenate your soul amidst the blooming wild, far from the noisy cities. We all have childhood memories related to rain and Mawsynram is the best place to revive down the memory lane while listening to some old classics like - <br /><br />
                    <b>"Rim-Jhim Gire Saavan, <br />
                      Sulag Sulag Jaae Mann, <br />
                      Bheege Aaj Is Mausam Mein, <br />
                      Lagi Kaisi Ye Agan"</b> - by Kishore Kumar <br /><br />
                    So, all the monsoon lovers out there plan your <strong>North East India Monsoon Holiday Package Tour</strong> in this wettest part of India and enjoy the serenity and sound of nature from a tiring everyday routine while relaxing by the window side of your room and getting intoxicated with the smell of fresh mud blended with cool breeze after every shower. <br /><br /></p>
                </div>
              </div>
              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE DETAIL <strong style={{ color: 'tomato' }}>FASCINATING GARO HILLS WITH MEGHALAYA TOUR PACKAGE</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE DETAIL <strong>FASCINATING GARO HILLS WITH MEGHALAYA TOUR PACKAGE</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : GUWAHATI-TURA [216 KM / 5 HRS]
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Arrival at Guwahati Airport. Pickup and Drive towards Tura. Tura is a municipality in the West Garo Hills district of the Indian state of Meghalaya. One of the largest towns in Meghalaya, Tura is located in the foothills of the Tura range of the Garo Hills. It is 216 kilometers from the nearest city Guwahati and is also the district headquarters of the West Garo Hills District.<br /><br />
                            Proceed for the local sightseeing visit to Pelga Falls. Overnight at Tura.<br /><br />
                          </p>
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : DAY EXCURSION TO WARI CHORA [3 HRS APPROX]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, proceed towards Wari Chora. Wari Chora is a small village located in the Meghalaya Garo hills. Warichora’s Warri means a deep river. Garo Hills is home to the Garo people, known for their unique culture and traditions. They have a rich history and a deep connection to the land, and their way of life revolves around nature.<br /><br />
                            River rafting in this tranquil river is one of the best things you can ever experience. The river water is blue. There are various caves present over there, so it might get a little tricky. Still, the sound of water, waterfalls, lush green forests, and the utmost calmness of Wari Chora make it an ideal destination to visit. They also have boating options but most people prefer river rafting. Overnight at Tura.<br /><br />
                          </p>
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : TURA TO SHILLONG [303 KM / 7 HRS APPROX]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>After early morning breakfast, proceed towards Shillong. Shillong, also known as ‘The Scotland of the East’ is the capital city of Meghalaya. You can visit Shillong, the largest manmade lake in Northeast India –Umium Lake. Reaching Shillong. Overnight Stay at Shillong.<br /><br />
                          </p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : EXCURSION TOWARDS MAWSYNRAM [THE WETTEST PLACE ON EARTH] OR EXCURSION TRIP TO JOWAI [5 HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After a breakfast drive towards Mawsynram, Enroute visits Elephanta Falls & Shillong Peak (Depending on Army Permission) situated on the outskirts of the city. Visit Mawphlang Sacred Forest, Mawjymbuin Cave (Mawsynram Cave).<br /><br />
                            After sightseeing in the evening back to Shillong, visit the Cathedral Catholic Church - In the heart of the city, there stands a beautiful cathedral of Roman Catholics dedicated to Mary's Help of Christians.<br /><br />
                            or<br /><br />
                            The morning after breakfast, proceed for a day excursion trip to Jowai, eastern Meghalaya - Jaintia Hills. Explore the Krang Suri Falls, Thadlaskein Lake, Stone Bridge, and Nartiang Temple (one of the Peetam). Also, enjoy your route which is very beautiful & scenic.<br /><br />
                            Afternoon drives back to Shillong via Laitlum- Laitlum Canyon is one of the beautiful destinations with its green lush hills and experiencing life above the clouds. Overnight stay at Shillong.<br /><br />
                          </p>
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : DAY EXCURSION TRIP TO MAWLYNNONG AND DAWKI & TRANSFER TO CHERRAPUNJEE
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Wake up early and have your breakfast and drive to Mawlynnong. Today you can explore the cleanest village Mawlynnong in the world. You can take the skywalk. Spectacular view of the living Root Bridge.<br /><br />
                            After lunch, drive to Dawki. Dawki is the gateway to Bangladesh where major export and import transactions take place. It is a splendid drive of 45 kilometers passing through deep gorges and ravines, which is certainly a thrilling experience. On approaching Dawki is the Umngot River, The Umngot River is the natural boundary between Ri Pnar or Jaintia Hills with Hima Khyrim of Khasi Hills over which a single-span suspension bridge was constructed. Evening drive to Cherrapunjee. Overnight Stay at Cherrapunjee.<br /><br />
                          </p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : CHERRAPUNJEE LOCAL SIGHTSEEING TOUR [BEST FOR MONSOON TIME]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, proceed to Visit Arwah Caves, Nohkalikai Falls, Wakaba Falls, Garden of Caves, Mawsmai Caves, Eco Park, Seven Sisters Falls, Ramakrishna Mission, and the Thangkharang Park. Overnight stay in Cherrapunjee.<br /><br />
                          </p>
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          DAY 7 : CHERRAPUNJEE TO GUWAHATI [5 HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, proceed towards Guwahati, then visit Sankardeva Kalakhetra, and 'Jagran' - the shop of Assamese handicrafts & culture.<br /><br />
                            Evening you can experience the Dinner Cruise on the mighty Brahmaputra River (Included). Overnight Stay at Guwahati.<br /><br /><br />
                          </p>
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          DAY 8 : CHERISH YOUR MEMORIES
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>In the morning after breakfast check out from your hotel drive to Kamakhya Temple for the darshan of Maa Kamakhya & then transfer to Guwahati airport with sweet memories & cherish your memories forever <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong></h4>
                </div>
                <div className="card-body">
                  <h4>4 Star Hotels :</h4><br />
                  <p><b>Guwahati</b> - Gateway Grandeur</p>
                  <p><b>Shillong </b> - Hotel Polo Tower</p>
                  <p><b>Cherrpunjee</b> - Polo Orchid Resort<br /><br /></p>

                  <h4>3 Star Hotels :</h4><br />
                  <p>
                    <b>Guwahati</b> - Lilawati Grand</p>
                  <p><b>Shillong </b> - Centre Point, Blue Berry Inn, Eee Cee Hotel</p>
                  <p><b>Cherrpunjee</b> - Cherrapunjee Holiday Resort, Kutmadam Resort<br /><br /></p>

                  <h4>2 Star Hotels :</h4><br />
                  <p><b>Guwahati</b> - Hotel Siroy Liya, Hotel Siroy Classic</p>
                  <p><b>Shillong </b> - Hotel Barbareek,  Season Rate, J K Interanational</p>
                  <p><b>Kaziranga</b> - Wild Grass Lodge, Dhanshree Resort</p>
                </div>
              </div>
              {/* <!--end of package details--> */}
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4><strong>Meghalaya Package Tour With Garo Hills</strong> Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion" id="accordionFlushExample">

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading1">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse1"
                              aria-expanded="false"
                              aria-controls="flush-collapse1"
                            >
                              What is Wangala Festival?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse1"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading1"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              Wangala Festival, also known as the <strong>Hundred Drums Festival</strong>, is one of Meghalaya's most cherished celebrations. Held annually in the second week of November at Asanang village near Tura in the Garo Hills, it marks the end of the agricultural season with a thanksgiving to the god of fertility. The festival features lively music, drums, orchestras, and flutes, creating a vibrant atmosphere. Booking a <strong>Shillong Meghalaya Tour Package</strong> in November lets you experience this unique event up close.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading2">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse2"
                              aria-expanded="false"
                              aria-controls="flush-collapse2"
                            >
                              What is the best time to travel to Cherrapunjee?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse2"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading2"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              The best time to visit Cherrapunjee is from October to May, during the post-monsoon and winter seasons. This period offers pleasant weather, making it ideal for outdoor activities and sightseeing. Though the monsoon months make the landscape lush and green, heavy rains may restrict outdoor adventures.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading3">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse3"
                              aria-expanded="false"
                              aria-controls="flush-collapse3"
                            >
                              How is the nightlife in Shillong?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse3"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading3"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              Shillong offers a vibrant nightlife with renowned restaurants and bars that serve quality food and beverages. Many venues feature live music or DJ performances, creating a lively atmosphere perfect for an evening out.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse4"
                              aria-expanded="false"
                              aria-controls="flush-collapse4"
                            >
                              Do I need a guide to visit Tura Peak?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse4"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading4"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              Although hiring a guide is not mandatory to visit Tura Peak, it is highly recommended. A local guide can enhance your experience with insights into the region’s geography, history, and culture, and ensure safety during the trek.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse5"
                              aria-expanded="false"
                              aria-controls="flush-collapse5"
                            >
                              Which cellular network is better to carry during our Shillong Meghalaya Tour Package ?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse5"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading5"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              Shillong covers almost every known cellular network like BSNL, Vodafone, Jio, and Airtel, so you can carry the sim card of any network on your Shillong Meghalaya Tour.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse5"
                              aria-expanded="false"
                              aria-controls="flush-collapse5"
                            >
                              Do i need a Guide to Visit Tura Peak?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse5"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading5"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              While it's not mandatory to have a guide to visit Tura Peak, having one can enhance your experience and ensure safety, especially if you are not familiar with the area. A local guide can provide valuable insights into the geography, history, and culture of the region, as well as help you navigate the trails and terrain.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse5"
                              aria-expanded="false"
                              aria-controls="flush-collapse5"
                            >
                              Are there any trekking routes that we can do during our Meghalaya Package Tour With Garo Hills ?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse5"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading5"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              Yes, Shillong has very beautiful trekking routes which follow the path carved out by nature for its visitors to explore and enjoy the untouched beauty of Shillong.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse5"
                              aria-expanded="false"
                              aria-controls="flush-collapse5"
                            >
                              Q. What are the local delicacies to try during our Meghalaya Package Tour With Garo Hillss ?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse5"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading5"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <p>The drool-worthy local delicacies which are worth giving a try are as given below - <br /></p>
                              <ul><li>Thukpa</li>
                                <li>Momo</li>
                                <li>Jadot, made with rice and pork</li>
                                <li>Dohneiiong, made with pork and spices</li>
                                <li>Fresh Pineapples</li>
                                <li>Tungtab, dry fish</li>
                                <li>Tarumbai, fermented beans</li>
                                <li>Kyat, local beverage</li>
                                <li>Smoked meats</li>
                                <li>Pukhlein, a dessert prepared from fermented rice</li>
                                <li>Chilly Pork</li>
                                <li>Betel Leaf <br /><br /><br /></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse5"
                              aria-expanded="false"
                              aria-controls="flush-collapse5"
                            >
                             Q. Is Meghalaya safe for solo travellers ?
                            </button>
                          </h4>
                          <div
                            id="flush-collapse5"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-heading5"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <p>The drool-worthy local delicacies which are worth giving a try are as given below - <br /></p>
                              <ul><li>Thukpa</li>
                                <li>Momo</li>
                                <li>Jadot, made with rice and pork</li>
                                <li>Dohneiiong, made with pork and spices</li>
                                <li>Fresh Pineapples</li>
                                <li>Tungtab, dry fish</li>
                                <li>Tarumbai, fermented beans</li>
                                <li>Kyat, local beverage</li>
                                <li>Smoked meats</li>
                                <li>Pukhlein, a dessert prepared from fermented rice</li>
                                <li>Chilly Pork</li>
                                <li>Betel Leaf <br /><br /><br /></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3><b> Meghalaya With Tura, Wari-Chora Garo Hills <span class="tomato"> Holiday Reviews</span></b></h3>
                      <h4><i>"Your Experience is our Marketing"</i></h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div id="testimonials">
                    <div className="testimonial-box-container">

                      {reviewersDataView}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-967473-8480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default Meghalaya_WariChora_Tura_7N_8D_Package;
