import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bhutanPackageTourSixSensesdirectflightindia';
import Sixsensefecilities from '../Bhutan/facilities_bhutanPackageTourSixSenses';
import TopSearch from '../Bhutan/TopSearches_bhutanPackageTourSixSenses';
import AuthenticBhutaneseCuisine from './AuthenticBhutaneseCuisine_bhutanPackageTourSixSenses';

class Bhutan_PackageTourSixsenseDirectFlightIndia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 40000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-package-tour-with-hotel-six-senses-thimphu.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-six-senses-tour-packages-india.webp";
        const FlightBannerImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/guwahati-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/guwahati-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/guwahati-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/guwahati-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/guwahati-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/guwahati-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-tour-3-days.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/call-button-bablu-adorable-vacation.webp";
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/call-button-sonali-adorable-vacation.webp";
        const PriceImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-tour-from-guwahati-call-price-button.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-thimphu-1.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-thimphu-2.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-thimphu-3.webp";
        const sightseen4ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-punakha-1.webp";
        const sightseen5ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-punakha-2.webp";
        const sightseen6ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-punakha-3.webp";
        const sightseen7ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-gangtey-1.webp";
        const sightseen8ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-gangtey-2.webp";
        const sightseen9ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-gangtey-3.webp";
        const sightseen10ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-bumthang-1.webp";
        const sightseen11ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-bumthang-2.webp";
        const sightseen12ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-bumthang-3.webp";
        const sightseen13ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-paro-1.webp";
        const sightseen14ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-paro-2.webp";
        const sightseen15ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/six-senses-luxury-hotel-paro-3.webp";
        const sightseen16ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-1.webp";
        const sightseen17ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-2.webp";
        const sightseen18ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-3.webp";
        const sightseen19ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-6.webp";
        const sightseen20ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-4.webp";
        const sightseen21ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-5.webp";
        const sightseen22ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-9.webp";
        const sightseen23ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-7.webp";
        const sightseen24ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/hotel-six-senses-thimphu-bhutan-8.webp";
        const sightseen25ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-six-senses.webp";
        const sightseen26ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-food-1.webp";
        const sightseen27ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-food-2.webp";
        const sightseen28ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-food-3.webp";
        const flight1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Airlines-1.webp";
        const flight2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Airlines-2.webp";
        const flight3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Airlines-3.webp";
        const AccommodationImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/lodge-suite-hotel-six-senses-thimphu.webp";
        const Accommodation1ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/upper-lodge-suite-hotel-six-senses-thimphu.webp";
        const Accommodation2ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/one-bedroom-villa-six-senses-thimphu-bhutan.webp";
        const Accommodation3ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/two-bedroom-villa-six-senses-thimphu-bhutan.webp";
        const Accommodation4ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/three-bedroom-villa-six-senses-thimphu-bhutan.webp";
        const hotel1ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanTourPackageSixsensesImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageSixsensesImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="Bhutan Packages From Guwahati tour from adorable vacation the best Bhutan Guwahati dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bhutan Hotel Six Senses Package Tour with Direct Flight from India</strong> - BEST DEAL | BOOK NOW! </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Bhutan Hotel Six Senses Package Tour with Direct Flight from India</strong> - BEST DEAL | BOOK NOW! </center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour From Guwahati from adorable vacation the best Bhutan Guwahati dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            Experience an unforgettable <strong>Luxury Tour to Bhutan</strong> with Adorable Vacation, featuring a stay at Six Senses Hotel for the ultimate in Comfort and Luxury
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> BOOK THE BEST <strong>BHUTAN PACKAGE TOUR WITH HOTEL SIX SENSES - ITINERARIES, TRAVEL PACKAGES, TRIPS & TOURS</strong> |  <a href="tel:+91-9883359713">  ☎ CALL +91- 98833-59713</a></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> BOOK THE BEST <strong>BHUTAN PACKAGE TOUR WITH HOTEL SIX SENSES - ITINERARIES, TRAVEL PACKAGES, TRIPS & TOURS</strong> |  <a href="tel:+91-9883359713">  ☎ CALL +91- 98833-59713</a></h3>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> INDIA'S LARGEST TOUR OPERATOR WITH HOTEL SIX SENSE </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> INDIA'S LARGEST TOUR OPERATOR WITH HOTEL SIX SENSE</h3>
                                </div>
                                <div className="card-body">
                                    <p>Six Senses Bhutan offers an unparalleled luxury experience in the heart of the Himalayan Kingdom of Bhutan. With five exclusive lodges spread across the country’s most scenic valleys -<b>Thimphu</b>, <b>Punakha</b>, <b>Gangtey</b>, <b>Bumthang</b>, and <b>Paro</b> - this luxurious retreat provides a perfect opportunity to explore Bhutan’s breath-taking landscapes, rich culture, and deep spirituality. Each lodge is meticulously designed to blend modern luxury with traditional Bhutanese elements, ensuring guests enjoy the ultimate comfort, wellness, and tranquillity. Whether you're seeking adventure or relaxation, <strong> Bhutan hotel Six Senses package tour with direct flight from India</strong> offer a seamless, immersive experience where every detail is curated to enhance your well-being.<br /><br />


                                        A <strong>Bhutan Six Senses Journeys: Itineraries, Travel Packages, Trips & Tours</strong> makes it even easier to embark on this extraordinary places. The exclusive lodges offer a harmonious blend of luxury and Bhutanese traditions, ensuring guests feel connected to the serene landscapes and spiritual atmosphere of Bhutan. From rejuvenating wellness treatments to cultural explorations, Six Senses ensures an enriching experience that caters to both body and soul. <strong>Bhutan Six Senses Journeys </strong> provides tailor-made itineraries and travel packages that allow you to experience the very best of Bhutan in complete luxury and style. <br /><br />

                                        Guests who book a <a href="/bhutan-luxury-package-tour-with-six-senses-hotel/" target='_blank'><strong>Bhutan Luxury Package Tour with Hotel Six Senses</strong></a> with <b>Adorable Vacation</b> can expect a luxurious and personalized experience, whether they stay at one lodge or embark on a multi-lodge adventure. Each lodge offers unique experiences, from wellness retreats with spa treatments and yoga to guided cultural tours of Bhutan's ancient monasteries and stunning landscapes. The resorts emphasize health-conscious cuisine with organic and locally sourced ingredients, creating a perfect balance of indulgence and wellness. The <strong>Six Senses Bhutan Lodges</strong> offer the ideal retreat for those seeking a luxurious yet authentic Bhutanese experience that combines relaxation with spiritual enrichment. <br /><br />

                                        Located at high altitudes in Bhutan’s serene valleys, each lodge offers breath-taking views of lush forests, tranquil rivers, and towering Himalayan peaks. The <b>Thimphu Lodge</b>, for example, is perched high above the valley with sweeping views of the city and the iconic Buddha Dordenma statue. With its elegant design, luxurious amenities, and proximity to cultural landmarks, it provides an ideal base to explore Bhutan’s capital. The other lodges  - <b>Punakha</b>, <b>Gangtey</b>, <b>Bumthang</b>, and <b>Paro</b> are equally stunning and offer unique opportunities for cultural immersion, including visits to ancient temples, bird watching, and hiking in untouched natural environments. <br /><br />

                                        Booking a <strong>Bhutan Six Senses Luxurious Journey</strong> not only guarantees a seamless travel experience but also ensures exclusive benefits. With direct flights from India and carefully designed itineraries, this package offers a stress-free and luxurious way to explore Bhutan. Whether you choose a short stay or a longer exploration, <strong >Six Senses Bhutan Package</strong> caters to every guest’s desires, from intimate cultural experiences to indulgent spa treatments. The commitment to personalized service, wellness, and luxury makes Six Senses Bhutan one of the most sought-after high-end destinations in the region, offering VIP benefits that elevate the travel experience. <br /><br />

                                        <strong>Six Senses Bhutan tour</strong> by Adorable Vacation represents the pinnacle of luxury in Bhutan. With its focus on sustainability, well-being, and local culture, the lodges provide an experience that goes beyond just a place to stay. It’s an immersive journey that taps into Bhutan’s spiritual energy while offering a sanctuary of luxury and relaxation. From the stunning valley views to the world-class amenities, Six Senses Bhutan package tour Journeys create a once-in-a-lifetime opportunity to experience Bhutan’s enchanting beauty in the most luxurious way possible. <br /><br /></p>
                                    <p>Apart from <strong>Bhutan Tour With Six Senses Hotel</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bangalore" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, <a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b className="tomato">Bhutan B2B Package Tour</b></a>, <a href="/bhutan-package-tour-from-guwahati-airport" target="_blank"><b className="tomato">Bhutan Package Tour from Guwahati</b></a>, <a href="/bhutan-luxury-package-tour-with-six-senses-hotel" target="_blank"><b className="tomato">Bhutan Package Tour With Hotel Six Senses</b></a> etc at Best Price.</p>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">DAY WISE SUMMERY FOR <strong>SIX SENSES BHUTAN PACKAGE WITH VIP BENEFITS</strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            SIX SENSES THIMPHU - "PALACE IN THE SKY"
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span>SIX SENSES THIMPHU - LOCAL SIGHTSEEING</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span> SIX SENSES PUNAKHA - SIX SENSES PUNAKHA "FLYING FARMHOUSE"</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span> SIX SENSES PUNAKHA - SIGHTSEEING / SPA TREATMENT</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>SIX SENSES PARO - SIX SENSES PARO "STONE RUINS"</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>SIX SENSES PARO - TIGER'S NEST HIKING / LIVE MUSIC / FOLK DANCE / STONE GRILL</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>SIX SENSES PARO "STONE RUINS" - PARO AIRPORT</span>
                                                    </a>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    {/* <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW MANY SIX SENSES HOTELS ARE IN BHUTAN ?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOW MANY SIX SENSES HOTELS ARE IN BHUTAN ?</h3>
                                </div>
                                <div className="card-body">
                                    <p>As of now, there are five Six Senses lodges in Bhutan, and all these lodges were carefully planned and designed to provide travelers with a complete immersion into the stunning natural beauty and rich culture of the Kingdom of Bhutan. These lodges present a modern interpretation of traditional Bhutanese architecture and style. Lets look at these lodges.</p>
                                    <br /><br />
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <td><b>Hotel</b></td>
                                                    <td><b>Known as</b></td>
                                                    <td><b>Altitude </b></td>
                                                    <td><b>Specialties</b></td>
                                                    <td><b>Map</b></td>
                                                </tr></thead>
                                            <tbody>
                                                <tr>
                                                    <td>Six Senses Thimphu</td>
                                                    <td>Palace in the Sky</td>
                                                    <td>2,755 meters</td>
                                                    <td>Wellness, spa treatments, cultural experiences,local cuisine, outdoor activities such as hiking and archery etc</td><td><a href="https://goo.gl/maps/AWFGUcBiKYmqag1e7" target="_blank">View of Google Maps</a></td></tr>
                                                <tr>
                                                    <td>Six Senses Punakha</td>
                                                    <td>Flying Farmhouse Amidst the Rice Fields</td>
                                                    <td>1,400 meters</td>
                                                    <td>Living Room and Lounge, all-day dining restaurant, library, outdoor pool, and a Spa and Wellness Center with three single rooms, gym, two meditation huts, and yoga facilities etc</td><td><a href="https://goo.gl/maps/kgSDEtJgbVUszsRP6" target="_blank">View of Google Maps</a></td></tr>
                                                <tr>
                                                    <td>Six Senses Gangtey</td>
                                                    <td>Traditional Birdwatching Bridge</td>
                                                    <td>3,300 meters</td>
                                                    <td>valley-facing, panoramic and private outdoor balcony, binoculars for birdwatching, private spa with Swedana treatments, Weathered timber floors and textured walls, Bukhari stoves etc</td><td><a href="https://goo.gl/maps/2upkbXYRd4ye3GPd6" target="_blank">View of Google Maps</a></td></tr>
                                                <tr>
                                                    <td>Six Senses Bumthang</td>
                                                    <td>Forest within a forest</td>
                                                    <td>2,615 meters</td>
                                                    <td>Traditional dotsho hot stone bath, with Himalayan salts. Massages, beauty treatments. Treks to the nearby Buddhist pilgrimage sites, hiking, and biking around the resort, visits to local villages, a palace tour, archery lessons, and mushroom foraging.</td><td><a href="https://goo.gl/maps/q1b5T19x3wMCuJz56" target="_blank">View of Google Maps</a></td></tr>
                                                <tr>
                                                    <td>Six Senses Paro</td>
                                                    <td>The Lodge of Stone Ruins</td>
                                                    <td>2,870 meters</td>
                                                    <td>Sunrise Hike to Tiger’s Nest monasteries, Light butter lamps at Samtenling Monastery, hike to Jele Dzong and behold Mt. Jumolhari, connect with nature and engage with mushroom hunting etc.</td><td><a href="https://goo.gl/maps/FM6hjCEXgJn37ytQ7" target="_blank">View of Google Maps</a></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THIMPHU SIX SENSES - PALACE IN THE SKY</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> THIMPHU SIX SENSES - PALACE IN THE SKY</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Exterior Wall :</b> Whitewashed local stone finish <br />
                                        <b>Interior Wall :</b> Whitewashed local stone, local timber finish <br />
                                        <b>Floor :</b> Local stone, local timber finish <br /><br /></p>

                                    <p>The Thimphu lodge, also known as the <b>Palace in the Sky</b>, is situated within apple orchards and pine forests and offers banquet facilities for 60 guests, a pool, prayer pavilion, business center, and sunken outdoor performance deck. <br /><br /></p>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> PUNAKHA SIX SENSES - FLYING FARMHOUSE AMIDST THE RICE FIELDS</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> PUNAKHA SIX SENSES - FLYING FARMHOUSE AMIDST THE RICE FIELDS</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Exterior Wall :</b> Rammed earth finish <br />
                                        <b>Interior Wall :</b> Plastered finish, local timber finish, ratten <br />
                                        <b>Floor :</b> Local stone, local timber finish <br /><br /></p>

                                    <p>Punakha lodge, the Flying Farmhouse amidst the rice fields, has a rustic feel and a warmer climate, with suites and villas, a pool, library, and yoga and meditation huts. Guests can visit one of the highlights, the majestic Punakha Dzong, from here. <br /></p>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen4ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen6ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> GANGTEY SIX SENSES - TRADITIONAL BIRDWATCHING BRIDGE</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> GANGTEY SIX SENSES - TRADITIONAL BIRDWATCHING BRIDGE</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Exterior Wall :</b> Local stone finish <br />
                                        <b>Interior Wall :</b> Local stone, local timber finish <br />
                                        <b>Floor :</b> Local stone, local timber finish <br /><br /></p>

                                    <p>The Gangtey lodge, also known as the Traditional Birdwatching Bridge, is strategically located to showcase a 180-degree view of the little-visited Phobjikha Valley, home to the fascinating black-necked cranes. <br /></p>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen8ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen9ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BUMTHANG SIX SENSES - FOREST WITHIN A FOREST</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> BUMTHANG SIX SENSES - FOREST WITHIN A FOREST</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Exterior Wall :</b> Local stone finish <br />
                                        <b>Interior Wall :</b> Local stone, local timber finish <br />
                                        <b>Floor :</b> Local stone, local timber finish <br /><br /></p>

                                    <p>The Bumthang lodge is carefully built as a Forest within a Forest, an auspicious base from which guests can take in hallowed monuments, monasteries, and winter festivals. <br /></p>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> PARO SIX SENSES - THE LODGE OF STONE RUINS</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> PARO SIX SENSES - THE LODGE OF STONE RUINS</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Exterior Wall :</b> Local stone, local timber, rammed earth finish <br />
                                        <b>Interior Wall :</b> Local stone, local timber finish <br />
                                        <b>Floor :</b> Local stone, local timber finish <br /><br /></p>

                                    <p>The Paro lodge, also known as Stone Ruins, is designed to maximize one of the most scenic settings within the Six Senses Bhutan journey, where guests can dine among the stone ruins and enjoy the breathtaking scenery. <br /></p>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen15ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> FACILITIES & SERVICES DURING <strong>BHUTAN SIX SENSES LUXURIOUS JOURNEY</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> FACILITIES & SERVICES DURING <strong>BHUTAN SIX SENSES LUXURIOUS JOURNEY</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                <Sixsensefecilities />
                                            }
                                        </div>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen19ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen20ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen21ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> TOP SEARCHES FOR <strong>BHUTAN TOUR WITH SIX SENSES</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TOP SEARCHES FOR <strong>BHUTAN TOUR WITH SIX SENSES</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                <TopSearch />
                                            }
                                        </div>
                                    </div>
                                    <br /><br />

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen25ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> AUTHENTIC BHUTANESE CUISINE MUST TRY DURING <strong>BHUTAN PACKAGE TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> AUTHENTIC BHUTANESE CUISINE MUST TRY DURING <strong>BHUTAN PACKAGE TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                <AuthenticBhutaneseCuisine />
                                            }
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen26ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen27ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen28ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST PLACES TO EXPLORE DURING <strong>BHUTAN HOTEL SIX SENSES PACKAGE TOUR WITH DIRECT FLIGHT FROM INDIA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST PLACES TO EXPLORE DURING <strong>BHUTAN HOTEL SIX SENSES PACKAGE TOUR WITH DIRECT FLIGHT FROM INDIA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th><b>PLACES</b></th>
                                                    <th><b>ATTRACTIONS</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Thimphu</td>
                                                    <td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td>
                                                </tr>
                                                <tr>
                                                    <td>Paro</td>
                                                    <td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>
                                                <tr>
                                                    <td>Punakha</td>
                                                    <td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td>
                                                </tr>
                                                <tr>
                                                    <td>Gangtey</td>
                                                    <td>Black-necked Crane Visitor Centre, Phobjikha, Gangtey Nature Trail, Khewang Lhakhang, Taktsang Palphug Monastery, Dargay Goempa, Tenkhor Yuetshe, Phobjikha Valley, Wangdue Phodrang, Gangtey Lodge Bhutan, Pelela Pass, Hot Stone Bath In Gangtey, Gaselo And Nahee Village</td>
                                                </tr>
                                                <tr>
                                                    <td>Bumthang </td>
                                                    <td> Jakar Dzong, Jambay Lhakhang, Wangduechhoeling Palace Museum, Ngang Lhakhang, Chakar Lhakhang, Tharpaling Goemba, Mebar Tsho, Tang Valley, Kurjey Lhakhang, Tamshing Lhakhang, Namkhe Nyingpo Goemba, Sumthrang Lhakhang, Padtselling Monastery, Choedrak Monastery, Ogyen Choling Palace Museum, Ura Valley, Bumthang Red Panda Brewery & Cheese Factory</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br /><br />
                            <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review</span> from our esteemed Guests Mr. & Mrs. Dalal from Mumbai</b><br /></h3>
                            <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <strong>Bhutan tour from Mumbai with Direct Flight</strong>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                            <div class="video-container">
                                <iframe
                                    src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>HOW TO REACH BHUTAN FROM INDIA?</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>HOW TO REACH BHUTAN FROM INDIA?</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>OPTION 1: DIRECT NON-STOP FLIGHT FROM MUMBAI TO PARO</b></p>
                                    <p>We're excited to announce that Adorable Vacation will be offering a direct non-stop chartered flight from Mumbai to Paro in May 2025 again. This special flight is eagerly awaited by Mumbaikars who wish to explore Bhutan, the Land of Happiness. Don't miss this exciting opportunity! Come and join us with Adorable Vacation for an unforgettable <strong>Mumbai to Bhutan Trip</strong></p>
                                    <br /><br />

                                    <p><b>OPTION 2 : CONNECTING FLIGHT VIA KOLKATA AIRPORT</b></p>
                                    <p>Bhutan Airlines operates direct flights from Kolkata Airport [CCU] to Paro International Airport [PBH] on regular basis. So, travellers from Mumbai can first arrive at Kolkata Airport and from there they can catch Kolkata to Paro direct Flight. Following are the flight schedule as published by Bhutan Airlines upto December 2024.</p>
                                    <br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>SECTOR</th>
                                                    <th>FLIGHT NO</th>
                                                    <th>DEP DAY</th>
                                                    <th>DEP TIME</th>
                                                    <th>ARR TIME</th>
                                                    <th>DURATION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>CCU - PBH</td>
                                                    <td>B3 701</td>
                                                    <td>EVERYDAY</td>
                                                    <td>08:15am</td>
                                                    <td>09:55pm</td>
                                                    <td>01 hrs 10 mins</td>
                                                </tr>
                                                <tr>
                                                    <td>PBH - CCU</td>
                                                    <td>B3 700</td>
                                                    <td>EVERYDAY</td>
                                                    <td>10:35am</td>
                                                    <td>11:15pm</td>
                                                    <td>01 hrs 10 mins</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <p><b>OPTION 3 [BY AIR]:</b> <strong>CONNECTING FLIGHT VIA DELHI AIRPORT</strong></p>
                                    <p>Druk Air [Royal Bhutan Airlines] operates Delhi to Paro direct Non-Stop Flight on every Sunday, Tuesday, Wednesday, Friday, Saturday. It's convenient and time saving options to fly directly Bhutan. The flight duration is merely 2.20 hrs. So any travellers looking for <strong>Mumbai to Bhutan trip</strong>, also can enter Bhutan via Delhi. Following are the Delhi to Paro flight schedule.</p>
                                    <br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>DEPARTURE</th>
                                                    <th>SECTOR</th>
                                                    <th>OUTWARD JOURNEY TIME</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Sunday</td>
                                                    <td>Delhi → Paro</td>
                                                    <td>Depart 10:55 - Arrive 14:35</td>
                                                </tr>
                                                <tr>
                                                    <td>Monday</td>
                                                    <td>Delhi → Paro</td>
                                                    <td>Depart 10:55 - Arrive 14:35</td>
                                                </tr>
                                                <tr>
                                                    <td>Wednesday</td>
                                                    <td>Delhi → Paro</td>
                                                    <td>Depart 10:55 - Arrive 14:35</td>
                                                </tr>
                                                <tr>
                                                    <td>Saturday</td>
                                                    <td>Delhi → Paro</td>
                                                    <td>Depart 10:55 - Arrive 14:35</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>DEPARTURE</th>
                                                    <th>SECTOR</th>
                                                    <th>INWARD JOURNEY TIME</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Sunday</td>
                                                    <td>Paro → Delhi</td>
                                                    <td>Depart 07:05 - Arrive 09:55</td>
                                                </tr>
                                                <tr>
                                                    <td>Monday</td>
                                                    <td>Paro → Delhi</td>
                                                    <td>Depart 07:05 - Arrive 09:55</td>
                                                </tr>
                                                <tr>
                                                    <td>Wednesday</td>
                                                    <td>Paro → Delhi</td>
                                                    <td>Depart 07:05 - Arrive 09:55</td>
                                                </tr>
                                                <tr>
                                                    <td>Saturday</td>
                                                    <td>Paro → Delhi</td>
                                                    <td>Depart 07:05 - Arrive 09:55</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <p><b>OPTION 4 :</b> <strong>BHUTAN TOUR BY ROAD VIA BAGDOGRA AIRPORT</strong></p>
                                    <p>Bagdogra Airport [IXB] is located just the 170 KM (4 hrs approx) away from border town of Bhutan - Phuentsholing. Bagdogra Airport has well flight connectivity from Mumbai. So, Guests from Mumbai can easily fly to Bagdogra Airport from where Adorable Vacation offers its exclusive customized <strong>Bagdogra to Bhutan Package</strong>. This is one of the best way to <strong>travel Bhutan from Mumbai</strong>.</p>
                                    <br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Airlines</th>
                                                    <th>Flight Number</th>
                                                    <th>City</th>
                                                    <th>Departure Time</th>
                                                    <th>Arrival Time</th>
                                                    <th>Travel Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>IndiGo</td>
                                                    <td>6E - 5305</td>
                                                    <td>Mumbai</td>
                                                    <td>8:05</td>
                                                    <td>10:50</td>
                                                    <td>2h 55m</td>
                                                </tr>
                                                <tr>
                                                    <td>Akasa Air</td>
                                                    <td>QP - 1385</td>
                                                    <td>Mumbai</td>
                                                    <td>11:30</td>
                                                    <td>14:25</td>
                                                    <td>2h 55m</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={flight1ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight2ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight3ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SOME IMPORTANT INFO ABOUT <strong>SIX SENSES LUXURY TOURS IN BHUTAN</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> SOME IMPORTANT INFO ABOUT <strong>SIX SENSES LUXURY TOURS IN BHUTAN</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody><tr><td>Elevation</td><td>8,695 feet (2,650 meters) above sea level</td></tr>
                                                <tr><td>Date of Inauguration</td><td>Early 2019</td></tr>
                                                <tr><td>Founder</td><td>Dasho Sangay Wangchuk - a Thimphu-born entrepreneur</td></tr>
                                                <tr><td>No of Lodges</td><td>Five collectively known as Six Senses Bhutan [Thimphu, Punakha, Paro, Gangtey, Bumthang]</td></tr>
                                                <tr><td>HQ</td><td>Bangkok, Thailand</td></tr>
                                                <tr><td>Room</td><td>Rooms 25, including 20 Suites</td></tr>
                                                <tr><td>Check-Out</td><td>12:00 Noon</td></tr>
                                                <tr><td>Check-In</td><td>2:00 PM - 3:00 PM</td></tr>
                                                <tr><td>Facilities</td><td>WiFi, TV, Bose speaker, wine fridge, minibar with free snacks and bottled water, Nespresso coffee machine and tea</td></tr>
                                                <tr><td>Pool</td><td>25m heated indoor pool</td></tr>
                                                <tr><td>Spa</td><td>World's best Spa with signature Bhutanese hot-stone bath</td></tr>
                                                <tr><td>Babysitting</td><td>$25 an hour, the first two hours are free</td></tr>
                                                <tr><td>Activities</td><td>archery, khuru (darts) tournaments, sand-mandala making, yoga and wellness activities, hiking, mountain biking, trekking</td></tr>
                                                <tr><td>Hotel Restaurant</td><td>Chef John Bakker's Organic veggie platters, chilli-laden shakaam ramen with wild mushrooms and crow beak, cordyceps-infused chicken, grilled chayote and confit ginger, </td></tr>
                                                <tr><td>Signature Drink</td><td>Butterfly Pea Daiquiri</td></tr>
                                                <tr><td>Breakfast Timing</td><td>7am until 10.30am</td></tr>
                                                <tr><td>Lunch Timing</td><td>Noon until 3pm</td></tr>
                                                <tr><td>Dinner Timing</td><td>7pm to 10.30pm</td></tr>
                                                <tr><td>Six Senses Bhutan Amadeus GDS Code</td><td>IG PBHSSB</td></tr>
                                                <tr><td>Six Senses Bhutan Galileo/Apollo GDS Code</td><td>IG E8805</td></tr>
                                                <tr><td>Six Senses Bhutan Sabre GDS Code</td><td>IG 321941</td></tr>
                                                <tr><td>Six Senses Bhutan WorldSpan GDS Code</td><td>IG PBHSB</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>ACCOMMODATION TYPES OF HOTEL SIX SENSES, THIMPHU, BHUTAN</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ACCOMMODATION TYPES OF HOTEL SIX SENSES, THIMPHU, BHUTAN</h3>
                                </div>
                                <div className="card-body">
                                    <h4>LODGE SUITE <br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><td colspan="4">
                                                    <div className="content-desktop">
                                                        <center><img src={AccommodationImageURL} alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <img src={AccommodationImageURL} className="responsive-image" alt="three bedroom villa six senses thimphu bhutan" />
                                                    </div>
                                                </td></tr>
                                                <tr>
                                                    <td><b>About :</b> Floor-to-ceiling windows, private over-sized decks and striking views of Thimphu Valley from the Lodge Suite</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Amenities :</b><br /> <li>Naturalmat organic bed and bed topper <br /></li>
                                                        <li>Beaumont and Brown organic bed linens <br /></li>
                                                        <li>Hanse organic pillows and duvet covers <br /></li>
                                                        <li>Traditional wood-burning bukhari stove <br /></li>
                                                        <li>Unlimited WiFi / Writing desk / IDD telephone <br /></li>
                                                        <li>Private wine fridge and minibar <br /></li>
                                                        <li> Six Senses bottled drinking water <br /></li>
                                                        <li> Tea and coffee making facilities <br /></li>
                                                        <li> Espresso machine / In-room safe <br /></li>
                                                        <li> Fresh beverage facilities <br /></li>
                                                        <li> 43-inch TV with cable service <br /></li>
                                                        <li> Optional privacy screen between bathroom and bedroom <br /></li>
                                                        <li> Hairdryer / Yoga mat / Bose Bluetooth speaker <br /></li>
                                                        <li> Bathtub, rain shower and toilet <br /></li>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Key Facts : </b> Private outdoor deck with mountain and valley views</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Max Guests :</b> 3 [3 Adults / 2 Adults + 1 Child / 1 Adult + 2 Child]</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4>UPPER LODGE SUITE <br /></h4>
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><td colspan="4">
                                                    <div className="content-desktop">
                                                        <center><img src={Accommodation1ImageURL} alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={Accommodation1ImageURL} className="responsive-image" alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>
                                                </td></tr>
                                                <tr>
                                                    <td><b>About :</b> Floor-to-ceiling windows, private over-sized decks, and striking views of Thimphu Valley </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Amenities :</b><br />
                                                        <ul>
                                                            <li>Naturalmat organic bed and bed topper</li>
                                                            <li>Beaumont and Brown organic bed linens</li>
                                                            <li>Hanse organic pillows and duvet covers</li>
                                                            <li>Traditional wood-burning bukhari stove</li>
                                                            <li>Unlimited WiFi / Espresso machine</li>
                                                            <li>Six Senses bottled drinking water</li>
                                                            <li>Tea and coffee making facilities</li>
                                                            <li>Fresh beverage facilities</li>
                                                            <li>Television with cable service</li>
                                                            <li>IDD telephone / In-room safe</li>
                                                            <li>Hairdryer / Yoga mat</li>
                                                            <li>Bose Bluetooth speaker</li>
                                                            <li>Bathtub, rain shower, and toilet</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Key Facts :</b> Private outdoor deck with mountain and valley views. Located on the top floor with advantageous views.</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Max Guests :</b> 3 [3 Adults / 2 Adults + 1 Child / 1 Adult + 2 Children]</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <br /><br />
                                    <h4>ONE-BEDROOM VILLA <br /></h4>
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td colspan="4">
                                                        <div className="content-desktop">
                                                            <center><img src={Accommodation2ImageURL} alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                        </div>

                                                        <div className="content-mobile2">
                                                            <center><img src={Accommodation2ImageURL} className="responsive-image" alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>About :</b> Private garden and views across Thimphu Valley from the One-Bedroom Villa</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Amenities :</b><br />
                                                        <ul>
                                                            <li>Dedicated GEM (Guest Experience Maker) available 24 hours a day</li>
                                                            <li>Naturalmat organic bed and bed topper</li>
                                                            <li>Beaumont and Brown organic bed linens</li>
                                                            <li>Hanse organic pillows and duvet covers</li>
                                                            <li>Traditional wood-burning bukhari stove</li>
                                                            <li>Private wine fridge and minibar</li>
                                                            <li>Six Senses bottled drinking water</li>
                                                            <li>Tea and coffee making facilities</li>
                                                            <li>Unlimited WiFi / Espresso machine</li>
                                                            <li>Fresh beverage facilities</li>
                                                            <li>49-inch TV with cable connection</li>
                                                            <li>IDD telephone / In-room safe / Pantry area</li>
                                                            <li>Hairdryer / Yoga mat</li>
                                                            <li>Bose Bluetooth speaker</li>
                                                            <li>Bathtub, rain shower and toilet / Powder room</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Key Facts :</b> Outdoor and indoor living area with private dining area for up to four guests, offering mountain and valley views</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Max Guests :</b> 4 [3 Adults + 1 Child / 2 Adults + 2 Children / 1 Adult + 3 Children]</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4>TWO-BEDROOM VILLA <br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><td colspan="4">
                                                    <div className="content-desktop">
                                                        <center><img src={Accommodation3ImageURL} alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={Accommodation3ImageURL} className="responsive-image" alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>
                                                </td></tr>
                                                <tr>
                                                    <td><b>About :</b> Spacious lifestyle villa designed for relaxing and entertaining, with a private outdoor courtyard and views across Thimphu Valley</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Amenities :</b><br />
                                                        <ul>
                                                            <li>Dedicated GEM (Guest Experience Maker) available 24 hours a day</li>
                                                            <li>Double spa treatment room</li>
                                                            <li>Naturalmat organic bed and bed topper</li>
                                                            <li>Beaumont and Brown organic bed linens</li>
                                                            <li>Hanse organic pillows and duvet covers</li>
                                                            <li>Traditional wood-burning bukhari stove</li>
                                                            <li>Private wine fridge and minibar</li>
                                                            <li>Six Senses bottled drinking water</li>
                                                            <li>Tea and coffee making facilities</li>
                                                            <li>Unlimited WiFi / Espresso machine</li>
                                                            <li>Fresh beverage facilities</li>
                                                            <li>49-inch TV with cable connection</li>
                                                            <li>IDD telephone / In-room safe / Pantry area</li>
                                                            <li>Hairdryer / Yoga mat</li>
                                                            <li>Bose Bluetooth speaker</li>
                                                            <li>Bathtub, rain shower and toilet / Powder room</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Key Facts :</b> Outdoor and indoor living area with a private dining area for up to eight people, a private open courtyard, and mountain and valley views</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Max Guests :</b> 8 [6 Adults + 2 Children / 5 Adults + 3 Children / 4 Adults + 4 Children / 3 Adults + 5 Children / 2 Adults + 6 Children / 1 Adult + 7 Children]</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4>THREE-BEDROOM VILLA <br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><td colspan="4">
                                                    <div className="content-desktop">
                                                        <center><img src={Accommodation4ImageURL} alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={Accommodation4ImageURL} className="responsive-image" alt="three bedroom villa six senses thimphu bhutan" /></center>
                                                    </div>
                                                </td></tr>
                                                <tr>
                                                    <td><b>About :</b> Nestled among pine trees, unwind in the only Three-Bedroom Villa in Thimphu Valley, offering ultimate relaxation and privacy</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Amenities :</b><br />
                                                        <ul>
                                                            <li>Nestled among pine trees, unwind in the only Three-Bedroom Villa in Thimphu Valley</li>
                                                            <li>Dedicated GEM (Guest Experience Maker) available 24 hours a day</li>
                                                            <li>Double spa treatment room</li>
                                                            <li>Naturalmat organic bed and bed topper</li>
                                                            <li>Beaumont and Brown organic bed linens</li>
                                                            <li>Hanse organic pillows and duvet covers</li>
                                                            <li>Traditional wood-burning bukhari stove</li>
                                                            <li>Private wine fridge and minibar</li>
                                                            <li>Six Senses bottled drinking water</li>
                                                            <li>Tea and coffee making facilities</li>
                                                            <li>Unlimited WiFi / Espresso machine</li>
                                                            <li>Fresh beverage facilities</li>
                                                            <li>49-inch TV with cable connection</li>
                                                            <li>IDD telephone / In-room safe / Pantry area</li>
                                                            <li>Hairdryer / Yoga mat</li>
                                                            <li>Bose Bluetooth speaker</li>
                                                            <li>Bathtub, rain shower and toilet / Powder room</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Key Facts :</b> Outdoor and indoor living area with a private dining area for up to eight people, a private open courtyard, and mountain and valley views</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Max Guests :</b> 8 [6 Adults + 2 Children / 5 Adults + 3 Children / 4 Adults + 4 Children / 3 Adults + 5 Children / 2 Adults + 6 Children / 1 Adult + 7 Children]</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY <strong> SIX SENSES THIMPHU</strong> THE MOST SAUGHT AFTER PREMIUM PROPERTY IN BHUTAN ? </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY <strong> SIX SENSES THIMPHU</strong> THE MOST SAUGHT AFTER PREMIUM PROPERTY IN BHUTAN ?</h3>
                                </div>
                                <div className="card-body">
                                    <p>The Six Senses Thimphu hotel in Bhutan is a luxurious <strong>Wellness Retreat in Thimphu</strong> located in a picturesque setting amidst apple orchards and pine forests. With stunning views of the valley and mountains, this Five Star ultra luxury lodges offers spacious accommodation, wellness experiences, cultural activities, and sustainable practices. During your <strong>Six Senses Luxury Tours in Bhutan</strong>, you can enjoy local and international cuisine at the hotel's restaurant or indulge in private dining experiences. The Six Senses Thimphu hotel provides an unforgettable experience of Bhutanese hospitality and luxury at its best. Here is some information for <strong>Luxury Bhutan Highlights with Six Senses</strong>. <br /></p>

                                    <li>Six Senses Thimphu is a luxury hotel located in Thimphu, the capital city of Bhutan. <br /><br /></li>
                                    <li>The hotel is perched on the valley hillside south of the town, at an elevation of 8,695 feet (2,650 meters) <br /><br /></li>
                                    <li>The hotel is surrounded by apple orchards to the north and young pine forests to the south <br /><br /></li>
                                    <li>The location of the hotel offers spectacular views of the Thimphu Valley and across to the giant Buddha Dordenma <br /><br /></li>
                                    <li>The hotel offers guests a comfortable escape from the bustling capital city <br /><br /></li>
                                    <li>The hotel is located just one hour's drive from Paro International Airport <br /><br /></li>
                                    <li>The hotel offers a variety of accommodation options, including suites and villas <br /><br /></li>
                                    <li>The hotel features a restaurant that serves local and international cuisine <br /><br /></li>
                                    <li>The hotel offers a range of wellness experiences, including spa treatments and yoga classes <br /><br /></li>
                                    <li>The hotel offers a variety of outdoor activities, including hiking, mountain biking, and archery <br /><br /></li>
                                    <li>The hotel features a library that houses a collection of books and films about Bhutan <br /><br /></li>
                                    <li>The hotel has a gift shop that sells local handicrafts and souvenirs <br /><br /></li>
                                    <li>The hotel offers a range of meeting and event facilities, including a ballroom and a boardroom <br /><br /></li>
                                    <li>The hotel offers a range of dining options, including in-room dining and private dining experiences <br /><br /></li>
                                    <li>The hotel offers a variety of cultural experiences, including traditional Bhutanese dance performances and visits to local monasteries <br /><br /></li>
                                    <li>The hotel offers a range of sustainable initiatives, including waste reduction and energy conservation programs <br /><br /></li>
                                    <li>The hotel offers a range of family-friendly activities, including storytelling sessions and cooking classes <br /><br /></li>
                                    <li>The hotel offers a range of adventure experiences, including white water rafting and paragliding <br /><br /></li>
                                    <li>The hotel offers a range of transportation services, including airport transfers and car rentals <br /><br /></li>
                                    <li>The hotel offers a range of facilities for guests with special needs, including wheelchair-accessible rooms <br /><br /></li>
                                    <li>The hotel offers a range of language services, including multilingual staff and translation services <br /><br /></li>
                                    <li>The hotel offers a range of recreational facilities, including a fitness center and a heated swimming pool <br /><br /></li>
                                    <li>The hotel offers a range of pet-friendly accommodations, including pet beds and bowls <br /><br /></li>
                                    <li>The hotel offers a range of seasonal packages and promotions, including holiday specials and wellness retreats <br /><br /></li>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DETAIL <strong> BHUTAN PACKAGE TOUR ITINERARY WITH HOTEL SIX SENSES</strong>
                                        </h3>
                                        <h4>6N 7D SIX SENSES JOURNEY THROUGH THE KINGDOM OF BHUTAN WITH ADORABLE VACATION</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: SIX SENSES THIMPHU - "PALACE IN THE SKY" </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your luxury vacation with a <strong>Bhutan package tour from Mumbai</strong>, featuring the exquisite <strong>Bhutan Luxury Package Tour with Six Senses Hotel</strong>. Upon arrival at Paro International Airport [PBH], you'll be warmly greeted and presented with a traditional Khadar (a white scarf) as part of your <strong>Bhutan Luxury Tours with Six Senses</strong>. After the warm welcome, enjoy a scenic one-hour drive to Thimphu, the capital of Bhutan, passing through beautiful mountain landscapes, pine forests, and crystal-clear streams. Thimphu, located at 7,700 feet (2,200 meters), is unique as the only city in the world without a traffic light. <br /><br />

                                                    Next, check in at Six Senses, your luxurious retreat situated at an altitude of 9,040 feet (2,755 meters). Take the day to relax and acclimatize to the altitude, exploring the expansive 15-acre grounds while enjoying stunning views of apple orchards and pine forests. The lodge offers the perfect getaway from the city's hustle and bustle, while still being close to many attractions. You can unwind in the indoor heated pool, take a peaceful walk around the grounds, or simply relax on your private balcony. <br /><br />

                                                    Indulge in healthy and delicious organic meals at the lodge's sunken terrace, prepared by expert chefs using fresh ingredients from the lodge’s own gardens. The Eat with Six Senses philosophy ensures that every meal is not only nutritious but also full of flavor. <br /><br />

                                                    For those wanting to explore, a visit to the 52-meter Buddha Dordenma statue in Thimphu is highly recommended. Inside, you'll find 125,000 smaller Buddhas. Alternatively, enjoy a leisurely hike to Changangkha Lhakhang to stretch your legs after your journey. <br /><br />

                                                    Relax further with a Jet Lag Massage or other rejuvenating treatments at the Six Senses Spa. (Please note, all spa treatments are optional and can be booked in advance through our reservations team or by notifying Adorable Vacation beforehand.) <br /><br /><br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: THIMPHU SIGHTSEEING - STAY AT "PALACE IN THE SKY"</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your first full day in Bhutan with a rejuvenating wellness session at the spa, where you can choose from a range of activities, including yoga and meditation, offered each morning. After breakfast, set off on a guided tour of Thimphu, the capital city of Bhutan, and explore its top attractions as part of your <strong>Bhutan Journey By Six Senses Lodge</strong>. <br /><br /> </p>

                                                <p><b>The National Memorial Chorten</b> – Also known as Thimphu Chorten, this site is a place for evening prayers. It was built in memory of Bhutan's third king, His Majesty Jigme Dorji Wangchuk, who is considered the father of modern Bhutan. The chorten was completed in 1974, after the king's untimely death, and stands as a memorial to him as well as a symbol of peace and prosperity. Visitors often walk around the chorten, spinning prayer wheels and chanting mantras. <br /><br /> </p>

                                                <p><b>The Clock Tower</b> – Located in the heart of Thimphu, the Clock Tower Square is an architectural highlight that you’ll see during your <strong>A Six Senses Journey through the Kingdom of Bhutan</strong>. This tower, with four clock faces facing in all directions, is unique in its design, featuring intricate carvings and Bhutanese artwork, including images of dragons. These dragons, symbols of Bhutanese independence, are facing each of the four clocks. The Clock Tower Square is a lively area, surrounded by restaurants, shops, and hotels, making it a popular place for both locals and visitors. In the evenings, the square comes alive with music, events, and a vibrant atmosphere. It’s also a great spot to pick up souvenirs and enjoy a leisurely evening stroll. <br /><br /> </p>

                                                <p><b>Sakyamuni Buddha</b> – Perched atop Kuensel Phodrang hill, the Great Buddha Dordenma statue is one of the largest in the world, standing at 51.5 meters tall. This gilded bronze statue is an impressive sight, offering breathtaking views of the surrounding valley. The site, also known as the Buddha Viewpoint, is a must-visit during your <strong>Bhutan Tour with Six Senses Hotel </strong>. <br /><br /></p>

                                                <p><b>Changangkha Lhakhang</b> – One of the oldest and most important religious sites in Thimphu, Changangkha Lhakhang is dedicated to Avalokiteshvara, the Bodhisattva of Compassion. Dating back to the 15th century, this monastery offers a beautiful view of the Thimphu valley and is an essential stop on your <strong>Bhutan Luxury Tours</strong>. <br /><br /></p>

                                                <p><b>Takin Zoo</b> – Visit the Takin Zoo, home to Bhutan’s national animal, the Takin. This rare and endangered species, found only in Bhutan, is preserved in a natural habitat and is a unique sight during your <strong>Bhutan Journey By Six Senses Lodge</strong>. <br /><br /></p>

                                                <p><b>Trashi Chhoe Dzong</b> – The Trashi Chhoe Dzong, or Fortress of the Glorious Region, is a significant site on your <strong>Bhutan Luxury Tours</strong>. Located on the west bank of the Wang Chhu River, this dzong serves as the site of the formal coronation of the fifth king of Bhutan and hosts the annual Tsechu festival. Built in 1641 by Shabdrung Ngawang Namgyal, the political and religious unifier of Bhutan, it was later reconstructed in the 1960s without nails or architectural plans. Spend the night in Thimphu at your hotel. <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: PROCEED FROM SIX SENSES THIMPHU "PALACE IN THE SKY" TO SIX SENSES PUNAKHA "FLYING FARMHOUSE"</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your day with a soothing cup of Hot Ngaja (sweet Bhutanese milk tea) while enjoying the stunning views of the valley from your room. After breakfast, you'll head towards Punakha, the former capital of Bhutan. Situated at an elevation of 1,200 meters, Punakha offers a peaceful retreat away from the hustle and bustle of city life. The valley enjoys a mild climate in winter and cool temperatures in summer, making it a pleasant destination year-round. The area is also known for its rice farming along the Pho and Mo Chu rivers. On the way, you’ll stop at some of the must-see attractions. <br /><br /></p>

                                                <p><b>Dochula Pass</b>: A highlight of your <strong>Luxury Travel to Bhutan – Six Senses</strong>, Dochula Pass is a scenic viewpoint located just an hour's drive from Thimphu. At an altitude of 10,000 feet, it offers breathtaking views of the Eastern Himalayas on clear days. The pass is decorated with colorful prayer flags and features 108 chortens, which are a key attraction. Be sure to pause and walk around the chortens to take in the peaceful atmosphere. The 108 memorial chortens were built in 2005 to honor the memory of Bhutanese soldiers who sacrificed their lives in 2003. This is an essential stop on any <strong>Six Senses Holidays in Bhutan</strong>. <br /><br /></p>

                                                <p><b>Punakha Dzong</b>: Known as <b>The Palace of Great Happiness</b>, Punakha Dzong is one of the most beautiful fortresses in Bhutan, situated at the confluence of the Pho Chhu (Father) and Mo Chhu (Mother) rivers in the Punakha Valley. This impressive structure is a prime example of Bhutanese architecture, and it served as the seat of the government until 1955. You can reach the dzong by crossing an arched wooden bridge, which adds to its charm. It's a fascinating site filled with historical relics from Bhutan’s royal past. Don't miss this incredible landmark during your <strong>Six Senses Luxury Tours in Bhutan</strong>. <br /><br /></p>

                                                <p><b>Suspension Bridge</b>: This hidden gem in the Punakha Valley connects the valley to the old administrative center of Punakha Dzongkhag. The bridge is lined with prayer flags, and it's a great spot for bird watching and photography. The view of the dzong from here is spectacular, and you can enjoy a relaxing afternoon with a refreshing pomegranate martini by the river, surrounded by rice fields. As the sun sets, savor local delicacies like fresh spring rolls and dzao (popped Bhutanese rice). <br /><br /></p>

                                                <p><b>Chimi Lhakhang (Fertility Temple)</b>: Situated on top of a hill, Chimi Lhakhang is a temple dedicated to Lama Drukpa Kuenley, also known as the "Divine Madman." This temple is famous for its association with fertility, and it is believed that couples who are childless can receive blessings for children by visiting and praying at the temple. The road to the temple is a scenic walk through paddy fields, with a small stream and a short climb leading to the temple. This is a peaceful and spiritual site on your <strong>Six Senses Luxury Tours in Bhutan</strong>. Overnight stay at your hotel in Punakha. <br /><br /></p>

                                                <p>After checking in at <b>Six Senses Punakha</b>, relax in the cozy "Flying Farmhouse," a cantilevered wooden structure offering stunning views of the lodge’s heated swimming pool and the terraced rice fields below. Enjoy a delicious dinner and a restful sleep in this peaceful and luxurious setting. <br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: PUNAKHA SIGHTSEEING AND ACTIVITIES </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your day with a scenic hike across the beautiful Punakha Valley to visit a monastery, where you’ll have the chance to join young novice monks for a traditional monastic breakfast. Afterward, take time to engage with the monks and the chief abbot, learning about their daily lives and spiritual practices. This is a unique experience on your <strong>Bhutan Luxury Package Tour with Six Senses Hotel</strong>. <br /><br /></p>

                                                <p>On your way back to the lodge, stop by a local farmhouse to sample ara, a traditional Bhutanese moonshine. For lunch, we recommend enjoying a delicious Bhutanese Thali set poolside at <b>Six Senses Punakha</b>. This will give you the chance to savor local flavors while relaxing in a beautiful setting. <br /><br /></p>

                                                <p>In the afternoon, take a walk across Bhutan’s longest suspension bridge, offering spectacular views of the surrounding valley. Afterward, visit the iconic *Punakha Dzong*, one of Bhutan's most impressive architectural wonders. Located at the meeting point of the Pho Chhu (father) and Mo Chhu (mother) rivers, this six-story dzong is a stunning example of traditional Bhutanese architecture. It’s a must-see on your <strong>Luxury Bhutan Tour with Hotel Six Senses</strong>. <br /><br /></p>

                                                <p>Once back at the lodge, unwind with a rejuvenating <b>Six Senses Spa</b> treatment. Try the shirodhara session, a soothing therapy where a warm stream of oil is poured over your third eye to calm the nervous system and relieve stress. This is a perfect way to end your day after a full day of exploration. <br /><br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PROCEED FROM SIX SENSES PUNAKHA "FLYING FARMHOUSE" TO SIX SENSES PARO "STONE RUINS" </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>Today, you will travel back to Paro along the East-West Highway, retracing your route through the scenic mountains. On your descent into Thimphu, make a stop at Simtokha Dzong, Bhutan's first dzong, where you can enjoy some peaceful time in this historical and spiritual site. Next, visit the three stupas at Chuzom Bridge, each one showcasing different architectural styles from Bhutan, Tibet, and Nepal. This is a unique opportunity to see the cultural blend of the region on your <strong>Bhutan Luxury Tours with Six Senses Hotel</strong>. <br /><br /></p>

                                                <p>Upon arrival in Paro, visit the majestic Rinpung Dzong, a fortress that has been an important landmark for centuries. Afterward, cross Nyamai Zampa, a traditional cantilevered bridge, and take some time to explore the Paro National Museum, which houses a fascinating collection of Bhutanese artifacts. These stops are part of your <strong>Bhutan Tour with Six Senses Hotel</strong>, giving you a deep dive into the history and culture of Bhutan. <br /><br /></p>

                                                <p>Once you arrive at <strong>Six Senses Paro</strong>, take in the breathtaking views of the valley below from the lodge, which sits at an altitude of 9,415 feet (2,870 meters). The lodge is located near the ruins of a 15th-century fortress, adding to its charm and historic appeal. To get a feel for Bhutanese culture, challenge your guide and driver to a friendly game of archery, the national sport of Bhutan. <br /><br /></p>

                                                <p>After a day of exploration, unwind with a swim in the pool or treat yourself to a soothing Marma massage at <b>Six Senses Spa</b>, designed to help you relax and rejuvenate. Your stay at <b>Six Senses Bhutan</b> offers the perfect blend of luxury and tranquility, ensuring you enjoy a well-deserved rest. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING + ACTIVITIES</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>Wake up to the beautiful rays of the sun shining over the Paro valley, surrounded by nature. From your window, enjoy the stunning view of the river and mountains all in one breathtaking scene. After a hearty breakfast, be sure to fuel up with some protein as you'll need plenty of energy for your hike to the famous Taktsang Monastery. <br /><br />

                                                    <b>Taktsang Monastery</b>: Known as the Tiger’s Nest, this sacred site is perched on a cliff 1,200 meters above the valley floor. The hike to the top is challenging, but once you reach the monastery, the stunning views of the valley and surrounding mountains will make it all worth it. Taktsang Monastery was founded by Guru Rinpoche in 747 CE, and the complex was completed in 1694. The three-hour trek will take you through lush green valleys and offer spectacular views of Paro town, the clear blue sky, and the sparkling river. If you prefer, you can opt for a horse ride from the parking lot to the top. After the adventure, spend the night in Paro. <br /><br /><br /></p>
                                                <h4>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY <br /><br /></h4>
                                                <div id="no-more-tables">

                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                                <p>During your stay in Paro, take a short trip to Kyichu Lhakhang, a historic temple built in the 7th century, home to two magical orange trees that bear fruit all year round. <br /><br />

                                                    In the evening, visit the nearby Samtenling Monastery for a Buddhist blessing ceremony. Here, you can light 108 butter lamps as a symbol of sharing your blessings with all sentient beings before you leave. (Optional activity). <br /><br />

                                                    For a truly unforgettable evening, enjoy our <b>Chubjakha Ruins Regales</b> dinner. Dine under a canopy of stars next to the ancient ruins, dressed in traditional Bhutanese attire. Enjoy live music, folk dances, and a delicious Bhutanese feast prepared on an outdoor stone grill. (Optional activity). <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: SIX SENSES PARO "STONE RUINS" – PARO AIRPORT</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After early morning breakfast, start for Paro Airport with a sweet memory to cherish forever. Your <strong>Bhutan Luxury Package Tour with Six Senses Hotel</strong> ends here. <br /><br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />

                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>Bhutan Luxury Trip With Six Senses</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPackages">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePackages"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePackages"
                                                >
                                                    What is Six Senses Hotel Bhutan, and what makes it unique among hotels in the region ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePackages"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPackages"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Six Senses Hotel Bhutan is a luxury resort located in the heart of the Bhutanese Himalayas, offering guests an exclusive and immersive experience. What sets it apart from other hotels in the region is its strong commitment to sustainability, wellness, and preserving Bhutanese culture. The hotel blends traditional Bhutanese elements with modern comforts, making it the perfect base for a luxury Bhutan tour. With its beautiful location and focus on sustainable practices, a stay at Six Senses Bhutan offers more than just luxury—it offers a true connection with nature and culture. You can experience this unique blend firsthand when you book a Bhutan tour with Six Senses Hotel through Adorable Vacation, one of the top Bhutan tour operators in Asia.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPermits">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePermits"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePermits"
                                                >
                                                    What is the cancellation policy for reservations made at Six Senses Hotel Bhutan, and are there any penalties or fees for cancellations?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePermits"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPermits"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The cancellation policy at Six Senses Hotel Bhutan may vary depending on the room type, package, and seasonal factors. For specific details, you can contact Six Senses Hotel directly or reach out to Adorable Vacation, who can assist you with the cancellation policy and any associated fees.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingBusiness">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseBusiness"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseBusiness"
                                                >
                                                    Are there any special discounts or promotions currently available for Six Senses Hotel Bhutan, and how can I take advantage of them ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseBusiness"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingBusiness"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Six Senses Hotel Bhutan sometimes offers special discounts and promotions. You can check their website or directly contact their reservations team to learn about current offers. If you need assistance, the Bhutan travel experts at Adorable Vacation can also provide information about available discounts and help you make the most of your stay at Six Senses Bhutan.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGroups">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGroups"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGroups"
                                                >
                                                    What are the dining options available at Six Senses Hotel Bhutan, and what types of cuisine are served?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGroups"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGroups"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Six Senses Hotel Bhutan offers a variety of dining options, including a main restaurant, a lounge, and private dining areas. The food is centered around fresh, organic ingredients, with a focus on traditional Bhutanese flavors combined with international cuisine. The talented chefs at Six Senses Bhutan are passionate about crafting dishes that showcase both local ingredients and global culinary techniques, ensuring a unique and delicious dining experience.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingDestinations">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseDestinations"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseDestinations"
                                                >
                                                    What activities and amenities are available at Six Senses Hotel Bhutan, and how can I make the most of my stay there ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseDestinations"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingDestinations"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Six Senses Hotel Bhutan offers a wide range of activities and amenities designed to enhance your stay. These include spa treatments, yoga and meditation classes, cultural experiences, and outdoor adventures like hiking, cycling, and archery. You can also experience traditional Bhutanese activities, such as making ara (local moonshine) and participating in the Bhutanese game of khuru (dart throwing). To make the most of your stay, it's recommended to plan ahead and contact Adorable Vacation to help schedule the best activities for your interests.<br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGuides">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGuides"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGuides"
                                                >
                                                    What is the location of Six Senses Hotel Thimphu, and what are some nearby attractions and activities that guests can enjoy ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGuides"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGuides"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Six Senses Hotel Thimphu is located in the picturesque Thimphu Valley, at an elevation of 9,040 feet (2,755 meters). The hotel is surrounded by breathtaking views of the Himalayas and offers easy access to nearby attractions such as the National Memorial Chorten, Buddha Dordenma Statue, Semtokha Dzong, Simply Bhutan Museum, and Tashichho Dzong. Guests can enjoy activities like hiking, visiting local monasteries, exploring Bhutan's rich cultural heritage, and enjoying the serene environment of the valley.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingQuality">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseQuality"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseQuality"
                                                >
                                                    What is the check-in and check-out process like at Six Senses Hotel Bhutan, and what are the hours of operation for the front desk ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseQuality"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingQuality"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The check-in and check-out process at Six Senses Hotel Bhutan is designed to be seamless and efficient. The friendly and professional staff is available to assist you throughout your stay. The front desk operates 24 hours a day to accommodate guests' needs. Check-in is typically from 3:00 PM, and check-out is by 12:00 PM.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCustomize">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCustomize"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCustomize"
                                                >
                                                    How can I get to Six Senses Hotel Thimphu, and are there any transportation options available from the airport or other locations in Bhutan ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCustomize"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCustomize"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    To reach Six Senses Hotel Thimphu, guests can fly into Paro International Airport, the main gateway to Bhutan. From there, the hotel offers private car or helicopter transportation to Thimphu, which is about a one-hour drive away. Alternatively, Adorable Vacation can arrange a luxury car and chauffeur service to suit your preferences and budget. The journey from the airport to the hotel offers stunning views of Bhutan's beautiful countryside and mountains.<br /><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>


                                            {/* For google */}
                                            {/* <div itemscope='' itemtype='http://schema.org/Product'>
                                                <div itemprop='name'><h3 className='dropdown-item2'>Bhutan Tour Reviews</h3></div>
                                                <div itemprop='aggregateRating itemscope' itemtype='http://schema.org/AggregateRating'>
                                                    <span className='dropdown-item2' itemprop='ratingValue'>4.8</span> <span className='dropdown-item2'>Stars Rating, out of</span> <span itemprop='bestRating' className='dropdown-item2'>5</span><br />
                                                    <span className='dropdown-item2'>based on</span> <span itemprop='ratingCount' className='dropdown-item2'>278</span> <span className='dropdown-item2'>Reviews</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_PackageTourSixsenseDirectFlightIndia;