import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Sikkim_Darjeeling/InclusionExclusion_NorthSikkim_Silkroute_8N_9D";
class NorthSikkim_Silkroute_8N_9D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-packages-with-gangtok-lachen-lachung-zuluk-rongpokhola.webp";
    const packageTopBanner2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-package-tour-with-gangtok-lachen-lachung-zuluk-rongpokhola.webp";
    const sightseen1ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-1.webp";
    const sightseen2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-2.webp";
    const sightseen3ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-3.webp";
    const sightseen4ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-4.webp";
    const sightseen5ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-5.webp";
    const sightseen6ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-6.webp";
    const sightseen7ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-7.webp";
    const sightseen8ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-8.webp";
    const sightseen9ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-9.webp";
    const sightseen10ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-10.webp";
    const sightseen11ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-11.webp";
    const sightseen12ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-12.webp";
    const navDay1ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-gangtok.webp";
    const navDay2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-lachen.webp";
    const navDay3ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-gurudongmar-lake.webp";
    const navDay4ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-yumthang-valley.webp";
    const navDay5ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package-Cost.webp";
    const navDay6ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-nathang-valley.webp";
    const navDay7ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-zuluk.webp";
    const navDay8ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silkroute-north-sikkim-tour-with-rongpokhola.webp";
    const navDay9ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package-with-Silkroute.webp";

    const CallButtonSoumyaImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/talk-to-silk-route-expert.webp";
    const Review1ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-review-1.webp";
    const Review2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/silk-route-tour-package-with-gangtok-north-sikkim-review-2.webp";
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM</strong> - BEST DEAL 2025 SUMMER HOLIDAYS</center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center><strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM</strong> - BEST DEAL 2025 SUMMER HOLIDAYS</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        6 Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Gangtok
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lachen
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lachung
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Nathang
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Zuluk
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Rongpokhola
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <br />
                    <p className="p-2">
                      Looking for a unique and unforgettable Summer Vacation? Discover the breathtaking beauty of North Sikkim and the legendary Silk Route with Gangtok on our specially crafted <strong>Silk Route Package Tour with North Sikkim</strong>. Adorable Vacation specializes in offering <a href="https://adorablevacation.com/silk-route-package-tour-from-kolkata/" target="_blank"><strong>Silk Route package tour from Kolkata</strong></a>, with the Best Prices and Exclusive Summer Offers.

                      <br /><br />

                      Our <strong>Silk Route Summer Special Group Tours</strong> are now OPEN FOR BOOKING, so don't miss out on this incredible opportunity. Whether you're planning a family getaway or an adventurous <strong>Silk Route group tour</strong>, we guarantee you'll have an unforgettable experience. Explore the scenic beauty of Zuluk, Old Silk Route, and North Sikkim while enjoying the best of Nature, Culture, and Adventure.

                      <br /><br />

                      Book your <strong>Silk Route & North Sikkim Summer Tour</strong> with us today and ensure a memorable vacation before our groups are fully booked!<br /><br />
                    </p>
                    <hr />
                    <br />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}>WE OFFER THE BEST <strong className="tomato">SILK ROUTE TOUR WITH NORTH SIKKIM</strong> <br /><br />
                      FOR BOOKING <strong className="tomato">NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE</strong>? <a href="tel:+91-9147061467"> CALL +91-91470-61467</a> <br /><br /></h2>
                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>WE OFFER THE BEST <strong className="tomato">SILK ROUTE TOUR WITH NORTH SIKKIM</strong> <br /><br />
                      FOR BOOKING <strong className="tomato">NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE</strong>? <a href="tel:+91-9147061467">  CALL +91-91470-61467</a> <br /><br /></h3>
                  </div>
                </div>
              </div>
              <br/><br/>
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>ADORABLE VACATION: SILK ROUTE WITH NORTH SIKKIM PACKAGE TOUR OPERATOR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ADORABLE VACATION: SILK ROUTE WITH NORTH SIKKIM PACKAGE TOUR OPERATOR</strong></h4>
                </div>
                <div className="card-body">
                  <p>Embark on an unforgettable summer adventure with our <strong>Silk Route Package Tour with North Sikkim</strong>, designed to offer you a unique blend of natural beauty, cultural heritage, and historical significance. This specially curated <strong>Silk Route Tour Package with North Sikkim</strong> will take you on an enchanting journey through the pristine landscapes of Lachen, Lachung, Gangtok, and the historic Silk Route villages of Zuluk and Nathang. As you travel through these captivating destinations, you'll experience the charm of quaint hamlets, scenic valleys, serene lakes, and towering Himalayan peaks that make North Sikkim and the Silk Route truly magical. <br /><br />

                    Your adventure begins in Gangtok, the bustling capital of Sikkim, where modern amenities meet traditional charm. Take a leisurely walk along the vibrant M G Marg, explore local markets, and enjoy panoramic views from the Gangtok Ropeway. From Gangtok, venture into the heart of North Sikkim, where you'll witness the surreal beauty of Tsomgo Lake, a sacred high-altitude lake surrounded by snow-capped peaks. Continuing your journey, you'll reach Lachen, home to the revered Gurudongmar Lake, one of the highest lakes in the world, where the tranquil waters reflect the mighty mountains that surround it. <br /><br />




                    Next, explore the scenic town of Lachung, which is a gateway to the breathtaking Yumthang Valley, also known as the Valley of Flowers. Here, you'll lose yourself in a kaleidoscope of alpine flowers, all set against the majestic backdrop of the Himalayas. From Lachung, your journey takes you to Zuluk, a historic village perched at over 10,000 feet on the ancient Silk Route. This part of the journey will immerse you in the history of the old trade route that once connected India with Tibet and China. As you traverse the winding roads of Zuluk, you'll be treated to panoramic views of rugged mountains, deep valleys, and remote villages, making it an experience like no other.<br /><br />




                    In addition to the stunning views of Zuluk, you'll also visit Nathang and Rongpokhola, two serene spots where the natural beauty of Sikkim remains untouched by time. Finally, a trip to Zero Point, one of the highest accessible points in Sikkim, will leave you in awe of the pristine Himalayan landscapes that stretch as far as the eye can see. <br /><br />


                    This <strong>North Sikkim Package Tour with Silk Route</strong> offers the perfect blend of adventure, culture, and natural beauty. Whether you're looking to explore the historic Silk Route, trek through picturesque valleys, or simply escape into the tranquility of the Himalayas, this journey has something for everyone. The carefully crafted itinerary ensures you experience the best of <strong>Silk Route Tour with North Sikkim</strong>, from the vibrant energy of Gangtok to the peaceful serenity of Lachen and Lachung, along with the historical significance of the ancient trade route.

                    <br /><br />

                    So, if you're seeking a summer vacation that combines breathtaking landscapes, serene nature, and unforgettable experiences, our <strong>Silk Route Package with North Sikkim</strong> is the perfect choice. Book your adventure today and immerse yourself in the charm and beauty of one of India's most pristine regions. <br /><br /></p>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr><td>Duration for <strong>Silk Route Tour Packages with North Sikkim</strong></td><td>8N | 9D</td></tr>
                        <tr><td>Pickup & Drop Point for <strong>Silk Route Package Tour Booking with North Sikkim</strong></td><td>NJP / Siliguri Station</td></tr>
                        <tr><td>Nearest Rail Station from Silk Route</td><td>NJP / Siliguri Station</td></tr>
                        <tr><td>Nearest Bus Stand from Silk Route</td><td>Tenzing Norgay Bus Terminus, Siliguri</td></tr>
                        <tr><td>Nearest Airport from Silk Route</td><td>Bagdogra International Airport [IXB]</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen5ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              {/* start short itinerary */}
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DURATION FOR <strong>SILK ROUTE TOUR PACKAGE WITH GANGTOK, NORTH SIKKIM</strong> : 8 NIGHTS | 9 DAYS </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DURATION FOR <strong>SILK ROUTE TOUR PACKAGE WITH GANGTOK, NORTH SIKKIM</strong> : 8 NIGHTS | 9 DAYS </h3>
                </div>
                <div className="card-body">
                  <br/><br/>
                  <h4>DESTINATIONS COVERED IN 8 NIGHTS 9 DAYS <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE</strong> : <span className="tomato">GANGTOK 3N | LACHEN 1N | LACHUNG 1N | NATHANG 1N | ZULUK 1N | RONGPOKHOLA 1N </span></h4><br />

                  <h4>PICKUP & DROP POINT FOR <strong>SILK ROUTE PACKAGE WITH NORTH SIKKIM</strong> : <span className="tomato">NJP OR SILIGURI STATION / SILIGURI BUS STOP </span></h4>
                  <br/><br/>
                </div>
              </div>
              <br /><br /><br />
              <table className="img-center">
                <tbody>
                  <tr>
                    <td className="image-cell">
                      <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR WITH NORTH SIKKIM</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR WITH NORTH SIKKIM</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Gangtok</td>
                          <td style={{ fontSize: "16px" }}>Lachen</td>
                          <td style={{ fontSize: "16px" }}>Kabi Lungstok</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Seven Sister Waterfalls</td>
                          <td style={{ fontSize: "16px" }}>Naga Waterfalls</td>
                          <td style={{ fontSize: "16px" }}>Gurudongmar Lake</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Lachung</td>
                          <td style={{ fontSize: "16px" }}>Yumthang Valley</td>
                          <td style={{ fontSize: "16px" }}>Zero Point</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Silent Valley</td>
                          <td style={{ fontSize: "16px" }}>Ramiteydara Viewpoint</td>
                          <td style={{ fontSize: "16px" }}>Lampokhri Lake</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Ariter Lake</td>
                          <td style={{ fontSize: "16px" }}>Mankhim Viewpoint</td>
                          <td style={{ fontSize: "16px" }}>Lingtam Monastery</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Kuekhola Falls on Old Silk Route</td>
                          <td style={{ fontSize: "16px" }}>Thambi Viewpoint</td>
                          <td style={{ fontSize: "16px" }}>Old Baba Mandir</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>War Memorial at Tukla Valley</td>
                          <td style={{ fontSize: "16px" }}>Kupup Lake (Elephant Lake)</td>
                          <td style={{ fontSize: "16px" }}>Yak Golf Course</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>PLACES</b></th><th><b>ALTITUDE</b></th><th><b>CO-ORDINATES</b></th></tr></thead>
                      <tbody>
                        <tr><td>Gangtok</td><td>1,650 ft</td><td>27.3314° N, 88.6138° E</td></tr>
                        <tr><td>Lachen</td><td>8,838 ft</td><td>27.7319° N, 88.5490° E</td></tr>
                        <tr><td>Lachung</td><td>2,700 m</td><td>27.6891° N, 88.7430° E</td></tr>
                        <tr><td>Gurudongmar Lake</td><td>5,425 m</td><td>28.0258° N, 88.7092° E</td></tr>
                        <tr><td>Yumthang Valley</td><td>11,394 ft</td><td>27.8268° N, 88.6958° E</td></tr>
                        <tr><td>Zero Point</td><td>15,300 ft</td><td>27.9305° N, 88.7346° E</td></tr>
                        <tr><td>Tsomgo Lake</td><td>12,300 ft</td><td>27.3742° N, 88.7619° E</td></tr>
                        <tr><td>SilleryGaon</td><td>6,000 ft</td><td>27.1396° N, 88.5804° E</td></tr>
                        <tr><td>Aritar</td><td>1,498 m</td><td>27.1879° N, 88.6748° E</td></tr>
                        <tr><td>Zuluk</td><td>2,865 m</td><td>27.2518° N, 88.7775° E</td></tr>
                        <tr><td>Nathang</td><td>13,500 ft</td><td>27.2986° N, 88.8173° E</td></tr>
                        <tr><td>Rongpokhola</td><td>3,000 ft</td><td>27.2215° N, 88.6892° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                 
                </div>
              </div>
              <br /><br /> <br/><br/>
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE SUMMARY FOR <strong>SILK ROUTE TOUR PACKAGE WITH NORTH SIKKIM</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE SUMMARY FOR <strong>SILK ROUTE TOUR PACKAGE WITH NORTH SIKKIM</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                PICKUP FROM NJP/BAGDOGRA TRANSFER TO GANGTOK
                              </span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>GANGTOK TO LACHEN TRANSFER</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>GURUDONGMAR LAKE EXCURSION, TRANSFER TO LACHUNG</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>LACHUNG TO GANGTOK VIA YUMTHANG VALLEY</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>FULL DAY GANGTOK CITY TOUR</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>GANGTOK TO NATHANG VALLEY</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>NATHANG TO ZULUK TOUR</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>ZULUK TO RONGPOKHOLA TOUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                              <span>RONGPOKHOLA TO NJP/SILIGURI</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Review1ImageURL} alt="Travel with the Best DMC Bhutan" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}><strong>SILK ROUTE PACKAGE TOUR COST WITH NORTH-SIKKIM & GANGTOK</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><strong>SILK ROUTE PACKAGE TOUR COST WITH NORTH-SIKKIM & GANGTOK</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>SILK ROUTE PACKAGE CATEGORY</b></th><th><b>SILK ROUTE TOUR DURATION</b></th><th><b>SILK ROUTE TOUR COST</b></th><th><b>BOOK NOW</b></th></tr></thead>
                      <tbody>
                        <tr>
                          <td>Standard Package</td>
                          <td>4 Nights 5 Days</td>
                          <td>Rs. 6,499/P (based on 8 pax)</td>
                          <td><a href="tel:+91-98833-59713" className="btn btn-success btn-md">
                            CALL
                          </a></td>
                        </tr>
                        <tr><td>Standard Package</td><td>5 Nights 6 Days</td><td>Rs. 7,699/P (based on 8 pax)</td>
                          <td><a href="tel:+91-98833-59713" className="btn btn-success btn-md">
                            CALL
                          </a></td>
                        </tr>
                        <tr><td>Deluxe Package</td><td>5 Nights 6 Days</td><td>Rs. 9,999/P (based on 8 pax)</td>
                          <td><a href="tel:+91-98833-59713" className="btn btn-success btn-md">
                            CALL
                          </a></td>
                        </tr>
                        <tr><td>SilkRoute + North Sikkim</td><td>6 Nights 7 Days</td><td>Rs. 16,900/P (based on 8 pax)</td>
                          <td><a href="tel:+91-98833-59713" className="btn btn-success btn-md">
                            CALL
                          </a></td></tr>
                        <tr><td>SilkRoute + North Sikkim + Gangtok</td><td>8 Nights 9 Days</td><td>Rs. 19,999/P (based on 8 pax)</td>
                          <td><a href="tel:+91-98833-59713" className="btn btn-success btn-md">
                            CALL
                          </a></td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen8ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>HOW TO BOOK YOUR <strong>SILK ROUTE PACKAGE WITH NORTH SIKKIM</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>HOW TO BOOK YOUR <strong>SILK ROUTE PACKAGE WITH NORTH SIKKIM</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Before booking your <strong>North Sikkim tour package with Silk Route</strong>, it's important to understand the travel logistics to ensure a smooth and enjoyable experience. Once you're ready, the next step is booking your <strong>Silk Route Package with North Sikkim</strong>. This is where Adorable Vacation, your trusted Sikkim tour specialist, comes in. We guide you through the entire process, providing all the details you need to make an informed decision about your trip.<br /><br />
                    When you reach out to Adorable Vacation, our team of Sikkim travel experts will assist you with everything, from customizing your tour <strong>Silk Route Itinerary</strong> to choosing the best hotels, homestays, and car rentals for your comfort and convenience. We ensure that your <a href="https://adorablevacation.com/silk-route-package-tour-from-kolkata/" target="_blank"><strong>Silk Route package tour</strong></a> includes all major sightseeing destinations, like Gangtok, Lachen, Lachung, Zuluk, and the Old Silk Route itself. Additionally, we cover all your meals, transportation, pickup and drop-off services, and even provide a detailed guide. <br /><br />
                    Once you finalize the details of your trip, we will provide you with the payment details for the <strong>Silk Route Package booking with North Sikkim</strong>. You can secure your booking by paying a certain amount upfront, and the rest of the payment can be made as per the travel date. For your convenience, we also accept spot payments, allowing for flexibility in your payment process. <br /><br /><br /></p>
                </div>
              </div>
              <br /><br /><br />
              <table className="img-center">
                <tbody>
                  <tr>
                    <td className="image-cell">
                      <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HIGHLIGHTS OF A <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HIGHLIGHTS OF A <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong></h4>
                </div>
                <div className="card-body">
                  <p>Booking your <strong>Silk Route Tour Package with North Sikkim & Gangtok</strong> through Adorable Vacation is the easiest and most reliable way to explore this beautiful region. Our personalized service, expertise in Sikkim tourism, and attention to detail ensure that you will have a seamless travel experience. From crafting the perfect itinerary to offering recommendations on the best activities and spots along the Silk Route, we make your journey smooth, hassle-free, and unforgettable. <br /><br />
                    Whether you're traveling for the first time or returning for another adventure, Adorable Vacation will ensure that your <strong>trip to North Sikkim and the Silk Route</strong> is memorable, comfortable, and filled with awe-inspiring moments. Don't wait - reach out to us today, and let us help you plan your dream holiday to <strong>North Sikkim and Silk Route tour in 2025</strong>.<br /><br /></p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen11ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR <strong>SILK ROUTE PACKAGE TOUR BOOKING WITH NORTH SIKKIM</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR <strong>SILK ROUTE PACKAGE TOUR BOOKING WITH NORTH SIKKIM</strong>?</h4>
                </div>
                <div className="card-body">
                  <li>We have pre-purchased room inventory in all key Silk Route destinations like Sillerygaon, Zuluk, Nathang, and Rongpokhola. <br /><br /></li>
                  <li>Based on your <strong>Silk Route tour booking with Gangtok & North Sikkim</strong>, we ensure you get a hill-facing room with beautiful views, depending on your booking date. <br /><br /></li>
                  <li>We offer both premium and deluxe hotel/home stay options with top-notch facilities and amenities during your <strong>Silk Route tour from Gangtok</strong>. <br /><br /></li>
                  <li>Deluxe homestays in Zuluk, Gnathang, Rongpokhola, and Sillery-Gaon are part of our offerings, with excellent comfort and hospitality. <br /><br /></li>
                  <li>Vehicles are provided from NJP, Bagdogra, Siliguri depending on your <strong>Silk Route tour package booking with Lachen & Lachung of North Sikkim</strong>. <br /><br /></li>
                  <li>For a couple or family of three with a standard package, we provide a Wagon-R or Santro.<br /><br /></li>

                  <li>For <strong>Silk Route group tours</strong>, we arrange vehicles like Sumo or Bolero. For small deluxe groups, we offer Xylo or Scorpio. <br /><br /></li>

                  <li>The choice of vehicle depends on your budget and the number of travelers. <br /><br /></li>
                  <li>We have a dedicated team and a "war room" specifically for <strong>Silk Route Tour</strong> to ensure uninterrupted service. <br /><br /></li>
                  <li>Our team offers guidance on peak season rush, weather, and off-season conditions to ensure safety and value for money during your tour. <br /><br /></li>
                  <li>Adorable Vacation specializes in <strong>Silk Route tour from Kolkata</strong>, and we have years of experience in curating and managing tours to this region. <br /><br /></li>
                  <li>We personally visit and certify every hotel and homestay to ensure top-quality accommodation and service for our guests. <br /><br /></li>
                  <li>We organize <strong>Silk Route group tour</strong> during popular times like Summer Vacations, Durga Puja holidays, Christmas and New Year Holidays ensuring a comfortable and enjoyable journey. <br /><br /></li>
                  <li>Our team provides complete day-to-day assistance, from NJP to NJP, during your <strong>Silk Route tour with North Sikkim</strong>. <br /><br /></li>
                  <li>Every trip is managed directly by Adorable Vacation without involving sub-agents, ensuring a seamless and personalized experience. </li>
                  <br />
                  <br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Review2ImageURL} alt="silk-route-tour-package-with-gangtok-north-sikkim-review-2" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>QUICK FACTS NEED TO KNOW BEFORE BOOKING <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>QUICK FACTS NEED TO KNOW BEFORE BOOKING <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>The Silk Route is a remote yet pristine <b>Sikkim offbeat destination</b>, offering a unique experience but with limited modern facilities and amenities. Some travelers may have high expectations regarding food, facilities, and hotel rooms in this area. So, it's important to understand a few basic facts before you proceed with your <strong>Silk Route tour package booking from Kolkata</strong>.<br /><br /></p>

                  <li>When you book a <strong>Silk Route package tour from Kolkata with North Sikkim [Lachen & Lachung]</strong>, don't expect modern amenities. Most of the destinations along the route are remote villages, far from urban facilities. Basic facilities like western toilets, running water, hot meals (usually egg curry and local vegetables) are available, but luxuries like TV, internet, geysers, or mineral water are not. <br /><br /></li>

                  <li>Staying in Nathang Valley is not advisable for children or elderly travelers. Nathang, located at an altitude of 13,200 ft, is popular among Silk Route travelers and often referred to as the "<b>Ladakh of East India</b>" due to its similarity to Leh Ladakh. However, due to the extreme altitude and cold, travelers may experience dizziness, headaches, and nausea. Temperatures can drop below freezing at night. We recommend that elderly travelers only visit Nathang during the day and return to a lower altitude after a short visit. Carrying a portable oxygen cylinder for safety is also advised. <br /><br /></li>

                  <li>For your <strong>Silk Route tour packages from Kolkata</strong>, always carry original and photocopies of your ID proofs (such as voter ID, passport, or driver's license), along with at least two passport-sized photos. If you're traveling with children, remember to carry their birth certificates or school IDs. <br /><br /></li>

                  <li>During your <strong>Silk Route tour from Kolkata</strong>, it's important to carry essential supplies for emergencies. Pack dry fruits, nuts, chocolates, hot coffee, ginger cubes, and biscuits. These come in handy in case of roadblocks, landslides, or sudden weather changes. It's also a good idea to carry a portable oxygen cylinder and oxygen meter. In case of a serious emergency, the Indian Army and Border Roads Organisation (BRO) are always available to assist. Additionally, for high-altitude locations like Gnathang Valley, Elephant Lake, and Kupup, carry items like camphor, chocolates, and ginger cubes to help with breathing. If you're on prescribed medication, don't forget to bring it along. <br /><br /></li>

                  <li>Make sure to pack a first aid kit with medicine for fever, cold, cough, headaches, stomach issues, and general pain relief (such as paracetamol, ointment, band-aids, and Vaseline). Woolen clothes, gloves, hats, and wind-resistant gear are essential for high-altitude regions where sub-zero temperatures may occur. Carry enough cash in small denominations, as ATMs are unavailable after Rongli. Withdraw cash from NJP or Bagdogra before heading to the higher altitudes, as ATM links may fail in remote areas. GST will also be applicable to your <strong>Silk Route tour package cost</strong>. <br /><br /></li>

                  <li>Bikers should ensure they have all necessary documents for their vehicles. Failure to provide valid papers can result in difficulties during your <strong>Silk Route tour</strong>. Additionally, we recommend avoiding alcohol at high altitudes, but if you still wish to bring your own, make sure to carry your preferred brand with you, as options may be limited in remote areas. If you visit lower-altitude areas like Sillerygaon, Ramdhura, or Rongpokhola, be cautious of leeches. Lastly, remember that Sikkim is a plastic-free state, so please avoid littering.<br /><br /></li>

                  <li>Above all, enjoy your <strong>Silk Route tour packages</strong> with Adorable vacation. Don't worry too much about what you have or haven't brought—just relax and soak in the beauty of this incredible journey. <br /></li>

                  <br /><br /><br />
                  <table className="img-center">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DISTANCE BETWEEN PLACES TO NOTE FOR <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DISTANCE BETWEEN PLACES TO NOTE FOR <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>FROM</b></th><th><b>TO</b></th><th><b>DISTANCE</b></th></tr></thead>
                      <tbody><tr><td>NJP</td> <td>Gangtok</td> <td>3 hr 54 min (118.1 km) via NH10</td> </tr>
                        <tr><td>Bagdogra</td> <td>Gangtok</td> <td>3 hr 50 min (122.2 km) via NH10</td></tr>
                        <tr><td>Gangtok</td> <td>Lachen</td> <td>4 hr 33 min (114.0 km) via Gangtok-Chungthang Rd</td></tr>
                        <tr><td>Lachen</td> <td>Gurudongmar Lake</td> <td>2 hr 30 min (65.9 km) via Gurudongmar Road</td></tr>
                        <tr><td>Lachen</td> <td>Lachung</td> <td>1 hr 44 min (46.9 km) via Lachen Rd</td></tr>
                        <tr><td>Lachung</td> <td>Yumthang Valley</td> <td>By Road - 1 hr 22 min (25 km)</td></tr>
                        <tr><td>Lachung</td> <td>Gangtok</td> <td>4 hr 10 min (109.2 km) via Singtam - Chungthang Rd</td></tr>
                        <tr><td>Gangtok</td> <td>Tsomgo Lake</td> <td>1 hr 29 min (44.1 km) via Jawaharlal Nehru Rd</td></tr>
                        <tr><td>Tsomgo Lake</td> <td>Baba Mandir</td> <td>Tsomgo Lake to baba mandir</td></tr>
                        <tr><td>Zuluk</td><td>Nathang</td><td>1 hr (23.2 km) via Dzuluk-Nathang Valley Part I Rd</td></tr>
                        <tr><td>Nathang</td><td>Rongpokhola</td><td>2 hr 47 min (61.0 km) via Rongli - Chujachen Rd</td></tr>
                        <tr><td>Rongpokhola</td><td>NJP</td><td>122 km via NH10</td></tr>
                      </tbody>
                    </table>
                  </div>

                  {/* <br /><br /><br />
                  <table className="img-center">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHAT ARE THE ATTRACTIONS OF <strong>NORTH SIKKIM, LACHEN LACHUNG TOUR PACKAGE WITH EAST SIKKIM SILK ROUTE</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHAT ARE THE ATTRACTIONS OF <strong>NORTH SIKKIM, LACHEN LACHUNG TOUR PACKAGE WITH EAST SIKKIM SILK ROUTE</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Embarking on a <strong>Silk Route Package Tour with Gangtok and North Sikkim</strong> offers a magical journey through the pristine landscapes of Sikkim. This carefully curated <strong>Silk Route Tour Package with Gangtok and North Sikkim</strong> allows travelers to explore iconic attractions, experience vibrant cultures, and immerse themselves in breathtaking natural beauty. Whether you're looking for detailed <strong>Silk Route Package Tour Booking with North Sikkim</strong> or want to understand the <strong>Silk Route Package Tour Cost with North Sikkim</strong>, this guide highlights the top destinations that make your trip unforgettable. <br /><br /><br /></p>
                  <h4 className="tomato1">TOP ATTRACTIONS TO NOTE FOR <strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM AND GANGTOK</strong></h4><br /><br />

                  <p><b>Lachen</b> :
                    This peaceful village is a gateway to the majestic Gurudongmar Lake and Thangu Valley. Experience its tranquility while enjoying panoramic views of the Himalayan ranges. <br /><br /><br />


                    <b>Lachung</b> : Nestled amid towering mountains, Lachung is a key highlight of the <strong>Silk Route Tour Package</strong>. It is the base for exploring the Yumthang Valley, the "Valley of Flowers" and nearby attractions like Mount Katao. <br /><br /><br />


                    <b>Gurudongmar Lake</b>
                    : Sacred high-altitude lake, its beauty is unparalleled. Often included in <strong>Silk Route Package Tour Cost with North Sikkim</strong>, it's a once-in-a-lifetime experience for travelers. <br /><br /><br />


                    <b>Yumthang Valley
                    </b> : This is an unmissable stop in the <strong>Silk Route Package Tour with Gangtok and North Sikkim</strong>. Known for its springtime rhododendron blooms, the valley is surrounded by snow-clad peaks and lush meadows.
                    <br /><br /><br />

                    <b>Thangu Valley
                    </b> : Situated close to Lachen, this picturesque valley is perfect for nature enthusiasts and often part of <strong>Silk Route Tour Package Booking with North Sikkim</strong>.
                    <br /><br /><br />

                    <b>Chopta Valley</b> : A hidden gem in North Sikkim, Chopta Valley is noted for its serene landscapes and vibrant alpine flora. <br /><br /><br />


                    <b>Chungthang</b> :
                    At the confluence of the Lachen and Lachung rivers, Chungthang is a fascinating town steeped in legends, often visited as part of the <strong>Silk Route Tour Package with North Sikkim</strong>.
                    <br /><br /><br />

                    <b>Seven Sisters Waterfalls</b> :
                    A must-visit natural attraction included in most <strong>Silk Route Package Tour Cost</strong>, especially during the monsoon when the cascades are at their peak. <br /><br /><br />


                    <b>Mangan</b> :
                    Known for its annual music festival, this town is an important stop in the <strong>Silk Route Package Tour Booking with North Sikkim</strong>.
                    <br /><br /><br />

                    <b>Mount Katao
                    </b> : Accessible with a special permit, Mount Katao is a thrilling destination for snow activities, perfect for adventure enthusiasts.
                    <br /><br /><br />

                    <b>Singhik Viewpoint</b> :
                    Known for its unobstructed views of Mount Kanchenjunga, it's a serene stop included in many <strong>Silk Route Package Tours with North Sikkim</strong>.<br /><br /><br />


                    <b>Phodong Monasteries</b> :
                    These monasteries offer a spiritual retreat and showcase the rich Buddhist heritage of the region.<br /><br /><br />
                  </p>

                  <p>By opting for a <strong>Silk Route Package Tour with Gangtok and North Sikkim</strong>, travelers can seamlessly explore these destinations with well-planned itineraries and enjoy a hassle-free experience. For details on <strong>Silk Route Package Tour Costs and Silk Route Tour Package Bookings</strong>, reach out to trusted travel operators and ensure an unforgettable journey through the enchanting landscapes of North Sikkim.</p>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen6ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 6" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHICH IS THE BEST TIME FOR BOOKING <strong>SILK ROUTE TOUR PACKAGE WITH NORTH SIKKIM, LACHEN LACHUNG & GANGTOK</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHICH IS THE BEST TIME FOR BOOKING <strong>SILK ROUTE TOUR PACKAGE WITH NORTH SIKKIM, LACHEN LACHUNG & GANGTOK</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>The best time for booking a <strong>Silk Route Tour Package with Gangtok, North Sikkim</strong> largely depends on the experience you're looking for and the weather conditions in the region. Each season offers a unique charm, and understanding the month-wise climate of Gangtok, Lachen, and Lachung can help you plan the perfect trip.

                    <br /><br />

                    From March to May, the region comes alive with vibrant blossoms and pleasant weather, making it an ideal time for a <strong>Silk Route Package Tour Booking with North Sikkim</strong>. The temperatures during the day range between 10°C and 20°C, while nights are cooler, around 2°C to 10°C. Gangtok is filled with the colors of blooming rhododendrons, and Lachen and Lachung showcase lush greenery and gushing waterfalls, creating a serene backdrop for nature lovers.

                    <br /><br />

                    The monsoon season from June to September sees heavy rainfall, transforming the hills into a lush green paradise. While the mist-covered landscapes of Gangtok and Lachung are stunning, travel can be challenging due to frequent landslides. If you're considering visiting during this time, ensure your <strong>Silk Route Package Tour Cost with North Sikkim</strong> includes flexibility to accommodate weather-related changes.<br /><br />



                    October and November are perhaps the best months for a <strong>Silk Route Tour Package with Gangtok, North Sikkim</strong>. The post-monsoon season brings clear skies and cool, crisp weather, with daytime temperatures between 12°C and 20°C. This is the perfect time to enjoy unobstructed views of snow-capped peaks and explore iconic spots like Yumthang Valley in Lachung and Gurudongmar Lake near Lachen. The autumnal beauty of the region makes it a favorite among travelers.

                    <br /><br />

                    Winter, from December to February, turns Lachen and Lachung into a snowy wonderland. With daytime temperatures between 5°C and 10°C and nights dipping to -5°C, this season is ideal for those who love the charm of snow-covered landscapes. Gangtok offers a cozy retreat during this time, while the high-altitude lakes and valleys of North Sikkim present breathtaking vistas. However, some routes may be inaccessible due to heavy snowfall, so plan your <strong>Silk Route Package Tour Booking with North Sikkim</strong> carefully during winter.

                    <br /><br />

                    No matter the season, destinations like Gangtok, Lachen, and Lachung are bound to leave you spellbound with their natural beauty and cultural richness. Check the <strong>Silk Route Tour Package Cost with North Sikkim</strong> for your preferred travel time and embark on a journey to discover the mesmerizing landscapes and serene ambiance of the Silk Route. <br /><br /><br /></p>


                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Travel Months</th><th>Weather</th><th>Temperature</th></tr></thead>
                      <tbody><tr><td>April to June</td><td>Summer</td><td>9°C to 15°C</td></tr>
                        <tr><td>July to September</td><td>Monsoon</td><td>8°C to 10°C</td></tr>
                        <tr><td>October to March</td><td>Winter</td><td>-5° C to 4° C</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen5ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen7ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 6" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHEN IS THE BEST TIME TO EXPERIENCE SNOW DURING <strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM [LACHEN + LACHUNG]</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHEN IS THE BEST TIME TO EXPERIENCE SNOW DURING <strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM [LACHEN + LACHUNG]</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>The <strong>Silk Route Tour with North Sikkim</strong> is a favorite among travelers seeking a magical winter experience, especially during the snowfall season. The best time to visit for snow lovers is from mid-December to the first week of March, when the region is blanketed in snow, transforming it into a winter wonderland. This period offers breathtaking views of snow-capped mountains, serene valleys, and shimmering white landscapes, making it an unforgettable getaway.<br /><br />



                    Many tourists, especially from Bengal, prefer this season for their <strong>Silk Route Package with North Sikkim</strong> due to the enchanting charm of the snow-covered surroundings. The temperatures during winter range from -5°C to 7°C, providing the perfect conditions for enjoying the crisp mountain air and the tranquil beauty of the region. The peaceful ambiance and stunning vistas make it an ideal destination for those seeking a serene escape from the bustling city life.

                    <br /><br />

                    For anyone looking to immerse themselves in the beauty of snow and create cherished memories, a <strong>Silk Route Tour with North Sikkim</strong> during the snowfall season is an excellent choice. Plan your journey to this picturesque destination and experience the captivating allure of the Himalayan winter. <br /><br /><br /></p>

                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>TRAVEL TIPS FOR SILK ROUTE TOUR PACKAGE FROM KOLKATA WITH NORTH SIKKIM</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>TRAVEL TIPS FOR SILK ROUTE TOUR PACKAGE FROM KOLKATA WITH NORTH SIKKIM</h4>
                </div>
                <div className="card-body">
                  <p>When planning a <strong>Silk Route Package Tour with North Sikkim</strong>, it's important to understand that most destinations in this region are remote villages, far removed from the conveniences of modern cities. While basic amenities like western-style commodes, running water, and freshly prepared meals—often consisting of egg curry and local vegetables—are available, luxury items such as TVs, internet, geysers, and on-demand mineral water are not. Travelers should embrace the simplicity and charm of these rustic areas as part of the unique experience.

                    <br /><br />

                    Nathang Valley, known as the "<b>Ladakh of East India</b>" is one of the most breathtaking highlights of any <strong>Silk Route Tour with North Sikkim</strong>. Located at an altitude of 13,200 ft, it boasts stunning landscapes but presents challenges such as extreme cold and the possibility of altitude sickness. Symptoms like dizziness, vomiting, and headaches are common at this height. For this reason, it is advisable for elderly travelers and children to visit Nathang only during the daytime and return to lower altitudes for the night. Carrying a portable oxygen cylinder can provide added safety, especially as temperatures in Nathang Valley can plummet to sub-zero levels during the night.

                    <br /><br />

                    Travelers must carry original and photocopies of ID proofs such as voter cards, driving licenses, or passports, along with at least two passport-size color photographs. For children or minors, a birth certificate or school ID is required to complete documentation formalities.

                    <br /><br />

                    Packing thoughtfully is essential for a <strong>Silk Route Package with North Sikkim</strong>. Dry fruits, chocolates, and biscuits are handy for emergencies, as roadblocks, landslides, or heavy snowfall can sometimes delay journeys. Essential medicines for fever, cold, cough, headaches, and stomach issues, along with first-aid items such as ointments, band-aids, and pain-relief sprays, should also be included. Woolen clothing, gloves, hats, and windproof glasses are crucial, as high-altitude areas often experience freezing temperatures.<br /><br />



                    Cash is another critical consideration, as ATMs are not available beyond Rongli. Ensure you withdraw enough cash in small denominations from NJP or Bagdogra before heading into higher altitudes, where ATM services are often unreliable due to link failures or cash shortages.<br /><br />



                    Bikers planning a <strong>Silk Route Tour with North Sikkim</strong> must carry valid documents for their vehicles, as failure to present these at checkpoints could result in disruptions. Travelers should also avoid consuming alcohol at high altitudes, as it may affect their health. However, if necessary, carrying your preferred brand is advised, as local options in remote areas are limited.<br /><br />

                    Lastly, keep in mind that GST is applicable to the <strong>Silk Route Tour Package Cost with North Sikkim</strong>, so ensure that your package pricing reflects this. By following these guidelines, you can ensure a safe, comfortable, and unforgettable journey through the majestic landscapes of the <strong>Silk Route and North Sikkim</strong>.</p>
                    <br /><br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen8ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen10ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 6" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>IMPORTANT INFORMATION FOR ZERO POINT FOR <strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>IMPORTANT INFORMATION FOR ZERO POINT FOR <strong>SILK ROUTE PACKAGE TOUR WITH NORTH SIKKIM</strong></h4>
                </div>
                <div className="card-body">
                  <p>Zero Point, located at an altitude of 15,000 feet, is a must-visit spot for travelers on a Silk Route Tour Package with North Sikkim. However, the high altitude makes altitude sickness a common concern, particularly for those visiting Yumesamdong, also known as Zero Point. This tour is not recommended for travelers with breathing difficulties due to the thin air and cold temperatures. <br /><br />



                    To visit Zero Point, a valid Protected Area Permit (PAP) is mandatory. This permit can be obtained from the Tourism Office in Gangtok, the District Administrative Center in Mangan, or the Sub-Divisional Magistrate's Office in Chungthang. Travelers must ensure they acquire this permit in advance for a hassle-free journey. <br /><br />



                    Given the sub-zero temperatures at Zero Point, packing adequate warm clothing is essential. While visiting, travelers can rent snow boots, gloves, and overcoats on-site for approximately ? 100 per item. However, carrying your own gear is recommended for better comfort and fit. It's also crucial to carry enough cash for the trip, as there are no ATMs available in or around Zero Point.

                    Only vehicles registered with the Sikkim government are permitted to travel to Zero Point. <br /><br />

                    It's important to note that the destination is not included in standard <strong>North Sikkim Package Tour Itineraries</strong>. Visitors must pay the driver an additional fee to include Zero Point in their travel plans. <br /><br />



                    The best time to visit Zero Point is between April and June, when the weather is relatively stable and the breathtaking landscapes are at their most vibrant. Located near the India-China border, Zero Point is the farthest point tourists can visit, as travel beyond it is prohibited for security reasons.


                    <br /><br />

                    For a seamless and enjoyable experience, plan your <strong>Silk Route Package Tour with North Sikkim</strong> keeping these essential details in mind. Zero Point's stunning beauty and unique location make it a worthwhile addition to your North Sikkim adventure. <br /><br /><br /></p>
                  <br /><br /><br />
                  <table className="img-center">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>POPULAR TRAIN TICKETS TO BOOK FOR <strong>SILK ROUTE TOUR WITH GANGTOK & NORTH SIKKIM</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>POPULAR TRAIN TICKETS TO BOOK FOR <strong>SILK ROUTE TOUR WITH GANGTOK & NORTH SIKKIM</strong></h4>
                </div>
                <div className="card-body">
                  <p>Traveling the <strong>Silk Route from Kolkata with Gangtok and North Sikkim</strong> is both easy and cost-effective, especially with the convenience of train travel. Trains departing daily or weekly from Sealdah and Howrah stations provide a comfortable and budget-friendly way to embark on your journey. Whether you choose the affordability of an AC 3 Tier coach or the luxury of AC 1st Class, there are plenty of options to match your budget and preferences.

                    Booking your train tickets for a <strong>Silk Route Package Tour from Kolkata</strong> ensures a smooth and hassle-free journey to the picturesque destinations of East Sikkim along this historic route. With reliable connectivity and multiple departure options, the train journey sets the stage for an unforgettable adventure through the serene landscapes of the Silk Route.</p>


                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Train No</b></th><th><b>Train Name</b></th><th><b>Dep. Stn</b></th><th><b>Dep. Time</b></th><th><b>Arr. Stn</b></th><th><b>Arr. Time</b></th><th><b>Frequency</b></th></tr></thead>

                      <tbody>
                        <tr><td>22301</td><td>Vande Bharat Express</td><td>Howrah (HWH)</td><td>05:55</td><td>New Jalpaiguri Jn (NJP)</td><td>13:25</td><td>Except Wednesday</td></tr>
                        <tr><td>22302</td><td>Vande Bharat Express</td><td>New Jalpaiguri Jn (NJP)</td><td>15:05</td><td>Howrah (HWH)</td><td>22:35</td><td>Except Wednesday</td></tr>
                        <tr><td>12343</td><td>Darjeeling Mail</td><td>Sealdah (SDAH)</td><td>22:05</td><td>New Jalpaiguri Jn (NJP)</td><td>08:15</td><td>Daily</td></tr>
                        <tr><td>12377</td><td>Padatik Express</td><td>Sealdah (SDAH)</td><td>23:00</td><td>New Jalpaiguri Jn (NJP)</td><td>09:15</td><td>Daily</td></tr>
                        <tr><td>13149</td><td>Kanchan Kanya Express</td><td>Sealdah (SDAH)</td><td>20:30</td><td>New Mal Jn(NMZ)</td><td>09:31</td><td>Daily</td></tr>
                        <tr><td>13147</td><td>UttarBanga Express</td><td>Sealdah (SDAH)</td><td>19:35</td><td>Falakata (FLK)</td><td>08:38</td><td>Daily</td></tr>
                        <tr><td>13141</td><td>Teesta Torsa Express</td><td>Sealdah (SDAH)</td><td>13:40</td><td>New Maynaguri (NMX)</td><td>04:22</td><td>Daily</td></tr>
                        <tr><td>12345</td><td>SaraiGhat Express</td><td>Howrah (HWH)</td><td>15:50</td><td>New Jalpaiguri (NJP)</td><td>01:40</td><td>Daily</td></tr>
                        <tr><td>12517</td><td>Garib Rath</td><td>Kolkata (KOOA)</td><td>21:40</td><td>New Jalpaiguri (NJP)</td><td>07:45</td><td>Sun, Thrus</td></tr>
                      </tbody>
                    </table>
                  </div><br /><br />

                  <h4 className="tomato1">FOR <strong>NORTH SIKKIM TOUR PACKAGE WITH SILK ROUTE</strong>, YOU CAN BOOK HOWRAH TO NJP VANDE BHARAT EXPRESS</h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Station</b></th><th><b>Arrival <br /> Departure</b></th><th><b>Halt</b></th><th><b>Distance</b></th></tr></thead>

                      <tbody>
                        <tr><td>Howrah Jn [HWH]</td><td>Starts <br />05:55</td><td>Howrah (HWH)</td><td>0 km</td></tr>
                        <tr><td>Bolpur Shantiniketan [BHP]</td><td>07:43 <br />07:45</td><td>2 min</td><td>145 km</td></tr>
                        <tr><td>Malda Town [MLTD]</td><td>10:32 <br />10:35</td><td>3 min</td><td>329.5 km</td></tr>
                        <tr><td>Barsoi Jn [BOE]</td><td>11:50 <br />11:52</td><td>2 min</td><td>419.7 km</td></tr>
                        <tr><td>New Jalpaiguri Jn [NJP]</td><td>13:25<br />ends</td><td></td><td>564.6</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h4 className="tomato1">NJP TO HOWRAH JUNCTION VANDE BHARAT 22302 TRAIN SCHEDULE [EXCEPT WEDNESDAY]</h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">

                      <thead className="cf"><tr><th><b>Station</b></th><th><b>Arrival <br /> Departure</b></th><th><b>Halt</b></th><th><b>Distance</b></th></tr></thead>

                      <tbody>
                        <tr><td>New Jalpaiguri Jn [NJP]</td><td>Starts <br />15:05</td><td></td><td>0 km</td></tr>
                        <tr><td>Barsoi Jn [BOE]</td><td>16:44 <br />16:46</td><td>2 min</td><td>145 km</td></tr>
                        <tr><td>Malda Town [MLTD]</td><td>17:50 <br />17:53</td><td>3 min</td><td>235 km</td></tr>
                        <tr><td>Bolpur Shantiniketan [BHP]</td><td>20:22 <br />20:24</td><td>2 min</td><td>420 km</td></tr>
                        <tr><td>Howrah Jn [HWH]</td><td>22:35 <br />ends</td><td>-</td><td>565 km</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h2>
                      DAY WISE PLAN FOR <strong className="tomato">NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong>
                    </h2>
                    <h4>8 Nights 9 Days North Sikkim Package Tour Itineraries</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h2>DAY 01: SILIGURI/NJP TO GANGTOK</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Upon arrival at Siliguri or NJP Station, you will be warmly welcomed for your <strong>East & North Sikkim Package Tour with Gangtok</strong>. From there, embark on a scenic drive to Gangtok, situated at an altitude of 1,650 m (5,550 ft). The mesmerizing Teesta River accompanies you along the route, adding to the charm of the journey.

                          Once you reach Gangtok, check in to your hotel and relax. In the evening, you can explore the vibrant neighborhood or indulge in shopping at the bustling MG Marg, a favorite spot for visitors. Enjoy an overnight stay at your hotel in Gangtok, ready to begin your Sikkim adventure. <br /></p>
                        <br /> <br />
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h2>DAY 02: GANGTOK TO LACHEN TRANSFER</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, your Lachen tour begins with a pick-up from your hotel in Gangtok at around 10 a.m. You'll journey along the scenic NH 31A, making stops at several remarkable spots along the way: <br /><br />


                          <b>
                            Tashi View Point</b> - Built by Tashi Namgyal, this spot offers a breathtaking panoramic view of Mt. Khangchendzonga, a treat for nature lovers and photography enthusiasts. <br /><br />

                          <b>Kabi Longstok</b> - A historic site along the North Sikkim Highway, where a treaty was signed between the Lepcha Chief Te-Kung-Tek and the Bhutia Chief Khey-Bum-Sar, marking a moment of harmony.
                          <br /><br />
                          <b>Seven Sisters Waterfalls</b> - Named for its cascading descent in seven stages, this enchanting waterfall is a natural marvel. The Sikkim Tourism Department has provided a waiting shed and cafeteria here, allowing visitors to relax and soak in the beauty. <br /><br />



                          <b>Naga Waterfalls</b> - Located at the confluence of Lachen-Chu and Lachung-Chu rivers near Chungthang, this perennial waterfall is one of many stunning cascades in the region, including Bhim Nala and Twin Waterfalls. The captivating Teesta River accompanies you throughout the journey, adding to the scenic charm. <br /><br />

                          A delicious lunch will be served at Namok, providing a refreshing break during the journey. You are expected to reach Lachen by the evening, where you'll check in to your hotel or homestay. Relax and enjoy an overnight stay, preparing for the adventures ahead. <br /><br /><br /></p>

                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>DAY 03: LACHEN TO GURUDONGMAR LAKE EXCURSION - TRANSFER TO LACHUNG</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>Begin your day early with a visit to the magnificent Gurudongmar Lake, situated at an altitude of 5,154 meters above sea level. This sacred lake, revered by both Buddhists and Hindus, is known for its year-round milky appearance. According to local legend, the lake used to remain frozen for most of the year, making its water undrinkable. However, when Guru Padmasambhava passed through the area on his return from Tibet, the local residents asked for his help. In response, he blessed the lake, and the portion of the lake he touched would never freeze, even in the harshest winters. The water is considered sacred, and visitors often take bottles of the blessed water as a souvenir. Witness the mesmerizing beauty of Gurudongmar Lake as part of your <strong>North Sikkim Package Tour with Silk Route</strong>.

                          <br /><br />

                          After visiting the lake, return for lunch and then check out from your hotel. You'll then drive to Lachung, located at an altitude of 2,700 meters above sea level. The drive takes approximately 1 hour and 55 minutes (46.9 km) via Lachen Road. Upon arrival, check in to your hotel or homestay and relax. Enjoy a peaceful overnight stay in Lachung, surrounded by the serene beauty of North Sikkim. <br /></p>
                        <br />  <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>Day 04: LACHUNG TO GANGTOK via YUMTHANG VALLEY EXCURSION</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, begin your scenic journey to the stunning Yumthang Valley, located 25 km from Lachung at an altitude of 11,800 feet. The drive takes about 1.5 hours, passing through picturesque mountain rivers, streams, bridges, and roads lined with blooming Rhododendrons. In winter, the valley is blanketed in thick snow, while in summer, you're greeted by vibrant Rhododendrons and Primulas along the way.
                          <br /><br />
                          For an even more breathtaking experience, you can visit Zero Point, located 23 km from Yumthang Valley at an altitude of 15,000 feet above sea level. (Please note that visiting Zero Point incurs an extra cost, and access is subject to permit availability and road conditions.) <br /><br />

                          You'll also have the chance to explore the Shingba Rhododendron Sanctuary, covering 43 sq km and located near Yumthang and Lachung. The sanctuary, home to a rich variety of Rhododendron trees and shrubs, is teeming with diverse flora and vegetation, making it a nature lover's paradise. <br /><br />

                          After your visit to Yumthang Valley and the sanctuary, you will head back to Gangtok for your overnight stay, concluding another day of breathtaking exploration in North Sikkim. <br /><br /></p>

                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>DAY 05: FULL DAY GANGTOK CITY TOUR</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, embark on a captivating <strong>Silk Route Tour with North Sikkim</strong>, beginning with a city tour of Gangtok. This vibrant city offers numerous attractions that showcase the natural beauty and cultural richness of the region.

                          <br /><br />

                          First, visit the Tashi View Point, built by Tashi Namgyal, offering a panoramic view of the majestic Mt. Khangchendzonga. Next, explore the Gonjang Monastery, established in 1981 by H.E. Tingkye Gonjang Rimpoche, and learn about the monastery's deep connection to the Nyingmapa tradition of Tibetan Buddhism.

                          <br /><br />

                          Continue your journey to Do-drul Chorten, a stupa built in 1945 by Venerable Trullshi Rimpoche. Inside, you'll find a complete mandala set and a collection of sacred Buddhist relics, while the surrounding 108 prayer wheels offer a spiritual atmosphere.

                          <br /><br />

                          Next, visit the Namgyal Institute of Tibetology, one of the world's premier research institutions, housing rare Tibetan manuscripts, statues, and colorful Thankas. Adjacent to this, the Orchid House boasts 250 species of orchids, making it a paradise for nature lovers.<br /><br />



                          For a stunning view of the lush forests and valleys, head to Hur Huray Dara, the Nam Nang View Point. Then, explore Tsuklakhang (Palace Monastery), located within the former Chogyal rulers' palace. This monastery, built in traditional Sikkimese style, features vibrant murals and Buddhist icons.


                          <br /><br />

                          You'll also visit the White Memorial Hall, a British-style structure from 1932, and the Flower Show Hall, showcasing Sikkim's diverse flowering plants. For a thrilling experience, take a ride on the Gangtok Ropeway, offering breathtaking aerial views of the mountains and valley.<br /><br />

                          After your city tour, return to the hotel and enjoy a free evening to shop and explore MG Marg, Gangtok's popular shopping street. End your day with an overnight stay at the hotel in Gangtok. Book your <strong>Silk Route Package Tour with Gangtok, North Sikkim</strong> today for an unforgettable adventure through this beautiful region. <br /><br /></p>
                        {/* <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>
                        <br /> */}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>DAY 06: GANGTOK TO NATHANG TRANSFER</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, begin your sightseeing tour, covering the picturesque Nathang Valley, Kalapokhri, and Laxman Chawk. Visit the stunning Mamecho Lake Viewpoint, the historic Old Baba Mandir, and the scenic Lungthung. Continue your journey to Thambi Viewpoint, famous for its panoramic views, and the thrilling 95 Zig Zag Roads. Finally, reach Upper Zuluk and soak in the serenity and natural beauty of Zuluk, often called a hidden paradise on earth. Overnight stay at Zuluk, immersing yourself in the peaceful surroundings. <br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>DAY 7: NATHANG TO ZULUK</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, embark on a sightseeing tour covering the beautiful Nathang Valley, Kalapokhri, and Laxman Chawk. Visit the breathtaking Mamecho Lake Viewpoint, the revered Old Baba Mandir, and the picturesque Lungthung. Continue to Thambi Viewpoint for panoramic views and navigate the famous 95 Zig Zag Roads. Finally, arrive at Upper Zuluk, where you can experience the serene beauty of Zuluk, a hidden paradise on earth. Overnight stay in Zuluk, surrounded by its peaceful and stunning landscape. <br /><br /></p>

                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay7ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>DAY 08: ZULUK TO RONGPOKHOLA TOUR</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>This day is a highlight of your <strong>Silk Route Package Tour with North Sikkim</strong>. After breakfast, begin your journey towards Rongpokhola and explore some of the most significant destinations along the route: <br /><br />

                          <b>Rongli</b> - A small township located on the banks of the Rongli River, Rongli is an important location for obtaining the Inner Line Permit required to visit Nathula. <br /><br />

                          <b>Rongli Dam</b> - This concrete dam, located on the Rongli River, stands 41 meters tall and can hold 45,200 cubic meters of water, adding to the scenic beauty of the area. <br /><br />

                          <b>Lingtam</b> - A tranquil village nestled amidst hills on all sides, Lingtam is known for its peaceful, sun-kissed weather. It's also home to the Lingtam Monastery, making it a perfect spot to soak in the local culture. <br /><br />

                          <b>Keukhela Falls</b> - Also known as Kali Khola Falls, this stunning 100-meter waterfall lies on the Old Silk Route, between Lingtam and Padamchen, surrounded by lush natural beauty. <br /><br />

                          <b>Zuluk</b> - A small village in East Sikkim, Zuluk is an integral part of the Silk Route and offers mesmerizing views of snow-capped mountains and the stunning Himalayan range. It's an ideal spot for tourists seeking virgin nature and serenity. You may catch a glimpse of the sunrise over the snow-covered mountains, a sight that will stay with you long after the trip. <br /><br />

                          After reaching Zuluk, enjoy the breathtaking scenery before lunch. Conclude your day with an overnight stay at a Rongpokhola Homestay, where you can relax and absorb the natural beauty around you. <br /><br />

                          This itinerary is a must-experience for anyone booking a <strong>Silk Route Package Tour with Gangtok, North Sikkim</strong>, as it covers the most stunning destinations of the region. For more details about the <strong>Silk Route Tour Package Cost with North Sikkim</strong>, please feel free to reach out for personalized travel options. <br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay8ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day9"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h2>DAY 09: RONGPOKHOLA TO NJP/SILIGURI TRANSFER</h2>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>Today marks the end of your memorable <strong>North & Silk Route East Sikkim Trip</strong>. Wake up early in the morning and visit the serene Rongpokhola River to enjoy the breathtaking beauty of the area. Take a leisurely stroll along the riverbank, play with the pebbles, and dip your feet into the icy cold Rongpo River—an experience that's truly refreshing. After soaking in the natural beauty, return to the resort for a hearty breakfast. Later, proceed towards NJP / Siliguri, carrying with you the sweet memories of your <strong>North Sikkim Package Tour with Silk Route from Kolkata</strong>.
                          <br /><br /></p>
                        {/* <br />
                        <div className="content-desktop">
                          <center><img src={navDay9ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br /><br /><br />
              <table className="img-center">
                <tbody>
                  <tr>
                    <td className="image-cell">
                      <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO PACK FOR <strong>SILK ROUTE & NORTH SIKKIM PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO PACK FOR <strong>SILK ROUTE & NORTH SIKKIM PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Winter Jacket</td>
                          <td style={{ fontSize: "16px" }}>Thermal Wear</td>
                          <td style={{ fontSize: "16px" }}>ThermoFLUX</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Portable Electric Kettle [optional]</td>
                          <td style={{ fontSize: "16px" }}>Raincoat</td>
                          <td style={{ fontSize: "16px" }}>Dry Fruits and Chocolate</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Biscuit & Dry Snack</td>
                          <td style={{ fontSize: "16px" }}>Woolen Socks</td>
                          <td style={{ fontSize: "16px" }}>Woolen Cap</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Mufflers</td>
                          <td style={{ fontSize: "16px" }}>Woolen Hand Gloves</td>
                          <td style={{ fontSize: "16px" }}>Rucksack</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Undergarments</td>
                          <td style={{ fontSize: "16px" }}>Toothpaste / Brush</td>
                          <td style={{ fontSize: "16px" }}>Moisturizers / Vaseline</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Power Bank</td>
                          <td style={{ fontSize: "16px" }}>Torch / Flashlight</td>
                          <td style={{ fontSize: "16px" }}>Gas Lighter</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>First Aid Kits</td>
                          <td style={{ fontSize: "16px" }}>Inhaler (for Asthma patient)</td>
                          <td style={{ fontSize: "16px" }}>Camphor</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST SIGHTSEEING PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR PACKAGES WITH GANGTOK & LACHEN LACHUNG</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST SIGHTSEEING PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR PACKAGES WITH GANGTOK & LACHEN LACHUNG</strong>?</h4>
                </div>
                <div className="card-body">
                  <li><b>Zuluk Loop :</b> 32 hair-pin curves <br /><br /></li>
                  <li><b>Thambi View Point :</b> Best for Zuluk Zigzag view and Himalayan Range <br /><br /></li>
                  <li><b>Nathang Valley :</b> Offer best snow all round year <br /><br /></li>
                  <li><b>Kupup Lake :</b> Named as Elephant Lake with picturesque natural beauty <br /><br /></li>
                  <li><b>Menmecho Lake :</b> Lake with good ambience and awesome scenic beauty <br /><br /></li>
                  <li><b>Lungthung :</b> A remote village at 11,500 ft offering great Himalayan view <br /><br /></li>
                  <li><b>Tukla Valley :</b> Offer great view of Mt. Kanchenjungha from 12,500 ft altitude <br /><br /></li>
                  <li><b>Old Baba Mandir :</b> A temple of faith dedicated to sepoy Baba Harbhajan Singh near kupup village <br /><br /></li>
                  <li><b>Tsomgo Lake :</b> A must visit place for every Sikkim Gangtok Traveller <br /><br /></li>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>NORTH EAST SIKKIM TOUR RELATED QUESTION </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Are restaurants and bars available in Sillerygaon, Zuluk, or Rongpokhola?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>There are no proper bars in these areas, but small roadside restaurants are available offering simple meals like momos, noodles, and soup for lunch. You will also find small stationary shops, some of which sell beverages along with other essentials. <br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Can I face network or internet issues during my Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>If you're reliant on mobile connectivity, you may experience network issues. There is no network in Zuluk and Nathang; the signal is entirely absent. Only BSNL works in some areas, but you may need to walk towards a tower to get a signal.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Is hot water available at homestays along the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, hot water is available at most homestays, and some are equipped with geysers. In cases where geysers are unavailable, hot water is provided in a bucket on request.<br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is vegetarian food available on the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, vegetarian meals are available at homestays, often featuring organic vegetables. However, it's advisable to inform your preferences in advance when booking your Silk Route Package Tour with North Sikkim to ensure your needs are met.<br /><br />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Can we find cake shops along the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>There are no cake shops along the Silk Route. If you wish to bring cakes, it is best to buy them from NJP and carry them with you.<br /><br />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Do we need to carry oxygen cylinders for Nathang?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>If you have any breathing issues, it's recommended to consult a doctor before your trip and carry the necessary medications. In some cases, if you have significant breathing problems, it may be advisable to bring a small oxygen cylinder as medical facilities are not readily available in these remote areas.<br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Is the Silk Route safe for solo women travelers?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, the entire region of Sikkim, including the Silk Route, is very safe for solo women travelers. The locals are friendly, welcoming, and always eager to help, making it a comfortable destination for solo travelers.<br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          How can I avoid high altitude sickness on the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>To minimize the risk of high altitude sickness during your Silk Route Tour with North Sikkim, it's important to stay hydrated, eat nutritious food, and consult your doctor before the trip for any necessary medications. Avoid overexertion and take regular breaks to acclimatize.<br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          How many days are enough for a Gangtok and North Sikkim tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>For a standard visit to North Sikkim, without trekking, 7-8 days are sufficient to explore key destinations such as Lachen, Lachung, Gurudongmar Lake, Yumthang Valley, Zero Point, and Katao Valley. This gives you ample time to experience the beauty of Gangtok and North Sikkim.<br /><br />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          What foods should I try during my Silk Route trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The Silk Route is famous for its delicious Momos (steamed, fried, and chilly), Thukpa or Gyathuk, Gundruk Soup, and Sael Roti. Don't miss trying Chaang, the local alcoholic beverage, which is a must-try experience during your Silk Route Package Tour with North Sikkim.
                            <br /><br /></p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Where can I get the best view of Mt. Kanchenjunga during the Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        <p>For the best view of Mt. Kanchenjunga, visit Sillerygaon, Ramdhura, and Thambi View Point. These spots offer breathtaking panoramic views of the majestic peak, and we are happy to arrange stays at these locations as part of your Silk Route Package with North Sikkim.<br /><br /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          North Sikkim <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-91470-61467">
            <i className="fa fa-phone"></i>
            {/* <span><small>+91 9147061467</small></span> */}
          </a>
        </div>
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
            {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
          </a>
        </div>
      </>
    );
  }
}
export default NorthSikkim_Silkroute_8N_9D_Package;
