import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS : <strong>KOLKATA TO KASHMIR TULIP FESTIVAL TOUR PACKAGES IN GROUP</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Return Train fare in 3 Tier Ac Class
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Assistance upon arrival at Amritsar
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Traditional welcome in the Hotel with Welcome Drink ( Hot Tea / Coffee/ Kahwa) on arrival</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;01 Night Accommodation in Amritsar– Hotel as per the Itinerary with Breakfast, Lunch and Dinner</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;02 Night Accommodation in Katra– Hotel as per the Itinerary with Breakfast, Lunch and Dinner</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;02 Night Accommodation in Srinagar– Hotel as per the Itinerary with Breakfast, Lunch and Dinner</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;2 Night Accommodation in Pahalgam as per the Itinerary with Breakfast, Lunch, and Dinner</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;01 Night Accommodation in Srinagar– Houseboat as per the Itinerary with Breakfast, Lunch, and Dinner</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;60 Minutes Boat Ride (SHIKARA) on world famous Dal Lake – Srinagar</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Chandanwari / Aru / Betaab Valley Sightseeing at Pahalgam</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All toll taxes & parking charges</li>
                </ul>
                <br/><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS : <strong>KASHMIR TULIP SPL PACKAGE FROM KOLKATA</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Food on Train</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any Kind of Personal Expenses or Optional Tours / ExtraMeals Ordered</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Anything not specifically mentioned under the heading “Prices included”</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Gondola Ride at Gulmarg / Pony Ride at Pahalgam& Gulmarg</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Snow Jeep ( chained vehicle ) from Tangmarg - Gulmarg & Vice - Versa. ( If Required)</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;The Services of Vehicle is not included on leisure days & after finishing the sightseeing tour as per the Itinerary</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Guide fee, Camera fee & any monument Entrances</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Medical & Travel insurance</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any changes you may choose to make during your tour</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Additional costs due to flight cancellation, road blocks etc</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;GST (5%)</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;