import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_bhutanPackageTourJaigaon';
import Activityfecilities from './Activity_bhutanPackageTourNjpSiliguri';

class Bhutan_PackageTourJaigaon extends Component {
    constructor(props) {
        super(props);
    }

    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };


    render() {
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tour-from-jaigaon-web.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tour-from-jaigaon-mobile.webp";
        const FlightBannerImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-package-from-bagdogra-via-phuentsholing.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-package-from-bagdogra-and-transfer-to-thimphu.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/with-bhutan-packages-from-bagdogra-explore-punkha-suspension-bridge.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/guwahati-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/guwahati-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/guwahati-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-3-days.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/call-button-bablu-adorable-vacation.webp";
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/call-button-sonali-adorable-vacation.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-from-guwahati-8.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-llp-9.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tour-from-guwahati-with-adorable-vacation-8.webp";
        const sightseen4ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tours-from-guwahati-airport-with-adorable-vacation-10.webp";
        const sightseen5ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-booking-from-guwahati-7.webp";
        const sightseen6ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-trip-tour-from-guwahati-with-adorable-vacation-9.webp";
        const sightseen7ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/guwahati-to-bhutan-tour-by-direct-flight-11.webp";
        const sightseen8ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/travel-bhutan-from-guwahati-with-adorable-vacation.webp";
        const sightseen9ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-trip-from-guwahati-airport-with-adorable-vacation-4.webp";
        const sightseen10ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-itinerary-from-guwahati-airport-with-adorable-vacation-5.webp";
        const sightseen11ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-from-guwahati-airport-with-adorable-vacation-2.webp";
        const sightseen12ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-cost-from-guwahati-airport-with-adorable-vacation-6.webp";
        const sightseen13ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-1.webp";
        const sightseen14ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-from-guwahati-airport-with-adorable-vacation-3.webp";
        const sightseen15ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-booking-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen16ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen17ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-tour-from-guwahati-with-direct-flight-in-winter.webp";
        const sightseen18ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-booking-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen19ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen20ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-booking-from-guwahati-with-direct-flights.webp";
        const sightseen21ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-from-guwahati-with-direct-flight-tickets.webp";
        const sightseen22ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-trip-from-guwahati-with-direct-flights.webp";
        const flight1ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-package-from-guwahati-with-direct-flight.webp";
        const flight2ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
        const flight3ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
        const hotel1ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const train1ImageURL = ApiURL.BhutanTourPackageFromNjpSiliguriImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-4.webp";
        const train2ImageURL = ApiURL.BhutanTourPackageFromNjpSiliguriImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-6.webp";
        const train3ImageURL = ApiURL.BhutanTourPackageFromNjpSiliguriImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-3.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanTourPackageFromJaigaonImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="Bhutan Packages From Guwahati tour from adorable vacation the best Bhutan Guwahati dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bhutan Package Tour from Jaigaon</strong> - BEST DEAL 2025</center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center> <strong> Bhutan Package Tour from Jaigaon</strong> - BEST DEAL 2025 </center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour From Guwahati from adorable vacation the best Bhutan Guwahati dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                6 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Phobjikha
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Jaigaon
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Phuentsholing
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            Planning a trip to Bhutan? Start your journey with the <strong>Bhutan Package Tour from Jaigaon</strong>, the ideal gateway to this enchanting land. Jaigaon, the Indian border town opposite Phuentsholing, offers seamless access to Bhutan. With Adorable Vacation, a trusted Destination Management Company (DMC), you can enjoy well-designed <strong>Jaigaon to Bhutan tour packages</strong> tailored for a smooth and budget-friendly travel experience.<br />

                                            From arranging permits to providing top-notch accommodations and curated itineraries, Adorable Vacation ensures a hassle-free trip. Explore Bhutan's serene landscapes, rich culture, and unique charm with the Best Bhutan DMC in India, and create lasting memories in the magical <b>Land of Happiness</b>!
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>BHUTAN TOUR PACKAES FROM JAIGAON</strong>? <br /><br />
                                            FOR BOOKING <strong>JAIGAON TO BHUTAN TOUR</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713
                                            </a>  <br /><br /></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>BHUTAN TOUR PACKAES FROM JAIGAON</strong>? <br /><br />
                                            FOR BOOKING <strong>JAIGAON TO BHUTAN TOUR</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713
                                            </a>  <br /><br /></h3>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>START YOUR MEMORABLE TRIP WITH <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong> - THE GATEWAY TO BHUTAN</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>START YOUR MEMORABLE TRIP WITH <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong> - THE GATEWAY TO BHUTAN</h3>
                                </div>
                                <div className="card-body">
                                    <p>Jaigaon, located in the Alipurduar district of West Bengal, serves as the primary gateway to Bhutan from India. This bustling town, adjacent to the Bhutanese town of Phuentsholing, is an essential stop for tourists embarking on a <strong>Bhutan package tour from Jaigaon</strong>. The twin cities of Jaigaon and Phuentsholing are closely intertwined, separated only by the Bhutan Gate. Visitors often choose to stay overnight in either town before continuing their Bhutan tour, as entry passes are issued in the morning. <br /><br />

                                        Jaigaon is a vibrant commercial hub, drawing traders, wholesalers, and transporters, especially those catering to the Bhutanese market. The town is an important economic center, but it is the peaceful, well-organized Phuentsholing on the Bhutanese side that appeals to most tourists. For those looking to shop, Jaigaon offers a wide range of options, from local markets to international goods. Nature lovers can explore the scenic Torsha riverbed, located just west of Jaigaon. <br /><br />

                                        A <strong>Bhutan tour package from Jaigaon</strong> typically includes visits to Phuentsholing and other nearby attractions, including the renowned forests of Dooars like Jaldapara (30 km) and Buxa (35 km). Both offer excellent opportunities for wildlife and nature enthusiasts. If you’re planning a <strong>Bhutan tour from Jaigaon</strong> - we, the Adorable Vacation – <a href="https://adorablevacation.com/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc/" target="_blank"><strong>The Best B2B DMC for Bhutan tour</strong></a> offers Best Bhutan Deal with 3 to 5 Star Hotels, All transportation, SDF, Lip smacking delicious foods with local Bhutanese touch and all sightseeing with best <strong>Bhutan tour Guide</strong>. <br /><br />

                                        For an unforgettable <strong>Bhutan trip from Jaigaon</strong>, consider visiting key Bhutanese attractions near Phuentsholing like the Royal Palace, Chhukha Dzong, Milerapa Lhakhang and the serene monasteries. Be sure to check with travel experts for the latest travel guidelines and the best <strong>Bhutan tour packages from Jaigaon</strong> to make the most of your adventure. <br /></p>
                                    <p>Apart from <strong>Bhutan Package Tour Booking From Jaigaon</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like
                                        <a href="/bhutan-package-tour-from-nagpur" target="_blank"><b class="tomato"> Bhutan Package Tour from Nagpur</b></a>, <a href="/bhutan-tour-package-from-pune" target="_blank"><b class="tomato">Bhutan Package Tour from Pune</b></a>, <a href="/bhutan-tour-from-delhi" target="_blank"><b class="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b class="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b class="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bangalore" target="_blank"><b class="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b class="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b class="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b class="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b class="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b class="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, <a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b class="tomato">Bhutan B2B Package Tour</b></a>, etc at Best Price.</p>
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen1ImageURL} alt="Sightseeing 1" />
                                                    <img src={sightseen3ImageURL} alt="Sightseeing 3" />
                                                    <img src={sightseen2ImageURL} alt="Sightseeing 2" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGES FROM JAIGAON</strong> : 6N | 7D</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGES FROM JAIGAON</strong> : 6N | 7D</h4>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong> : THIMPHU 2N | PUNAKHA 2N | PARO 2N
                                    </h4><br />
                                    <h4>PICKUP & DROP POINT FOR <strong>JAIGAON TO BHUTAN PACKAGE TOUR</strong> : HASIMARA STATION [HSA] | NEW ALIPURDUAR JUNCTION</h4>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE SUMMERY FOR <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> DAY WISE SUMMERY FOR <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <b>DAY 1 → </b>
                                                        <span>
                                                            JAIGAON ARRIVAL, THIMPHU TRANSFER VIA PHUENTSHOLING
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <b>DAY 2 → </b>
                                                        <span>THIMPHU LOCAL SIGHTSEEING</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <b>DAY 3 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEINGS</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <b>DAY 4 → </b>
                                                        <span>PUNAKHA TO PHOBJIKHA VALLEY DAY TRIP</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <b>DAY 5 → </b>
                                                        <span>PUNAKHA TO PARO TRANSFER & SIGHTSEEINGS</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <b>DAY 6 → </b>
                                                        <span>PARO TO TAKTSANG MONASTERY HIKING / CHELE-LA-PASS DAY TRIP</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <b>DAY 7 → </b>
                                                        <span>PARO TO JAIGAON DROP VIA PHUENTSHOLING</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>INCLUSIONS : <strong>JAIGAON TO BHUTAN PACKAGE TOUR</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>INCLUSIONS : <strong>JAIGAON TO BHUTAN PACKAGE TOUR</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Accommodation in TCB listed Hotels (Double/Twin Sharing Room)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Meal Plan MAP (Breakfast and Dinner)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Parking, Toll Tax, E-Permits
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Thimphu Local Sightseeing
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Paro City Tour
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            English / Hindi Speaking Tour Guide
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            All transfer will be on point to point basis
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            SDF Charges (Sustainable Development Fee)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            01 Bottle Packages Drinking Water Per Day on Vehicle
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Guest Service Support during the trip from Bhutan & India Office 24/7
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE BEFORE BOOKING <strong>JAIGAON TO BHUTAN TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE BEFORE BOOKING <strong>JAIGAON TO BHUTAN TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>CITIES</th><th>ALTITUDE</th><th>COORDINATES</th></tr>
                                                <tr><td> Phuentsholing</td><td>293 m</td><td>26.8603°N, 89.3938° E</td></tr>
                                                <tr><td> Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                                                <tr><td>Paro</td><td>2200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                                                <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                                                <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                                                <tr><td>Chele-La-Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                                                <tr><td>Haa Valley</td><td>2,670 m</td><td>27.3333° N, 89.1833° E</td></tr>
                                                <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                                                <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>BHUTAN TOUR PACKAGE FROM JAIGAON</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>BHUTAN TOUR PACKAGE FROM JAIGAON</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li><b>Trusted Brand : </b> Adorable Vacation has been the most trusted brand for <strong>Bhutan tour from Jaigaon</strong>. <br /><br /></li>
                                        <li><b>Passenger Service Agent (PSA) : </b> We are proud to be the PSA for Bhutan Airlines and Druk Airlines. <br /><br /></li>
                                        <li><b>Luxury Packages : </b> Experience the best <a href="https://adorablevacation.com/bhutan-hotel-six-senses-package-tour-with-direct-flight-from-india/" target="_blank"><strong>Bhutan Luxury Package Tour with Hotel Six Senses</strong></a>, Amankora, COMO Uma, Le Méridien, and Pemako. <br /><br /></li>
                                        <li><b>Super Peak Season Inventory : </b> We have pre-purchased hotel room inventory for peak season for <a href="https://adorablevacation.com/mumbai-special-chartered-flight/ " target="_blank"><strong>Bhutan package tour from Mumbai with chartered flight</strong></a>. <br /><br /></li>
                                        <li><b>Authorized Travel House : </b> We are an authorized travel house by the Tourism Council of Bhutan [License No: 1053228]. <br /><br /></li>
                                        <li><b>Direct Employment : </b> Our employees in Bhutan are directly on our payroll, ensuring quality service.<br /><br /></li>
                                        <li><b>Customized Itineraries : </b> We offer tailored <strong>Bhutan tour itineraries from Jaigaon</strong> with unique and signature programs. <br /><br /></li>
                                        <li><b>In-Depth Knowledge : </b> Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws. <br /><br /></li>
                                        <li><b>Assured Services : </b> We ensure premium services to enrich your experience while in Bhutan. <br /><br /></li>
                                        <li><b>E-Permit Processing : </b> We handle the e-permit process in advance for all your sightseeing needs. <br /><br /></li>
                                        <li><b>Personalized Attention : </b> Enjoy safe and secure personal attention throughout your stay in Bhutan. <br /><br /></li>
                                        <li><b>Complete Responsibility : </b> Adorable Vacation takes full responsibility for your entire <strong>Bhutan tour from Jaigaon</strong>. <br /><br /></li>
                                        <li><b>Hassle-Free Experience : </b> We guarantee hassle-free and trustworthy services in Bhutan. <br /><br /></li>
                                        <li><b>Best Deals : </b> Access the best deals for top properties and services based on your preferences. <br /><br /></li>
                                        <li><b>Flexible Travel Options : </b>Plan a customized Bhutan private trip or join a <strong>Bhutan Package tour from Jaigaon</strong> with Adorable Vacation. <br /><br /></li>
                                    </ul>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen4ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"

                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="Bhutan Guwahati travel packages"

                                                    />
                                                    <img
                                                        src={sightseen6ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"

                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> QUICK FACTS NEED TO KNOW WHILE PLANNING <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> QUICK FACTS NEED TO KNOW WHILE PLANNING <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">

                                            <tbody>

                                                <tr><th>Fact</th><th>Details</th></tr>
                                                <tr><td>Capital</td><td>Thimphu</td></tr>
                                                <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                                                <tr><td>Official Language</td><td>Dzongkha</td></tr>
                                                <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                                                <tr><td>Area</td><td>38,394 km²</td></tr>
                                                <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                                                <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                                                <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                                                <tr><td>National Animal</td><td>the Takin</td></tr>
                                                <tr><td>Major Religion</td><td>Buddhism</td></tr>
                                                <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>

                                                <tr><td>Notable Attractions in Bhutan</td><td>
                                                    Paro Taktsang, Dochula Pass, Punakha Dzong, National Memorial Chhorten, Tashichho Dzong, National Museum of Bhutan, Gangtey Monastery, Punakha Suspension Bridge, Royal Textile Academy, Trashigang Dzong, Ura valley, Dechen Phodrang Monastery, National Library & Archives Of Bhutan</td></tr>

                                                <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>

                                                <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>BHUTAN PACKAGE TOUR COST FROM JAIGAON</strong>  </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN PACKAGE TOUR COST FROM JAIGAON</strong> </h3>
                                </div>
                                <div className="card-body">

                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th className="text-center">No of Pax</th>
                                                    <th className="text-center">Bhutan Package Price</th>
                                                    <th className="text-center">Book Now</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td>02 Adults</td>
                                                    <td>57,900/P</td>
                                                    <td><a href="tel:9883359713" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>04 Adults</td>
                                                    <td>44,600/P</td>
                                                    <td><a href="tel:9883359713" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>06 Adults</td>
                                                    <td>39,900/P</td>
                                                    <td><a href="tel:9883359713" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"

                                                    />
                                                    <img
                                                        src={sightseen8ImageURL}
                                                        alt="Bhutan Guwahati travel packages"

                                                    />
                                                    <img
                                                        src={sightseen9ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"

                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>

                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM JAIGAON</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM JAIGAON</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>PLACES</th>
                                                    <th>CITY</th>
                                                    <th>ENTRY FEES</th>
                                                    <th>REMARKS</th>
                                                </tr>
                                            </thead>
                                            <tbody><tr><td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td></tr>
                                                <tr><td>Tashichhoe Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td></tr>
                                                <tr><td>Buddha Dordenma Statue</td><td>Thimphu</td><td>No Entry Fee</td><td>Time-9am to 5pm</td></tr>
                                                <tr><td>Thimphu Memorial Chorten</td><td>Thimphu</td><td>NU 500</td><td>Anytime</td></tr>
                                                <tr><td>Changangkha Lhakhang (Temple)</td><td>Thimphu</td><td>NU 500</td><td>Anytime between 8am to 5pm</td></tr>
                                                <tr><td>Takin Preservation Centre</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>Simtokha Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>National Library</td><td>Thimphu</td><td>NU 100</td><td>9am to 5pm on Mon to Fri</td></tr>
                                                <tr><td>The Royal Textile Museum</td><td>Thimphu</td><td>NU 250</td><td>Mon to Sat from 9am to 4pm</td></tr>
                                                <tr><td>National Institute for Zorig Chusum (Painting School)</td><td>Thimphu</td><td>NU 200</td><td>Mon to Fri 10am to 3:30pm</td></tr>
                                                <tr><td>Folk Heritage Museum</td><td>Thimphu</td><td>NU 200</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>
                                                <tr><td>Simply Bhutan Museum</td><td>Thimphu</td><td>NU 1000</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>

                                                <tr><td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Punakha Dzong (Fort)</td><td>Punakha</td><td>NU 500</td><td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td></tr>
                                                <tr><td>Chimmi Lhakhang (Temple)</td><td>Punakha</td><td>NU 500</td><td>9am to 1pm and 2pm to 5pm </td></tr>
                                                <tr><td>Khamsum Yueely Namgyal Chorten</td><td>Punakha</td><td>NU 100</td><td>9am to 1pm and 2pm to 5pm </td></tr>

                                                <tr><td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Kyichu Lhakhang (temple)</td><td>Paro</td><td>NU 500</td><td>9am to 12pm & 1pm to 4pm </td></tr>
                                                <tr><td>Rinpung Dzong (Fort)</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm (Summer) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Dobji Dzong</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>National Museum of Bhutan</td><td>Paro</td><td>NU 300</td><td>Opens from 9am to 4pm throughout the week except on govt & local holidays </td></tr>
                                                <tr><td>Taktshang (Tiger Nest Monastery)</td><td>Paro</td><td>NU 1000</td><td>9am to 5pm </td></tr>

                                                <tr><td colspan="4">TRONGSA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Trongsa Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Trongsa Ta Dzong (Watch tower)</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">BUMTHANG SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Jampa Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Kurjey Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Jakar Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">JAIGAON SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Rinchending Goenpa (temple) </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>Palden Tashi Choling Shedra </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td colspan="4">NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below. </td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour Booking From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHICH IS THE BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHICH IS THE BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM JAIGAON</strong></h3>
                                </div>
                                <div className="card-body">
                                    <h4>SUMMER [JUNE - SEPTEMBER]</h4>
                                    <p><b>Summer</b> in Bhutan (June to September) offers pleasant temperatures between 20-25°C, with sunny days and chilly nights. The landscapes are lush, and the skies are clear, offering stunning views of the Himalayas. Summer is also the time for the Haa Festival, a vibrant celebration of Bhutanese culture. With fewer tourists, it’s a peaceful time to explore. So, summer is a great season to book a <strong>Bhutan Holiday Package from Jaigaon</strong>. <br /><br /><br /></p>

                                    <h4>AUTUMN [SEPTEMBER - NOVEMBER]</h4>
                                    <p>Autumn (September to November) is perfect for booking a <strong>Bhutan tour from Jaigaon</strong>. Temperatures are comfortable (10-15°C), and Bhutan's festivals like the Paro Tshechu and Punakha Tshechu bring the country’s culture to life. The weather is ideal for sightseeing, and booking early ensures availability during the busy season. <br /><br /><br /></p>

                                    <h4>WINTER [DECEMBER - FEBRUARY]</h4>
                                    <p><b>Winter</b> (December to February) in Bhutan can be cold, with snow in places like Thimphu, Paro, and Punakha. While temperatures can drop below freezing, this season is also when festivals like Monggar Tshechu take place. If you love snow and winter festivals, a <strong>Bhutan package tour in December</strong> is perfect. Just be prepared for the cold! <br /><br /><br /></p>

                                    <h4>SPRING [MARCH - MAY]</h4>
                                    <p>Spring (March to May) is one of the best times to visit Bhutan. The weather is mild and dry, with clear skies and blooming flowers like Jacaranda and Rhododendrons. This season hosts major festivals, including Paro Tshechu and Gasa Tshechu, making it a lively time to visit. To avoid high-season costs, it’s best to book your <strong>Bhutan package in May</strong> in advance. <br /><br /><br /></p>

                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>SEASON</th><th>MONTH</th><th>WEATHER</th><th>TEMPERATURE</th></tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className="img-center">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> HOW TO REACH BHUTAN FROM JAIGAON?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}> HOW TO REACH BHUTAN FROM JAIGAON?</h3>
                                </div>
                                <div className="card-body">
                                    <p>To start your <strong>Bhutan package tour from Jaigaon</strong> - Adorable Vacation always suggest you to reach Hasimara, a small town in West Bengal's Alipurduar district, is the perfect starting point for your Bhutan adventure. Located just 17 kilometers (about 10 miles) from Jaigaon, Bhutan's main border town, Hasimara offers easy access to Bhutan. The drive from Hasimara to Jaigaon, opposite to Phuentsholing takes approximately 30 to 45 minutes, making it a convenient option for travelers. <br /><br />

                                        For those planning a <strong>Bhutan package tour from Jaigaon</strong>, Hasimara Station is well-connected by the Kanchankanya Express from Kolkata, ensuring a hassle-free journey. You can also reach Hasimara via New Alipurduar or New Jalpaiguri (NJP) railway stations. Alternatively, Bagdogra Airport (IXB), about 165 km (4 hours) from Phuentsholing, is the nearest airport for travelers who prefer to fly. <br /><br />

                                        Booking a <a href="https://adorablevacation.com/bhutan-package-tour-from-hasimara-new-alipurduar/" target="_blank"><strong>Bhutan Package tour from Hasimara</strong></a> allows you to explore the stunning beauty of Bhutan with ease. Whether you're drawn to Bhutan's serene landscapes, rich culture, or iconic landmarks, Hasimara serves as the ideal gateway for a seamless and memorable journey. <br /><br /><br /></p>
                                    <h4 className='tomato1'>REACH BHUTAN FROM HASIMARA BY TRAIN</h4>

                                    <p>The <b>Kanchan Kanya Express (Train No. 13149)</b> is the only direct train connecting Sealdah to Hasimara Station, making it a convenient choice for travelers planning a <strong>Bhutan package tour from Jaigaon via Hasimara Station</strong>. Departing from Sealdah station at 8:35 PM and arriving at Hasimara the next day at 11:05 AM, this train covers the 720 km journey in approximately 14 hours and 30 minutes. Operating daily, it provides flexibility for those organizing their travel plans. <br /><br />

                                        The train offers various classes, including sleeper and air-conditioned coaches, catering to different preferences and budgets. Whether you're heading to Hasimara for business, leisure, or embarking on a <strong>Bhutan family trip from Hasimara</strong>, the <b>Kanchan Kanya Express</b> ensures a reliable and comfortable travel experience. <br /><br />

                                        Hasimara is a key starting point for exploring Bhutan, with many <strong>Bhutan tour operators from Hasimara</strong> available for travelers. Booking one of these <strong>Bhutan travel packages from Hasimara</strong> allows you to seamlessly begin your journey to <b>the Land of the Thunder Dragon</b>, ensuring a smooth and memorable trip. <br /><br /></p>
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">

                                            <thead className="cf">

                                                <tr class="table-danger"><th><b>Train No</b></th><th><b>Train Name</b></th><th><b>Dep. Stn</b></th><th><b>Dep. Time</b></th><th><b>Arr. Stn</b></th><th><b>Arr. Time</b></th><th><b>Frequency</b></th></tr></thead>
                                            <tbody><tr><td>22301</td><td>Vande Bharat Express</td><td>Howrah (HWH)</td><td>05:55</td><td>New Jalpaiguri Jn (NJP)</td><td>13:25</td><td>Except Wednesday</td></tr>
                                                <tr><td>22302</td><td>Vande Bharat Express</td><td>New Jalpaiguri Jn (NJP)</td><td>15:05</td><td>Howrah (HWH)</td><td>22:35</td><td>Except Wednesday</td></tr>
                                                <tr><td>12343</td><td>Darjeeling Mail</td><td>Sealdah (SDAH)</td><td>22:05</td><td>New Jalpaiguri Jn (NJP)</td><td>08:15</td><td>Daily</td></tr>
                                                <tr><td>12377</td><td>Padatik Express</td><td>Sealdah (SDAH)</td><td>23:00</td><td>New Jalpaiguri Jn (NJP)</td><td>09:15</td><td>Daily</td></tr>
                                                <tr><td>13149</td><td>Kanchan Kanya Express</td><td>Sealdah (SDAH)</td><td>20:30</td><td>New Mal Jn(NMZ)</td><td>09:31</td><td>Daily</td></tr>
                                                <tr><td>13147</td><td>UttarBanga Express</td><td>Sealdah (SDAH)</td><td>19:35</td><td>Falakata (FLK)</td><td>08:38</td><td>Daily</td></tr>
                                                <tr><td>13141</td><td>Teesta Torsa Express</td><td>Sealdah (SDAH)</td><td>13:40</td><td>New Maynaguri (NMX)</td><td>04:22</td><td>Daily</td></tr>
                                                <tr><td>12345</td><td>SaraiGhat Express</td><td>Howrah (HWH)</td><td>15:50</td><td>New Jalpaiguri (NJP)</td><td>01:40</td><td>Daily</td></tr>
                                                <tr><td>12517</td><td>Garib Rath</td><td>Kolkata (KOOA)</td><td>21:40</td><td>New Jalpaiguri (NJP)</td><td>07:45</td><td>Sun, Thrus</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4 className='tomato1'>FOR <strong>BHUTAN TOUR PACKAGE FROM JAIGAON</strong>, YOU CAN LOOK FOR HOWRAH JUNCTION TO NEW JALPAIGURI VANDE BHARAT 22301 EXPRESS </h4><br /><br />
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr class="table-danger"><th><b>Station</b></th><th><b>Arrival <br /> Departure</b></th><th><b>Halt</b></th><th><b>Distance</b></th></tr></thead>
                                            <tbody>
                                                <tr><td>Howrah Jn [HWH]</td><td>Starts <br />05:55</td><td>Howrah (HWH)</td><td>0 km</td></tr>
                                                <tr><td>Bolpur Shantiniketan [BHP]</td><td>07:43 <br />07:45</td><td>2 min</td><td>145 km</td></tr>
                                                <tr><td>Malda Town [MLTD]</td><td>10:32 <br />10:35</td><td>3 min</td><td>329.5 km</td></tr>
                                                <tr><td>Barsoi Jn [BOE]</td><td>11:50 <br />11:52</td><td>2 min</td><td>419.7 km</td></tr>
                                                <tr><td>New Jalpaiguri Jn [NJP]</td><td>13:25<br />ends</td><td></td><td>564.6</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={train1ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"

                                                    />
                                                    <img
                                                        src={train2ImageURL}
                                                        alt="Bhutan Guwahati travel packages"

                                                    />
                                                    <img
                                                        src={train3ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"

                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            <span>DETAIL ITINERARY FOR 6N/7D  <strong>BHUTAN TOUR PACKAGE FROM JAIGAON</strong></span>
                                        </h3>
                                        <h4>6N/7D <strong>Bhutan Tour Package Itinerary From Hashimara</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: WELCOME TO JAIGAON - TRANSFER TO THIMPHU </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>To day marks the start of your exciting <strong>Bhutan tour from Jaigaon</strong>. Once you arrive in Jaigaon, our representative will assist you with the permit process, including biometric data collection. The permit process typically takes between 2 to 6 hours, depending on the time of year and the crowd at the immigration office. During this waiting period, feel free to have lunch. After your permit is ready, you will continue your journey to Thimphu, the capital of Bhutan, which is about 4.5 hours away by car.<br /><br />
                                                    Upon reaching Thimphu, check in at your hotel in the afternoon or evening. After freshening up, take a relaxing stroll around the city. Explore the local nightlife, try a local beer, enjoy karaoke, or unwind with a traditional hot stone bath at a spa. End your day with a delicious dinner and get a restful night's sleep in Thimphu. <br /><br /></p>

                                                <h4>BARS & NIGHT CLUBS TO HANGOUT NIGHTLIFE IN THIMPHU</h4><br /><br />

                                                <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <thead className="cf"><tr><th>NAME</th><th>ADDRESS</th><th>TIMINGS</th></tr></thead>
                                                        <tbody><tr><td>Mojo Park</td><td>Chang Lam, Thimphu, Bhutan</td><td>5 PM - 12 AM</td></tr>
                                                            <tr><td>Space 34</td><td>Chang Lam, Main Town, Thimphu</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Om Bar</td><td>Thimphu, Bhutan</td><td>5 PM - 1 AM</td></tr>
                                                            <tr><td>Vivacity</td><td>Sheten Lam, Thimphu, Bhutan</td><td>7 PM - 12 AM</td></tr>
                                                            <tr><td>Club Ace</td><td>Thimphu, Bhutan</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Barz Brothers</td><td>Thimphu, Bhutan</td><td>Open 24 hours</td></tr>
                                                            <tr><td>Benez Bar</td><td>Jangchhub Lam, Thimphu, Bhutan</td><td>10 AM - 11 PM</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /> <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>This is the 2nd day of this wonderful <strong>Bhutan tour packages from Jaigaon</strong>. Start your day with a hot cup of <b>Suja (Butter Tea)</b> as the sun shines over the beautiful Thimphu valley. After breakfast, get ready for a tour of Thimphu's local attractions. <br /><br /></p>

                                                <p><b>The National Memorial Chorten (Thimphu Chorten)</b>: A popular spot for evening prayers, this chorten was built in memory of Bhutan’s third king, Jigme Dorji Wangchuk, and completed in 1974. Locals walk around, spinning prayer wheels and chanting mantras. It is a symbol of peace and prosperity. <br /><br />

                                                    <b>The Clock Tower</b>: Located at the heart of Thimphu, the Clock Tower Square is an excellent example of Bhutanese architecture. The tower features four clock faces, with intricate carvings of flowers and dragons, symbolizing Bhutan’s independence. The square is a lively spot surrounded by shops, restaurants, and hotels, and often hosts live music events. It’s a great place to shop for souvenirs or enjoy a picnic in the nearby park. <br /><br />

                                                    <b>Sakyamuni Buddha</b>: Perched atop Kuensel Phodrang hill, the giant statue of Buddha Dordenma stands at 51.5 meters tall and is made of gilded bronze. This peaceful site offers panoramic views of the Thimphu valley, making it a must-see landmark, also known as Buddha Viewpoint. <br /><br />

                                                    <b>Changangkha Lhakhang</b>: One of Thimphu’s oldest and most important monasteries, dating back to the 15th century. Dedicated to Avalokiteshvara, the symbol of compassion, it offers stunning views of the Thimphu valley. <br /><br />

                                                    <b>Takin Zoo</b>: Home to Bhutan’s national animal, the Takin, this zoo provides an open habitat where you can see these unique, endangered animals in a natural setting. <br /><br />

                                                    <b>Trashi Chhoe Dzong</b>: Known as the Fortress of the Glorious Region, this site is a must-visit. Located on the west bank of the Wang Chhu River, it is the coronation site of Bhutan’s fifth king and hosts the annual <strong>Tsechu Festival</strong>. Built in 1641 and later rebuilt in the 1960s using traditional Bhutanese techniques, this dzong is a symbol of Bhutanese heritage. You’ll spend the night at a hotel in Thimphu. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your 3rd day of the <strong>Bhutan Trip from Jaigaon</strong> in Thimphu with a warm cup of Ngaja (sweet Bhutanese milk tea) while enjoying the stunning view of the valley from your room. After breakfast, set off for Punakha, the former capital of Bhutan. Located at 1,200 meters, Punakha is a peaceful, quieter destination compared to the bustling cities. It enjoys a warm climate in winter and cool weather in summer. The valley is known for its rice fields along the Pho and Mo Chu rivers. On the way, you’ll visit: <br /><br />

                                                    <b>Punakha Dzong</b>: Known as "<b>The Palace of Great Happiness</b>", this breath-taking fortress sits at the confluence of the Pho Chhu (father) and Mo Chhu (mother) rivers. It's one of Bhutan’s most iconic dzongs, connected to the mainland by an arched wooden bridge. The dzong is home to many valuable relics and served as the political and religious centre when Bhutan’s kings ruled from here. <br /><br />

                                                    <b>Suspension Bridge</b>: This picturesque bridge in the Punakha Valley connects the valley to the old administrative centre of Punakha Dzongkhag. Covered in prayer flags, it offers a scenic spot for birdwatching and photography. <br /><br />

                                                    <b>Chimi Lhakhang (Fertility Temple)</b>: Situated on a hill, this temple is dedicated to Lama Drukpa Kuenley, known as the 'Divine Madman'. Using humour and songs to teach, Lama Drukpa Kuenley is believed to bless childless couples who visit the temple. The walk to the temple is through beautiful paddy fields and a small stream, followed by a short climb to reach the temple. You will spend the night at a hotel in Punakha. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - PUNAKHA TO PHOBJIKHA VALLEY DAY EXCURSION & RETURN </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>As the Sun shines up high in the Land of Happiness, open your eyes to a beautiful morning in the lap of serene nature, and sip into your cup of hot tea while admiring the green valley from your bed before moving towards Phobjikha Valley post breakfast. <br /><br />

                                                    <b>Phobjikha Valley</b> : A treat for all nature lovers, and an idle place for spotting various birds, including the famous Black Necked Cranes that migrate to this valley. Being one of the most important wildlife preserves in Bhutan, this serene and untouched beauty of nature away from the hustle-bustle of the city life is a pleasure to enjoy with the happiest people surrounding the entire region that makes this place worth the visit. The Phobjikha Valley is the largest and the widest in Bhutan and is known for the panoramic vistas with tranquil nature that not only call the nature lovers to witness its beauty but also traps the adventure buffs who wish to follow the trekking and hiking routes while exploring the untouched beauty of nature. Overnight at hotel in Punakha. <br /><br /><br /></p>


                                                <h4>Following are the places to explore in <b>Phobjikha Valley</b> : <br /><br /></h4>
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Black-Necked Crane Information Centre</td>
                                                                <td style={{ fontSize: "16px" }}>Gangtey (Hilltop) Monastery or Gangtey Goemba</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Gangtey Nature Trails</td>
                                                                <td style={{ fontSize: "16px" }}>Dotsho (Traditional Hot Stone Bath)</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Experience Stay in Bhutanese Farm Stay</td>
                                                                <td style={{ fontSize: "16px" }}>Khewang Lhakhang</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Experience Archery with Local People</td>
                                                                <td style={{ fontSize: "16px" }}>Open Air Campsite at Phobjikha</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Picnic with Family And Friends</td>
                                                                <td style={{ fontSize: "16px" }}>Kumbhu Lhakhang</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Restaurant in Phobjikha</td>
                                                                <td style={{ fontSize: "16px" }}>Nyelung Dechenling</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Bird Watching in Phobjikha</td>
                                                                <td style={{ fontSize: "16px" }}>Wangmo Hand-Woven Carpet Factory (Phobjikha)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br /><br />
                                                </div>

                                                <br /> <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PUNAKHA TO PARO BACK & LOCAL SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>This is 5th Day of your <strong>Bhutan trip from Jaigaon</strong>. After breakfast, begin your journey to Paro and visit the following places along the way: <br /><br />

                                                    <b>National Museum of Bhutan</b>: Situated above Rinpung Dzong in Paro, the National Museum of Bhutan showcases the country’s culture. It was built in 1968 inside the old Ta Dzong building. The museum displays beautiful Bhutanese art, paintings, and bronze statues. It also has large galleries where you can learn about Bhutan’s history and culture. <br /><br />

                                                    <b>Drukgyal Dzong</b>: Drukgyal Dzong is a ruined fortress and monastery in the upper Paro Valley. It was built in 1649 to celebrate Bhutan’s victory over a Tibetan invasion. The site is currently closed to visitors. <br /><br />

                                                    <b>Ta Dzong</b>: Ta Dzong was originally built as a watchtower to protect Rinpung Dzong during the 17th-century wars. In 1968, it became the National Museum of Bhutan. <br /><br />

                                                    <b>Rinpung Dzong</b>: Built in 1646 by Shabdrung Ngawang Namgyal, the first ruler of Bhutan, Rinpung Dzong is home to monks, the district head (Dzongda), and the judge (Thrimpon). To reach the dzong, you will cross a traditional covered bridge called Nemi Zam. <br /><br />

                                                    <b>Nya Mey Zam Bridge</b>: Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. Locals believe that seeing a fish in the river is a bad omen. The original bridge, which could be folded during wars, was destroyed in a flood in 1969. <br /><br />

                                                    <b>Paro Airport View Point</b>: This viewpoint offers a stunning view of Paro Valley and the airport. You can see the Paro River, dzongs, and monasteries from here. Overnight stay at a hotel in Paro. <br /><br /><br /></p>

                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING / CHELE-LA PASS EXCURSION</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>Today is the most exciting day of your <strong>Bhutan Trip</strong> - the hike to Taktsang Monastery! Start your day early in the beautiful Paro Valley, with the sun rising over the mountains and river. Enjoy a hearty breakfast to fuel up, as this hike will need some energy. <br /><br />

                                                    <b>Taktsang Monastery</b>: The hike to Taktsang, also known as the Tiger’s Nest, takes you up a steep cliff for 1,200 meters. Once you reach the top, you’ll be rewarded with fresh air and amazing views of the valley below. The monastery was built in 1694 and is a sacred site founded by Guru Rinpoche, who introduced Buddhism to Bhutan in 747 CE. The hike takes around 3 hours, but if you prefer, you can ride a horse from the parking lot to the top. Along the way, you’ll enjoy beautiful views of Paro town, its green valleys, the clear blue sky, and the sparkling river. It’s a great spot to take in nature. Overnight stay in Paro. <br /><br /></p>
                                                <h4>SOME FACTS TO NOTE ABOUT – TAKTSANG MONASTERY</h4><br /><br />
                                                <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody>
                                                            <tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Km) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                    <br /><br />
                                                </div>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: PARO TO NJP DROP VIA PHUENTSHOLING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, say goodbye to the Last Buddhist Kingdom from Paro and head to NJP, taking with you unforgettable memories of your Bhutan holiday.  <br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT FACTS TO KNOW FOR <strong>BHUTAN TRIP FROM JAIGAON</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT FACTS TO KNOW FOR <strong>BHUTAN TRIP FROM JAIGAON</strong></h3>
                                </div>
                                <div className="card-body">

                                    <li>Locals call their country "Druk Yul," which means "the Land of the Dragons." <br /><br /></li>
                                    <li>Bhutan has never been conquered or ruled by outside forces. <br /><br /></li>
                                    <li>Bhutan is the only carbon-negative country in the world. <br /><br /></li>
                                    <li>Bhutan was isolated from the rest of the world until the 1970s. <br /><br /></li>
                                    <li>You won’t find any traffic lights in Bhutan. <br /><br /></li>
                                    <li>Bhutan is a constitutional monarchy. <br /><br /></li>
                                    <li>Bhutan does not use GDP as a measure of economic growth. <br /><br /></li>
                                    <li><b>GNH</b> (Gross National Happiness) is the measure of growth and development in Bhutan. <br /><br /></li>
                                    <li>Bhutan is a plastic-free country. <br /><br /></li>
                                    <li>There are fewer than 20 qualified pilots who can fly into Bhutan's only airport. <br /><br /></li>
                                    <li>Smoking is not allowed in Bhutan and is considered a punishable offense. <br /><br /></li>
                                    <li>Bhutan has some of the world’s highest unclimbed mountain peaks, including Gangkhar Puensum at 7,570m (24,981ft). <br /><br /></li>
                                    <li>The national animal of Bhutan is the Takin, a goat-antelope. <br /><br /></li>
                                    <li>Bhutanese food is very spicy, and you will find chilies in almost every dish. <br /><br /></li>
                                    <li>Education and healthcare are free for all citizens. <br /><br /></li>
                                    <li>Archery is the national sport of Bhutan. <br /><br /></li>
                                    <li>Bhutanese people love their king, and you’ll find his photo in every home. <br /><br /></li>
                                    <li>Bhutan has a very low crime rate. <br /><br /></li>
                                    <li>Wearing the national dress (Gho for men and Kira for women) is mandatory in schools, government offices, and during festivals. <br /><br /></li>
                                    <li>Cleanliness is very important in Bhutan, and the environment is highly prioritized. <br /><br /></li>
                                    <li>Bhutanese people often chew betel leaf and areca nut. <br /><br /></li>
                                    <li>Ema Datshi is the national dish of Bhutan, made with spicy chilies and local cheese. <br /><br /></li>


                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM JAIGAON</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM JAIGAON</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                <Activityfecilities />
                                            }
                                        </div>
                                    </div>
                                    <br /><br />

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen20ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>BHUTAN TOUR PACKAGES FROM JAIGAON</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingJaigaon">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseJaigaon"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseJaigaon"
                                                >
                                                    What is Jaigaon known for?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseJaigaon"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingJaigaon"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Jaigaon is a bustling town located at the gateway to Bhutan, specifically to the town of Phuentsholing. It thrives as a key trade hub, with Bhutanese citizens frequently visiting Jaigaon to purchase goods for their daily and weekly needs. As the main entry point to Bhutan, Jaigaon plays a vital role in facilitating cross-border trade and travel.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSmoking">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSmoking"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSmoking"
                                                >
                                                    Is Smoking and Drinking permitted in Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSmoking"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSmoking"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Smoking is strictly prohibited in public places across Bhutan. However, drinking is permitted, and you can find many restaurants and bars offering a variety of local beverages, including traditional Bhutanese drinks.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCurrency">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCurrency"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCurrency"
                                                >
                                                    What is the currency of Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCurrency"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCurrency"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan’s official currency is the Ngultrum (BTN). The Ngultrum is pegged at par with the Indian Rupee (INR), so Indian currency is widely accepted in Bhutan, making it convenient for travelers from India.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingStation">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseStation"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseStation"
                                                >
                                                    Which is the nearest station to Jaigaon?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseStation"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingStation"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The nearest railway station to Jaigaon is Hasimara Railway Station, located approximately 17.9 km away from the land border. Hasimara serves as a convenient rail link for travelers heading to Jaigaon and Bhutan.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPack">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePack"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePack"
                                                >
                                                    What to pack for a Bhutan Tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePack"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPack"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    As Bhutan is located in the mountainous regions of the Himalayas, it’s important to pack warm clothes, especially in winter when temperatures can drop significantly. In the summer months, a light jacket or sweater should suffice, but it's always advisable to be prepared for fluctuating weather conditions.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingAirport">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseAirport"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseAirport"
                                                >
                                                    Which is the nearest airport to Jaigaon?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseAirport"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingAirport"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The nearest airport to Jaigaon is Bagdogra Airport, located in Siliguri, West Bengal. It serves as the main airport for travelers heading to Jaigaon and Bhutan. Additionally, Kolkata is the closest major port, with goods often being transported from there to Bhutan.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingHeight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseHeight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseHeight"
                                                >
                                                    What is the height of Jaigaon from sea level?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseHeight"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingHeight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Jaigaon is situated at an elevation of approximately 289 meters (948 feet) above sea level, making it a relatively low-lying town in the region.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_PackageTourJaigaon;