import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import NorthSikkim_Silkroute_8N_9D_Package from '../../../components/package/Sikkim_Darjeeling/NorthSikkim_Silkroute_8N_9D_Package';
import { Helmet } from "react-helmet";

class NorthSikkim_Silkroute_8N_9D_Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ReviewersData: [],

        }
    }

    componentDidMount() {
        window.scroll(0, 0);

        const reviewersDataList = [
            {
                Name: "Kaushik patnaik",
                ImageName: "kaushik.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Accommodations were fantastic & truly of the deluxe category. Transportation arrangements were up to the mark. Most importantly, the team arranged all these in just two days and could modify the point to point travel based on my requirements. I will surely recommend Adorable Vacation LLP. Thanks !",
            },
            {
                Name: "Pratyusha Mahapatra",
                ImageName: "pratyusha.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Excellent service. Especially the ambience of silk route and north Sikkim also. We enjoyed the trip. Thank you team . We'll again travel to more destinations with our trusted travel agency Adorable Vacation 😍 in near future",
            },
            {
                Name: "Tanmay Santra",
                ImageName: "tanmoy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We opted for a silk route with north sikkim tour with them for 9 days. They are very professional and they have taken care of all of our needs nicely during the tour. The driver was very good in behaviour, punctual. Thank you team.",
            },
            {
                Name: "Anirban Roy",
                ImageName: "anirban.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Dear team Adorable , we have safely reached in our hometown leaving a very good memories.It was a really satisfying experience with the Adorable group & Ms. Anushka she is professional. The tour was well organised, hotels they provided was very satisfying. We got prompt response from the team. They did continuous connection with us. Appreciated 😊",
            },
        ];


        this.setState({
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Silk Route Package Tour with North Sikkim, Silk Route Tour Package with Gangtok, North Sikkim</title>
                    <meta name="description" content="We are specialist for Silk Route Package Tour with North Sikkim with Standard & Deluxe Homestay. FOR BOOKING CALL ☎ +91-91470-61467" />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Sikkim Darjeeling Package Tour Reviews",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.8",
                                "bestRating": "5",
                                "ratingCount": "352"
                            }
                        })}
                    </script>
                </Helmet>
                {/* <Helmet>
                    <title>{pageData.title}</title>
                    <meta name="description" content={pageData.description} />
                </Helmet> */}
                
                <MainHeader />

                <NorthSikkim_Silkroute_8N_9D_Package

                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
                <div>
                    <h3 className="dropdown-item2">Silk Route Package Tour with North Sikkim Reviews</h3>
                    <div>
                        <span className="dropdown-item2">4.8</span> <span className="dropdown-item2">Stars Rating, out of</span> <span className="dropdown-item2">5</span><br />
                        <span className="dropdown-item2">based on</span> <span className="dropdown-item2">352</span> <span className="dropdown-item2">Reviews</span>
                    </div>
                </div>
            </>
        )
    }
}

export default NorthSikkim_Silkroute_8N_9D_Page;