import React, { Component, Fragment } from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./route/AppRoute";
import { Helmet } from 'react-helmet';
class App extends Component {
  handleClick = () => {
    this.setState({ isShaking: true });
    const currentUrl = window.location.href;
    const message = `Hi, Please send about the Tour Package details "`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };

  render() {
    const GTM_ID = 'GTM-5RJNQ96G'; // Replace with your GTM ID
    return (
      <Fragment>
        <Helmet>
          <title>Your Page Title</title>
          <meta name="description" content="Your Page Description" />
          {/* Google Tag Manager Script */}
          <script async src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}></script>
          <noscript>
            {`
              <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
                      height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `}
          </noscript>
        </Helmet>
        <BrowserRouter>
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
              {/* <span>
                <small>+91 9883359713</small>
              </span> */}
            </a>
          </div>
          <div className="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-98833-59713">
              <i className="fa fa-phone"></i>
              {/* <span><small>+91 9883359713</small></span> */}
            </a>
          </div>

          <AppRoute />
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default App;