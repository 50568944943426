import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "./InclusionExclusion_TawangBumlaPass_7N_8D";

class AssamArunachalBangalore_11N_12D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/GUWAHATI.webp";

    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Assam_Arunachal_Bangalore_11N_12D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Arunachal Package Tour from Bangalore</strong> - BEST DEAL | BOOK NOW</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Arunachal Package Tour from Bangalore</strong> - BEST DEAL | BOOK NOW</center>
                      <hr />
                    </h3>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}>Looking for <strong>Arunachal Pradesh Tour Package from Bangalore</strong>? <br /><br /> <a href="tel:+91-9674738480"> FOR BOOKING <strong>Arunachal Pradesh Tour Packages from Bangalore</strong> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>Looking for <strong>Arunachal Pradesh Tour Package from Bangalore</strong>? <br /><br /> <a href="tel:+91-9674738480"> FOR BOOKING <strong>Arunachal Pradesh Tour Packages from Bangalore</strong> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>North East Tour Operator</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Embark on a captivating journey through the enchanting landscapes of Assam and Arunachal Pradesh with our meticulously designed <strong>Arunachal package tour from Bangalore</strong>. Ideal for those seeking a harmonious blend of cultural richness and natural wonders, our <strong>Arunachal tour package itineraries</strong> promise an unforgettable adventure. Begin your exploration with our <strong>Arunachal Pradesh package tour from Bangalore</strong>, showcasing the breath-taking beauty of Tawang, Dirang, and Bomdila. <br /><br />
                        For a comprehensive experience, our <strong>Assam Arunachal Pradesh tour package from Bangalore</strong> seamlessly integrates the cultural and wild marvels of Assam – including <b>Majuli</b>, <b>Kaziranga</b>, <b>Tezpur</b>, and <b>Nameri</b> – with the mystical beauty of <b>Arunachal Pradesh</b>. Traverse through tea gardens of Assam, witness wildlife wonders of Kaziranga and Nameri National Park, and experience the serene charm of river islands of Majuli with our <strong>Assam Arunachal tour package from Bangalore by flight</strong>. <br /><br />
                        For enthusiasts seeking the best of Arunachal, our meticulously designed <a href="/meghalaya-shillong-cherrapunji-package-tour-from-guwahati-with-garo-hills-tura-wari-chora" target="_blank"><strong>Shillong Meghalaya tour packages with Garo Hill Tura Wari</strong></a> promise an extraordinary journey. Encounter the serene beauty Arunachal with <a href="/assam-meghalaya-arunachal-pradesh-package-tour-from-guwahati" target="_blank">Assam Arunachal Package tour</a>. Tawang, a picturesque town perched at 10,000 feet above sea level, enchants with its natural grandeur and boasts the title of housing India's largest Buddhist monastery. Bomdila offers a different charm, with its apple orchards, traditional crafts, and awe-inspiring views of the Himalayas. Enjoy the unique flora and fauna through thrilling activities such as jeep safaris and <a href="/kaziranga-package-tour-from-guwahati-with-elephant-safari-jeep-safari" target="_blank"><strong>Elephant Safari at Kaziranga National Park</strong></a>.<br /><br />
                        Embrace our customized <strong>Arunachal Pradesh tour package from Bangalore</strong>, meticulously designed to whisk you away to the prime attractions while ensuring a seamless and enjoyable journey. With accommodation, transportation, meals, and expert guidance included, these tours offer a holistic experience that leaves no detail unattended. <br /><br /></p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DURATION FOR <strong>ASSAM ARUNACHAL TOUR PACKAGE FROM BANGALORE</strong>: 11N | 12D </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DURATION FOR <strong>ASSAM ARUNACHAL TOUR PACKAGE FROM BANGALORE</strong>: 11N | 12D </h4>
                </div>
                <div className="card-body">
                  <p>Places to visit in <strong>Arunachal Tour Starting from Bangalore to Guwahati Airport</strong> :  Majuli 2N | Kaziranga 2N | Bomdila 1N | Tawang 3N | Dirang 1N | Tezpur or Nameri 1N | Guwahati 1N <br /></p>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody><tr><td>Pickup Point for <strong>Bangalore to Arunachal trip</strong></td><td>Jorhat Airport [JRH]</td></tr>
                        <tr><td>Drop Point for <strong>Bangalore to Assam Arunachal Trip</strong></td><td>Guwahati Airport [GAU]</td></tr>
                        <tr><td>Altitude of Tawang</td><td>3,048 m [Coordinates - 27.5861° N, 91.8594° E] </td></tr>
                        <tr><td>Altitude of Dirang</td><td>1,560 m [Coordinates - 27.3584° N, 92.2409° E]</td></tr>
                        <tr><td>Altitude of Bomdila</td><td>2,415 m [Coordinates - 27.2645° N, 92.4159° E]</td></tr>
                        <tr><td>Altitude of Bhalukpong</td><td>213 m [Coordinates - 27.0137° N, 92.6345° E]</td></tr>
                        <tr><td>Altitude of Tezpur</td><td>48 m [Coordinates - 26.6528° N, 92.7926° E]</td></tr>
                        <tr><td>Altitude of Itanagar</td><td>310 m [Coordinates - 27.0843° N, 93.6053° E]</td></tr>
                        <tr><td>Altitude of Ziro</td><td>80/225 m [Coordinates - 26.9324° N, 92.8772° E]</td></tr>
                        <tr><td>Altitude of Aalong</td><td>619 m [Coordinates - 28.1628° N, 94.8054° E]</td></tr>
                        <tr><td>Altitude of Pasighat</td><td>152 m [Coordinates - 28.0632° N, 95.3239° E]</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SUMMARY OF <strong>ARUNACHAL PRADESH PACKAGE TOUR PLAN FROM BANGALORE</strong> </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SUMMARY OF <strong>ARUNACHAL PRADESH PACKAGE TOUR PLAN FROM BANGALORE</strong> </h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              JORHAT TO MAJULI ISLAND
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>MAJULI ISLAND LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>MAJULI - KAZIRANGA NATIONAL PARK</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>JUNGLE ACTIVITIES AT KAZIRANGA [SAFARIS INCLUDED]</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>KAZIRANGA - BOMDILA</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>BOMDILA - TAWANG</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>TAWANG – BUMLA PASS – TAWANG</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>TAWANG LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 9 → </b>
                            <span>TAWANG TO DIRANG</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 10 → </b>
                            <span>DIRANG - TEZPUR / NAMERI NATIONAL PARK</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 11 → </b>
                            <span>TEZPUR / NAMERI - GUWAHATI</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 12 → </b>
                            <span>GUWAHATI AIRPORT DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                  </div>
                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY YOU SHOULD PUT <strong>ASSAM AND ARUNACHAL PRADESH TOUR PLAN</strong> ON YOUR TRAVEL LIST?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY YOU SHOULD PUT <strong>ASSAM AND ARUNACHAL PRADESH TOUR PLAN</strong> ON YOUR TRAVEL LIST?</h4>
                </div>
                <div className="card-body">
                  <p>You must visit Arunachal once in a lifetime because it is a destination that promises an unforgettable odyssey through untouched landscapes and rich cultural heritage. Nestled in the embrace of the eastern Himalayas, this hidden gem unveils mist-draped mountains, luxuriant valleys, and untamed rivers that paint a vivid tapestry of nature's grandeur. Arunachal Pradesh is a destination that beckons with its untouched beauty and cultural richness. Our <strong>Assam Arunachal package tour from Bangalore</strong> invites you to explore this hidden gem nestled in the eastern Himalayas, promising an odyssey through mist-draped mountains, lush valleys, and wild rivers that showcase nature's grandeur in all its glory. <br /><br />
                    Immerse yourself in the warmth of diverse indigenous communities, each weaving intricate traditions and captivating stories that add depth to your journey. Traverse ancient forests, encountering rare wildlife along the way, and feel a profound connection with the earth's vitality. Witness the breath-taking spectacle of the first sun rays delicately kissing the snow-capped peaks, an image that transcends words. <br /><br />
                    Our <strong>Arunachal Pradesh tour packages from Bangalore with Kaziranga, Tezpur, Nameri, and Guwahati</strong> offer an immersive experience, blending the serenity of Arunachal with the vibrant landscapes of Assam. Picture yourself in the enchanting realms of Dirang and Tawang, surrounded by awe-inspiring beauty, and then venture into the wild wonders of Kaziranga National Park. <br /><br />
                    A journey beyond just a destination, Arunachal Pradesh is a symphony of nature's wonders and human heritage. Let this be your moment to create enduring memories, resonating with the adventurer in your soul. Our <strong>Arunachal tour packages from Bangalore with Dirang, Tawang, and Kaziranga</strong> ensure that you leave with an indelible mark on your heart and spirit, defining a lifetime of extraordinary experiences. <br /><br />
                    Book your <strong>Assam Arunachal Pradesh tour package from Bangalore</strong> now and seize the opportunity to witness the magic of Arunachal—a journey that transcends ordinary travel, offering an unforgettable chapter in the travelogue of your life. <br /><br /></p>
                  <h4 className="tomato1">ASSAM ARUNACHAL TOUR PACKAGE FROM BANGALORE BY FLIGHT</h4><br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><td><b>AIRLINES</b></td><td><b>ORIGIN</b></td><td><b>DEPARTURE</b></td><td><b>DESTINATION</b></td><td><b>ARRIVAL</b></td><td><b>DURATION</b></td></tr>
                      </thead>
                      <tbody><tr><td>AirAsia</td><td>Bangalore [BLR]</td><td>08:25</td><td>Guwahati [GAU]</td><td>11:20</td><td>02 h 55 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>08:20</td><td>Guwahati [GAU]</td><td>11:20</td><td>03 h 00 m (non-stop)</td></tr>
                        <tr><td>Akasa Air</td><td>Bangalore [BLR]</td><td>09:20</td><td>Guwahati [GAU]</td><td>12:30</td><td>03 h 10 m (non-stop)</td></tr>
                        <tr><td>Air India Express</td><td>Bangalore [BLR]</td><td>08:20</td><td>Guwahati [GAU]</td><td>11:10</td><td>02 h 50 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>06:15</td><td>Jorhat [JRH]</td><td>14:10</td><td>07 h 55 m (1 stop CCU)</td></tr>
                        <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>08:15</td><td>Jorhat [JRH]</td><td>14:10</td><td>05 h 55 m (1 stop CCU)</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHICH IS THE BEST TIME TO BOOK <strong>ASSAM & ARUNACHAL TOUR PACKAGES FROM BANGALORE</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHICH IS THE BEST TIME TO BOOK <strong>ASSAM & ARUNACHAL TOUR PACKAGES FROM BANGALORE</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Booking an <strong>Assam and Arunachal Pradesh combined tour package from Bangalore</strong> depends largely on the season. These north eastern states boast breath-taking landscapes and unique cultural experiences, making them popular tourist destinations. Here's a guide to help you choose the <strong>best time to book your Arunachal package tour from Bangalore</strong>: <br /><br /></p>
                  <p><b>Spring [March to May]:</b> Spring brings pleasant weather to Assam and Arunachal Pradesh, making it an ideal time for outdoor activities and sightseeing like Kaziranga elephant safari, Kaziranga Jeep Safari, Bird watching in Kaziranga, Brahmaputra river cruise, Mazuli island sightseeing tour and different places in Arunachal. The temperature in spring is moderate, and the lush greenery of the region is at its prime. This season is perfect for exploring the tea gardens of Assam and the pristine valleys of Arunachal Pradesh. Booking an <strong>Arunachal tour package from Bangalore</strong> during this time ensures comfortable travel conditions and enjoyable experiences. <br /><br /></p>
                  <p><b>Summer [June to August]:</b> While summers in Assam and Arunachal Pradesh can be warm and humid, this season also offers unique opportunities for adventure enthusiasts. The higher altitude regions of Arunachal Pradesh experience milder temperatures, making it suitable for trekking and exploring the stunning landscapes. Booking an <strong>Arunachal package from Bangalore</strong> during the summer allows you to indulge in activities like river rafting, hiking, and wildlife safaris.<br /><br /></p>
                  <p><b>Monsoon [September to November]:</b> The monsoon season brings heavy rainfall to the north eastern states, creating lush greenery and vibrant landscapes. While the rainfall may affect outdoor activities, it also adds to the region's natural beauty. If you're a nature lover and don't mind occasional showers, booking an <strong>Arunachal tour from Bangalore</strong> during the monsoon can offer a unique experience of witnessing cascading waterfalls, blooming flowers, and mist-covered hills.<br /><br /></p>
                  <p><b>Winter [December to February]:</b> <strong>Winter is considered the best time to visit Assam and Arunachal Pradesh</strong>. The weather is cool and pleasant, making it ideal for sightseeing and outdoor adventures. This season offers clear skies, perfect for capturing stunning views of the Himalayan peaks in Arunachal Pradesh. From exploring ancient monasteries to indulging in traditional festivals, there's a lot to experience during winters in these states. Booking an <strong>Arunachal Pradesh package tour from Bangalore</strong> during this time ensures comfortable weather conditions and memorable travel experiences.<br /><br /></p>
                  <p>In conclusion, <strong>the best time to book an Assam and Arunachal Pradesh combined tour package from Bangalore</strong> depends on your preferences and the season. Whether you prefer mild weather for outdoor activities or vibrant landscapes during the monsoon, there's something for everyone in these enchanting north eastern states.<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Arunachal Travel Months</b></th><th><b>Arunachal Weather</b></th><th><b>Arunachal Temperature</b></th></tr></thead>
                      <tbody><tr><td>March to June</td><td>Summer</td><td>10°C to 30°C</td></tr>
                        <tr><td>July to October</td><td>Monsoon</td><td>18°C to 32°C</td></tr>
                        <tr><td>November to February</td><td>Winter</td><td>6° C to 22° C</td></tr></tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY ADORABLE VACATION IS ONE OF THE <strong>BEST TOUR OPERATORS FOR ARUNACHAL PRADESH IN BANGALORE</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS ONE OF THE <strong>BEST TOUR OPERATORS FOR ARUNACHAL PRADESH IN BANGALORE</strong>?</h4>
                </div>
                <div className="card-body">
                  <li>Adorable Vacation have a wide variety of Pre-Purchased Room Inventory in Arunachal Pradesh which ensures assured valley/mountain/river view room based on your <strong>Arunachal package booking from Bangalore</strong>.<br /><br /></li>
                  <li>With our Premium & Deluxe Hotel/Luxury Home Stay, you get best facilities and amenities during your <strong>Assam Arunachal tour from Bangalore</strong>. <br /><br /> </li>
                  <li>Your vehicle for <a href="/arunachal-pradesh-package-tour-from-kolkata" target="_blank"><strong>Arunachal tour packages</strong></a> will be operated from Guwahati / Tezpur / Dibrugarh / Itanagar / Jorhat. <br /><br /></li>
                  <li>You get a variety of car according to your <strong>Arunachal tour budget from Bangalore</strong> like Innova / Ertiga / Scorpio / XUV 500 (Sunroof). <br /><br /></li>
                  <li>We have separate war room and specialized staff from North East for <b>Arunachal tour operations</b>, so that you get best uninterrupted service throughout your <a href="/arunachal-pradesh-package-tour-from-guwahati" target="_blank"><strong>Arunachal tour from Guwahati Airport</strong></a>. <br /><br /></li>
                  <li>Being <strong>Best Arunachal tour operator in Bangalore</strong>, we always guide you for your <strong>Arunachal trip</strong> in accordance with Peak Season Rush / Weather / Off-Season, because we are best among the responsible <strong>Arunachal tour guides from Bangalore</strong> and always care for your safety and hard earned money. <br /><br /></li>
                  <li>Adorable Vacation is specialist for <strong>Arunachal Pradesh Tour Package with Tawang Kaziranga Majuli Nameri</strong> for more than 11 years. Company Offers Delux Homestays / Premium Stays / Luxury Boutique Resorts / Flavoured Properties. <br /><br /></li>
                  <li>We have a team of experience Passionate Travelers. Every hotels / homestays is personally visited & certified by Adorable Vacation team. <br /><br /></li>
                  <li>The company is expert in conducting <strong>Arunachal group tour</strong>. Every year company conduct <strong>Arunachal Group Departure in Summer Vacation</strong> and <strong>Arunachal Tour in Durga Puja / Diwali Holidays</strong>. <br /><br /></li>
                  <li>Guests will be given complete day to day guidance and assistance from Guwahati to Guwahati by Adorable Vacation team member and their representation on field. Every <strong>Guwahati Kaziranga Tawang tour</strong> is always handled by Adorable Vacation Team itself, not handed over to a Sub-Agent. <br /><br /></li>
                  <h4 className="tomato1">DISTANCES TO NOTE FOR <strong>ARUNACHAL PRADESH TOUR PACKAGES WITH KAZIRANGA TEZPUR NAMERI GUWAHATI</strong></h4><br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>FROM</b></th><th><b>TO</b></th><th><b>DISTANCE / TRAVEL TIME</b></th></tr></thead><tbody>
                        <tr><td>Jorhat</td><td>Majuli Island</td><td>1 hr 51 min (41.3 km) via Neamati Ghat - Jorhat Rd and Kamalibari - Nimati Ferry Service</td></tr>
                        <tr><td>Majuli Island</td><td>Kaziranga National Park</td><td>3 hr 53 min (138.8 km) via NH715</td></tr>
                        <tr><td>Kaziranga National Park</td><td>Bomdila</td><td>5 hr (213.8 km) via NH715</td></tr>
                        <tr><td>Dirang</td><td>Tawang</td><td>4 hr 18 min (139.2 km) via NH13</td></tr>
                        <tr><td>Tawang</td><td>Bomdila</td><td>5 hr 3 min (176.6 km) via NH13</td></tr>
                        <tr><td>Bomdila</td><td>Tezpur</td><td>3 hr 38 min (150.2 km) via NH13 and Chariduar - Tawang Rd</td></tr>
                        <tr><td>Tezpur</td><td>Itanagar</td><td>2 hr 44 min (139.3 km) via NH 15</td></tr>
                        <tr><td>Itanagar</td><td>Pashighat</td><td>5 hr 45 min (261.4 km) via NH 15 and NH515</td></tr>
                        <tr><td>Pasighat</td><td>Dibrugarh</td><td>3 hr 13 min (148.8 km) via NH515</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>

              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE <strong className="tomato">ASSAM ARUNACHAL PRADESH PACKAGE TOUR ITINERARY FROM BANGALORE</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE <strong className="tomato">ASSAM ARUNACHAL PRADESH PACKAGE TOUR ITINERARY FROM BANGALORE</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <h4>DAY 1 : JORHAT TO MAJULI ISLAND [1 hr 53 min | 41.3 km]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Meet and greet on arrival at Jorhat Airport for your much awaited <strong>Arunachal Pradesh tour package from Bangalore</strong>. Have a tea break and then proceed towards Majuli, the Guinness Book of World Records has named Majuli as the world's largest river island. The beautiful river island is located on the river Brahmaputra which is one of the most important cultural centres of Assam since the 16th century. It was once the primary hub of the neo-Vaishnavite culture. Many monasteries constructed by the Assamese Saint - Srimanta Sankardeva, still survive and depict the vibrant culture of Assam. Majuli has been the cultural capital and the cradle of Assamese civilization.
                            Your journey's exciting part will start from Nimati and will require you to embark upon a Ferry to cross the Brahmaputra River and reach Majuli Island. As soon as you reach Majuli Island, you will be greeted by white beach sand, which is actually silt carried along by the mighty river. Bumpy ride will make you feel like riding on a camel. Overnight Stay at Majuli Island. <br /><br /></p>

                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <h4>DAY 2 : MAJULI ISLAND LOCAL SIGHTSEEING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>In the morning after breakfast, visit local markets of Majuli and buy some suveniors like masks, clay pots, traditional dresses and hand made boats. You can actually see all of it being made by hand by the local artisans and craftsmen. At lunch you can experience the traditional meal on the island. Visit Garamur Satra, Sri Sri Auniati satra, Dakhinpat Satra, Shamaguri Satra, Tengapania, Kalambari Satra are the major royal Satras of the Majuli Island. Overnight Stay at Majuli Island. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 3 : MAJULI - KAZIRANGA NATIONAL PARK [4 hr 12 min | 151.0 km]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>After morning breakfast, proceed towards Kaziranga National Park for <a href="/kaziranga-package-tour-from-guwahati-with-elephant-safari-jeep-safari" target="_blank"><strong>Kaziranga Elephant Safari package tour</strong></a>. Covering an area of 430 sq-km, Kaziranga is inhabited by the world's largest population of one-horned rhinoceroses, as well as many mammals, including tigers, elephants, panthers, and bears, and thousands of birds. In 1985, it was declared a World Heritage Site by UNESCO for its unique natural environment. In the evening, you can enjoy Orchid Park for a cultural program in Kohora. Overnight Stay at Kaziranga National Park.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          <h4>DAY 4 : JUNGLE ACTIVITIES AT KAZIRANGA [SAFARIS INCLUDED]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After an early wake-up, we will take you to the riding point for Kaziranga Elephant Safari in the Western Range. Watch the famous One-Horn Rhino in the jungle. After the jeep safari, return to the Resort. After lunch, drive to Kaziranga Central Range for the Kaziranga Jeep Safari. The Central zone of the Kaziranga National Park is the prime zone for eco-tourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The Kaziranga Jungle Safari offers you some spectacular sightings of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with adorable baby elephants to big old elephants in the herd, and many other species of the wild flora and fauna of this tourism zone. Overnight Stay at Kaziranga National Park. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          <h4>DAY 5 : KAZIRANGA - BOMDILA [200 KM / 5 HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>After morning breakfast, start your car and drive to Bomdila (8,000 ft. appx). Bomdila is situated at an altitude of over 8500 feet in the northeastern state of Arunachal Pradesh in India. Hooked amongst the mighty mountainous ranges of Himalaya, Bomdila is the headquarters of the West Kameng district of the state of Arunachal Pradesh and offers a panoramic view of the awesome Himalayan terrain. Overnight stay and enjoy your <a href="/assam-meghalaya-arunachal-pradesh-package-tour-from-guwahati" target="_blank"><strong>Assam Meghalaya Package Tour From Guwahati</strong></a>. <br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          <h4>DAY 6 : BOMDILA - TAWANG [176 KM / 5 HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>In the morning after breakfast, start your car and drive for <a href="/tawang-bumla-pass-package-tour-from-guwahati" target="_blank"><strong>Tawang Bumla Pass tour</strong></a> crossing Sela Pass (13720 ft.) which runs through a small ridge and along the paradise lake. Stop to enjoy the Sella Pass and Paradise Lake. From the Sela pass road winds down to Jong Village. On the way to Jong visit Yashwant Garh War Memorial. After lunch at Jong Village starts for Tawang. On the way, visit Nuranang Fall. Overnight at Tawang.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          <h4>DAY 7 : TAWANG TO BUMLA-PASS DAY EXCURSION</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Enjoy a full-day excursion to visit the PT-Tso Lake, Bumla Pass, and Sangestsar Lake. Sangestser is popularly known as (Madhuri Lake) which was named after the Legendary Bollywood star Madhuri Dixit, located 35 km from Tawang near China border. The drive for Sangestser Lake is full of thrill and one can still see the war bankers that had been used during the Chinese aggression in 1962 and the beautiful lake. Drive back to Tawang. Overnight at Tawang.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          <h4>DAY 8 : TAWANG LOCAL SIGHTSEEING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>Sightseeing in and around Tawang City. Visit the famous Tawang Monastery, which is 400 years old, accommodates over 500 monks, and houses priceless Buddhist texts and scriptures. The Tibetan influence here is unmistakable, with the elaborately painted wooden windows and other motifs. An 8 mt high gilded statue of the Buddha is seated here, as are numerous ancient Thankas or Traditional paintings and manuscripts. Also visit the Craft Centre, and Tawang War Memorial. Evening free time. Overnight at Tawang.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          <h4>DAY 9 : TAWANG TO DIRANG TRANSFER [134 KM / 4 HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, check out of the Hotel & transfer for <a href="/tawang-bumla-pass-package-tour-from-guwahati" target="_blank"><strong>Tawang Bumla Pass Package tour</strong></a>, crossing the same Sela Pass (14000 ft.) from sea level and reaching Dirang by afternoon. We will visit to drive to Shangti valley, this valley is famous for Black Named cranes that come in October and fly back in May. Considered to be the best valley in Eastern Himalayan Zone. Overnight at Dirang.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          <h4>DAY 10 : DIRANG – TEZPUR/NAMERI</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>After early breakfast, drive toward the foothills of Arunachal Pradesh and reach the plains of Assam and onwards. You can enjoy boating at Jia Bharali River (Open till 02 PM) and do the sacred forest walk inside the Nameri National Park. Overnight stay at Tezpur / Nameri National Park.<br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day11">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          <h4>DAY 11 : TEZPUR/NAMERI - GUWAHATI</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>After early breakfast, drive toward the foothills of Arunachal Pradesh and reach the plains of Assam and onwards. You can enjoy boating at Jia Bharali River (Open till 02 PM) and do the sacred forest walk inside the Nameri National Park. Overnight stay at Tezpur / Nameri National Park.<br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day12">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          <h4>DAY 12 : CHERISH YOUR MEMORIES</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>In the morning after breakfast, check out from your hotel & then transfer to Guwahati airport. Your 12 days <strong>Assam Arunachal Pradesh Package Tour starting from Jorhat Airport</strong> ends here with sweet memories to cherish forever.<br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> VALLEYS MUST VISIT DURING <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> VALLEYS MUST VISIT DURING <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <p>Revered as the <b>Land of The Rising Sun</b> Arunachal unveils valleys of unparalleled beauty that leave every traveler and nature enthusiast awestruck. The valleys we are about to delve into are a canvas of picturesque charm, adorned with lush meadows, pine forests, crystalline streams, and unending apple and orange orchards. Allow us to offer you a glimpse into a selection of the most captivating valleys waiting to be discovered on your <strong>Arunachal Trip.</strong><br /><br /></p>
                  <p><b>Sangti Valley</b> - Situated within the West Kameng District of Arunachal Pradesh, this valley becomes a popular summer destination thanks to its delightful climate. What sets this valley apart is the graceful presence of black-necked cranes along with flourishing fruit orchards, boasting a variety of apples, oranges, and kiwis.<br /><br /></p>
                  <p><b>Mechuka Valley</b> - Nestled within the Shi Yomi District of Arunachal Pradesh, this valley rests at an elevation of 6000 feet above sea level, in close proximity to the Indo-China border at a mere distance of 29 kilometers. Embellished with snow-capped peaks, meandering mountain streams, and luxuriant green meadows, Mechuka stands as one of Arunachal's most sought-after destinations.<br /><br /></p>
                  <p><b>The Three Valleys</b> - Encompassing three distinct valleys – Pakke Valley, Pappu Valley, and Passa Valley – this region presents a captivating spectacle of natural beauty. During the summer season, the valleys come alive with a vibrant display of wildflowers, while an abundance of clear streams adds to the enchantment of the scenery, ensuring an unforgettable experience. Furthermore, the arrival of numerous migratory birds in these valleys during summer contributes to their allure, making them a highly favored destination among tourists.<br /><br /></p>
                  <p>Apart from these valleys, there are Dibang Valley in Dibang District / Lohit Valley in Lohit District / Lal Ane valley in Papum Pare District / Aalo Valley in West Siang District / Ziro Valley in Lower Subansiri district are very popular among tourists. <br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO GET ILP [INNER LINE PERMIT] FOR <strong>ARUNACHAL TOUR STARTING FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO GET ILP [INNER LINE PERMIT] FOR <strong>ARUNACHAL TOUR STARTING FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <p>Here to note that due to its immense strategic importance, you can experience heavy deployment of Army personals and military hardware in different parts of Arunachal. So to travel sensitive area, you need to acquire a special permit called <b>ILP</b> or <b>Inner Line Permit</b>. Basically to enter Arunachal you need an ILP. After crossing Valukpong, in every location like Dirang, Bomdila, Tawang in western part and Itanagar in eastern part, travellers need to produce ILP. For Ziro and other protected/preserved areas, a special permit is required called <b>RAP</b> or <b>Restricted Area Permit</b>. That can be arranged by Adorable Vacation. You need not to take any headache.<br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO CARRY FOR <strong>ARUNACHAL TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO CARRY FOR <strong>ARUNACHAL TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Essential Documents</b> - To explore the diverse destinations of Arunachal, securing an Inner Line Permit (ILP) is imperative. Ensure you carry original identification documents such as your Voter Card, Aadhar Card, or Driving License, along with passport-sized photos. It's advisable to keep a printed copy of your tour details for convenience.<br /><br />
                    <b>Skin Care Essentials</b> - Shield your skin from both the sun's rays and chilly nights by including ample amounts of sunscreen lotion and moisturizers in your travel kit.<br /><br />
                    <b>Camera with Extra Power</b> - Opt for prudence by packing spare batteries for your camera. Arunachal's remote locations might not offer quick access to battery replacements during urgent situations.<br /><br />
                    <b>Adaptable Clothing</b> - Arunachal's climate remains capricious. Be ready for unexpected rain, wind, and chill. Pack a combination of heavy woolens, a sturdy jacket, and light clothing to stay comfortable.<br /><br />
                    <b>Footwear for All Occasions</b> - The right shoes are pivotal for any journey. For your <strong>Arunachal Pradesh Package Tour from Bangalore</strong>, waterproof shoes and socks are a wise choice due to the uncertain weather. Carry slippers for casual strolls.<br /><br />
                    <b>Medicinal Preparedness</b> - Medicines should be in the top most priority list of packing for any tour and in places like Arunachal, no way rather than packing lots of medicines for your need like paracetamol, pain relief balm or spray, tablets for headache, motion sickness, vomiting, loose motion, acidity, gas, anti allergic medicine and cough syrup. <br /><br />
                    Apart from that other necessary things that need to be in your travel bag are - <br />
                    Camphor, Inhaler, Sunglasses, Tripod, Rain Gear, Power Bank, Rain Coat, Umbrella, <strong>Arunachal Tourism Map</strong>, Spare pairs of socks, under garments and slippers etc. <br /><br /></p>
                </div>
              </div>
              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>FREQUENTLY ASKED <span>QUESTIONS [FAQ]</span> FOR</h2>
                      <h4>FOR <strong>ARUNACHAL PACKAGE TOUR FROM BANGALORE</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Q. What is Losar?
                          </button>
                        </h4>
                        <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Losar is one of the major festivals in Arunachal Pradesh as it marks the Tibetan New Year. Monpas, Akas, Sherdukpens, Memba , Khamba and nah tribes of Arunachal Pradesh which are following the Mahayana set of Buddhism celebrate this festival with all Pomp. In this festival, the priests make the offerings to the highest priests called Dharmapala or Palden Lhamo whereas the masses visit their friends and family to wish them Tashi Delek (Best Wishes). National leaders and kings are honoured and on the last day peoples make offering to the Dharmapala and tie prayer flags on rooftops and across the locality. This wonderful events can be witnessed if you book a <strong>Arunachal Tour Packages from Bangalore</strong> starting from Guwahati Airport with Adorable Vacation Holidays.<br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Q. What are the permit rules for foreign tourists ?
                          </button>
                        </h4>
                        <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Foreign visitors must possess a Protected Area Permit (PAP) to enter Arunachal Pradesh. PAPs can be obtained through Indian Missions abroad, FRROs in select cities, Home Ministry of India, and Arunachal Pradesh's Home Commissioner. Since 2008, the permit process is more lenient, often requiring a minimum of two travelers, but solo travelers can access limited areas. Permits cost $50/person, valid for 30 days without extension. Apply online and submit at an FRRO.<br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFive">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Q.What food to try during our trip to Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Arunachal Pradesh, is not only a traveler's delight, but also a loved destination within the foodies, basically for various meat preparations like lukter made out of Beef, chicken or yak momo, thukpas, bamboo shoot dishes, dung po, Chura sabji, pika pila, and many more. <br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSix">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Q. is the ILP for visiting Tawang sufficient?
                          </button>
                        </h4>
                        <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Certainly, the identical ILP suffices for trips to Bhalukpong, Dirang, Tawang, and Bomdila. However, securing a distinct border permit from the Tawang DC office, approved by the army, is essential for visiting Bum La Pass. This permit should be obtained a day ahead of your intended visit.<br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSeven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            Q. Can I do trekking during my Compllete arunachal pradesh package tour from Guwahati
                          </button>
                        </h4>
                        <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>North East is full of enthralling trekking routes, and yes, while visiting this State, trekking can be a part of your package, with prior information to your holidaymaker.<br /><br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEight">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Q.  Which are the best spot to watch birds during my North East trip?
                          </button>
                        </h4>
                        <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Kaziranga National Park, Nameri National Park, Eagle Nest Wildlife Sanctuary, and Dipor Bil are some of the renounced places within the bird watchers in North East, which homes to a numerous variety of exotic birds and also known for their pristine beauty.<br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingNine">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                            Q. Can I avail Jain food during my Arunachal Tour?
                          </button>
                        </h4>
                        <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>You can avail Jain food during your <strong>Arunachal Trip</strong>, but it is advisable to mention your meal preference at the time of booking the <strong>Arunachal Tour Package with Adorable Vacation</strong>.</p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                          Can I get medical shops on the way for Arunachal Pradesh Tour?
                          </button>
                        </h4>
                        <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Yes You can, but only at town like Guwahati, Itanagar, Pasighat. As most of your journey will be in high altitude terrain, It is better to carry the prescribed and general medicines with you while traveling Arunachal. <br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEleven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                            Q. Do I need to carry high altitude medicines while traveling to Arunachal?
                          </button>
                        </h4>
                        <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>To be on the safe side, it is better to carry upon consultation with your physician based on your age and health condition.<br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTwelve">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                            Q. Shall we visit offbeat destinations also during our Arunachal Pradesh Tour?
                          </button>
                        </h4>
                        <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>During your <strong>16 Nights & 17 Days Arunachal Tour</strong> you will visit many offbeat destinations like Ziro Valley, Mechuka Valley, Pasighat, and Aalo. <br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. Who is the chief minister of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Shri Pema Khandu is the chief minister of Arunachal Pradesh.<br /></p></div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. How many districts are there in Arunachal Pradesh as on 2023?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>As on 2023, Arunachal Pradesh comprises of 25 districts, they are: Tawang, West Kameng, East Kameng, Papum Pare, Kurung Kumey, Kra Daadi, Lower Subansiri, Upper Subansiri, West Siang, East Siang, Siang, Upper Siang, Lower Siang, Lower Dibang Valley, Upper Dibang Valley, Anjaw, Lohit, Namsai, Changlang, Tirap, Longding, Kamle, Lepa-Rada, Pakke-Kessang and Shi-Yomi.
                          Among these 25 districts, Shi-Yomi is the last district created with two others Pakke-Kessang and Shi-Yomi.<br /></p></div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. Which is the main river in Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Brahmaputra River which is known as "Yarlung Tsangpo" in Tibet, "Luit / Dilao" in Assam and "Siang/Dihang" in Arunachal is the life line in Arunachal Pradesh.<br /></p></div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. Is it safe to travel to Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Arunachal Pradesh is the safest state in the entire northeastern region of India. One of the vital reasons to mark it out as the safest of all is the people here are not that much religious; therefore, they stay away from all types of vices over the nation. You may not have any problem while traveling Arunachal Pradesh, but you need to carry your identity cards, also you must have an inner line permit to enter any of the states in North-eastern states.<br /></p></div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. Do I need a Postpaid Sim to travel to Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Yes, it's advisable to use a postpaid SIM when visiting Arunachal Pradesh due to potentially better network coverage in remote areas. This can ensure more reliable communication during your trip.<br /></p></div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. Can I receive snowfall in Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Tawang is situated at high altitude 3,048 m or 10,000 ft above sea level. Visitors can experience plenty of snowfall from end of November to end of April. Freezing cold accompanied by chill breeze is the weather in Tawang in winter season. So, if you want to enjoy snow, visit this time.<br /></p></div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Q. How many days does it take to complete the Arunachal Pradesh Tour?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Well it depends. It may take more than 30 days if you want to know every detail about the place. However, if you want to cover the best places in Arunachal, a 10 days of <strong>Arunachal Tour Package from Bangalore starting from Guwahati</strong> is enough to experience a wonderful trip. Total sightseeing of the Tawang region along with the Bum La Pass and Zimithang can be covered in 9 night/10 days. You can also choose the other side of the state such as Ziro and Mechuka region that takes 8 to 9 days to cover(if you want to cover Bhalukpong and Bomdila). Do not miss to visit Kaziranga and Nameri.<br /></p></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3><b> Arunachal Tour Bangalore<span class="tomato"> Holiday Reviews</span></b></h3>
                      <h4><i>"Your Experience is our Marketing"</i></h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div id="testimonials">
                    <div className="testimonial-box-container">

                      {reviewersDataView}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-9674738480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default AssamArunachalBangalore_11N_12D_Package;
