import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import BangkokPattaya_8N_9D_Package from '../../../components/package/Thailand/BangkokPattaya_8N_9D_Package';
import { Helmet } from 'react-helmet';
class BangkokPattaya_8N_9D_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ThemePackagesData: [],
            HotelsData: [],
            TrendingBhutanDataList: [],
            ReviewersData: [],

        }
    }


    componentDidMount() {

        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "4N-5D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro | Free Sim Card | E-Permit",
                PricePerNight: "22,599",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/4N-5D-FIFO-package',
                EXPARO: "EX-PARO"
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "5N-6D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "27,899",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/5N-6D-FIFO-package',
                EXPARO: "EX-PARO"

            },
            {
                Title: "Explore Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,999",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/6N-7D-FIFO-package',
                EXPARO: "EX-PARO"
            },

            {
                Title: "Excape Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,999",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/mumbai-special-chartered-flight',
                EXPARO: "EX-PARO"
            },
            {
                Title: "Pleasing Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "43,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/8N-9D-FIFO-package',
                EXPARO: "EX-PARO"
            },
            {
                Title: "Fascinating Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Bumthang...more | Free Sim Card | E-Permit",
                PricePerNight: "53,899",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/10N-11D-FIFO-package',
                EXPARO: "EX-PARO"
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ]

        const reviewersDataList = [
            {
                Name: "Upama Roy",
                ImageName: "Upama-Roy-Mehta.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We always travel with Adorable Vacation. This time we went for a 6 days trip to Bangkok, Phuket and Krabi. As usual the trip was very well planned. Everybody was always ready to help. Great hotels, great hospitality. Really had fun in the trip.Looking forward to travel again with Adorable Vacation team in our next holiday.",
            },
            {
                Name: "Sourav Das",
                ImageName: "Sourav-Das.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Good Hotels, Good Food, Good Location... All together it was really Very well arranged Tour to Thailand. I am thanks to total team Adorable.",
            },
            {
                Name: "Ruchir Mehta",
                ImageName: "RUCHIR-MEHTA.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We had an amazing 8-night, 9-day family tour of Thailand with Adorable Vacation LLP! From the bustling streets of Bangkok to the serene beaches of Pattaya, every moment was perfectly organized and hassle-free. The accommodations were luxurious, the itinerary well-balanced, and the local experiences unforgettable. Highlights included the vibrant markets, breathtaking temples, and delightful Thai cuisine. Our driver knowledgeable and friendly, making our trip even more enjoyable. We couldn't have asked for a better family vacation!",
            },
            {
                Name: "Shalini Paul",
                ImageName: "Shalini-Paul.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Thanks this wonderful team for organising my honeymoon trip to Thailand with ease They regularly followed up weather we had any problems or not Kudos to the team for their good work.",
            },
        ];

        const hotelDataList = [
            {
                HotelName: 'Hotel Ugyen, Thimpu',
                ImageName: "hotel-ugyen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Amodhara, Thimphu',
                ImageName: "amodhara-2.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'White Dragon, Punakha',
                ImageName: "white-dragon.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Pema karpo, Punakha',
                ImageName: "pema-karpo.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Layhuel, Paro',
                ImageName: "lehul.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Tshringma, Paro',
                ImageName: "Tsherim-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '43 Reviews'
            },
            {
                HotelName: 'Green Touch, Lataguri',
                ImageName: "green-touch.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '44 Reviews'
            },
            {
                HotelName: 'Suncity, Lataguri',
                ImageName: "suncity.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '40 Reviews'
            }
        ];

        const trendingBhutanDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "31,899",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Url: 'tel://9883359713',
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp ",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,599",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Url: 'tel://9883359713',
            },
            {
                Title: "Explore Bhutan",
                ImageName: "9N-10D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "48,899",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Url: 'tel://9883359713',
            },
            {
                Title: "Excape Bhutan",
                ImageName: "12N-13D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "76,500",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Url: 'tel://9883359713',
            }

        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            ThemePackagesData: themePackageDataList,
            HotelsData: hotelDataList,
            TrendingBhutanDataList: trendingBhutanDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bangkok Pattaya Package Tour from Kolkata</title>
                    <meta name="description" content=" We Offer the Best Bangkok Pattaya package tour from Kolkata, including Phuket and Krabi. For Booking ☎ CALL Mr. AKASH +91-91636-76037" />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Bangkok Pattaya Tour From Kolkata Reviews",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.8",
                                "bestRating": "5",
                                "ratingCount": "352"
                            }
                        })}
                    </script>
                </Helmet>

                <MainHeader />

                <BangkokPattaya_8N_9D_Package
                    HotPackagesData={this.state.HotPackagesData}
                    ThemePackagesData={this.state.ThemePackagesData}
                    HotelsData={this.state.HotelsData}
                    TrendingBhutanDataList={this.state.TrendingBhutanDataList}
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
                <div>
                    <h3 className="dropdown-item2">Bangkok Pattaya Tour From Kolkata Reviews</h3>
                    <div>
                        <span className="dropdown-item2">4.8</span> <span className="dropdown-item2">Stars Rating, out of</span> <span className="dropdown-item2">5</span><br />
                        <span className="dropdown-item2">based on</span> <span className="dropdown-item2">352</span> <span className="dropdown-item2">Reviews</span>
                    </div>
                </div>
            </>
        )
    }
}

export default BangkokPattaya_8N_9D_Page;