import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Meghalaya/InclusionExclusion_KazirangaPackageTourWithElephantSafari_7N_8D";

class KazirangaPackageTourWithElephantSafari_7N_8D extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,

    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919830889567&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };


  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-elephant-safari-tour-package-from-guwahati-web.webp";
    const packageTopBanner2ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-elephant-safari-tour-package-from-guwahati-mobile.webp";
    const sightseen1ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-nameri-national-park-safari-package-tour-booking.webp";
    const sightseen2ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-nameri-national-park-safari-package-tour-itinerary.webp";
    const sightseen3ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-national-park-elephant-safari-package-tour.webp";
    const sightseen4ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-national-park-jeep-safari-package-tour.webp";
    const sightseen5ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-nameri-national-park-safari-package-tour.webp";
    const sightseen6ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-nameri-pobitora-national-park-package.webp";
    const sightseen7ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-nameri-pobitora-national-park-package-tour.webp";
    const sightseen8ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-nameri-pobitora-national-park-tour.webp";
    const sightseen9ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-national-park-elephant-safari-package-tour.webp";
    const sightseen10ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-national-park-elephant-safari-tour-packages.webp";
    const sightseen11ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-manas-national-park-package-tour.webp";
    const sightseen12ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-elephant-safari-package-tour-from-guwahati.webp";
    const sightseen13ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-elephant-safari-tour-packages-from-guwahati.webp";
    const sightseen14ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-jeep-safari-package-tour-booking.webp";
    const sightseen15ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-elephant-safari-package-tour-booking.webp";

    const CallButtonSonaliImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/call-button-kaziranga-adorable-vacation.webp";

    const navDay1ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-national-park-jungle-safari-package-tour.webp";
    const navDay2ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/kaziranga-national-park-jungle-safari-tour-packages.webp";
    const navDay4ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/nameri-tiger-reserve-tour-package-booking.webp";
    const navDay5ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/manas-national-park-elephant-safari-package-tour.webp";
    const navDay7ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/pobitora-wild-life-sanctuary-package-tour-safari.webp";
    const navDay8ImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/Kamakhya-Temple-Guwahati.webp";



    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.kaziranga_With_Elephant_Safari_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kaziranga Package Tour From Guwahati</strong> with Elephant + Jeep Safari</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4.5vw" }}><center><strong>Kaziranga Package Tour From Guwahati</strong> with Elephant + Jeep Safari</center>
                      <hr />
                    </h3>

                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        4 Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Kaziranga
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Nameri
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Manas
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Pobitora
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <br />
                    <p className="p-2">
                      Embark on an unforgettable <strong>Kaziranga Package Tour from Guwahati</strong>, offering an immersive wildlife experience in Assam's National Parks. This tour begins with a convenient pickup from Guwahati Airport, taking you to the heart of the nature. Experience the thrill of a <strong>Kaziranga Package Tour with Elephant Safari</strong> and <strong>Kaziranga Package Tour with Jeep Safari</strong>, where you'll encounter the famous One-Horned Rhinoceros and diverse wildlife. This <strong>Kaziranga Safari Package</strong> also includes spectacular Nameri National Park and the lesser-known but beautiful Pobitora National Park. Ideal for nature lovers and adventure enthusiasts, this <strong>Kaziranga Package</strong> promises a rich and diverse wildlife experience.
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}>LOOKING FOR <strong className="tomato">KAZIRANGA ELEPHANT SAFARI PACKAGE TOUR</strong>?<br /><br />
                    FOR BOOKING <strong className="tomato">KAZIRANGA PACKAGE TOUR WITH ELEPHANT SAFARI FROM GUWAHATI</strong> <a href="tel:+91-9674738480"> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>LOOKING FOR <strong className="tomato">KAZIRANGA ELEPHANT SAFARI PACKAGE TOUR</strong>?<br /><br />
                    FOR BOOKING <strong className="tomato">KAZIRANGA PACKAGE TOUR WITH ELEPHANT SAFARI FROM GUWAHATI</strong> <a href="tel:+91-9674738480"> ☎ CALL +91-96747-38480</a><br /><br /></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> START YOUR <strong>KAZIRANGA SAFARI PACKAGE</strong> WITH ADORABLE VACATION - BEST DMC FOR NORTH EAST INDIA</h3>
                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> START YOUR <strong>KAZIRANGA SAFARI PACKAGE</strong> WITH ADORABLE VACATION - BEST DMC FOR NORTH EAST INDIA</h4>
                    </div>
                    <div className="card-body">
                      <p>If you're a wildlife enthusiast looking to explore nature in its purest form, a <strong>Kaziranga Package Tour from Guwahati</strong> offers the perfect escape. Famous for its thriving population of one-horned rhinoceroses, Kaziranga National Park is one of India's most well-preserved and picturesque wildlife sanctuaries. Located on the banks of the majestic Brahmaputra River, the park is only about 250 km from Guwahati, making it easily accessible. The <strong>Kaziranga Package Tour from Guwahati Airport</strong> is an ideal way to begin your adventure, as you are seamlessly transferred to this wildlife haven.<br /><br />

                        A highlight of this tour is the <strong>Kaziranga Elephant Safari</strong>, where you'll embark on a thrilling journey through the grasslands on the back of an elephant, offering a unique perspective of the park's diverse flora and fauna. The <a href="https://adorablevacation.com/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati/" target="_blank"><strong>Kaziranga Shillong Cherapunjee Package Tour from Guwahati</strong></a> also includes exciting jeep safaris, taking you closer to the park's exotic wildlife, including tigers, wild elephants, and the famous one-horned rhinoceros. This UNESCO World Heritage site is home to more than 480 species of birds, wild boars, deer, and several reptiles, providing an unparalleled experience for nature lovers.<br /><br />

                        Additionally, your tour will include visits to local tea estates, souvenir shops, and the orchid & biodiversity park, enhancing the cultural richness of the experience. The evenings are equally captivating, with performances of traditional Assamese dances and folk songs, allowing you to immerse yourself in the local culture. So, don't miss out on this unforgettable adventure! Book your <strong>Kaziranga Package Tour from Guwahati with Elephant Safari & Jeep Safari</strong> today with Adorable Vacation, the best [DMC] Destination Management Company for North East India.<br /><br /></p>
                      <br /><br />
                      <table className="image-table">
                        <tbody>
                          <tr>
                            <td className="image-cell">
                              <img src={sightseen1ImageURL} alt="Sightseeing 7" />
                              <img src={sightseen2ImageURL} alt="Sightseeing 9" />
                              <img src={sightseen3ImageURL} alt="Sightseeing 8" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br /><br />
                      <table className='img-center'>
                        <tbody>
                          <tr>
                            <td className="image-cell">
                              <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best kaziranga package Tour with elephant safari" /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DURATION FOR <strong>KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong> : 7N | 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DURATION FOR <strong>KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong> : 7N | 8D</h4>
                </div>
                <div className="card-body">
                  <h4>DESTINATIONS COVERED IN <strong>KAZIRANGA SAFARI PACKAGE WITH NAMERI, MANAS & POBITORA</strong> : KAZIRANGA 2N | NAMERI 2N | MANAS 2N | POBITORA 1N</h4><br />
                  <h4>PICKUP & DROP POINT FOR <strong>KAZIRANGA SAFARI PACKAGE</strong> : LOKPRIYA GOPINATH BORDOLOI INTERNATIONAL AIRPORT [GAU] | GUWAHATI STATION</h4><br />
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr class="table-danger"><th>CITY</th><th>ALTITUDE</th><th>CO-ORDINATES</th></tr></thead>
                      <tbody>
                        <tr><td>Kaziranga</td><td>60 m</td><td>26.6445° N, 93.3525° E</td></tr>
                        <tr><td>Nameri</td><td>80 m</td><td>26.9324° N, 92.8772° E</td></tr>
                        <tr><td>Manas</td><td>60 m</td><td>26.8102° N, 91.2395° E</td></tr>
                        <tr><td>Pobitora</td><td>40 m</td><td>26.2308° N, 92.0551° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>Attribute</th>
                          <th>Kaziranga National Park</th>
                          <th>Manas National Park</th>
                          <th>Nameri National Park</th>
                          <th>Pobitora Wildlife Sanctuary</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Location</td>
                          <td>Kanchanjuri, Assam 784177 [Golaghat and Nagaon District]</td>
                          <td>Manas Road, Barangabari Gyati Village, Dist Baksa, Gobardhana, Assam 781315</td>
                          <td>Sonitpur, Guwahati, Assam 784001</td>
                          <td>Morigaon, Assam 782105</td>
                        </tr>
                        <tr>
                          <td>Area</td>
                          <td>430 km² (170 sq mi)</td>
                          <td>950 sq km</td>
                          <td>200 sq km</td>
                          <td>38.85 sq km</td>
                        </tr>
                        <tr>
                          <td>Nearest City</td>
                          <td>Golaghat, Nagaon, Furkating</td>
                          <td>Nalbari, Barpeta</td>
                          <td>Tezpur</td>
                          <td>Morigaon</td>
                        </tr>
                        <tr>
                          <td>Established</td>
                          <td>1904, 117 years ago</td>
                          <td>1990</td>
                          <td>15 November 1998</td>
                          <td>1987</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE SUMMERY FOR <strong>KAZIRANGA TOUR PACKAGES FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE SUMMERY FOR <strong>KAZIRANGA TOUR PACKAGES FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              GUWAHATI TO KAZIRANGA TRANSFER
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>KAZIRANGA JUNGLE SAFARI [ELEPHANT + JEEP SAFARI]</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>TRANSFER FROM KAZIRANGA TO NAMERI</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>DAY SIGHTSEEING / ACTIVITIES AT NAMERI</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>NAMERI TO MANAS NATIONAL PARK</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>DAY SIGHTSEEING / ACTIVITIES AT MANAS</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>MANAS NATIONAL PARK TO POBITORA</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingEight"
                          >
                            <b>DAY 8 → </b>
                            <span>POBITORA TO GUWAHATI AIRPORT / RAILWAY STN</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    {/* <table className='img-center'>
                                            <tbody>
                                                <tr>
                                                    <td className="image-cell">
                                                        <img src={Review2ImageURL} alt="Travel with the Best DMC Bhutan" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                    <br /><br />
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS : <strong>KAZIRANGA SAFARI PACKAGE WITH MANAS, NAMERI & POBITORA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS : <strong>KAZIRANGA SAFARI PACKAGE WITH MANAS, NAMERI & POBITORA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              All transfers in Private Exclusive Vehicle
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Accommodation in Best Properties
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Driver's Allowance, Fuel, Toll, Parking
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              1 Elephant Safari + 2 Jeep Safari + Entry Fees
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              All Permits & Applicable Taxes
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /> <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>EXPLORE THE BEST OF ASSAM : <strong>KAZIRANGA SAFARI PACKAGES WITH NAMERI, MANAS, AND POBITORA NATIONAL PARKS</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>EXPLORE THE BEST OF ASSAM : <strong>KAZIRANGA SAFARI PACKAGES WITH NAMERI, MANAS, AND POBITORA NATIONAL PARKS</strong></h4>
                </div>
                <div className="card-body">
                  <p>If you're an adventure seeker or a wildlife enthusiast, a <strong>Kaziranga Safari Package</strong> is an experience that should be on your bucket list. Nestled in the heart of Assam, Kaziranga National Park is not just famous for its rich wildlife, but it also offers a remarkable experience that includes safaris across varied landscapes, all in close proximity to other natural gems like Nameri National Park, Manas National Park, and Pobitora Wildlife Sanctuary. These parks are a haven for wildlife lovers and bird watchers, offering an unparalleled experience to see some of the rarest and most majestic animals in the wild.<br /><br />

                    Kaziranga National Park, a UNESCO World Heritage site, is world-renowned for its large population of the One-Horned Rhinoceros—around two-thirds of the world's remaining population resides here. Along with the Asiatic Water Buffalo, Eastern Swamp Deer, and Indian Elephants, Kaziranga is also home to the Bengal Tiger, Indian Gaur, Leopard, Sambar, and Wild Boar. The park is a unique haven for nature lovers, offering both <strong>Kaziranga Elephant Safari Packages</strong> and <strong>Kaziranga Jeep Safari Packages</strong> that provide the ultimate wildlife exploration. Apart from Kaziranga, Nameri National Park and Manas National Park are other exceptional wildlife destinations in Assam. Nameri is home to a wealth of biodiversity, including Hog Deer, wild elephants, and tigers, while Manas National Park is a paradise for birdwatchers, with sightings of the Great Indian Hornbill, Wreathed Hornbill, and the rare Lesser White-fronted Goose. Similarly, Pobitora Wildlife Sanctuary, known for its population of rhinos, is an off-the-beaten-path sanctuary perfect for intimate wildlife experiences.<br /><br />

                    Each of these parks offers an incredible diversity of wildlife that makes them stand out among the best national parks in India. Kaziranga is home to the famous One-Horned Rhinoceros, while Nameri offers sightings of the Asian Openbill Stork and the Black-necked Stork. Manas National Park is an important habitat for the Bengal Tiger and Leopard, while Pobitora is known for having one of the highest densities of One-Horned Rhinos in the world. The birdlife across these parks is truly spectacular. Kaziranga alone is home to over 480 species of birds, including the Ferruginous Duck, Baer's Pochard Duck, and Lesser Adjutant Stork. These parks are a paradise for birdwatchers, attracting those looking to witness some of the rarest bird species in India, like the Greater Adjutant and the Lesser Adjutant.<br /><br />

                    Kaziranga National Park is divided into several ranges, each offering a unique safari experience. The Central Range (Kohora Range) is the most popular range, famous for its rich wildlife and scenic beauty. This range provides an excellent opportunity to see the One-Horned Rhino and Indian Elephants up close. The Western Range (Bagori Range) is known for its dense forests and varied terrain, offering a more secluded and serene safari experience. The Eastern Range (Agartoli Range) is the least crowded range, perfect for those seeking a quiet, immersive experience amidst nature. It is an excellent location for birdwatching and wildlife photography. The Burhapahaar Range (Ghorakati) is famous for its unique landscape and sightings of Indian Gaur and Tigers, making it ideal for adventure seekers and nature lovers. <br /><br />

                    To experience the best of Kaziranga, Nameri, Manas, and Pobitora, consider booking a <strong>Kaziranga Safari Package</strong> that includes both Elephant and Jeep Safaris. These safaris offer a once-in-a-lifetime opportunity to get up close to some of the most majestic wildlife in the world, including Bengal Tigers, Indian Rhinos, Elephants, and a host of exotic bird species. For those looking for a <strong>Kaziranga Package Tour from Guwahati Airport</strong>, you can enjoy a hassle-free transfer to the park and start your safari adventure right away. Whether you opt for a <strong>Kaziranga Package Tour with Elephant Safari from Guwahati</strong> or a <strong>Kaziranga Jeep Safari Package Tour</strong>, each offers a unique way to explore the park's diverse landscapes and wildlife. Experience the ultimate wildlife adventure with <strong>Kaziranga Package Tours</strong>, and book your trip today to explore one of India's most pristine national parks. Let Adorable Vacation help you plan the best <strong>Kaziranga Safari Package</strong> for your next adventure, offering you an unforgettable journey through Assam's unparalleled natural beauty and wildlife. <br /><br /></p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br /><br />
                  <h4 className="tomato1">ANIMALS THAT CAN BE SEEN DURING <strong>KAZIRANGA TOUR FROM GUWAHATI</strong></h4>
                  <p>During your <strong>Kaziranga Manas Nameri Tour</strong>, you can witness wild animals in the open air like - One Horned Rhinoceros, Asiatic water buffalo, Eastern swamp deer, Indian elephants, gaur, sambar, wild boar, hog deer, Bengal tigers, leopards, jungle cat, fishing cat, leopard cat, Indian gray mongoose, small Indian mongooses, large Indian civet, small Indian civets, Bengal fox, golden jackal, sloth bear, Chinese pangolin, Indian pangolins, hog badger, Chinese ferret badgers, flying squirrel etc. <br /><br /><br /></p>

                  <h4 className="tomato1">BIRDS CAN BE SEEN IN <strong>KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></h4>

                  <p>Kaziranga is like a heaven for birders. Different species of birds that can be seen here are Great Indian hornbill, wreathed hornbill, lesser white-fronted goose, ferruginous duck, Baer's pochard duck, lesser adjutant, greater adjutant, black-necked stork, Asian openbill stork, Blyth's kingfisher, white-bellied heron, Dalmatian pelican, spot-billed pelican, Nordmann's greenshank, black-bellied tern, marsh babblers, common baya weaver, threatened Finn's weavers, Hodgson's bushchat, bristled grassbird, black-breasted parrotbill, rufous-vented grass babbler etc. <br /><br /><br /></p>
                  <h4 className="tomato1">ANIMALS THAT CAN BE FOUND AT KAZIRANGA NATIONAL PARK</h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr className="table-danger"><th>Name</th><th>No. (Approx)</th></tr></thead>
                      <tbody>
                        <tr><td>Greater One-Horned Rhinoceros</td><td>2413</td></tr>
                        <tr><td>Indian Elephant</td><td>1940</td></tr>
                        <tr><td>Tiger</td><td>118</td></tr>
                        <tr><td>Gaur</td><td>1300</td></tr>
                        <tr><td>Wild Asian water Buffalo</td><td>1666</td></tr>
                        <tr><td>Sambar</td><td>58</td></tr>
                        <tr><td>Eastern Swamp Deer</td><td>468</td></tr>
                        <tr><td>Indian Muntjac / Barking Deer</td><td>120</td></tr>
                        <tr><td>Wild Boar</td><td>431</td></tr>
                        <tr><td>Hog Deer</td><td>5045</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /> <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHAT IS THE BEST TIME TO BOOK KAZIRANGA PACKAGE TOUR?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHAT IS THE BEST TIME TO BOOK KAZIRANGA PACKAGE TOUR?</h4>
                </div>
                <div className="card-body">
                  <p>The best time to book your <strong>Kaziranga package tour</strong> is during the winter holidays, which last from the end of November to the first week of April. Winter in the Northeast, spanning from early November to late March, is long, offering excellent opportunities for a winter vacation. During this period, Kaziranga experiences pleasant, travel-friendly weather. The temperature typically ranges from a high of 24°C to a low of 6°C. Visiting Kaziranga in winter allows you to explore its vast landscapes under a beautiful mist, making it an ideal time for an adventure. One of the best ways to experience the park is on an elephant safari. Unlike jeep safaris, elephant safaris can take you to areas that are otherwise inaccessible, allowing for a more intimate wildlife experience. The serene, sprawling plains of Kaziranga look even more enchanting during the winter months. <br /><br />

                    If you prefer a jeep safari, winter is also a fantastic time for this. While jeep safaris can sometimes get a bit dusty, they offer an exciting way to explore Kaziranga's different ranges and get close-up views of its diverse wildlife. <strong>Kaziranga Jeep Safari Package Tour</strong> during winter are a great way to cover the park's vast areas and enjoy its natural beauty. <br /><br />

                    Though summer in Kaziranga can be hot and humid, it still offers a chance to see the park's wildlife, especially in the cooler mornings and evenings. However, the monsoon season, which lasts from June to September, is not the ideal time for a visit. The park is heavily affected by the floods from the Brahmaputra River during this period, and both human and animal activities come to a halt. For a more enjoyable and safe visit, it's best to avoid booking any <strong>Kaziranga package tour</strong> during the monsoon season, from early June to late September. <br /><br /></p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen11ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DIFFERENT RANGES TO KNOW FOR <strong>KAZIRANGA NATIONAL PARK SAFARI PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DIFFERENT RANGES TO KNOW FOR <strong>KAZIRANGA NATIONAL PARK SAFARI PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <h4 className="tomato1">CENTRAL RANGE [KOHORA RANGE]</h4><br /><br />
                  <p>The <b>Central Zone</b> of Kaziranga National Park is the primary area for ecotourism within the park. The entry gate to this zone is located about two kilometers from the National Highway, passing through the village of Kohora. <br /><br />

                    In the Central Zone, you can enjoy both <strong>Kaziranga Elephant Safari</strong> and <strong>Kaziranga Jeep Safari</strong>, though this zone is particularly ideal for a Jeep Safari. The Jeep safari in this area is organized in two shifts, allowing visitors to explore the park's unique landscapes and wildlife. During both the Jeep Safari and Elephant Safari, visitors are not allowed to disembark from their vehicles in the middle of the safari. The Jeep safari offers some of the most spectacular views, including sightings of the One-Horned Rhinos grazing or wallowing in the expansive grasslands, herds of elephants—ranging from adorable baby elephants to majestic, older ones-and various other species of flora and fauna found in this rich tourism zone. It's an unforgettable experience that captures the essence of Kaziranga's wildlife.<br /><br /></p>
                  <h4 className="tomato1">WESTERN RANGE [BAGORI RANGE]</h4><br /><br />
                  <p>The Bagori Ecotourism Range of Kaziranga National Park is located in the western part of the forest and is considered one of the best zones for wildlife sightings. This range offers both <b>Elephant Safari</b> and <b>Jeep Safari</b>, but it is particularly renowned for its Elephant Safari due to the better vantage points it provides for spotting One-Horned Rhinos and other wildlife. The landscape of the western range is magnificently beautiful, with lush greenery and an abundance of wildlife species. The entry gate to the Western Zone is located in the village of Bagori. This range is known for its tall, dense trees, which elephants can easily navigate through, taking you closer to the One-Horned Rhinos and other wildlife in their natural habitat.<br /><br />

                    During the <strong>Elephant Safari in Kaziranga</strong>, only four tourists and a mahout are allowed at a time. The safari charges include the riding fees and the entrance fee. If you wish to take photos, a camera fee is optional and must be paid if you are carrying one. To begin the safari, visitors are picked up by jeep from a designated point and transported to the starting location of the elephant ride, which concludes by returning in the same manner. This intimate experience allows you to get incredibly close to the wildlife in the serene, dense surroundings of the Bagori range. <br /><br /><br /></p>
                  <h4 className="tomato1">EASTERN RANGE [AGARTOLI RANGE]</h4><br /><br />
                  <p>The <b>Agartoli Range of Kaziranga National Park</b> is located on the eastern side of the park, along the National Highway near the village of Agartoli. This zone is particularly popular among adventure-loving wildlife tourists and photographers due to its raw natural beauty and rich diversity of wildlife species. <br /><br />

                    Unlike other zones, the Agartoli Range does not offer an Elephant Safari, so visitors must rely on the Jeep Safari to explore this part of Kaziranga. The Jeep safari allows tourists to experience the untamed beauty of the eastern zone and get up close to the park's incredible wildlife in its natural habitat. <br /><br /><br /></p>
                  <h4 className="tomato1">BURHAPAHAAR RANGE OR GHORAKATI</h4><br /><br />
                  <p>The Burhapahaar tourism zone of Kaziranga is located around 30 km from the central zone of Kaziranga National Park. The entry gate of this zone is situated in the village of Ghorakati on the National Highway and the first zone on the way to Kaziranga from Guwahati by road. <br /><br />

                    In this zone you can avail the <b>Kaziranga Jeep Safari</b> to explore the park as well as trekking is also allowed in this zone. The Jeep safari starting point is the Rhinoland Park in the Ghorakati from where you can catch your Jeep Safari for this zone. <br /><br />

                    The hilly landscape with the dense greenery makes this zone the best for the bird lovers. The trekking in this zone has its own charm and is a very enriching experience to explore the forest at so close.<br /><br /><br /></p>

                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>KAZIRANGA JEEP SAFARI PRICE - [KOHORA / BAGORI / AGARATOLI]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>KAZIRANGA JEEP SAFARI PRICE - [KOHORA / BAGORI / AGARATOLI]</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>Range</th>
                          <th>Vehicle</th>
                          <th>Entry Fee/Indian</th>
                          <th>Entry Fee/Foreigner</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2700 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                        <tr>
                          <td>Western Range (Bagori)</td>
                          <td>INR 2800 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                        <tr>
                          <td>Eastern Range (Agaratoli)</td>
                          <td>INR 3200 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen5ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen7ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen15ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>KAZIRANGA ELEPHANT SAFARI PRICE - [KOHORA RANGE / BAGORI RANGE]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>KAZIRANGA ELEPHANT SAFARI PRICE - [KOHORA RANGE / BAGORI RANGE]</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>Citizen</th>
                          <th>Ranges</th>
                          <th>Rate/hour</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Indian</td>
                          <td>Western Range (Bagori)</td>
                          <td>INR 1000</td>
                        </tr>
                        <tr>
                          <td>Foreigner</td>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2100</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen3ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 
                  <br /><br />
                  <p><b>NOTE</b> : <strong>Elephant Safari at the Kohora Range (Central) in Kaziranga National Park</strong> is reserved only for Foreign nationals and VIPs from India while the Bagori Safari Range (Western) offers Elephant Rides for Indian Nationals.</p>
                </div>
              </div>
              <br /><br /><br />
              {/* <!--By Itinerary--> */}
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h3>
                      DAY WISE ITINERARY FOR <span><strong className="tomato">KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></span>
                    </h3>
                    <h4>8 NIGHTS / 9 DAYS <strong className="tomato">Kaziranga Package Tour </strong> from Adorable Vacation</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h4> DAY 01: GUWAHATI TO KAZIRANGA NATIONAL PARK </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Meet and Greet at Guwahati Airport [GAU] / Guwahati Railway Station [GHY] and start your journey for <strong>Kaziranga Package Tour</strong>. Kaziranga located 193 KM from Guwahati, covering an area of 430 sq-kms, is inhabited by the world's largest population of one-horned rhinoceroses, as well as many mammals, including tigers, elephants, panthers and bears, and thousands of birds. In 1985, it was declared a World Heritage Site by <abbr title="The United Nations Educational, Scientific and Cultural Organization">UNESCO</abbr> for its unique natural environment. Go to bed early for next day early morning <strong>Kaziranga Jungle Safari</strong>. Overnight Stay at Hotel in Kaziranga.</p>
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h4>DAY 02: <strong>KAZIRANGA SAFARI</strong> (INCLUDED)</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>After an early wake up, you will be taken to the riding point for <strong>Kaziranga Elephant Safari</strong> in the Western Range. Watch the famous One-Horn Rhino within the jungle from stone throwing distance. After the elephant safari, return back to the Resort. After lunch, drive to Kaziranga Central Range for the <strong>Kaziranga Jeep Safari</strong>. The Central zone of the Kaziranga National Park is the prime zone for the ecotourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The <strong>Kaziranga Jungle Safari</strong> offers you some spectacular sighting of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with having the adorable baby elephants to big old elephants in the herd, and many other species of the wild flora and fauna of this tourism zone. Overnight Stay at Kaziranga National Park.<br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 03: TRANSFER FROM KAZIRANGA TO NAMERI</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>After breakfast check out and proceed towards <strong>Nameri National Park Tour</strong>, Assam famous for Tiger Reserve. Nameri National Park is located in the foothills of Himalyas in the Sonitpur District of Assam. Nameri is one of famous National Park of India for bird lovers and Wild Life Photographer. Overnight Stay at Nameri National Park.<br /><br /></p>
                        <br />
                        {/* <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>
                        <br /><br /> */}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 04: DAY SIGHTSEEING / ACTIVITIES AT NAMERI </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>Nameri National Park covers an area of 200 sqkms and is located in the Sonitpur dist, bodering Arunachal Pradesh. It is also the core area of <strong>Nameri Tiger Reserve</strong>. It is beautiful deciduous forests and the adjacent river Jia Bhorali, fringe the border of Assam and Arunachal Pradesh. This is therefore a popular stop for fishing / river raffting. Animals seen here Leopard, Clouded Leopard, Elephant, Gaur, Wild Pigs, Sambar, Capped Languretcs. Overnight Stay at Nameri.<br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 05: NAMERI TO MANAS NATIONAL PARK </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>After early breakfast, start for Manas Sanctuary or <strong>Manas National Park Tour</strong>. It is located two district of Bongaigaon and Barpeta of Assam. This park is famous for its rare and endangered wildlife like hispid hare, Assam roofed turtle,  golden langur, pygmy hog and wild water buffalo also. In 1985, it was also declared as UNESCO Natural World Heritage Site. Overnight Stay at Manas Park.<br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>
                        <br /> <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 06: DAY SIGHTSEEING / ACTIVITIES AT MANAS</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>Wake up early and enjoy a wonderful <strong>Elephant Safari in Manas National Park</strong>. The elephant will walk through sometimes through dense forest and sometime through tall grass land. You can see some of the rare species of birds and animals through Jungle Safari and if you are lucky, you can see the tiger also. Later in the afternoon, go for <strong>Manas National Park Jeep Safari</strong> (included). Overnight Stay at Manas.</p>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 07: TRANSFER FROM MANAS NATIONAL PARK TO POBITORA</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Pobitora Wildlife Sanctuary is located in the Morigaon district of Assam, in the southern bank of River Brahmaputra. The sanctuary is covering around 40 Sq KM and mainly with grassland and wetland and home to many Indian One horn rhino. You can also do Jeep safari in Pobitora National Park, if time permits. Overnight Stay at Pobitora.<br /><br /></p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay7ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 08: TRANSFER FROM POBITORA TO GUWAHATI AIRPORT / RAILWAY STN</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Start from Pobitora to Guwahati for your return journey and <strong>Kaziranga Trip</strong> ends with sweet memory.<br /><br /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />

              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {<InclusionExclusion />}
                    <br /><br />
                    <br />
                    <table className='img-center'>
                      <tbody>
                        <tr>
                          <td className="image-cell">
                            <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* inclusion End  */}
              <br />  <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong><strong>KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong> WITH MANAS & NAMERI</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <h4>What is the famous local cuisine to taste during Assam Kaziranga Tour ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><b>"Assamese khar"</b> is one of the top dishes of Assam. It is prepared from raw papayas, chillies banana peel as well as the trunk of the banana plants. There are few other types also to prepare khar with fish. It is an indigenous product, an alkaline-based delicacy of Assam. The Assamese people cannot do without it and hence the epithet '<b>KHAR KHUWA ASOMIYA</b>'. The other famous food of Assam to experience during <strong>Assam Kaziranga Package Tour</strong> includes Masor Tenga, Kumurat diya Duck Manxho, Xaak aru bhaji, Paro Manxho, Baanhgajor Lagot Kukura etc are widely consumed and one can easily be ordered in the restaurants with the food descriptions against the food items on the menu also Authentic Restaurants of the states.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <h4>Which is the most famous festival in Assam ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>It is one of the famous and renowned all over the festival in Assam which is held every year 14th April. It is celebrated to welcome the Assamese New year called Bohag Bihu. In this festival the small villages also enjoy with sound, light, and music and nice local pithas or local beer and meats in wide served and entire Assam and other neighbouring states like Meghalaya and Arunachal Pradesh celebrates in the big grounds or parks or in the banquet. Girls / Women’s and Boys / Men all takes participate in this festival and perform their traditional Bihu dance. It is one of the rejoicing festivals of Assam, North East. Although the festival is observed for 7 days, the celebration extends for about a month. Stages are set in different parts of the state, where Bihu dance is performed regularly for almost a month. If you want to be a part of this festival, contact Adorable Vacation. We will design your tour itinerary with <strong>Kaziranga Package Tour</strong> in such a way that you will also enjoy this awesome festival during your <strong>Assam Tour</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <h4>What is the best time to book Kaziranga Package Tour ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to visit Kaziranga, rather booking a <strong>Kaziranga Package Tour from Guwahati</strong> is anywhere from November to April. Since the whole of Assam receives a major rainfall in the monsoon and the pre-monsoon time of the year, the Kaziranga National Park floods in the months of June, July and August. No wonder the rhinos love bathing and staying in water during these months. The water starts receding in September and October and the place is open to tourism from November again. <br /><br />
                            Tickets are available for sale one day in advance at the central booking office (near Kohora range) between 7 pm and 8pm. It is best to get the tickets through the agent because they can get you confirmed seat. <br /><br />
                            So, according to the tourists, the best time to book <strong>Kaziranga Tour Package</strong> is November, February and April who want to avoid the bitter cold weather. But, who travelers want to enjoy the chillness can go with <strong>Kaziranga Tour</strong> in December and January as well. Basically seasonal wise, November to April is the best time to visit, rest of the time the forest remain closed. During the period of November to April, the forest remains purely dry and easy accessible to walk around without any wetness though the wetness on the small grasses will give you a freshness on your above feet and make you feel comfortable to walk around the forest. It will be a good time for trekking, good season for the bird lovers. A wake up natural alarm by the birds in the morning will make you fresh and alive.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <h4>How to reach for Kaziranga Jungle Safari ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><b>By Air</b> : To visit Kaziranga by Air, there are two nearest airports which are Guwahati International Airport and Jorhat Airport, located at a distance of 217 km and 97 km respectively from the park. The Lokpriya Gopinath Bordoloi International Airport, located in Guwahati, is the best option to arrive by air from any corner of India or abroad for the <strong>Kaziranga Safari Tour</strong>. From the Airports, you can further use the roadways to reach the park. So the booking the <strong>Kaziranga Package Tour from Guwahati</strong> is the best option. <br /><br />
                            <b>By Train</b> : If travelers choose to reach Kaziranga, the nearest railway station will be Furkating which is located at a distance of 75 km from the park. The station can be reached from major cities like Guwahati, Kolkata, New Delhi, etc. However, travelers can also use the other nearest railway stations like Guwahati railway station and Jorhat railway station located at a distance of 240 km and 90 km respectively. Both these railway stations are the major railway stations of the Northeast railways. <br /><br />
                            <b>By Road</b> : The main gate of the Kaziranga Park is situated in a small Kohora town which lies on the National Highway NH 37 due to which Kohora is connected to all the major cities of Assam such a Guwahati, Tezpur, Jorhat, Nagaon, Golaghat, Dimapur, Tinsukia, Sivasagar, etc. by the state transport buses or the privately run buses and minibusses. So to reach the park, first, you can reach Guwahati & Jorhat and then use the roadways to reach the park conveniently. <br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          <h4>Can I visit Kaziranga during Puja vacations i.e. in September to October ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>In the month of September, the forest remains closed. The region experiences rainfall of 2,220 mm (87 inch) between June, and September, which leads to the flood. In 2019, Kaziranga was opened in October 1st despite of heavy rain fall. In 2020, you can expect the Park may open from 1st of October but due to the after affect of flood it is better to avoid the Park before 15th October. This year, Durga Puja is from 20th October, so there will be a fair chance to enjoy the <strong>Kaziranga Jeep Safari</strong>, <strong>Kaziranga Elephant Safari</strong> and <strong>Kaziranga Jungle Safari</strong> during the <b>Durga Puja / Diwali Holidays 2024</b>.<br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          <h4>Which is better to choose in Kaziranga - Jeep Safari or Elephant Safari ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>There are two ways to see these animals in this park, jeep safari is the simple and affordable, while elephant safari is much more exciting option. It is mostly depends on the choice of the tourists. They can explore both or one as per their interest.
                            <br /><br />
                            <strong>Elephant Safari in Kaziranga</strong> takes you through the Kohora, Mihimukh, Arimarah, Hole Path and Bagori or western zone. Western zone is highly recommended safari zone in the Kaziranga where there is strong probability of spotting tiger and most famous one-horned rhinos. <br /><br />
                            Elephant can traverse into the deep jungles to reach very close to the place where mainly Rhino resides, and Jeep Safari requires muddy forest roads to traverse inside the Jungles, so to watch the Rhinos more closely, Obviously <strong>Kaziranga Elephant Safari</strong> is a better choice. <br /><br />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          <h4> How can I book a guaranteed Elephant Safari in Kaziranga ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can book <em>Elephant Safari in Kaziranga</em> through online or you can ask your <strong>Kaziranga Travel Agent</strong> / <strong>Kaziranga Tour Operator</strong> like Adorable Vacation for guaranteed Elephant Safari. Elephant Safari is famous in the Western Range and first priority goes to Indian national cost Rs. 1450/- approx per head and for Foreign Nationals Central range is famous and cost Rs. 3200/- approx per head.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          <h4>How much does a trip cost only in Kaziranga ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can't visit only Kaziranga. For this <em>Kaziranga Trip</em> you need to spend minimum 3N/4D and with cost generally depends on the group size, mode of transportation/hotel category, etc. For a rough idea you need to spend Rs.3,200 to 3,500/- per day basis (Safari cost extra).</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          <h4>Can I see tiger in Kaziranga National Park ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes. Kaziranga is one of those rare national parks in India, where you can see the big five mammals - Indian Rhinos, Asiatic Elephants, Royal Bengal Tigers, Swamp Deer and Water Buffalo. There are approximately 86 tigers in the area but due to the tall elephant grass, it's hard to spot them.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          <h4>How to Book Jeep Safari in Kaziranga ?</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>One can enjoy the main attractions of Kaziranga by Jeep, which is usually accompanied by a guide or escort. <strong>Kaziranga Jeep Safari</strong> is a wonderful ride through which one can explore tigers, one horned rhinoceros, wild buffaloes, elephants, herd of deer, Pythons, and many other mammals & birds as well. <br /><br />
                            You can book safari permit by directly visiting the Kaziranga zone offices but unavailability of permit is a usual scenario, the best way to consult Adorable Vacation to book the safari for you in advance so that you can enjoy the safari conveniently without any hassles when you will reach there on your scheduled date.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          <h4>How to Book Elephant Safari in Kaziranga</h4>
                        </button>
                      </div>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><strong>Kaziranga Elephant Safari</strong> can be booked at the two centres at the two safari zones which are the Central zone, also known as Kohora zone, at Kohora and the Western zone at the Bagori. At both the centres, the permits for the elephant safari can be obtained depending on the availability. The elephant safari is organized only in the morning time in the three shifts for one hour from 05:00 am to 06:00 am, 06:00 am to 07:00 am and 07:00 am to 08:00 am. The third shift (07-08 am) is organized only if there are more tourists who cannot be accommodated in the earlier two shifts. So, the third shift is optional and depends on the park authority to organize or not. For the <strong>Elephant Safari in Kaziranga</strong> western zone, the permits can be obtained from the range-office at Bagori and for the elephant safari in the central zone, the safari permits can be obtained from the centenary convention center at Kohora. The western zone is considered as the best zone for the elephant safari and most of the tourists choose this zone for the maximum enjoyment of the elephant safari in the dense forest of Kaziranga.<br /><br />
                            You can book elephant safari permit by directly visiting the Kaziranga zone offices but but unavailability of permit is a usual scenario, the best way you can contact Adorable Vacation to book the safari for you in advance so that you can enjoy the safari conveniently without any hassles when you will reach there on your scheduled date.<br /><br /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Kaziranga Shillong Cherrapunjee Package Tour <span className="tomato"> Reviews</span>
                        </b>
                      </h3>
                      <p>
                        <i>"Your Happiness is our Priority"</i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-96747-38480">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div >
      </>
    );
  }
}
export default KazirangaPackageTourWithElephantSafari_7N_8D;
