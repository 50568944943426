import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import InclusionExclusion from './InclusionExclusion_kashmir_tulip';
// import IndividualIntervalsExample from "./carousel-slideKashmirTulipPackage"
// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_7N_8D_Tulip_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: "tab1",
        };
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-group-tour-package.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-group-tour-package-with-train-tickets.webp";
        const brandImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-group-tour-packages.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/amazing-kashmir-tulip-festival-package-tour.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-holiday-package-booking.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/book-kashmir-tulip-trip-from.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-package-trip.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-tour-and-travel-package-cost.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-tour-packages.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-travel-package-cost.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-tulip-festival-tour-packages.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-tulip-garden-tour.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/srinagar-tulip-garden-tour-packages.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/tulip-festival-kashmir-packages.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/tulip-festival-special-kashmir-tour.webp";
        const sightseen13ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/tulip-kashmir-tour-package.webp";
        const sightseen14ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/unforgettable-kashmir-tulip-experience.webp";
        const sightseen15ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/kashmir-tulip-festival--family-package-tour.webp";
        const PriceImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/bhutan-tour-package-cost-from-bangalore-with-return-flight-via-kolkata-airport.webp";
        // const Flight1ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/bhutan-package-from-guwahati-with-direct-flight.webp";
        // const Flight2ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
        // const Flight3ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/Tulip-Festival-Special-2024.webp";
        const CallButtonSoumyaImageURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/talk-to-kashmir-expert.webp";

        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_with_Tulip + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };



        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL =
                ApiURL.Kashmir_Tour_Package_with_Tulip + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img
                            src={packageTopBannerImageURL}
                            className="mobile-image2"
                            alt="..."
                        />
                    </div>
                </div>

                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Kashmir Tulip Festival Package Tour</strong> - BEST DEAL 2025</center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Kashmir Tulip Festival Package Tour</strong> - BEST DEAL 2025</center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <a href="tel:+91-91470-61467"><img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." /></a>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                5 Destinations:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Sonmarg
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Doodhpathri
                                                </a>{" "}
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <br/>
                                        <p className="p-2">
                                        <p>If you're seeking a memorable <strong>Kashmir Tour Package during the Tulip Festival</strong>, you've arrived at the perfect place. As in every year, Adorable Vacation - the Best DMC of Kashmir, presents the <strong>Tulip Festival Special Kashmir Tour from Kolkata</strong>. This all-inclusive package features AC 3-tier train tickets, 3-star hotel accommodations, all meals, sightseeing, a 1-night Dal Lake houseboat stay, a Shikara ride, and more. Book this <a href="/tulip-festival-special-kashmir-group-tour-packages-from-kolkata-by-train/" target="_blank"><strong>Kashmir Tulip Festival Group Tour Package</strong></a> and experience the Heaven on Earth – Jannat-E-Kashmir.</p>
                                        </p>
                                        <hr />
                                    </div>
                                    <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE TOUR 2025</strong>? <br /><br />
                                        FOR BOOKING <strong>TULIP FESTIVAL SPECIAL KASHMIR TOUR 2025</strong> <a href="tel:+91-9147061467"> ☎ CALL +91-91470-61467</a> <br /><br /></h2>
                                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE TOUR 2025</strong>? <br /><br />
                                        FOR BOOKING <strong>TULIP FESTIVAL SPECIAL KASHMIR TOUR 2025</strong> <a href="tel:+91-9147061467"> ☎ CALL +91-91470-61467</a> <br /><br /></h3>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> START YOUR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong> WITH ADORABLE VACATION</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> START YOUR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong> WITH ADORABLE VACATION</h4>
                                </div>
                                <div className="card-body">
                                    <p>Are you ready to experience the beauty of a spectacular flower show? Look no further than the Kashmir Tulip Garden, which hosts the annual <a href="/tulip-festival-special-kashmir-group-tour-packages-from-kolkata-by-train/" target='_blank'><strong>Tulip Festival Spl Kashmir Group Tour From Kolkata</strong></a>. This incredible event is filled with vibrant colors and is a once-in-a-lifetime experience you won’t want to miss. Our specially designed <strong>Kashmir Tulip Festival package tour</strong> lets you see this natural wonder in all its beauty. Let’s take a closer look at what makes this journey so special. <br /><br />

                                        The <a href="/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg/" target="_blank"><strong>Kashmir Package Tour From Kolkata</strong></a> takes place in the famous Kashmir Tulip Garden, located in the breathtaking Jammu and Kashmir region. This stunning garden is set at the base of the towering Zabarwan mountain range and is next to the renowned Botanical Garden of Kashmir. Covering a vast 74 acres, the garden is a peaceful and scenic paradise. It's also conveniently close to the beautiful Dal Lake, making it a must-see destination for nature lovers and tourists from all over the world. <br /><br />

                                        The <strong>Kashmir Tulip Festival 2025</strong> is a short but magical event that runs for just 20 days. During this time, over 1.5 million tulips will be in full bloom, creating a colorful and fragrant wonderland. It’s a visual feast you won’t forget, with every corner of the garden bursting with vibrant colors. <br /><br />

                                        The garden was originally called Siraj Bagh and was renamed the <b>Indira Gandhi Memorial Tulip Garden in 2007</b>. It was created by the floriculture department to help promote <strong>Kashmir tourism</strong>. Since then, it has become one of the most popular tourist attractions in the region. The garden features rows and rows of tulips in many different varieties, along with 70 other types of flowers, including daffodils and hyacinths. Many of the tulip bulbs are imported from Amsterdam, giving the garden an international touch. The garden also has seven terraces, each with pathways, fountains, and plenty of space to relax and enjoy the surroundings. <br /><br />

                                        If you want to experience the magic of Kashmir in April, Adorable Vacation offers a special <strong>Kashmir Tulip festival package tour 2025</strong>. This tour gives you a guided experience to make sure you don’t miss any of the highlights. This <strong>Kashmir Tulip Festival Package</strong> includes train tickets, all meals, all sightseeing, houseboat stay, entry tickets, a tour of the tulip garden. You’ll also get to visit other beautiful places in Kashmir, making your <strong>Kashmir trip</strong> even more unforgettable. <br /><br />

                                        The <strong>Kashmir Tulip festival package</strong> is your chance to witness the stunning beauty of the tulips and explore the rich culture of Kashmir. During the festival, you can also experience the local handicrafts, cuisine, and music, adding even more to your journey. <br /><br />

                                        Don’t miss out on this incredible experience! Book your <strong>Kashmir Tulip festival tour package</strong> today and get ready to immerse yourself in the vibrant colors and natural beauty of Jammu and Kashmir. It's an experience you will cherish forever! <br /><br /></p>

                                        <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={brandImageURL} alt="Sightseeing 1" />
                                                   
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Kashmir package tour" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong> : 7N | 8D</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong> : 7N | 8D</h4>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN 7N/8D <strong className='tomato'>KASHMIR TULIP GARDEN TOUR PACKAGES</strong> : SRINAGAR 4N [HOUSEBOAT 1N] | GULMARG 1N | PAHALGAM 2N </h4><br />
                                    <h4>PICKUP & DROP POINT FOR <strong className='tomato'>KASHMIR TULIP FESTIVAL TOUR</strong> : SHEIKH UL ALAM INTERNATIONAL AIRPORT, SRINAGAR [SXR] </h4>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td><b>Kashmir Group Tour Date</b></td>
                                                    <td>3rd April 2025</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Kashmir Tulip Festival Group Tour </strong>Duration</td>
                                                    <td>12 Nights | 13 Days</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Kashmir Tulip Festival Group Tour Cost </strong></td>
                                                    <td>Rs. 25,900/P</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Pick & Drop </strong></td>
                                                    <td>Kolkata</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                    <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                    <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* start short itinerary */}

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DAY WISE SUMMARY FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DAY WISE SUMMARY FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <b>DAY 1 → </b>
                                                        <span>
                                                            ARRIVE IN SRINAGAR
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <b>DAY 2 → </b>
                                                        <span>TAKE A FULL-DAY EXCURSION FROM SRINAGAR TO GULMARG</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <b>DAY 3 → </b>
                                                        <span>TRAVEL FROM GULMARG TO PAHALGAM</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <b>DAY 4 → </b>
                                                        <span>EXPLORE PAHALGAM WITH VISITS TO BETAB VALLEY AND MORE</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <b>DAY 5 → </b>
                                                        <span>RETURN TO SRINAGAR FOR SIGHTSEEING AND AN OVERNIGHT STAY</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <b>DAY 6 → </b>
                                                        <span>ENJOY A DAY TRIP TO DOODHPATHRI AND SPEND THE NIGHT IN SRINAGAR</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <b>DAY 7 → </b>
                                                        <span>TAKE A DAY TRIP TO SONMARG </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <b>DAY 8 → </b>
                                                        <span>DEPART FROM SRINAGAR AIRPORT</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end Short description--> */}
                            <br /><br /><br />

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>INCLUSIONS : <strong>KASHMIR TULIP FESTIVAL PACKAGE TOUR</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>INCLUSIONS : <strong>KASHMIR TULIP FESTIVAL PACKAGE TOUR</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Accommodation for 7 Nights
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Meal plan MAP (Breakfast and Dinner)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            All transfer and sightseeing by Non-AC Cars
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Shikara Ride on Dal Lake for 1 Hour
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Vehicle will be provided on point to point basis and not at disposal
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Inner line permits to visit restricted / protected areas
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Above rates include all hotel taxes, toll taxes and vat
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Aru, Betaab valley and Chandanwari trip at Pahalgam
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES TO NOTE FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong> FROM ADORABLE VACATION</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES TO NOTE FOR <strong>KASHMIR TULIP FESTIVAL PACKAGE</strong> FROM ADORABLE VACATION</h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><th>FROM</th><th>TO</th><th>DISTANCE</th></tr></thead>
                                            <tbody>
                                                <tr><td>Ahmedabad</td><td>Srinagar</td><td>30 hr (1,671.9 km) via NH 48</td></tr>
                                                <tr><td>Surat</td><td>Srinagar</td><td>36 hr (1,926.2 km) via NH 48</td></tr>
                                                <tr><td>Chennai</td><td>Srinagar</td><td>54 hr (3,017.4 km) via NH 44</td></tr>
                                                <tr><td>Pune</td><td>Srinagar</td><td>41 hr (2,245.6 km) via NH 44</td></tr>
                                                <tr><td>Bangalore</td><td>Srinagar</td><td>53 hr (2,985.2 km) via NH 44</td></tr>
                                                <tr><td>Kolkata</td><td>Srinagar</td><td>46 hr (2,343.4 km) via NH 44</td></tr>
                                                <tr><td>Delhi</td><td>Srinagar</td><td>18 hr 22 min (812.5 km) via NH 44</td></tr>
                                                <tr><td>Mumbai</td><td>Srinagar</td><td>42 hr (2,169.8 km) via NH 48</td></tr>
                                                <tr><td>Jammu</td><td>Srinagar</td><td>8 hr 14 min (266.5 km) via NH 44</td></tr>
                                                <tr><td>Srinagar</td><td>Gulmarg</td><td>1 hr 30 min (49.5 km) via Srinagar - Tangmarg Rd</td></tr>
                                                <tr><td>Srinagar</td><td>Sonmarg</td><td>2 hr 11 min (79.4 km) via NH1</td></tr>
                                                <tr><td>Srinagar</td><td>Pahalgam</td><td>2 hr 11 min (89.6 km) via Srinagar - Kanyakumari Hwy</td></tr>
                                                <tr><td>Srinagar</td><td>Dras</td><td>4 hr 2 min (141.2 km) via NH1</td></tr>
                                                <tr><td>Srinagar</td><td>Kargil</td><td>5 hr 19 min (202.1 km) via NH1</td></tr>
                                                <tr><td>Srinagar</td><td>Leh</td><td>9 hr 41 min (418.5 km) via NH1</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORE KASHMIR’S LOCAL MARKETS DURING <strong>KASHMIR TULIP FESTIVAL TOUR PACKAGES</strong> WITH ADORABLE VACATION</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORE KASHMIR’S LOCAL MARKETS DURING <strong>KASHMIR TULIP FESTIVAL TOUR PACKAGES</strong> WITH ADORABLE VACATION</h4>
                                </div>
                                <div className="card-body">

                                    <p>If you’re booking a <strong>Kashmir Tulip Festival package tour for 2025</strong>, you’re in for an unforgettable experience. While the <strong>Kashmir Tulip Garden Festival 2025</strong> offers a breathtaking display of tulips, don’t miss the opportunity to explore the vibrant local markets of Srinagar. These markets are the perfect way to immerse yourself in the culture, crafts, and cuisine of Kashmir. Here’s a guide to the best local markets to visit during your <strong>Srinagar Tulip Garden tour</strong>. <br /><br /></p>

                                    <h4 className='dark-blue'>Lal Chowk Markets : The Heart of Srinagar’s Culture</h4>
                                    <p>Start your <strong>Kashmir Tulip Festival tour package</strong> with a visit to Lal Chowk, Srinagar’s bustling city center. This vibrant market is known for its wide variety of Kashmiri handicrafts, from Pashmina shawls to traditional pherans and papier-mâché items. It’s the perfect place to take home authentic Kashmir souvenirs, and don’t forget to haggle for the best prices! <br /><br /></p>

                                    <h4 className='dark-blue'>Polo View Market : A Peaceful Shopping Experience</h4>
                                    <p>For a more relaxed experience, head to Polo View Market, where you can shop for beautiful Kashmiri carpets, traditional jewelry, and aromatic spices. This charming market offers a perfect mix of shopping and sightseeing, as you can enjoy the lovely views of Dal Lake while browsing the stalls. <br /><br /></p>

                                    <h4 className='dark-blue'>Raghunath Bazaar : Authentic Kashmiri Goods</h4>
                                    <p>For a deeper dive into Kashmiri culture, Raghunath Bazaar is the place to be. This market is filled with local spices, dried fruits, handwoven rugs, and intricate wooden artifacts. It’s an excellent spot to pick up authentic Kashmir-made products that reflect the region’s rich craftsmanship. <br /><br /></p>

                                    <h4 className='dark-blue'>Residency Road Market : Savor the Local Cuisine</h4>
                                    <p>A visit to Residency Road Market is a must for food lovers. During your <strong>Kashmir Tulip Festival tour</strong>, make sure to stop by food stalls offering Kashmiri specialties like Rogan Josh, Yakhni, and the famous Wazwan feast. Enjoy these delicious dishes while soaking in the lively ambiance of this popular market. <br /><br /></p>

                                    <h4 className='dark-blue'>Floating Market at Dal Lake : A Unique Shopping Experience</h4>
                                    <p>Add a magical twist to your <strong>Kashmir Tulip Festival tour packages</strong> with a visit to the Floating Market on Dal Lake. A shikara ride will take you to vendors who sell everything from flowers and fruits to handcrafted items right from their boats. The serene lake setting makes this a one-of-a-kind shopping experience. <br /><br /></p>

                                    <p>Exploring Kashmir’s local markets is the perfect complement to the stunning <strong>Kashmir Tulip Garden Festival</strong>. The markets offer a wonderful mix of vibrant colors, local crafts, delicious food, and breathtaking views. By booking a <strong>Kashmir Tulip Festival 2025 package tour</strong>, you’ll be able to seamlessly enjoy both the Tulip Festival and the charm of Kashmir’s bustling markets, making it a truly unforgettable experience. <br /><br />

                                        Book your <strong>Kashmir Tulip Festival package tour</strong> now and get ready to experience the best of Kashmir and its unique local markets! <br /><br /></p>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="kashmir package tour booking from srinagar" />
                                                    <img src={sightseen8ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                    <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TULIP FESTIVAL</strong> <b>2025: DATES, TIMINGS & TICKET INFORMATION</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TULIP FESTIVAL</strong> <b>2025: DATES, TIMINGS & TICKET INFORMATION</b></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>HIGHLIGHTS</th>
                                                    <th>TULIP FESTIVAL DETAILS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Festival Dates</td>
                                                    <td>March 19 - April 20, 2025</td>
                                                </tr>
                                                <tr>
                                                    <td>Festival Location</td>
                                                    <td>Indira Gandhi Memorial Tulip Garden, Srinagar, J&K</td>
                                                </tr>
                                                <tr>
                                                    <td>Festival Timings</td>
                                                    <td>10:00 AM - 7:30 PM Daily</td>
                                                </tr>
                                                <tr>
                                                    <td>Ticket Fees</td>
                                                    <td>INR 50 per adult, INR 25 per child</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>KASHMIR TULIP GARDEN TOUR PACKAGE COST</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <strong>KASHMIR TULIP GARDEN TOUR PACKAGE COST</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>TOUR DURATION</th>
                                                    <th>2 PAX</th>
                                                    <th>4 PAX</th>
                                                    <th>6 PAX</th>
                                                    <th>8 PAX</th>
                                                    <th>BOOK NOW</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>4N/5D</td>
                                                    <td>₹ 20,500/-</td>
                                                    <td>₹ 18,300/-</td>
                                                    <td>₹ 16,600/-</td>
                                                    <td>₹ 16,400/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5N/6D</td>
                                                    <td>₹ 24,700/-</td>
                                                    <td>₹ 22,000/-</td>
                                                    <td>₹ 19,800/-</td>
                                                    <td>₹ 21,800/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6N/7D</td>
                                                    <td>₹ 30,900/-</td>
                                                    <td>₹ 27,400/-</td>
                                                    <td>₹ 24,700/-</td>
                                                    <td>₹ 24,500/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7N/8D</td>
                                                    <td>₹ 35,600/-</td>
                                                    <td>₹ 30,800/-</td>
                                                    <td>₹ 28,300/-</td>
                                                    <td>₹ 28,100/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORE THE ENCHANTING KASHMIR TULIP GARDEN WITH <strong>KASHMIR TULIP FESTIVAL PACKAGE TOUR</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORE THE ENCHANTING KASHMIR TULIP GARDEN WITH <strong>KASHMIR TULIP FESTIVAL PACKAGE TOUR</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>If you're looking for a serene and unforgettable experience amidst nature's splendor, the <strong>Kashmir Tulip Festival Package Tour 2025</strong> offers the perfect opportunity to immerse yourself in the vibrant beauty of <strong>Kashmir Tulip Garden Festival</strong>. Just a short 9-kilometer journey from Srinagar, along the tranquil shores of Dal Lake, lies the breathtaking Indira Gandhi Tulip Garden, a hidden gem you won’t want to miss during the <strong>Tulip Festival Special Kashmir Tour 2025</strong>. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>DISCOVER THE BEAUTY OF KASHMIR TULIP GARDEN</h4>
                                    <p>Nestled on the gentle slopes of the majestic Zabarwan hills, the Tulip Garden is surrounded by lush greenery, with a meticulously manicured golf course to the north and the serene Dal Lake gracefully bordering its western entrance. The adjacent historic sites of Chashme Shahi and Pari Mahal add an air of ancient charm, making this destination a perfect blend of natural beauty and regal history. <br /><br />

                                        Stepping into the Kashmir Tulip Garden feels like entering paradise. The garden is home to over 1.5 million tulips, arranged in neat, colorful rows. These tulips come in a spectrum of vibrant colors, including red, orange, yellow, purple, and mauve, along with unique multi-colored hybrid varieties. The sight is nothing short of spectacular, and it’s a must-see for anyone exploring <strong>Kashmir Tulip Festival Tour Packages</strong>. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>A RIOT OF COLORS AND DIVERSE TULIP VARIETIES</h4>
                                    <p>As you walk through the Tulip Garden, you’ll be captivated by the kaleidoscope of colors and the breathtaking hybrid tulip varieties. These hybrid flowers are cultivated in individual flower beds, each measuring about 1 meter wide and 80 meters long. The sight of the tulips blooming in a perfect, multi-tiered arrangement is mesmerizing. The garden is carefully laid out in seven terraces, each offering a different perspective of the stunning floral displays.<br /><br />

                                        The variety of tulips is astounding, with flowers in unique shapes ranging from hemispherical turbans to elongated petals. Some tulips feature petals that are scattered and loose, while others have delicate upward-flowing petals and sepals, resembling the fiery blooms of a flame. This intricate display of nature’s artistry makes the Srinagar Tulip Garden Tour an unforgettable experience. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>THE TRANQUIL BEAUTY OF THE SURROUNDINGS</h4>
                                    <p>As you ascend through the terraces, you’ll come across fruit trees along the garden's edges, their branches adorned with colorful buds and blossoms. With the clear blue sky above and the spectacular flowers below, the entire landscape feels like a dream. From a higher vantage point, Pari Mahal overlooks the tulip gardens, offering a regal view of the blooming landscape. This historic site provides a picturesque backdrop, reminding you of the garden’s rich cultural significance.<br /><br />

                                        The sight of the tulips arranged in neat, alternating stripes of complementary and contrasting colors is a visual delight. The rows of vibrant flowers seem to extend all the way to the shores of Dal Lake, where beautiful floating fountains add to the tranquil atmosphere. The entire garden, with its view of the shimmering lake and surrounding hills, creates a divine spectacle that is hard to match anywhere in the world. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>BEYOND TULIPS : MORE FLORAL DELIGHTS</h4>
                                    <p>In addition to the tulips, the Indira Gandhi Tulip Garden is also home to a variety of other bulbous plants, including hyacinths, narcissus, daffodils, muscaria, and iris. These flowers bloom alongside the tulips, adding even more layers of color and fragrance to the garden. The careful cultivation of these diverse species contributes to the garden’s reputation as one of the most remarkable horticultural sites in the world. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>THE BEAUTY OF TULIPS : A CLOSER LOOK</h4>
                                    <p>The tulips themselves are a marvel of nature. Each flower typically grows about 1 to 2 feet tall and features a delicate blossom measuring 4-6 cm in diameter. The flowers have a distinct tapering apex and a gently curving base, with three petals and three sepals closely grouped together, giving them the appearance of a half-open bud. Some varieties, particularly those imported from the Netherlands, can produce up to four blossoms per plant, making the garden even more mesmerizing. <br /><br /><br /></p>
                                    <h4 className='dark-blue'>BOOK YOUR KASHMIR TULIP FESTIVAL PACKAGE TOUR NOW</h4>

                                    <p>Don’t miss the chance to experience the splendor of the <strong>Kashmir Tulip Festival 2025</strong> firsthand. Whether you're visiting as part of a <strong>Tulip Festival Special Kashmir Tour 2025</strong> or booking a dedicated <strong>Kashmir Tulip Festival Package</strong>, this is an opportunity to witness nature at its most vibrant. The beauty of the Indira Gandhi Tulip Garden, with its stunning array of flowers, rich cultural heritage, and peaceful surroundings, will leave you with memories that last a lifetime. <br /><br />

                                        Book your <strong>Kashmir Tulip Festival Package Tour</strong> today and embark on an unforgettable journey through the heart of Kashmir, where nature’s colors and timeless beauty await you. <br /><br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO REACH THE KASHMIR TULIP GARDEN FESTIVAL 2025</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH THE KASHMIR TULIP GARDEN FESTIVAL 2025</h4>
                                </div>
                                <div className="card-body">
                                    <p>Welcome to the enchanting <strong>Kashmir Tulip Garden Festival 2025</strong>, where nature’s beauty comes alive in a stunning display of vibrant tulips in full bloom. If you’re eager to experience this spectacular event, we’ve put together a simple guide on how to reach the <strong>Kashmir Tulip Garden Festival</strong> with ease. Let’s help you plan your journey to this unforgettable floral paradise!  <br /><br /><br /></p>


                                    <h4 className='tomato1'>KASHMIR TULIP GARDEN FESTIVAL TOUR WITH FLIGHT</h4>
                                    <p>For those looking for a quick and comfortable way to reach the <strong>Kashmir Tulip Garden Festival</strong>, flying into Srinagar is the most convenient option. The nearest airport is Sheikh Ul-Alam International Airport (SXR), also known as Srinagar Airport. This well-connected airport welcomes daily flights from a variety of domestic airlines, linking Srinagar to major cities across India. Whether you're traveling from Delhi, Mumbai, or other key locations, you’ll find frequent flights that make your journey to the tulip festival smooth and hassle-free. Once you arrive at the airport, the Tulip Garden is just a short drive away, ensuring you can quickly begin your floral adventure! <br /><br /><br /></p>


                                    <h4 className='tomato1'>POPULAR FLIGHTS YOU CAN BOOK FOR  <strong>KASHMIR TULIP GARDEN TOUR</strong></h4>

                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th><strong>Flight Operator</strong></th><th><strong>Departure From</strong></th><th><strong>Departure Time</strong></th><th><strong>Arrival at Srinagar</strong></th><th><strong>Stopover</strong></th><th><strong>Travel Time</strong></th></tr></thead>
                                            <tbody>
                                                <tr><td>IndiGo</td><td>Kolkata [CCU]</td><td>08:15</td><td>15:15</td><td>New Delhi [DEL]</td><td>07 h 00 m</td></tr>
                                                <tr><td>IndiGo</td><td>Mumbai [BOM]</td><td>09:30</td><td>15:15</td><td>New Delhi [DEL]</td><td>05 h 45 m</td></tr>
                                                <tr><td>SpiceJet</td><td>New Delhi [DEL]</td><td>08:00</td><td>09:25</td><td>NA</td><td>01 h 25 m</td></tr>
                                                <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>09:30</td><td>14:10</td><td>Sri Guru Ram Dass Jee [ATQ]</td><td>04 h 40 m</td></tr>
                                                <tr><td>IndiGo</td><td>Goa [GOX]</td><td>10:30</td><td>16:45</td><td>New Delhi [DEL]</td><td>06 h 15 m</td></tr>
                                                <tr><td>IndiGo</td><td>Hyderabad [HYD]</td><td>08:55</td><td>15:15</td><td>New Delhi [DEL]</td><td>06 h 20 m</td></tr>
                                                <tr><td>IndiGo</td><td>Chennai Airport [MAA]</td><td>08:30</td><td>14:20</td><td>New Delhi [DEL]</td><td>05 h 50 m</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4 className='tomato1'>KASHMIR TULIP GARDEN FESTIVAL TOUR BY TRAIN</h4>
                                    <p>If you're considering a <strong>Kashmir Tulip Garden Tour Package</strong>, one of the best ways to reach the stunning Kashmir Tulip Garden Festival is by train. You can travel to Jammu Tawi Railway Station, which is the nearest major railway station to Srinagar. From Jammu, take the scenic Jammu-Baramulla train route, offering breathtaking views of the changing landscape as you approach the beautiful Kashmir Valley. <br /><br />

                                        The train journey is not only a comfortable option but also an unforgettable experience, as you'll pass through lush green valleys, majestic mountains, and pristine rivers, all the way into the heart of Kashmir. Once you arrive in Srinagar, you’ll find taxis and buses easily available to take you directly to the <strong>Kashmir Tulip Garden Festival</strong>. <br /><br />

                                        For a seamless experience, you can book a <strong>Kashmir Tulip Festival Tour Package</strong>, which includes transportation and guided tours to the festival. With a <strong>Kashmir Tulip Garden Festival Package</strong>, you'll enjoy a hassle-free journey to witness the mesmerizing tulip blooms and other floral wonders of the region. Depending on the package, <strong>Kashmir Tulip Garden tour package cost</strong> typically covers transport, accommodation, and entry fees, ensuring you can fully immerse yourself in the beauty of this iconic event.<br /><br /><br /></p>


                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr>
                                                <th><strong>Train No</strong></th>
                                                <th><strong>Train Name</strong></th>
                                                <th><strong>Dep. Stn.</strong></th><th><strong>Dep. Time</strong></th><th><strong>Arr. Stn.</strong></th><th><strong>Arr. Time</strong></th><th><strong>Travel Time</strong></th></tr></thead>
                                            <tbody>
                                                <tr><td>12471</td><td>Swaraj Express</td><td>MUMBAI CENTRAL [MCC]</td><td>11:00</td><td>JAMMU TAWI [JAT]</td><td>14:55</td><td>27 h 55 m</td></tr>
                                                <tr><td>19027</td><td>Vivek Express</td><td>MUMBAI CENTRAL [MCC]</td><td>12:00</td><td>JAMMU TAWI [JAT]</td><td>23:05</td><td>35 h 05 m</td></tr>
                                                <tr><td>12331</td><td>Himgiri Express</td><td>HOWRAH JN [HWH]</td><td>23:55</td><td>JAMMU TAWI [JAT]</td><td>13:00</td><td>37 h 05 m</td></tr>
                                                <tr><td>13151</td><td>Jammu Tawi Express</td><td>KOLKATA CHITPUR [KOAA]</td><td>11:45</td><td>JAMMU TAWI [JAT]</td><td>08:50</td><td>45 h 05 m</td></tr>
                                                <tr><td>22317</td><td>Humsafar Express</td><td>SEALDAH [SDAH]</td><td>13:10</td><td>JAMMU TAWI [JAT]</td><td>23:25</td><td>34 h 15 m</td></tr>
                                                <tr><td>22477</td><td>Vande Bharat Express</td><td>NEW DELHI [NDLS]</td><td>15:00</td><td>JAMMU TAWI [JAT]</td><td>21:38</td><td>06 h 38 m</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4 className='tomato1'>KASHMIR TULIP GARDEN FESTIVAL TOUR BY ROAD</h4>
                                    <p>Embarking on a road trip to the <strong>Kashmir Tulip Garden Festival</strong> is an adventure filled with scenic beauty and local charm. The well-maintained National Highway 44 connects key cities like Jammu and Srinagar, offering a smooth and picturesque drive. Along the way, you'll pass through charming villages, lush green landscapes, and serene valleys, making the journey as memorable as the destination itself. You can also stop at local eateries to savor authentic Kashmiri cuisine, adding a flavorful touch to your trip. <br /><br />

                                        If you're traveling from nearby cities like Delhi or Chandigarh, you have the option of driving a private car for a more personalized experience. Alternatively, for a seamless and stress-free journey, you can opt for a Tulip Garden tour package by Adorable Vacation. This <strong>Kashmir Tulip Festival tour package</strong> ensures you have all the logistics taken care of, so you can simply relax and enjoy the breathtaking landscapes on your way to the festival. <br /><br /><br /></p>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="Sightseeing 4" />
                                                    <img src={sightseen11ImageURL} alt="Sightseeing 5" />
                                                    <img src={sightseen12ImageURL} alt="Sightseeing 6" />

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> EXPLORE THE STUNNING TULIP VARIETIES AT THE <strong>KASHMIR TULIP FESTIVAL 2025</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORE THE STUNNING TULIP VARIETIES AT THE <strong>KASHMIR TULIP FESTIVAL 2025</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Welcome to the captivating world of the <strong>Kashmir Tulip Festival 2025</strong>! This annual event is a breathtaking celebration of nature, where a wide array of tulip varieties bloom amidst the stunning landscapes of Kashmir. If you're planning to attend, consider booking the exclusive <strong>Kashmir Tulip Garden Festival Package</strong> for a truly immersive experience. <br /><br />

                                        At the festival, you'll be treated to a kaleidoscope of colors as over 1.5 million tulips come to life in a spectacular display. From bold reds to soft pastels, and from classic shapes to exotic hybrids, the tulips at the <strong>Kashmir Tulip Garden Festival</strong> are a visual feast for flower lovers. Whether you're a gardening enthusiast or simply a traveler in search of natural beauty, this festival promises an unforgettable experience. <br /><br />

                                        Join us in discovering the unique varieties of tulips that make this festival one of the most anticipated floral events of the year! <br /><br /></p>

                                    <li><b>Double Late 'Blue Spectacle' Tulips:</b> Captivating violet-blue blooms create a regal centerpiece in your garden.<br /><br /></li>
                                    <li><b>Lily-flowered 'West Point' Tulips:</b> Brilliant golden-yellow hues add grace and sophistication to any garden bed.<br /><br /></li>
                                    <li><b>Fosteriana 'Pink Impression' Tulips:</b> Large, cup-shaped flowers in soft pink evoke a dreamy, serene atmosphere.<br /><br /></li>
                                    <li><b>Greigii 'Pinocchio' Tulips:</b> Bi-colored petals in shades of red and white bring a playful and whimsical touch.<br /><br /></li>
                                    <li><b>Darwin Hybrid 'Daydream' Tulips:</b> 'Daydream' stands out with timeless elegance, featuring soft apricot-pink petals.<br /><br /></li>
                                    <li><b>Viridiflora 'Artist' Tulips:</b> 'Artist' showcases a captivating blend of red, pink, and green, adding an artistic flair to your garden.<br /><br /></li>
                                    <li><b>Parrot 'Ice Cream' Tulips:</b> Exotic, fringed petals in white and green resemble delicate ice cream cones, adding a unique touch.<br /><br /></li>
                                    <li><b>Triumph 'Gavota' Tulips:</b> Radiant red petals make a bold statement, infusing energy and vibrancy into your garden.<br /><br /></li>
                                    <li><b>Rembrandt Tulips:</b> 'Rem's Favorite' and 'Marilyn' pay homage to the artistic Rembrandt style with intricate and beautiful color patterns.<br /><br /></li> <li><b>Double Early Peony Tulips:</b> 'Abigail' in creamy white and 'Pink Star' in soft pink create an elegant and regal atmosphere.<br /><br /></li>
                                    <li><b>Fosteriana Kaufmanniana Tulips:</b> 'Stresa' in soft pink and 'Ancilla' in bright yellow bring a sense of serenity to your garden.<br /><br /></li>
                                    <li><b>Parrot 'Rococo' Tulips:</b> Fringed, multi-colored petals in pink, green, and white add an exotic and unique beauty.<br /><br /></li>
                                    <li><b>Fringed 'Shirley' Tulips:</b> Delicate fringes on pastel pink and white petals create an alluring and soft appearance.<br /><br /></li>
                                    <li><b>Greigii 'Toronto' Tulips:</b> Vibrant red blooms paired with mottled foliage bring a playful and charming element to your garden.<br /><br /></li>
                                    <li><b>Viridiflora 'Greenland' Tulips:</b> Mesmerizing shades of green and pink create a sophisticated and intriguing look.<br /><br /></li>
                                    <li><b>Kaufmanniana 'Chopin' Tulips:</b> Distinctive white blooms with a subtle blush of pink create a sense of purity and musicality.<br /><br /></li>
                                    <li><b>Triumph 'Menton' Tulips:</b> Elegant cup-shaped blooms in soft apricot-pink bring a whimsical, romantic touch to your garden.<br /><br /></li>
                                    <li><b>Parrot 'Blue Parrot' Tulips:</b> Vivid blue and violet hues, combined with fringed, feather-like petals, create a dramatic focal point.<br /><br /></li>
                                    <li><b>Kaufmanniana 'Johann Strauss' Tulips:</b> Bright yellow petals with red streaks offer a cheerful, lively touch.<br /><br /></li>
                                    <li><b>Parrot 'Carnaval de Nice' Tulips:</b> A festive mix of red, white, and green creates a celebration of color and joy.<br /><br /><br /></li>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen13ImageURL} alt="Sightseeing 4" />
                                                    <img src={sightseen14ImageURL} alt="Sightseeing 5" />
                                                    <img src={sightseen15ImageURL} alt="Sightseeing 6" />

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br />  <br />
                                    <li><b>Single Early Tulips: </b>'Red Emperor' and 'Apricot Beauty' bring vibrant early pops of color to the garden.<br /><br /></li>
                                    <li><b>Triumph Tulips:</b> Admire the rich purple petals of 'Negrita' and the brilliant yellow blooms of 'Golden Apeldoorn'.<br /><br /></li>
                                    <li><b>Darwin Hybrid Tulips:</b> Look out for the intense red of 'Oxford' and the striking scarlet hues of 'Apeldoorn Elite'.<br /><br /></li>
                                    <li><b>Lily-flowered Tulips:</b> 'Ballerina' offers a beautiful blend of orange and red, while 'White Triumphator' delights with pristine white blooms.<br /><br /></li> <li><b>Double Late Tulips:</b> Marvel at the soft pink beauty of 'Angelique' and the deep maroon blooms of 'Black Hero'.<br /><br /></li>
                                    <li><b>Fosteriana Tulips:</b> Bold and vivid, 'Purissima' and 'Red Emperor' stand out with their striking colors.<br /><br /></li>
                                    <li><b>Greigii Tulips:</b> Enjoy the playful apricot-pink flowers of 'Casa Grande' and the vibrant red blooms of 'Toronto'.<br /><br /></li>
                                    <li><b>Parrot Tulips:</b> 'Estella Rijnveld' and 'Flaming Parrot' captivate with their uniquely feathered petals.<br /><br /></li>
                                    <li><b>Viridiflora Tulips:</b> 'Golden Artist' and 'China Town' showcase stunning color patterns with hints of green streaks.<br /><br /></li>
                                    <li><b>Parrot 'Texas Flame' Tulips:</b> Spectacular hues of fiery red and orange create a dramatic effect, perfect for striking displays.<br /><br /></li>
                                    <li><b>Double Early 'Monte Carlo' Tulips:</b> Soft yellow, peony-like blooms bring refinement, elegance, and sophistication.<br /><br /></li>
                                    <li><b>Lily-flowered 'Aladdin' Tulips:</b> A charming blend of red and white adds an enchanting, magical touch to your garden.<br /><br /></li>
                                    <li><b>Fosteriana 'White Emperor' Tulips:</b> Dazzling pure white blooms stand out beautifully against the greenery, creating a serene atmosphere.<br /><br /></li> <li><b>Viridiflora 'Doll's Minuet' Tulips:</b> An ethereal blend of pink and green creates a dreamy, enchanting atmosphere in your garden.<br /><br /></li>
                                    <li><b>Darwin Hybrid 'Golden Apeldoorn' Tulips:</b> Regal and timeless, with large, golden-yellow blooms that symbolize the beauty of spring.<br /><br /></li> <li><b>Lily-flowered 'White Triumphator' Tulips:</b> Pristine white, pointed petals create a sense of purity, simplicity, and elegance.<br /><br /></li>
                                    <li><b>Miniature Tulips:</b> 'Little Beauty' in deep pink and 'Tarda' in cheerful yellow bring a playful charm to any space.<br /><br /></li>
                                    <li><b>Greigii 'Red Riding Hood' Tulips:</b> Bold and dramatic, 'Red Riding Hood' adds intensity with its scarlet-red blooms.<br /><br /></li>
                                    <li><b>Fringed Tulips:</b> 'Fringed Beauty' in lilac-pink and 'Fancy Frills' in white captivate with their delicately fringed edges.<br /><br /></li>
                                    <li><b>Greigii 'Oratorio' Tulips:</b> The delicate pink and white petals of 'Oratorio' add a touch of refinement and grace.<br /><br /></li>
                                    <li><b>Triumph 'Queen of the Night' Tulips:</b> With dramatic, almost black blooms, these tulips bring an air of mystery and elegance to the garden.<br /><br /></li>
                                    <li><b>Double Late 'Black Hero' Tulips:</b> Radiant deep maroon, peony-like blooms stand out as heroes in the garden, offering a bold contrast.<br /><br /></li> <li><b>Fosteriana 'Purissima' Tulips:</b> Graceful ivory-white blooms exude chic and sophisticated charm, perfect for refined gardens.<br /><br /></li>
                                    <li><b>Greigii 'Red Riding Hood' Tulips:</b> Dazzling scarlet-red blooms, coupled with unique foliage, add a playful and eye-catching element.<br /><br /></li> <li><b>Fringed 'Burgundy Lace' Tulips:</b> Delicate fringes on deep burgundy petals bring mystery, allure, and dramatic beauty.<br /><br /></li>
                                    <li><b>Parrot 'Pretty Princess' Tulips:</b> Exquisite fringed pink and white petals add a royal touch of delicacy and elegance.<br /><br /></li>
                                    <li><b>Triumph 'Prinses Irene' Tulips:</b> A radiant combination of orange and purple hues brings warmth, sophistication, and charm.<br /><br /></li>
                                    <li><b>Parrot 'Green Wave' Tulips:</b> Whimsical, fringed green and white petals add movement, energy, and vibrancy to your garden.<br /><br /></li>
                                    <li><b>Viridiflora 'Flaming Spring Green' Tulips:</b> A captivating blend of green and red on petals symbolizes the transition from winter to spring.<br /><br /></li>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP ATTRACTIONS TO EXPLORE DURING <strong>KASHMIR TULIP GARDEN FESTIVAL TOUR</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP ATTRACTIONS TO EXPLORE DURING <strong>KASHMIR TULIP GARDEN FESTIVAL TOUR</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <p>The <strong>Kashmir Tulip Festival 2025</strong> promises a breathtaking experience, showcasing nature's beauty at its best. Held every spring, from March to April, this vibrant festival is centered around the Indira Gandhi Memorial Tulip Garden (Siraj Bagh) in Srinagar. If you're planning a <strong> Kashmir Tulip Garden tour</strong> , here are the must-visit places and top attractions that will make your <strong>Tulip Festival trip in Kashmir</strong> truly unforgettable. Whether you're exploring the stunning gardens, experiencing Kashmiri culture, or visiting historical sites, Kashmir offers a blend of natural beauty and cultural richness.  <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Indira Gandhi Memorial Tulip Garden (Siraj Bagh)</h4>
                                    <p>The heart of the <strong> Kashmir Tulip Festival 2025</strong> , the Indira Gandhi Memorial Tulip Garden is the largest of its kind in Asia. With over a million tulips in full bloom, the garden is a feast for the eyes. It boasts a spectacular array of tulip varieties, from bright reds and oranges to delicate pinks and whites. Stroll through the meticulously manicured beds and enjoy the stunning display of colors, making it a perfect spot for nature lovers and photography enthusiasts. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Shankaracharya Temple</h4>
                                    <p>Perched atop a hill, Shankaracharya Temple offers a panoramic view of Srinagar and the surrounding valleys. This historic temple dedicated to Lord Shiva is an important pilgrimage site, and the journey to the top provides an opportunity to enjoy breathtaking views of the city, Dal Lake, and the snow-capped mountains. It’s a must-visit for spiritual seekers and those wanting a scenic hike during their <strong> Tulip Festival Kashmir tour</strong> . <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Dal Lake & Shikara Ride</h4>
                                    <p>A Shikara ride on Dal Lake is one of the most iconic experiences of any trip to Kashmir. Glide across the calm waters of the lake, surrounded by floating gardens and traditional houseboats. The view of the surrounding snow-capped mountains adds to the charm. As part of your <strong> Kashmir Tulip Garden Festival package</strong> , you can also visit the Mughal Gardens along the lakeshore, such as Shalimar Bagh and Nishat Bagh, both of which are architectural masterpieces. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Shalimar Bagh & Nishat Bagh</h4>
                                    <p>These Mughal gardens are an essential part of any Kashmir Tulip Garden Festival tour. Shalimar Bagh is famous for its terraced lawns, cascading fountains, and classical Mughal design, while Nishat Bagh is known for its wide vistas and charming views over Dal Lake. Both gardens, with their lush greenery and water features, are perfect spots to unwind after exploring the vibrant tulip displays. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Pari Mahal</h4>
                                    <p>Known as the "Palace of Fairies," Pari Mahal is a stunning Mughal ruin located on the western edge of Dal Lake. With its dramatic backdrop of the Zabarwan Range, it offers some of the best views of the lake and Srinagar. The ancient monument, with its terraced gardens and grand architecture, is a serene and picturesque spot for relaxation and photography. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Hazratbal Shrine</h4>
                                    <p>For those interested in Kashmir’s rich Muslim heritage, Hazratbal Shrine is an important pilgrimage site. Situated on the northern shores of Dal Lake, the shrine is known for its pristine white marble structure and the holy relic it houses. Visiting the shrine provides a spiritual experience along with a peaceful ambiance, perfect for contemplation. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Jamia Masjid</h4>
                                    <p>This iconic mosque in Srinagar is one of the largest and most beautiful in Kashmir. The Jamia Masjid, built in Indo-Saracenic style, is known for its wooden architecture, intricately carved pillars, and peaceful atmosphere. Located in the heart of Srinagar, it’s a perfect place to experience the region's architectural heritage. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Chashme Shahi</h4>
                                    <p>Another gem in the Mughal Gardens of Kashmir, Chashme Shahi is known for its natural spring and terraced layout. It’s a tranquil spot to relax and enjoy the views of the mountains and the gardens around it. The garden’s peaceful environment makes it a perfect place to unwind after a busy day of sightseeing during your <strong> Kashmir Tulip Festival Package Tour 2025</strong> . <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Kashmir Handicraft Markets</h4>
                                    <p>No visit to Kashmir is complete without exploring its vibrant handicraft markets. During the <strong> Kashmir Tulip Garden Festival</strong> , you can shop for exquisite Pashmina shawls, traditional Kashmiri carpets, papier-mâché items, and beautiful wooden artifacts. Lal Chowk, Polo View Market, and Raghunath Bazaar in Srinagar are the best places to shop for souvenirs and immerse yourself in Kashmiri culture. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Sonmarg</h4>
                                    <p>For those looking to explore beyond Srinagar, Sonmarg is a must-visit destination. Located around 80 kilometers from Srinagar, Sonmarg is known for its lush meadows, alpine lakes, and majestic views of snow-covered peaks. It’s a great place for trekking, picnics, and photography. As part of your <strong> Kashmir Tulip Garden Festival package</strong> , a visit to Sonmarg offers a refreshing escape into nature. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Gulmarg</h4>
                                    <p>Gulmarg, a popular hill station and skiing destination, is also known for its stunning meadows and alpine beauty. Just 51 kilometers from Srinagar, it’s a great option for a day trip. Whether you’re a nature lover or an adventure seeker, Gulmarg offers something for everyone, including the famous Gulmarg Gondola, which offers panoramic views of the snow-capped mountains. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Betaab Valley</h4>
                                    <p>Located in Pahalgam, Betaab Valley is a picturesque haven for nature lovers and adventure seekers. Known for its lush greenery, crystal-clear streams, and breathtaking landscapes, it’s a great place to relax and enjoy the natural beauty of Kashmir. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Events at the Tulip Festival in Kashmir</h4>
                                    <p>During your <strong> Kashmir Tulip Garden Festival tour</strong> , you’ll also get the chance to experience cultural events that showcase the local traditions. Attend Kashmiri music and dance performances, explore handicraft exhibitions, and savor delicious local delicacies like Rogan Josh, Yakhni, and the famous Wazwan feast. These activities add a unique cultural touch to your trip. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Plan Your Kashmir Tulip Festival Package Now!</h4>
                                    <p>Whether you're coming for the tulips, the serene lakes, the lush gardens, or the vibrant culture, the <strong> Kashmir Tulip Festival 2025</strong>  offers an unforgettable experience. Book your <strong> Kashmir Tulip Garden Festival package today</strong>  and explore the best that Kashmir has to offer in one remarkable tour. <br /><br /><br /></p>

                                    {/* <table style={{ width: '100%', textAlign: 'center' }}>
                                          <tbody>
                                              <tr>
                                                  <td className="image-cell" style={{ textAlign: 'center' }}>
                                                      <img
                                                          src={flight1ImageURL}
                                                          alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                          style={{ margin: '10px' }}
                                                      />
                                                      <img
                                                          src={flight2ImageURL}
                                                          alt="Bhutan Guwahati travel packages"
                                                          style={{ margin: '10px' }}
                                                      />
                                                      <img
                                                          src={flight3ImageURL}
                                                          alt="travel bhutan with best b2b dmc - adorable vacation"
                                                          style={{ margin: '10px' }}
                                                      />
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table> */}
                                </div>
                            </div>
                            <br /><br />
                           
                            {/* <!--By Itinerary--> */}
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DAY WISE <span className='tomato'><strong> KASHMIR TULIP FESTIVAL TOUR PACKAGE ITINERARY</strong></span>
                                        </h3>
                                        <br/><br/>
                                        <h4>7N 8D <strong>Kashmir Tour Itinerary for Tulip Festival starting from Srinagar International Airport</strong></h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: KASHMIR TULIP FESTIVAL : YOUR UNFORGETTABLE JOURNEY BEGINS </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Upon your arrival at Srinagar Sheikh Ul Alam International Airport, start your <strong>Kashmir Tulip Festival 2025 Adventure</strong> with one of the most scenic flights you will ever experience. Srinagar, known as the "City of Reshi's & Sufi's," is not only a historical and cultural hub but also the summer capital of Jammu & Kashmir. This charming city offers a perfect blend of tradition, nature, and spirituality, making it an ideal base for your <strong>Srinagar Tulip Garden Tour</strong>. <br /><br />

                                                    Upon arrival, our representative will meet you at the airport and transfer you to your hotel for check-in. After a refreshing welcome drink, get ready for a serene experience as you embark on a Shikara ride on the world-famous Dal Lake. During your ride, you'll have the unique opportunity to visit the floating vegetable gardens and enjoy the peaceful beauty of the lake. <br /><br />

                                                    While gliding across the shimmering waters, keep your eyes peeled for the diverse birdlife in the area. You might spot species such as the Kingfisher, Little Bittern, Common Pariah Kites, and Grebe, among others. The tranquil ride offers not only a perfect escape into nature but also an incredible chance to immerse yourself in the sights and sounds of Kashmir. <br /><br />

                                                    Your day will conclude with an overnight stay at a traditional Houseboat, where you can unwind and reflect on the day’s experiences. This is just the beginning of your Kashmir Tulip Festival tour, so get ready for a breathtaking journey filled with natural beauty, cultural treasures, and unforgettable memories. <br /><br />

                                                    Book your <strong>Tulip festival tour packages</strong> now and embark on the trip of a lifetime! <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: KASHMIR TULIP FESTIVAL TOUR PACKAGE - SRINAGAR TO GULMARG</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>On the second day of your <strong>Kashmir Tulip Festival tour package</strong>, after a relaxing breakfast, check out from your charming Houseboat and embark on a scenic drive to Gulmarg. The journey from Srinagar to Gulmarg takes approximately 1 hour and 27 minutes (51 km) via the Srinagar - Tangmarg Road, allowing you to soak in the picturesque views of the Kashmir Valley along the way. <br /><br />

                                                    Gulmarg, famously known as the Meadow of Flowers, is a breathtaking hill station located at an altitude of 8,700 feet. This enchanting destination is not only known for its natural beauty but also for being a renowned ski resort and home to the world’s highest 18-hole golf course. Gulmarg's history dates back to the British era, and its majestic beauty, combined with its location, makes it one of India's premier hill resorts. The name "Gulmarg" was given in the 16th century by Sultan Yusuf Shah, inspired by the sight of its grassy slopes adorned with wildflowers, and it was once known as 'Gaurimarg' by the local shepherds. <br /><br />

                                                    During the reign of Emperor Jehangir, Gulmarg was a favorite retreat, and he famously collected 21 different varieties of flowers from the region. Today, Gulmarg is not only an iconic mountain resort but also a top destination for skiing during winter and a golfer’s paradise with the highest green golf course at 2,650 meters above sea level. <br /><br />

                                                    Upon arrival in Gulmarg, check into your pre-arranged hotel for a comfortable stay. You’ll then have the opportunity to experience the famous Gulmarg Gondola ride, which takes you up to the 1st or 2nd Phase of Mount Affarwat. The Gulmarg Gondola is one of the highest cable cars in the world, reaching an impressive 3,979 meters. The two-stage ropeway can ferry about 600 people per hour and offers panoramic views as you ascend to the shoulder of Mount Affarwat Summit at an altitude of 4,200 meters (13,780 feet). <br /><br />

                                                    For adventure enthusiasts, Khilanmarg Meadows at the foot of Mount Affarwat provide a stunning backdrop for skiing, and the Affarwat Ridge offers an avalanche-controlled ski area. This is a prime destination for advanced skiers who enjoy extreme sports, and it is popular among international ski professionals, frequently appearing in ski films. <br /><br />

                                                    In the evening, return to the hotel after a day filled with natural beauty and adventure. Your night will be spent at a cozy hotel in Gulmarg, ready for another exciting day ahead. <br /><br />

                                                    Make your experience even more memorable by booking a <strong>Kashmir Tulip Garden Festival package for 2025</strong> to explore the stunning landscapes of Gulmarg and Srinagar, along with the <strong>Kashmir Tulip Garden tour</strong>. Don’t miss this once-in-a-lifetime opportunity to witness the vibrant <strong>Tulip Festival in Kashmir</strong> while exploring the most iconic destinations in the region! <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: GULMARG TO PAHALGAM – EXPLORE THE “VALLEY OF SHEPHERDS”</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>After a hearty breakfast, check out from your hotel in Gulmarg and begin your journey to Pahalgam, also known as the "Valley of Shepherds". This picturesque town is located approximately 148 km away, taking about 4 to 5 hours to reach by road. Nestled at the confluence of the streams from Sheshnag Lake and the Lidder River, Pahalgam (2,130 meters) offers some of the most breathtaking views in Kashmir. <br /><br />

                                                    Once a modest shepherd's village, Pahalgam has transformed into a popular tourist destination known for its serene landscapes and lush greenery. Stroll along the banks of the Lidder River and embrace the tranquility of nature. The town is also famous for its trekking routes and serves as the base camp for the Amarnath Yatra pilgrimage.
                                                    <br /><br />

                                                    Pahalgam holds a special place in the Indian film industry, particularly in Bollywood, where it has served as a backdrop for several iconic movies. Despite its popularity, Pahalgam retains its peaceful charm, with pleasant weather that remains cool even in the height of summer, where the maximum temperature rarely exceeds 25°C.
                                                    <br /><br />

                                                    Upon arrival in Pahalgam, check into your pre-arranged hotel, where you can relax and unwind. The rest of the day is free for leisure activities, allowing you to explore the town or simply enjoy its natural beauty. Pahalgam is a perfect destination to relax and rejuvenate after your visit to the <strong>Kashmir Tulip Garden Festival</strong>. <br /><br />

                                                    Whether you're embarking on a <strong>Kashmir Tulip Garden tour</strong> or exploring the scenic wonders of Kashmir, Pahalgam offers a peaceful retreat that perfectly complements the vibrancy of the <strong>Tulip Festival in Kashmir</strong>. Overnight stay at the hotel in Pahalgam. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: PAHALGAM – EXPLORE THE NATURAL BEAUTY OF KASHMIR </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>After a leisurely breakfast, the entire day is free for you to explore and enjoy the breathtaking beauty of Pahalgam. As part of your <strong>Kashmir Tulip Festival Package Tour</strong>, you can visit some of the most scenic spots in and around Pahalgam. Here are some must-visit attractions included in your <strong>Tulip Festival Special Kashmir Tour 2025</strong>:<br /><br /></p>

                                                <h4>Aru Valley</h4>
                                                <p>Located about 12 km from Pahalgam, Aru Valley is a serene destination known for its stunning meadows, crystal-clear lakes, and snow-capped mountains. This peaceful village, situated on the left bank of the Aru River, is a popular base camp for treks to Kolahoi Glacier and Tarsar Lake. The scenic beauty here is perfect for photography, sightseeing, and nature walks. Visitors can also enjoy activities such as fishing, horse riding, and trekking. Aru Valley is the ideal spot for nature lovers and adventure enthusiasts alike. <br /><br /></p>

                                                <h4>Betaab Valley</h4>
                                                <p>At a distance of about 15 km from Pahalgam, Betaab Valley is one of Kashmir’s most picturesque locations. Famous for its lush green meadows, dense forests, and snow-covered mountains, Betaab Valley was named after the Bollywood hit film Betaab. This valley is not only a popular tourist destination but also a favorite camping site for trekkers. The crystal-clear stream flowing down from the snowy peaks is a highlight, and you can also indulge in nature walks, photography, and picnicking. <br /><br /></p>

                                                <h4>Chandanwari</h4>
                                                <p>Located 16 km from Pahalgam, Chandanwari is the starting point for the Amarnath Yatra pilgrimage. It is famous for its snow sledding on a snow bridge, which makes for a thrilling experience. You can hire a taxi from Pahalgam for a round-trip journey to Chandanwari. Many visitors enjoy having a cup of tea at the local dhabas or engage in playful snow fights. The picturesque location has been featured in several Bollywood films, making it an attractive spot for movie buffs and nature lovers alike. <br /><br /></p>

                                                <h4>Optional: Baisaran Valley / Mini Switzerland</h4>
                                                <p>For those looking for an additional adventure, you can hire ponies directly from Pahalgam to visit Baisaran Valley, often referred to as Mini Switzerland. Surrounded by pine forests and snow-clad mountains, Baisaran Valley is a beautiful meadow that offers panoramic views of the surrounding landscape. This area is also famous for its film shoots and offers ample opportunities for hiking, photography, and enjoying the serenity of Kashmir. <br /><br />

                                                    In the evening, return to your hotel in Pahalgam for dinner and a restful overnight stay. Whether you're enjoying the calm of Aru Valley, the beauty of Betaab Valley, or the adventure of Chandanwari, your <strong>Kashmir Tulip Garden Festival tour</strong> promises a mix of natural beauty and cultural exploration. <br /><br />

                                                    As part of your <strong>Kashmir Tulip Festival Package Tour</strong>, Pahalgam provides the perfect opportunity to immerse yourself in the breathtaking landscapes of Kashmir, enhancing your experience of the <strong>Kashmir Tulip Garden Festival 2025</strong>. <br /><br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PAHALGAM TO SRINAGAR – EXPLORE THE CULTURAL GEMS OF <strong>KASHMIR TULIP GARDEN FESTIVAL TOUR PACKAGE</strong></h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>After a hearty breakfast, check out from your hotel and drive to Srinagar. As part of your <strong>Kashmir Tulip Garden Festival Package</strong>, your journey to Srinagar includes visits to some of the most iconic spiritual and cultural landmarks in the region. <br /><br /></p>

                                                <h4>Hazratbal Shrine</h4>
                                                <p>Your first stop is the Hazratbal Shrine, an important Muslim pilgrimage site located on the northern shores of Dal Lake. Known for its pristine white marble architecture, the shrine is famous for housing a relic believed to be the hair of Prophet Muhammad. The tranquil atmosphere and stunning surroundings make it a must-visit during your Kashmir Tulip Garden Tour. <br /><br /><br /></p>


                                                <h4>Shankaracharya Temple</h4>
                                                <p>Next, head to the Shankaracharya Temple, located atop a hill that offers panoramic views of Srinagar. This ancient temple is dedicated to Lord Shiva and is believed to be the place where the philosopher-saint Shankaracharya stayed during his visit to Kashmir. The temple is a blend of spirituality and history, and its breathtaking views of the city and the surrounding mountains are a highlight of any Kashmir Tulip Festival Tour Package. <br /><br /><br /></p>


                                                <h4>Mughal Gardens</h4>
                                                <p>In the afternoon, embark on a visit to some of the renowned Mughal Gardens of Srinagar: <br /><br />

                                                    <b>Nishat Bagh (Garden of Pleasure) :</b> Built in 1633 by Asif Khan, the brother of Noor Jahan, Nishat Bagh is one of the largest Mughal gardens in Kashmir. Its terraced lawns and fountains make it a perfect place to relax and enjoy the beauty of the tulips that bloom in the spring. <br /><br />

                                                    <b>Shalimar Bagh (Garden of Love) :</b> Known for its lush greenery and romantic ambiance, Shalimar Bagh was built by Emperor Jahangir in 1619 for his beloved wife, Mehr-un-Nissa (Noor Jahan). The garden’s majestic layout and intricate fountains represent the pinnacle of Mughal garden design. <br /><br />

                                                    <b>Chashme Shahi (The Royal Spring) :</b> One of the smaller yet equally beautiful gardens, Chashme Shahi is famous for its natural spring water. Built by Emperor Shah Jahan, it offers spectacular views of Dal Lake and is a serene spot to unwind after your garden tours. <br /><br /><br /> </p>

                                                <p>By the evening, return to your hotel in Srinagar. Enjoy a restful overnight stay after an enriching day filled with cultural exploration, stunning gardens, and spiritual landmarks, all included in your <strong>Kashmir Tulip Festival Package Tour</strong>. <br /><br /><br />

                                                    This day of your <b>Kashmir Tulip Festival Tour Package</b> offers an unforgettable journey through the cultural heart of Srinagar, enhancing your experience of the Kashmir Tulip Garden Festival while uncovering the region’s rich heritage. Whether visiting the Hazratbal Shrine, the peaceful Shankaracharya Temple, or the majestic Mughal Gardens, this day promises to be a highlight of your Kashmir experience. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: SRINAGAR TO DOODHPATHRI – A SCENIC ESCAPE IN <strong>KASHMIR TULIP FESTIVAL 2025 TOUR</strong></h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>After an early breakfast, embark on a memorable day trip to Doodhpathri, one of Kashmir's hidden gems, as part of your <strong>Srinagar Tulip Garden Tour</strong>. This picturesque destination, located 42 kilometers from Srinagar, is known as the "Valley of Milk" due to a fascinating local legend. <br /><br /></p>

                                                <h4>Doodhpathri : The Valley of Milk</h4>
                                                <p>Doodhpathri, nestled in the Budgam district of Jammu & Kashmir, is a serene hill station surrounded by lush green meadows and a pristine, sparkling stream. The name "Doodhpathri" (meaning Milk Valley) originates from a story about the revered Kashmiri saint Sheikh Noor din Noorani. According to the tale, when the saint was in search of water for his prayers, he struck the ground with his stick, and milk flowed out instead of water. He instructed that the milk be used only for drinking and not for ablution, after which the milk miraculously transformed into water. This mystic tale adds a layer of charm to the already stunning landscape. <br /><br />

                                                    The area is known for its vast meadows, which are blanketed with wildflowers like daisies, buttercups, and forget-me-nots in the spring and summer months. The meadows, which are covered in snow during the winter, turn into a vibrant sea of color during the warmer months, creating a perfect backdrop for nature walks, photography, and peaceful reflection. The stream that runs through the meadows sparkles like milk from a distance, adding to the serene atmosphere of the region. <br /><br /><br /></p>

                                                <h4>A Scenic Day in Doodhpathri</h4>
                                                <p>As part of your <strong>Kashmir Tulip Festival 2025</strong> experience, this excursion offers the perfect blend of natural beauty and cultural intrigue. The lush green landscapes, rolling hills, and the cool, clear water that flows through the meadows create an idyllic setting for relaxation and exploration. The area is less crowded than other tourist destinations, allowing you to enjoy the tranquility and beauty of Doodhpathri at your own pace. It’s a great opportunity to indulge in some quiet moments of reflection while surrounded by nature’s splendor. <br /><br />

                                                    In the evening, head back to Srinagar for an overnight stay at your hotel, with the memories of a peaceful day spent exploring the Valley of Milk. This day trip from Srinagar to Doodhpathri is the perfect complement to your Tulip Festival Tour Packages, offering a serene break from the vibrant, colorful landscapes of the Kashmir Tulip Garden Festival. <br /><br />

                                                    This excursion adds another layer of beauty and tradition to your <strong>Kashmir Tulip Festival 2025</strong> experience, giving you the chance to explore one of Kashmir’s most enchanting and off-the-beaten-path locations. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: SRINAGAR – SONMARG – SRINAGAR [87 KMS – 2 TO 3 HOURS ONE WAY]</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After an early breakfast, check out from your hotel and embark on a scenic full-day excursion to Sonmarg, meaning "Meadow of Gold." Nestled amidst towering snow-capped peaks and lush green valleys, Sonmarg offers a breathtaking backdrop of majestic mountains and clear blue skies. The drive itself is a visual treat, passing through dense pine forests and rugged mountain landscapes, providing a truly captivating experience. <br /><br />

                                                    Sonmarg is not only known for its natural beauty but also as an important base for various trekking routes, high-altitude lakes, glaciers, and the Shri Amarnath Cave. The crystal-clear Sonmarg River, meandering through the meadow, is home to Trout and Mahseer fish, making it a popular spot for anglers. Sonmarg is also the last stop on the Srinagar-Leh road, before heading towards Leh, making it a significant location for travelers exploring the Himalayas. <br /><br />

                                                    During your visit, you can hire ponies or vehicles (on direct payment basis) to take you up to Thajiwas Glacier, a major attraction in Sonmarg during the summer months. The glacier offers awe-inspiring views of snow-covered landscapes, and the area is perfect for nature lovers and photographers alike. <br /><br />

                                                    After spending the day exploring the Meadow of Gold, you’ll return to Srinagar by evening for an overnight stay at your hotel. <br /><br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day8"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 08: DEPARTURE FROM SRINAGAR – FLY OUT</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>Your <strong>Kashmir Tulip Festival Tour</strong> concludes today. After breakfast, enjoy your final moments in the picturesque Srinagar as you prepare for your onward journey. Our representative will assist with your timely transfer to Srinagar Domestic Airport for your flight home or to your next destination. <br /><br />

                                                    Thank you for choosing Adorable Vacation for your <strong>Kashmir Tulip Garden Tour</strong>. We hope you had a memorable experience surrounded by the beauty of Kashmir, and we look forward to welcoming you on another journey in the future! <br /><br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">{<InclusionExclusion />}</div>
                                </div>
                            </div>
                            
                            <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="kashmir expert" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />

                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Tulip Festival and Kashmir Tours Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                       <h4> Q. WHAT IS THE SIGNIFICANCE OF THE KASHMIR TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Discover the enchanting beauty of Kashmir through the mesmerizing <strong>Kashmir Tulip Festival 2025</strong>. This annual event, also known as the Tulip Festival in Kashmir, takes place in the scenic Indira Gandhi Memorial Tulip Garden, situated in Srinagar. Blooming in April, this festival showcases the vibrant hues of tulips against the majestic backdrop of the Zabarwan Range.

                                                        Immerse yourself in a kaleidoscope of colors as you explore one of Asia's largest tulip gardens. The festival not only celebrates the natural beauty of the region but also promotes tourism, making it an ideal time to witness the breathtaking landscapes of Kashmir.

                                                        This <strong>Tulip Festival Special Kashmir Tour</strong> is not just an event; it’s an experience that captivates your senses and leaves you with lasting memories. Plan your <strong>Kolkata to Kashmir Tulip Festival Tour Packages</strong> today and embrace the beauty that nature unfolds in this picturesque destination.
                                                        <br/><br/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    <h4>Q. IS THERE AN ENTRANCE FEE FOR THE TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                    For seamless entry, domestic visitors can obtain tickets at just INR 50 per adult and INR 25 per child. International travelers can access this enchanting paradise for INR 150 (including GST) per person.<br/><br/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                    <h4>Q. ARE THERE ANY SPECIFIC DATES FOR THE TULIP FESTIVAL IN 2025?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                    Discover the optimal time for a visit to the Tulip Garden in Kashmir! The Tulip Garden in Srinagar opens its gates in March 2025, and you can conveniently book your tickets online. The prime time to explore the enchanting Tulip Garden is in early April, when the tulips are at their peak, showcasing a breathtaking array of colors. The tulips bloom for a brief period, not lasting beyond 20 days, so it’s important to plan your visit accordingly to witness the stunning beauty of the garden in full glory during this time.<br/><br/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    <h4>Q. WHY IS INDIRA GANDHI MEMORIAL TULIP GARDEN FAMOUS?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Formerly known as the Model Floriculture Centre, the garden was unveiled to the public in 2007 with the goal of enhancing tourism and floriculture in the Kashmir Valley. With over 68 diverse tulip varieties, totaling in the hundreds of thousands, the garden showcases a breathtaking display of color. Additionally, visitors can marvel at various species of daffodils, hyacinths, and ranunculus, contributing to the garden’s allure as a vibrant and diverse floral paradise. <br/><br/></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    <h4>Q. WHEN DO TULIPS BLOOM IN KASHMIR?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The tulips in Kashmir grace the landscape from late March to mid-May, with their peak bloom occurring in mid-April. For the most spectacular views, plan your visit during this period. The annual <strong>Kashmir Tulip Festival Tour</strong> offers a fantastic opportunity to witness the tulips in their full glory. Stay updated with the latest information to ensure your visit coincides with the peak bloom, creating unforgettable memories amid the picturesque tulip fields. <br/><br/></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    <h4>Q. WHAT IS INCLUDED IN THE <strong>KASHMIR TULIP FESTIVAL SPECIAL KASHMIR GROUP TOUR</strong>?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The <strong>Kashmir Tulip Festival Special Kashmir Tour</strong> includes everything you need for a seamless and unforgettable experience. The package covers return 3AC Tier train tickets from Howrah Station to Kashmir, accommodation, all meals, a guided tour of the Tulip Garden, Shikara ride on Dal Lake, and sightseeing across iconic locations such as Gulmarg, Pahalgam, and Sonmarg. It's the perfect opportunity for travelers from Kolkata to enjoy the Tulip Festival in Kashmir with ease.<br/><br/></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. HOW CAN I TRAVEL FROM KOLKATA TO KASHMIR FOR THE TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Adorable Vacation offers the best way to travel from Kolkata to Kashmir for the Tulip Festival. The Kashmir Tour, taking travelers on a comfortable journey in 3AC Tier. The train will return to Kolkata after an exciting and immersive tour of Kashmir’s most famous tulip gardens and other breathtaking locations. With train travel included, this is a budget-friendly and hassle-free option to explore the beauty of Kashmir.<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. ARE THERE OTHER SIGHTSEEING OPTIONS IN KASHMIR APART FROM THE TULIP GARDEN?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Absolutely! Kashmir is rich in cultural and natural attractions. While visiting for <strong>Kashmir Tulip Garden tour</strong>, you'll also experience the splendor of places like Dal Lake, Shankaracharya Temple, the Mughal Gardens, Gulmarg, Sonmarg, and the famous Betaab Valley. You can also enjoy local cultural performances, visit traditional markets for handicrafts, and take part in spiritual experiences like the Vaishno Devi Darshan. The <strong>Kashmir Tulip Festival Packages ex Kolkata</strong> ensure a well-rounded Kashmir experience.<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. HOW LONG IS THE KASHMIR TULIP FESTIVAL SPECIAL GROUP TOUR?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The <strong>Kashmir Tulip Festival Special Tour Packages</strong> lasts for 12 nights and 13 days. The package includes everything you need, from travel to sightseeing and accommodation, ensuring you have plenty of time to explore Kashmir and enjoy the Tulip Festival at its peak.<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. WHAT IS THE BEST TIME TO VISIT KASHMIR FOR THE TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The best time to visit Kashmir for the Tulip Festival is in early April, when the tulips are in full bloom and the entire garden is a riot of color. However, it's important to note that the Tulip Festival lasts for just 20 days, so make sure to plan your visit during the prime bloom period for the most spectacular views.<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. IS THE TULIP FESTIVAL ACCESSIBLE FOR SENIOR CITIZENS AND CHILDREN?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, the <strong>Kashmir Tulip Garden Tour in April 2025</strong> is designed to be accessible for travelers of all ages, including senior citizens and children. The train journey is comfortable and offers a relaxing travel experience. Additionally, most of the key attractions in Kashmir, including the Tulip Garden and Dal Lake, are accessible with minimal walking, making them ideal for senior citizens. Children can also enjoy the beauty and fun activities at the festival, making it a family-friendly experience.<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. CAN I EXTEND MY STAY IN KASHMIR AFTER THE TULIP FESTIVAL TOUR?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, if you wish to extend your stay and explore more of Kashmir, you can easily do so. Adorable Vacation can help you customize your trip, providing you with additional accommodation options and guided tours for a more in-depth experience. Just let us know your preferences, and we’ll arrange everything for you.<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    <h4>Q. HOW DO I BOOK A <strong>KASHMIR TULIP FESTIVAL SPECIAL GROUP TOUR</strong>?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Booking your <strong>Kashmir Tulip Festival Special Tour Packages</strong> is simple! Visit our website or contact our customer service team to book your package. Our team will assist you in choosing the best dates and arrangements to ensure a seamless and memorable Kashmir experience. Don't miss out on this opportunity to be part of the spectacular <strong>Kashmir Tulip Garden Tour</strong>!<br/><br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>
            </>
        );
    }
}
export default Kashmir_7N_8D_Tulip_Package;