import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import HomePage from "../pages/HomePage";
import PolicyPage from "../pages/PolicyPage";
import PurchasePage from "../pages/PurchasePage";
import RefundPage from "../pages/RefundPage";
import VisaService from "../pages/VisaPage";
import BlogPage from "../pages/packages/Blog/BlogPage";
import GalleryPage from "../pages/packages/Gallery/GalleryPage";
import BhutanGalleryInsidePage from "../pages/packages/Gallery/BhutanGalleryInsidePage";
import AdventureTourLadakhToSikkimPage from "../pages/packages/AdventureTourLadakhToSikkimPage";
import BhutanTourPackagePage from "../pages/packages/Bhutan/BhutanTourPackagePage";
import FIFO_5N_6D_Page from "../pages/packages/Bhutan/FIFO_5N_6D_Page";
import FIFO_4N_5D_Page from "../pages/packages/Bhutan/FIFO_4N_5D_Page";
import FIFO_6N_7D_Page from "../pages/packages/Bhutan/FIFO_6N_7D_Page";
import FIFO_7N_8D_Page from "../pages/packages/Bhutan/FIFO_7N_8D_Page";
import FIFO_8N_9D_Page from "../pages/packages/Bhutan/FIFO_8N_9D_Page";
import FIFO_10N_11D_Page from "../pages/packages/Bhutan/FIFO_10N_11D_Page";
import DelhiToBhutanPage from "../pages/packages/Bhutan/DelhiToBhutanPage";
import MumbaiToBhutanPage from "../pages/packages/Bhutan/MumbaiToBhutanPage";
import KolkataToBhutanPage from "../pages/packages/Bhutan/KolkataToBhutanPage";
import ChennaiToBhutanPage from "../pages/packages/Bhutan/ChennaiToBhutanPage";
import HyderabadToBhutanPage from "../pages/packages/Bhutan/HyderabadToBhutanPage";
import AhemdabadToBhutanPage from "../pages/packages/Bhutan/AhemdabadToBhutanPage";
import PuneToBhutanPage from "../pages/packages/Bhutan/PuneToBhutanPage";
import BangaloreToBhutanPackage from "../pages/packages/Bhutan/BangaloreToBhutanPage";
import Bagdogra_6N_7D_Page from "../pages/packages/Bhutan/Bagdogra_6N_7D_Page";
import SuratToBhutanPage from "../pages/packages/Bhutan/SuratToBhutanPage";
import Bhutan_B2B_7N_8D_Page from "../pages/packages/Bhutan/Bhutan_B2B_7N_8D_Page";
import Bhutan_TourGuwahatiPage from "../pages/packages/Bhutan/Bhutan_TourGuwahatiPage";
import Bhutan_TourSixsenseHotelPage from "../pages/packages/Bhutan/Bhutan_TourSixsenseHotelPage";
import Bhutan_TourSixsenseDirectFlightIndiaPage from "../pages/packages/Bhutan/Bhutan_TourSixsenseDirectFlightIndiaPage";
import Bhutan_TourHasimaraNoqPage from "../pages/packages/Bhutan/Bhutan_TourHasimaraNoqPage";
import Bhutan_TourPhuentsholingPage from "../pages/packages/Bhutan/Bhutan_TourPhuentsholingPage";
import Bhutan_TourNjpSiliguriPage from "../pages/packages/Bhutan/Bhutan_TourNjpSiliguriPage";
import Bhutan_TourJaigaonPage from "../pages/packages/Bhutan/Bhutan_TourJaigaonPage";
import BangaloreToBhutanviaKolkataAirportPage from "../pages/packages/Bhutan/BangaloreToBhutanviaKolkataAirportPage";
import Bhutan_B2B_7N_8D_Bagdogra_Airport_Page from "../pages/packages/Bhutan/Bhutan_B2B_7N_8D_Bagdogra_Airport_Page";
import Bagdogra_7N_8D_Group_Page from "../pages/packages/Bhutan/Bagdogra_7N_8D_Group_Page";
import Bhutan_Nagpur_6N_7D_Page from "../pages/packages/Bhutan/Bhutan_Nagpur_6N_7D_Page";
import Bhutan_Nashik_6N_7D_Page from "../pages/packages/Bhutan/Bhutan_Nashik_6N_7D_Page";
import Bhutan_Bhopal_6N_7D_Page from "../pages/packages/Bhutan/Bhutan_Bhopal_6N_7D_Page";
// Blog url-------------------------------------------------------------
import Bhutan_blog_Page from "../pages/packages/Blog/Bhutan_blog_Page";
// meghalaya url-----------------------------------------------------------------
import MeghalayaTourPackagePage from "../pages/packages/Meghalaya/MeghalayaTourPackagePage";
import Meghalaya_5N_6D_Page from "../pages/packages/Meghalaya/Meghalaya_5N_6D_Page";
import Meghalaya_6N_7D_Page from "../pages/packages/Meghalaya/Meghalaya_6N_7D_Page";
import Meghalaya_7N_8D_Page from "../pages/packages/Meghalaya/Meghalaya_7N_8D_Page";
import ShillongMeghalayaTour_6N_7D_Page from "../pages/packages/Meghalaya/ShillongMeghalayaTour_6N_7D_Page";
import KazirangaShillongMeghalayaTour_7N_8D_Page from "../pages/packages/Meghalaya/KazirangaShillongMeghalayaTour_7N_8D_Page";
import Arunachal_10N_11D_Page from "../pages/packages/Meghalaya/Arunachal_10N_11D_Page";
import Arunachal_11N_12D_Page from "../pages/packages/Meghalaya/Arunachal_11N_12D_Page";
import MeghalayaArunachalTour_12N_13D_Page from "../pages/packages/Arunachal_Pradesh/MeghalayaArunachalTour_12N_13D_Page";
import MeghalayaShillongCherrapunjeeTour_7N_8D_Page from "../pages/packages/Meghalaya/MeghalayaShillongCherrapunjeeTour_7N_8D_Page";
import Meghalaya_WariChora_Tura_7N_8D_Page from "../pages/packages/Meghalaya/Meghalaya_WariChora_Tura_7N_8D_Page";
import Assam_Meghalaya_Arunachal_19N_20D_Page from "../pages/packages/Meghalaya/Assam_Meghalaya_Arunachal_19N_20D_Page";
import KazirangaShillongCherrapunjeeGroupTour_8N_9D_Page from "../pages/packages/Meghalaya/KazirangaShillongCherrapunjeeGroupTour_8N_9D_Page";
import KazirangaTourWithElephantSafari_7N_8D_Page from "../pages/packages/Meghalaya/KazirangaTourWithElephantSafari_7N_8D_Page";
//sikkim
import Sikkim_Darjeeling_4N_5D_Page from "../pages/packages/Sikkim_Darjeeling/Sikkim_Darjeeling_4N_5D_Page";
import Sikkim_5N_6D_Page from "../pages/packages/Sikkim_Darjeeling/Sikkim_5N_6D_Page";
import NorthSikkim_Silkroute_8N_9D_Page from "../pages/packages/Sikkim_Darjeeling/NorthSikkim_Silkroute_8N_9D_Page";
import Sikkim_Tour_9N_10D_bagdogra_Page from "../pages/packages/Sikkim_Darjeeling/Sikkim_Tour_9N_10D_bagdogra_Page";
//Silkroute URL
import Silkroute_TourKolkataPage from "../pages/packages/Sikkim_Darjeeling/Silkroute_TourKolkataPage";
//Andaman
import Andaman_6N_7D_Page from "../pages/packages/Andaman/Andaman_6N_7D_Page";
import Andaman_7N_8D_Package_Tour_Kolkata_Page from "../pages/packages/Andaman/Andaman_7N_8D_Package_Tour_Kolkata_Page";
import Andaman_5N_6D_Honeymoon_Tour_Page from "../pages/packages/Andaman/Andaman_5N_6D_Honeymoon_Tour_Page";
// kashmir URL
import Kashmir_6N_7D_Page from "../pages/packages/Kashmir/Kashmir_6N_7D_Page";
import Kashmir_8N_9D_Doodhpathri_Yusmarg_Page from "../pages/packages/Kashmir/Kashmir_8N_9D_Doodhpathri_Yusmarg_Page";
import Kashmir_7N_8D_Gurez_valley_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Gurez_valley_Page";
import Kashmir_Leh_Ladakh_Tour_Page from "../pages/packages/Kashmir/Kashmir_Leh_Ladakh_Tour_Page";
import Ladakh_6N_7D_Page from "../pages/packages/Ladakh/Ladakh_6N_7D_Page";
import Leh_Ladakh_Kargil_8N_9D_Page from "../pages/packages/Ladakh/Leh_Ladakh_Kargil_8N_9D_Page";
import Leh_Ladakh_Turtuk_Page from "../pages/packages/Ladakh/Leh_Ladakh_Turtuk_Page";
import Kashmir_7N_8D_Sinthan_Top_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Sinthan_Top_Page";
import Kashmir_7N_8D_Vaishno_Devi_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Vaishno_Devi_Page";
import Kashmir_7N_8D_Tulip_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tulip_Page";
import Kashmir_7N_8D_Tour_From_Kolkata_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Kolkata_Page";
import Kashmir_7N_8D_Tour_From_Surat_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Surat_Page";
import Kashmir_7N_8D_Tour_From_Chennai_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Chennai_Page";
import Kashmir_7N_8D_Tour_From_Bangalore_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Bangalore_Page";
import Kashmir_7N_8D_Tour_From_Pune_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Pune_Page";
import Kashmir_7N_8D_Tour_From_Mumbai_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Mumbai_Page";
import Kashmir_4N_5D_Tour_From_Delhi_Page from "../pages/packages/Kashmir/Kashmir_4N_5D_Tour_From_Delhi_Page";
import Kashmir_4N_5D_Tour_From_Ahmedabad_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Tour_From_Ahmedabad_Page";
import Kashmir_7N_8D_Family_Page from "../pages/packages/Kashmir/Kashmir_7N_8D_Family_Page";
import Kashmir_9N_10D_Vaishno_Devi_Amritsar_Page from "../pages/packages/Kashmir/Kashmir_9N_10D_Vaishno_Devi_Amritsar_Page";
import Kashmir_12N_13D_Tulip_Group_Tour_Kolkata_Page from "../pages/packages/Kashmir/Kashmir_12N_13D_Tulip_Group_Tour_Kolkata_Page";
///Thailand URL------------------------------------------------
import Thailand_8N_9D_Page from "../pages/packages/Thailand/Thailand_8N_9D_Page";
import BangkokPattaya_8N_9D_Page from "../pages/packages/Thailand/BangkokPattaya_8N_9D_Page";
//Vietnam URL
import Vietnam_7N_8D_Page from "../pages/packages/Vietnam/Vietnam_7N_8D_Page";
import Vietnam_7N_8D_Kolkata_Page from "../pages/packages/Vietnam/Vietnam_7N_8D_Kolkata_Page";
//Egypt Route--------------------------------------------------
import Egypt_8N_9D_Page from "../pages/packages/Egypt/Egypt_8N_9D_Page";
//Arunachal URL-----------------------------------------------
import ArunachalTourGuwahati_15N_16D_Page from "../pages/packages/Arunachal_Pradesh/ArunachalTourGuwahati_15N_16D_Page";
import ArunachalTourMumbai_14N_15D_Page from "../pages/packages/Arunachal_Pradesh/ArunachalTourMumbai_14N_15D_Page";
import ArunachalTourKolkata_9N_10D_Page from "../pages/packages/Arunachal_Pradesh/ArunachalTourKolkata_9N_10D_Page";
import NorthEastIndiaTour_10N_11D_Page from "../pages/packages/Arunachal_Pradesh/NorthEastIndiaTour_10N_11D_Page";
import TawangBumlaPass_7N_8D_Page from "../pages/packages/Arunachal_Pradesh/TawangBumlaPass_7N_8D_Page";
import AssamArunachalBangalore_11N_12D_Page from "../pages/packages/Arunachal_Pradesh/AssamArunachalBangalore_11N_12D_Page";
//sundarban URL-----------------------------------------------
import SundarbanTourPage_Sonarbangla from "../pages/packages/Sundarban/SundarbanTourPage_Sonarbangla";
import SundarbanTourPage_Kolkata from "../pages/packages/Sundarban/SundarbanTourPage_Kolkata";
import Sandakphu_trekking_Page from "../pages/packages/Sandakphu/Sandakphu_trekking_Page";
import Sandakphu_land_rover_Page from "../pages/packages/Sandakphu/Sandakphu_land_rover_Page";

class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <HomePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/home"
            render={props => <HomePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/contact"
            render={props => <ContactPage {...props} key={Date.now()} />}
          />
           <Route
            exact
            path="/blog"
            render={props => <BlogPage {...props} key={Date.now()} />}
          />
              <Route
            exact
            path="/gallery"
            render={props => <GalleryPage {...props} key={Date.now()} />}
          />
           <Route
            exact
            path="/bhutan-inside"
            render={props => <BhutanGalleryInsidePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/purchase"
            render={props => <PurchasePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/cancellation-policy"
            render={props => <PolicyPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/refund"
            render={props => <RefundPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/visa-consutancy-services"
            render={props => <VisaService {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/about"
            render={props => <AboutPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/adventure-tour-ladakh-to-sikkim"
            render={props => <AdventureTourLadakhToSikkimPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-blog"
            render={props => <Bhutan_blog_Page {...props} key={Date.now()} />}
          />
          {/* Bhutan start */}
          <Route
            exact
            path="/bhutan-package-tour-from-bhopal"
            render={props => <Bhutan_Bhopal_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-nashik"
            render={props => <Bhutan_Nashik_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-nagpur"
            render={props => <Bhutan_Nagpur_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-group-tour-packages-from-bagdogra-airport"
            render={props => <Bagdogra_7N_8D_Group_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-DMC-b2b-packages-for-Bhutan-tour-from-bagdogra-airport-via-phuentsholing"
            render={props => <Bhutan_B2B_7N_8D_Bagdogra_Airport_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-bangalore-with-flight-from-kolkata-airport-guwahati-airport"
            render={props => <BangaloreToBhutanviaKolkataAirportPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-jaigaon"
            render={props => <Bhutan_TourJaigaonPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-njp-station-siliguri-bus-stand"
            render={props => <Bhutan_TourNjpSiliguriPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-phuentsholing"
            render={props => <Bhutan_TourPhuentsholingPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-hasimara-new-alipurduar"
            render={props => <Bhutan_TourHasimaraNoqPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-hotel-six-senses-package-tour-with-direct-flight-from-india"
            render={props => <Bhutan_TourSixsenseDirectFlightIndiaPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-luxury-package-tour-with-six-senses-hotel"
            render={props => <Bhutan_TourSixsenseHotelPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-package-tour-from-guwahati-airport"
            render={props => <Bhutan_TourGuwahatiPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc"
            render={props => <Bhutan_B2B_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package"
            render={props => <BhutanTourPackagePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/4N-5D-FIFO-package"
            render={props => <FIFO_4N_5D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/5N-6D-FIFO-package"
            render={props => <FIFO_5N_6D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/6N-7D-FIFO-package"
            render={props => <FIFO_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/mumbai-special-chartered-flight"
            render={props => <FIFO_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/8N-9D-FIFO-package"
            render={props => <FIFO_8N_9D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/10N-11D-FIFO-package"
            render={props => <FIFO_10N_11D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-from-delhi"
            render={props => <DelhiToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-mumbai"
            render={props => <MumbaiToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-kolkata"
            render={props => <KolkataToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-chennai"
            render={props => <ChennaiToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-hyderabad"
            render={props => <HyderabadToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-ahemdabad"
            render={props => <AhemdabadToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-bangalore"
            render={props => <BangaloreToBhutanPackage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-pune"
            render={props => <PuneToBhutanPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-bagdogra"
            render={props => <Bagdogra_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bhutan-tour-package-from-surat"
            render={props => <SuratToBhutanPage {...props} key={Date.now()} />}
          />
          {/* Meghalaya Route */}
          <Route
            exact
            path="/kaziranga-package-tour-from-guwahati-with-elephant-safari-jeep-safari"
            render={props => <KazirangaTourWithElephantSafari_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/meghalaya-tour-package"
            render={props => <MeghalayaTourPackagePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Assam-Meghalaya-5N-6D-Tour-Itinerary"
            render={props => <Meghalaya_5N_6D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Meghalaya-Assam-Tour-Packages-6N-7D"
            render={props => <Meghalaya_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/shillong-meghalaya-package-tour-from-guwahati"
            render={props => <ShillongMeghalayaTour_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati"
            render={props => <KazirangaShillongMeghalayaTour_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Meghalaya-Kaziranga-Tour-Packages-7N-8D"
            render={props => <Meghalaya_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/meghalaya-arunachal-pradesh-package-tour-from-guwahati"
            render={props => <MeghalayaArunachalTour_12N_13D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/meghalaya-shillong-cherrapunji-package-tour-from-guwahati-with-garo-hills-tura-wari-chora"
            render={props => <MeghalayaShillongCherrapunjeeTour_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/meghalaya-package-tour-with-wari-chora-tura-south-garo-hills-mawsynram-jowai-mawlynnong-dawki"
            render={props => <Meghalaya_WariChora_Tura_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/assam-meghalaya-arunachal-pradesh-package-tour-from-guwahati"
            render={props => <Assam_Meghalaya_Arunachal_19N_20D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kaziranga-shillong-cherrapunjee-group-tour-packages-from-guwahati"
            render={props => <KazirangaShillongCherrapunjeeGroupTour_8N_9D_Page {...props} key={Date.now()} />}
          />
          {/* North East 2nd lot sukantada new page end */}
          <Route
            exact
            path="/arunachal-pradesh-package-tour-from-guwahati"
            render={props => <ArunachalTourGuwahati_15N_16D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/arunachal-pradesh-package-tour-from-mumbai"
            render={props => <ArunachalTourMumbai_14N_15D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/arunachal-pradesh-package-tour-from-kolkata"
            render={props => <ArunachalTourKolkata_9N_10D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Arunachal-Pradesh-Tawang-Tour-Package-10N-11D"
            render={props => <Arunachal_10N_11D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Arunachal-Pradesh-11N-12D-Tour-Package"
            render={props => <Arunachal_11N_12D_Page {...props} key={Date.now()} />}
          />
           <Route
            exact
            path="/tawang-bumla-pass-package-tour-from-guwahati"
            render={props => <TawangBumlaPass_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/assam-arunachal-pradesh-package-tour-from-bangalore"
            render={props => <AssamArunachalBangalore_11N_12D_Page {...props} key={Date.now()} />}
          />
          {/* silkroute start */}
          <Route
            exact
            path="/north-east-india-package-tour-from-guwahati-airport"
            render={props => <NorthEastIndiaTour_10N_11D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/silk-route-package-tour-with-gangtok-north-sikkim-from-bagdogra-airport-njp-station"
            render={props => <NorthSikkim_Silkroute_8N_9D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Sikkim-Gangtok-Darjeeling-Tour-Package"
            render={props => <Sikkim_Darjeeling_4N_5D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/sikkim-tour-packages-from-bagdogra-njp-siliguri"
            render={props => <Sikkim_Tour_9N_10D_bagdogra_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/sikkim-tour-package"
            render={props => <Sikkim_5N_6D_Page {...props} key={Date.now()} />}
          />
           <Route
            exact
            path="/silk-route-package-tour-from-kolkata"
            render={props => <Silkroute_TourKolkataPage {...props} key={Date.now()} />}
          />
           {/* silkroute end */}

          {/* Andaman Package Tour */}
          <Route
            exact
            path="/Andaman-6N-7D-Tour-Package"
            render={props => <Andaman_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/andaman-package-tour-from-kolkata"
            render={props => <Andaman_7N_8D_Package_Tour_Kolkata_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/andaman-honeymoon-package-tour-from-kolkata"
            render={props => <Andaman_5N_6D_Honeymoon_Tour_Page {...props} key={Date.now()} />}
          />
          {/* kashmir package tour */}
          <Route
            exact
            path="/tulip-festival-special-kashmir-group-tour-packages-from-kolkata-by-train"
            render={props => <Kashmir_12N_13D_Tulip_Group_Tour_Kolkata_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Kashmir-Tour-Package-from-Srinagar-Airport"
            render={props => <Kashmir_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg"
            render={props => <Kashmir_8N_9D_Doodhpathri_Yusmarg_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-offbeat-package-tour-with-gurez-valley"
            render={props => <Kashmir_7N_8D_Gurez_valley_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg"
            render={props => <Kashmir_7N_8D_Sinthan_Top_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar"
            render={props => <Kashmir_7N_8D_Vaishno_Devi_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-tulip-garden-festival-package-tour"
            render={props => <Kashmir_7N_8D_Tulip_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg"
            render={props => <Kashmir_7N_8D_Tour_From_Kolkata_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-surat"
            render={props => <Kashmir_7N_8D_Tour_From_Surat_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-chennai"
            render={props => <Kashmir_7N_8D_Tour_From_Chennai_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-bangalore"
            render={props => <Kashmir_7N_8D_Tour_From_Bangalore_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-pune"
            render={props => <Kashmir_7N_8D_Tour_From_Pune_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-mumbai"
            render={props => <Kashmir_7N_8D_Tour_From_Mumbai_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-delhi"
            render={props => <Kashmir_4N_5D_Tour_From_Delhi_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-from-ahmedabad"
            render={props => <Kashmir_4N_5D_Tour_From_Ahmedabad_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/jammu-kashmir-family-tour-packages"
            render={props => <Kashmir_7N_8D_Family_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/kashmir-package-tour-with-amritsar-katra-vaishno-devi-pahalgam-gulmarg-srinagar-sonmarg"
            render={props => <Kashmir_9N_10D_Vaishno_Devi_Amritsar_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Kashmir-Leh-Ladakh-Tour-Package"
            render={props => <Kashmir_Leh_Ladakh_Tour_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Ladakh-Tour-Package"
            render={props => <Ladakh_6N_7D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Leh-Ladakh-Kargil-Package-Tour"
            render={props => <Leh_Ladakh_Kargil_8N_9D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Leh-Ladakh-Turtuk-Package-Tour-from-Mumbai-with-Flight"
            render={props => <Leh_Ladakh_Turtuk_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/Thailand-Tour-Package-from-kolkata-Airport"
            render={props => <Thailand_8N_9D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/bangkok-pattaya-thailand-holiday-package-tour-from-india"
            render={props => <BangkokPattaya_8N_9D_Page {...props} key={Date.now()} />}
          />

          <Route
            exact
            path="/Vietnam-Tour-Package"
            render={props => <Vietnam_7N_8D_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/vietnam-package-tour-from-kolkata"
            render={props => <Vietnam_7N_8D_Kolkata_Page {...props} key={Date.now()} />}
          />
          {/* ----------------Egypt Tour Package--------------------- */}
          <Route
            exact
            path="/egypt-package-tour-from-kolkata-8night-9days"
            render={props => <Egypt_8N_9D_Page {...props} key={Date.now()} />}
          />
          {/* ----------------Sundarban Tour Package--------------------- */}
          <Route
            exact
            path="/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla"
            render={props => <SundarbanTourPage_Sonarbangla {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/sundarban-package-tour-from-kolkata"
            render={props => <SundarbanTourPage_Kolkata {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/sandakphu-trekking-package-booking"
            render={props => <Sandakphu_trekking_Page {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/sandakphu-land-rover-package-tour-booking"
            render={props => <Sandakphu_land_rover_Page {...props} key={Date.now()} />}
          />
          {/* <Route
            exact
            path="/customer-reg"
            render={props => <CustomerRegistrationPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/provider-reg"
            render={props => <ProviderRegistrationPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/login"
            render={props => <LoginPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/verifyotp/:mobileNo"
            render={props => <VerifyOTPPage {...props} key={Date.now()} />}
          />  */}
          {/* <Route
            exact
            path="/login"
            render={props => <UserOnboardPage {...props} key={Date.now()} />}
          />*/}

        </Switch>
      </Fragment>
    );
  }
}

export default AppRoute;
