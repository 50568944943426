import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Meghalaya/InclusionExclusion_KazirangaShillongCherrapunji_8n_9d";

class KazirangaShillongCherrapunjeeGroupTour_8N_9D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,

    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919830889567&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };


  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Package-from-Guwahati.webp";
    const packageTopBanner2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Tour-Package-from-Guwahati.webp";
    const sightseen1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/KAZIRANGA-Cherrapunjee-JUNGLE-SAFARI.webp";
    const sightseen2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunji-package-tour.webp";
    const sightseen3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/ASSAM-CULTURE.webp";
    const sightseen4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CULTURE.webp";

    const navDay1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Indian-one-horned-rhinoceroses-KAZIRANGA.webp";
    const navDay2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Western-Zone-of Kaziranga-National-Park-Jungle-Safari.webp";
    const navDay3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Sholling-Barapani-Lake-Umiam-Lake.webp";
    const navDay4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/JOWAI-SHILLONG-Krang-Suri-Waterfalls.webp";
    const navDay5ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/MAWLYNNONG-DAWKI–CHERRAPUNJEE.webp";
    const navDay6ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CHERRAPUNJEE–NONGRIAT-TREK-DOUBLE-DECKER-BRIDGE.webp";
    const navDay7ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/SEVEN-SISTER-WATERFALLS-CHERAPUNJEE.webp";
    const navDay8ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kamakhya-Temple-Guwahati.webp";



    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kaziranga Shillong Cherrapunjee Group Package Tour from Guwahati</strong> with BOM-GAU-BOM Return Flight</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Kaziranga Shillong Cherrapunjee Group Package Tour from Guwahati</strong> with BOM-GAU-BOM Return Flight</center>
                      <hr />
                    </h3>

                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Guwahati
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          kaziranga
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Shillong
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Dawki
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Cherrapunjee
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Kaziranga Shillong Cherrapunjee Group Package</strong>, you have come to the right place!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}><strong>Kaziranga Shillong Cherrapunjee Group Tour Packages</strong> with MUMBAI - GUWAHATI - MUMBAI RETURN FLIGHT <br /><br /> FOR BOOKING <strong>ASSAM MEGHALAYA GROUP PACKAGES</strong> <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480</a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.0vw" }}><strong>Kaziranga Shillong Cherrapunjee Group Tour Packages</strong> with MUMBAI - GUWAHATI - MUMBAI RETURN FLIGHT <br /><br /> FOR BOOKING <strong>ASSAM MEGHALAYA GROUP PACKAGES</strong> <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480</a></h2>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> INDIA'S LARGEST <b>NORTH EAST TOUR OPERATOR</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> INDIA'S LARGEST <b>NORTH EAST TOUR OPERATOR</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Begin an extraordinary journey through the captivating landscapes of Northeast India with our <strong>Kaziranga Shillong Cherrapunjee group package tour from Guwahati</strong>. This journey offers a perfect blend of culture, nature, and thrilling experiences, all wrapped in the vibrant beauty of this region. Whether you&rsquo;re a nature lover or an adventure seeker, this tour will captivate you.<br /><br /></p>
                      <p>Our <strong>Kaziranga Shillong Cherrapunjee group tour packages from Guwahati</strong> will take you through stunning landscapes. Explore the natural treasures of Kaziranga National Park in Assam and the enchanting terrains of Meghalaya. From misty hills to hidden caves, waterfalls, sacred forests, and picturesque lakes, this tour offers a slice of untouched beauty, making it an ideal choice for nature enthusiasts.<br /><br /></p>
                      <p>Start your adventure in Kaziranga, a UNESCO World Heritage Site known for its rich biodiversity. Experience the thrill of the <strong>Kaziranga elephant safari</strong> as you journey through dense forests and grasslands, spotting exotic wildlife, including the iconic Great Indian One-Horned Rhinoceros, Bengal tigers, elephants, and leopards. For even more excitement, hop on a jeep safari to explore the varied terrain of this wildlife sanctuary.<br /><br /></p>
                      <p>The tour continues with a serene cruise on the Brahmaputra River, where you can relax and watch the sunset over the tranquil waters. From here, journey into Meghalaya, also known as the "Scotland of the East." Lush evergreen forests, stunning waterfalls, and the famous Living Root Bridges await, providing a unique experience for travelers. This <strong>North East group package tour from Guwahati</strong> will let you discover hidden gems and awe-inspiring landscapes.<br /><br /></p>
                      <p>In Shillong and Cherrapunjee, you&rsquo;ll explore the mysteries of underground caves and witness nature&rsquo;s architectural wonders. The breathtaking <strong>9 Days Shillong Cherrapunjee Pongtung Kaziranga group package</strong> offers thrilling spelunking adventures, treks through dense forests, and visits to the renowned Living Root Bridges, all while immersing yourself in the unique culture of the Khasi tribe.<br /><br /></p>
                      <p>Throughout the journey, experience the local culture and cuisine, with delicious dishes like Jadoh and Doh Khlieh. The warm hospitality of the Northeast will leave you with unforgettable memories. This <strong>Guwahati Kaziranga Shillong Cherrapunjee group tour packages</strong> is ideal for those seeking a mix of adventure and tranquility.<br /><br /></p>
                      <p>Join our <strong>Kaziranga Shillong Cherrapunjee group tour packages from Guwahati with Flight</strong> for an all-inclusive, hassle-free experience. With <strong>8 Nights and 9 Days</strong> to explore, our <strong>Guwahati Kaziranga Shillong Cherrapunjee group package tour</strong> will leave you enchanted by the beauty of Northeast India.<br /><br /></p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FLIGHT DETAILS FOR <strong>MUMBAI TO ASSAM + MEGHALAYA GROUP TOUR</strong> STARTING FROM BAGDOGRA AIRPORT</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FLIGHT DETAILS FOR <strong>MUMBAI TO ASSAM + MEGHALAYA GROUP TOUR</strong> STARTING FROM BAGDOGRA AIRPORT</h4>
                </div>
                <div className="card-body">
                  <p>This <strong>Kaziranga Shillong Cherrapunjee Package Tour</strong> includes many attractions in Assam and Meghalaya. Here are some of the places we'll explore :</p>
                  <p>Kaziranga National Park, Shillong City, Umium Lake, Elephanta Falls, Shillong Peak, Spread Eagle Falls, Ward's Lake, Cathedral Catholic Church, Cherrapunjee, Seven Sister Falls, Mawsmai Cave, Nohkalikai Falls, Eco Park, Double Decker Living Root Bridge, Mawsynram, Jowai, Krang Suri Falls, Thadlaskein Lake, Stone Bridge, Tura, Mawlynnong, Dawki, Balpakram National Park, Kyllang Rock.</p>
                  <br /><br />

                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>AIRLINES</th>
                          <th>SECTOR</th>
                          <th>TRAVEL DATE</th>
                          <th>DEP TIME</th>
                          <th>ARR TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>6E 228</td>
                          <td>BOM - GAU</td>
                          <td>03rd NOV'24</td>
                          <td>0840 hrs</td>
                          <td>1130 hrs</td>
                        </tr>
                        <tr>
                          <td>6E 6342</td>
                          <td>GAU - BOM</td>
                          <td>11th NOV'24</td>
                          <td>1700 hrs</td>
                          <td>2045 hrs</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>ASSAM & MEGHALAYA GROUP TOUR FROM MUMBAI WITH FLIGHT</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ASSAM & MEGHALAYA GROUP TOUR FROM MUMBAI WITH FLIGHT</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Assam & Meghalaya Premium Group Tour Origination Point</strong></td>
                          <td>Chhatrapati Shivaji Maharaj International Airport [BOM]</td>
                        </tr>
                        <tr>
                          <td>Duration of <strong>Assam & Meghalaya Premium Group Tour Package</strong></td><td>8 Nights / 9 Days</td>
                        </tr>
                        <tr>
                          <td>Destinations Covered During <strong>North East Group Tour</strong></td><td>Kaziranga 2N | Shillong 2N | Pongtung 1N | Cherrapunji 2N | Guwahati 1N</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Assam & Meghalaya Premium Group Tour Date</strong></td>
                          <td>
                            <strong>03 <sup>rd</sup> NOV 2024</strong></td>
                        </tr>
                        <tr><td>8 Nights 9 Days <strong>North East Group Tour Package Cost from Guwahati</strong> with Mumbai - Guwahati - Mumbai Direct Non-Stop Flights</td><td><strong>₹ </strong>75,000/P</td></tr>
                        <tr><td>Pickup & Drop Point for <strong>North East Group Tour</strong></td><td>Guwahati Airport [GAU]</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>PLACES OF ATTRACTIONS IN <strong>KAZIRANGA SHILLONG CHERRAPUNJEE GROUP TOUR PACKAGES FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PLACES OF ATTRACTIONS IN <strong>KAZIRANGA SHILLONG CHERRAPUNJEE GROUP TOUR PACKAGES FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <p>This <strong>Kaziranga Shillong Cherrapunjee group tour package from Guwahati</strong> offers the opportunity to explore numerous attractions across Assam and Meghalaya. Some of the key destinations include Kaziranga National Park, Shillong City, Umiam Lake, Elephant Falls, Shillong Peak, Spread Eagle Falls, Ward's Lake, Cathedral Catholic Church, and Cherrapunji. You'll also visit Seven Sister Falls, Mawsmai Cave, Nohkalikai Falls, Eco Park, the Double Decker Living Root Bridge, Mawsynram, Jowai, Krang Suri Falls, Thadlaskein Lake, the Stone Bridge, Tura, Mawlynnong, Dawki, Balpakram National Park, and Kyllang Rock. <br /><br /></p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DAY WISE SUMMARY OF <strong>KAZIRANGA SHILLONG CHERRAPUNJEE GROUP TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DAY WISE SUMMARY OF <strong>KAZIRANGA SHILLONG CHERRAPUNJEE GROUP TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR ITINERARY</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                FLY TO GUWAHATI AND PROCEED TO KAZIRANGA NATIONAL PARK
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span> KAZIRANGA JUNGLE SAFARI</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span> KAZIRANGA TO SHILLONG TRANSFERS</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>SHILLONG TO MAWSYNRAM EXCURSION</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>SHILLONG TO JOWAI & TRANSFER TO PONGTUNG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>SHILLONG TO DAWKI, MAWLYNNONG & TRANSFER CHERRAPUNJEE</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>CHERAPUNJEE LOCAL SIGHTSEEING</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>CHERRAPUNJEEE TO GUWAHATI</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 9 → </b>
                              <span>TRANSFER TO GUWAHATI AIRPORT</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST TIME TO BOOK A <strong>SHILLONG CHERRAPUNJEE GROUP TOUR PACKAGES FROM GUWAHATI</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO BOOK A <strong>SHILLONG CHERRAPUNJEE GROUP TOUR PACKAGES FROM GUWAHATI</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>If you're wondering about the ideal time to book your <strong>Shillong Cherrapunjee group tour</strong>, Adorable Vacation recommends planning your trip during the monsoon season. This period offers a unique experience in Cherrapunjee and Mawsynram, unlike any other place in India. While Kerala may be known for its monsoon, Mawsynram, in Meghalaya, holds the Guinness World Record for being the wettest place on earth, receiving an annual average rainfall of about 11,871mm. Experiencing the monsoon here is truly unforgettable.<br /><br /></p>
                  <p>During the monsoon, Cherrapunjee and Mawsynram transform into a lush paradise, with vibrant greenery, overflowing lakes, and majestic waterfalls. It's the perfect season for honeymooners, rain enthusiasts, and adventure seekers. Imagine sitting by a window, sipping tea or coffee, and listening to the roaring rains of Mawsynram. The deafening sound of the rainfall brings a sense of serenity and wonder. The rains here have a captivating beauty that awakens both nature and travelers. If you wish to witness Shillong, Cherrapunjee, and Mawsynram in their rain-soaked glory, book your <strong>Shillong Cherrapunjee group package tour from Guwahati</strong> at a special price between July and September. This season offers a once-in-a-lifetime experience.<br /><br /></p>
                  <p>For those looking to explore the breathtaking landscapes, the <strong>Shillong Cherrapunjee group package tour from Guwahati</strong> is ideal during the monsoon. The rain-washed surroundings of Shillong and Cherrapunjee offer stunning views, while Mawsynram becomes an enchanting destination for nature lovers. Don't miss out on booking your <strong>Shillong Cherrapunjee group tour packages</strong> this monsoon. Whether it's for a peaceful escape or an adventurous outing, the <strong>Shillong group tour packages</strong> promises a remarkable journey through the rain-drenched beauty of Northeast India.<br /><br /></p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST TIME TO TRAVEL KAZIRANGA ?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO TRAVEL KAZIRANGA ?</h4>
                </div>
                <div className="card-body">
                  <p>Winter is the best time to  visit Kaziranga National Park. During winter i.e. anytime between ends of November to first week of April you can travel Kaziranga. Winter in North East begins in early November and goes till end of March. The winters in these regions are soothing and travel friendly. The temperature in Kaziranga during this winter ranges from 24 degrees Celsius maximum to 6 degrees Celsius minimum. The long stretched vast swampy land of Kaziranga among winter mist appears majestic when you roam about this wonderland on an elephant safari. Exploring Kaziranga is best on elephant back as it don't follow any fixed path and takes you through some of the places where jeep cannot reach. Jeep Safari during winter holidays is also wonderful. Book an open hood jeep and explore some of the best ranges which offer majestic wildlife of Kaziranga from a close distance. Though Jeep safari can be little bit dusty but enjoyable. <br /><br />
                    Summer is also a good time to explore Kaziranga, but hot and humid temperature and make you uncomfortable. <strong>Monsoon is not the ideal time to book any Kaziranga Package</strong> as this vast open land receives plenty of rains and every year gets flooded by mighty Brahmaputra River. Human and wild life becomes standstill during monsoon. So it is better to avoid <strong>Kaziranga tour plan</strong> between first of June to end of September. <br /><br /></p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR THE <strong>KAZIRANGA SHILLONG CHERRAPUNJI GROUP PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR THE <strong>KAZIRANGA SHILLONG CHERRAPUNJI GROUP PACKAGE TOUR</strong></h4>
                </div>
                {/* <br />
                  <div className="content-desktop">
                    <center><img src={sightseen4ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br/> */}
                <br />
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Places</th>
                          <th>Distances</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Guwahati to Kaziranga Distance - By Road</td>
                          <td>193.1 Kms</td>
                        </tr>
                        <tr>
                          <td>Kaziranga to Shillong Distance - By Road</td>
                          <td>252.2 Kms</td>
                        </tr>
                        <tr>
                          <td>Guwahati to Shillong Distance - By Road</td><td>98.2 km</td>
                        </tr>
                        <tr>
                          <td>Shillong to Cherrapunji Distance - By Road</td>
                          <td>54 Kms</td>
                        </tr>
                        <tr>
                          <td>Shillong to Mawlynnong Distance - By Road</td>
                          <td>73 Kms</td>
                        </tr>
                        <tr>
                          <td>Shillong to Dawki Distance - By Road</td>
                          <td>81.8 Kms</td>
                        </tr>
                        <tr>
                          <td>Kaziranga to Cherrapunji Distance - By Road</td>
                          <td>300.8 Kms</td>
                        </tr>
                        <tr>
                          <td>Guwahati to Cherrapunji Distance - By Road</td>
                          <td>147.4 Kms</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h3>PRICE RANGE FOR <strong>KAZIRANGA ELEPHANT SAFARI</strong><br /></h3>
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Citizen</th>
                          <th>Ranges</th>
                          <th>Rate/Hour</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Indian</td>
                          <td>Western Range (Bagori)</td>
                          <td>INR 1,000</td>
                        </tr>
                        <tr>
                          <td>Foreigner</td>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2,100</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p><br /><span>NOTE:</span> Elephant Safari at the Kohora Range (Central) in Kaziranga National Park is reserved only for Foreign Nationals and VIPs from India while the Bagori Safari Range (Western) offers spectacular Elephant Rides for Indian Nationals.<br /><br /></p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>TIPS FOR KAZIRANGA NATIONAL PARK JEEP SAFARI / ELEPHANT SAFARI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>TIPS FOR KAZIRANGA NATIONAL PARK JEEP SAFARI / ELEPHANT SAFARI</strong></h4>
                </div>

                <div className="card-body">
                  <p>When planning a Jeep or Elephant Safari in Kaziranga National Park, here are a few essential tips to keep in mind:<br /><br /></p>
                  <ul><li><b>Carry Valid Identification</b>: Make sure to have a valid ID like a passport, driving license, or Aadhar card with you, as it is required for entry into the park for security purposes.<br /><br /></li>
                    <li><b>Adhere to Park Rules</b>: Kaziranga National Park enforces strict regulations, including no littering, no smoking, and no carrying of weapons. Abide by these rules to ensure a smooth and safe experience.<br /><br /></li>
                    <li><b>Choose the Best Time to Visit</b>: The ideal period for a safari is between November and April, as the weather is pleasant and wildlife is more visible. Avoid visiting during the monsoon season when the park is closed due to heavy rainfall.<br /><br /></li>
                    <li><b>Dress for the Safari</b>: Wear comfortable, lightweight clothing in neutral colors such as brown, green, or khaki to blend in with the environment. Comfortable shoes are a must, especially for the Elephant Safari.<br /><br /></li>
                    <li><b>Bring Essential Items</b>: Be sure to pack sunscreen, insect repellent, a hat or cap, sunglasses, and a water bottle. A camera with a good zoom lens is also recommended to capture the park's wildlife and scenery.<br /><br /></li>
                    <li><b>Hire a Local Guide</b>: It&rsquo;s beneficial to hire a local guide who is familiar with the park's wildlife and terrain, as they can enhance your experience and help you spot animals.<br /><br /></li>
                    <li><b>Practice Patience</b>: Wildlife sightings can be unpredictable. Stay calm, respect the animals' natural habitat, and avoid disturbing them during your safari.<br /><br /></li>
                  </ul>

                  <p>By keeping these points in mind, you'll have a more enjoyable and responsible safari experience in Kaziranga National Park.<br /><br /></p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>

              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h3>
                      DAY WISE ITINERARY FOR <span><strong> KAZIRANGA SHILLONG CHERRAPUNJEE GROUP TOUR PACKAGES FROM MUMBAI </strong> STARTING FROM BAGDOGRA AIRPORT</span>
                    </h3>
                    <h4>8 NIGHTS / 9 DAYS <strong>Kaziranga Shillong Cherrapunjee Group Tour Itinerary</strong> from Adorable Vacation</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h4> DAY 01: FLY TO GUWAHATI FROM MUMBAI – TRANSFER TO KAZIRANGA NATIONAL PARK [198 KM / 4 HRS] </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Meet and greet on arrival at Guwahati Airport and Proceed towards Kaziranga, covering an area of 430 sq-km, is inhabited by the world's largest population of one-horned rhinoceroses, as well as many mammals, including tigers, elephants, panthers, and bears, and thousands of birds. In 1985, it was declared a World Heritage Site by UNESCO for its unique natural environment. In the evening, you can enjoy Orchid Park for a cultural program in Kohora. <b>Overnight Stay at Kaziranga National Park</b>.</p>
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h4>DAY 02: JUNGLE ACTIVITIES AT KAZIRANGA [SAFARI INCLUDED]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>After an early wake-up, we will take you to the riding point for Kaziranga Elephant Safari in the Western Range. Watch the famous One-Horn Rhino in the jungle from stone throwing distance. After the elephant safari, return to the Resort. After lunch, drive to Kaziranga Central Range for the Kaziranga Jeep Safari. The Central zone of the Kaziranga National Park is the prime zone for eco-tourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The Kaziranga Jungle Safari offers you some spectacular sightings of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with adorable baby elephants to big old elephants in the herd, and many other species of the wild flora and fauna of this tourism zone. <b>Overnight Stay at Kaziranga National Park</b>.<br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 03: TRANSFER FROM KAZIRANGA TO SHILLONG [252.2 KMS / 6 HRS]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>After Breakfast, Proceed towards Shillong. Shillong, also known as ‘The Scotland of the East’ is the capital city of Meghalaya. En route, you will visit the largest manmade lake in Northeast India –Umium Lake. Reaching Shillong. Proceed for the local sightseeing visit Ward’s Lake located at the heart of the city which is popular for its garden walks and boating, Lady Hydari Park & walk into Police Bazaar. <b>Overnight Stay at Shillong</b>.<br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 04: - EXCURSION TOWARDS MAWSYNRAM [THE WETTEST PLACE ON EARTH] </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>After a breakfast drive towards Mawsynram, Enroute visits Elephanta Falls & Shillong Peak (Depending on Army Permission) situated on the outskirts of the city. Visit Mawphlang Sacred Forest, Mawjymbuin Cave (Mawsynram cave). After sightseeing in the evening back to Shillong, visit the Cathedral Catholic Church - In the heart of the city, there stands a beautiful cathedral of Roman Catholics dedicated to Mary's Help of Christians. <b>Overnight stay at Shillong.</b><br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 05: TRIP TO JOWAI & TRANSFER TO PONGTUNG </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>In the morning after breakfast, proceed for a day excursion trip to Jowai, eastern Meghalaya - Jaintia Hills. En route En route you will visit Laitlum- Laitlum canyon is one of the beautiful destinations with its green lush hills and experiencing life above the clouds. Explore Thadlaskein Lake, Phe Phe falls and Krang Suri falls. Also, enjoy your route which is very beautiful & scenic. Evening drive to Pongtung. Overnight Stay at Pongtung.<br /><br /></p>

                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 06: VISIT DAWKI, MAWLYNNONG & TRANSFER TO CHERRAPUNJEE</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>Wake up early and have your breakfast and drive to Dawki. Dawki is the gateway to Bangladesh where major export and import transactions take place. It is a splendid drive of 45 kilometers passing through deep gorges and ravines, which is certainly a thrilling experience. On approaching Dawki is the Umngot river, The Umngot river is the natural boundary between Ri Pnar or Jaintia Hills with Hima Khyrim of Khasi hills over which a single-span suspension bridge was constructed. Later drive to Mawlynnong. Today you can explore the cleanest village Mawlynnong in the world. You can take the skywalk. Spectacular view of the living Root Bridge. Evening drive to Cherrapunjee. Overnight Stay at Cherrapunjee.<br /><br /></p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 07: CHERRAPUNJEE LOCAL SIGHTSEEING</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, proceed to Visit Arwah Caves, Nohkalikai Falls, Wakaba Falls, Garden of Caves, Mawsmai Caves, Eco Park, Seven Sisters falls and the Thangkharang Park. Overnight stay at Cherrapunjee.<br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 08: CHERRAPUNJEEE TO GUWAHATI [145 KM / 5 HRS]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, proceed towards Guwahati, enroute visit Kamakhya Temple for the darshan of Maa Kamakhya & Kalakhetra Jagran. Overnight stay in Guwahati.<br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day9"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 09: CHERISH YOUR MEMORIES</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>In the morning after breakfast check-out from the hotel visit Umanada Temple (Peacock Island) and Assam State Museum & after authentic Assamese lunch transfer to Guwahati airport with sweet memories & cherish your memories forever....<br /><br /></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />

              <br />
              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {<InclusionExclusion />}
                  </div>
                </div>
              </div>

              {/* inclusion End  */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE BEST SIGHTSEEING PLACES TO EXPLORE IN <strong>KAZIRANGA, SHILLONG, CHERRAPUNJEE GROUP TOUR FROM GUWAHATI?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE BEST SIGHTSEEING PLACES TO EXPLORE IN <strong>KAZIRANGA, SHILLONG, CHERRAPUNJEE GROUP TOUR FROM GUWAHATI?</strong></h4>
                </div>
                <div className="card-body">
                  <p>For an unforgettable journey through Northeast India, exploring Kaziranga, Shillong, and Cherrapunjee offers some of the most remarkable sightseeing experiences. Here are some must-visit destinations to include in your itinerary:<br /><br /></p>
                  <h4><b>Kaziranga</b><br /></h4>
                  <ol>
                    <li><b>Kaziranga National Park</b>: Known for its population of one-horned rhinoceroses, this park is a UNESCO World Heritage site and a haven for endangered wildlife.<br /><br /></li>
                    <li><b>Kaziranga Orchid and Biodiversity Park</b>: This park showcases over 500 species of orchids, making it a paradise for plant enthusiasts and nature lovers.<br /><br /></li>
                    <li><b>Panbari Reserve Forest</b>: A serene spot for birdwatching and peaceful nature walks, this forest offers a tranquil escape from the hustle and bustle.<br /><br /></li>
                    <li><b>Deoparbat Ruins</b>: These ancient archaeological ruins, dating back to the 9th century, are a must-visit for history enthusiasts.<br /><br /></li>
                    <li><b>Hoollongapar Gibbon Sanctuary</b>: The only sanctuary in India for the Hoolock Gibbon, this site is perfect for observing these rare and playful apes. <br /><br /><br /></li>
                  </ol>

                  <h4><b>Shillong</b><br /></h4>
                  <ol>
                    <li><b>Umiam Lake</b>: Surrounded by hills, this man-made lake is ideal for boating, kayaking, and picnicking.<br /><br /></li>
                    <li><b>Shillong Peak</b>: The highest point in Shillong provides panoramic views of the city and surrounding landscapes.<br /><br /></li>
                    <li><b>Ward&rsquo;s Lake</b>: A charming lake located in the heart of the city, perfect for a relaxing walk or a leisurely boat ride.<br /><br /></li>
                    <li><b>Don Bosco Museum</b>: This cultural museum highlights the rich heritage of Northeast India through vibrant exhibits.<br /><br /></li>
                    <li><b>Elephant Falls</b>: A three-tiered waterfall offering scenic beauty and a popular spot for nature lovers. <br /><br /><br /></li>
                  </ol>

                  <h4><b>Cherrapunjee</b><br /></h4>
                  <ol>
                    <li><b>Nohkalikai Falls</b>: One of the tallest waterfalls in India, it offers breathtaking views and is perfect for adventure seekers.<br /><br /></li>
                    <li><b>Mawsmai Cave</b>: Explore these fascinating limestone caves that offer a thrilling underground experience.<br /><br /></li>
                    <li><b>Seven Sisters Falls</b>: Named after the seven Northeastern states, this stunning waterfall is a must-see natural wonder.<br /><br /></li>
                    <li><b>Thangkharang Park</b>: This park provides sweeping views of the Bangladesh plains and the surrounding Khasi hills.<br /><br /></li>
                    <li><strong>Dainthlen Falls</strong>: Nestled in lush greenery, this beautiful waterfall is a photographer's dream and a peaceful retreat for nature lovers.<br /><br /></li>
                  </ol>
                  <p>Incorporating these destinations into your Kaziranga, Shillong, and Cherrapunjee tour guarantees a memorable adventure through the heart of Northeast India. <br /><br /></p>
                </div>
              </div>
              <br />  <br />  <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong>KAZIRANGA SHILLONG CHERRAPUNJEE GROUP TOUR PACKAGES FROM GUWAHATI WITH FLIGHT</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What Is Included In The Kaziranga Shillong Cherrapunjee Group Tour Package From Guwahati With Flight?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>This package includes round-trip flights from Guwahati, accommodation, meals, guided tours, entry fees to major attractions, and transportation between Kaziranga, Shillong, and Cherrapunjee. It also covers safaris in Kaziranga National Park and sightseeing in Shillong and Cherrapunjee.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          What Are The Highlights Of This Tour Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The main highlights of this tour include a safari in Kaziranga National Park, visits to Shillong’s Ward’s Lake and Don Bosco Museum, and exploring Cherrapunjee’s stunning waterfalls like Nohkalikai Falls, as well as the Mawsmai Caves and the Living Root Bridges.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What Is The Best Time To Book The Kaziranga Shillong Cherrapunjee Group Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to book this package is between October and April when the weather is pleasant, and Kaziranga National Park is open for safaris. The monsoon season (June to September) is also ideal for witnessing the waterfalls of Cherrapunjee in full flow.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is The Safari In Kaziranga National Park Included In The Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, the package includes a jeep or elephant safari in Kaziranga National Park, where you can spot the famous one-horned rhinoceros, tigers, elephants, and other wildlife.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          How Many Days Does The Tour Last?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>This tour is typically spread over 8 to 9 days, allowing ample time to explore Kaziranga, Shillong, and Cherrapunjee at a comfortable pace.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Is This Package Suitable For Families And Senior Citizens?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, the package is designed to be family-friendly and is suitable for senior citizens, with carefully planned itineraries and comfortable accommodations.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          What Should I Pack For The Kaziranga Shillong Cherrapunjee Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>It’s advisable to pack light, comfortable clothing, trekking shoes, sunscreen, insect repellent, and rain gear, especially if you are traveling during the monsoon season.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          How Can I Book This Kaziranga Shillong Cherrapunjee Group Package From Guwahati With Flight?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can book this package through various travel agencies or online platforms that specialize in North East India tours. It’s recommended to book in advance, especially during peak travel seasons.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Kaziranga Shillong Cherrapunjee Package Tour <span className="tomato"> Reviews</span>
                        </b>
                      </h3>
                      <p>
                        <i>"Your Happiness is our Priority"</i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-96747-38480">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div >
      </>
    );
  }
}
export default KazirangaShillongCherrapunjeeGroupTour_8N_9D_Package;
