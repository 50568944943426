import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import InclusionExclusion from './InclusionExclusion_kashmir_tulip_group_tour_kolkata';
// import IndividualIntervalsExample from "./carousel-slideKashmirTulipPackage"
// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_12N_13D_Tulip_Group_Tour_Kolkata_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: "tab1",
        };
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-group-tour-package.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-group-tour-package-with-train-tickets.webp";
        const brandImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-group-tour-packages.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/amazing-kashmir-tulip-festival-package-tour.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-holiday-package-booking.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/book-kashmir-tulip-trip-from.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-package-trip.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-tour-and-travel-package-cost.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-tour-packages.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-travel-package-cost.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-tulip-festival-tour-packages.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-tulip-garden-tour.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/srinagar-tulip-garden-tour-packages.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/tulip-festival-kashmir-packages.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/tulip-festival-special-kashmir-tour.webp";
        const sightseen13ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/tulip-kashmir-tour-package.webp";
        const sightseen14ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/unforgettable-kashmir-tulip-experience.webp";
        const sightseen15ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/kashmir-tulip-festival--family-package-tour.webp";
        const PriceImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/bhutan-tour-package-cost-from-bangalore-with-return-flight-via-kolkata-airport.webp";
        // const Flight1ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/bhutan-package-from-guwahati-with-direct-flight.webp";
        // const Flight2ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
        // const Flight3ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/howrah-station.webp";
       
        const navDay3ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/wagah-border.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/golden-temple-tour.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/vaishno-devi.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/srinagar-kashmir-1.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/sonmarg-kashmir-1.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/gulmarg-kashmir-1.webp";
        const navDay9ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/pahalgam-kashmir-2.webp";
        const navDay10ImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/Tulip-Festival-in-Kashmir.webp";
        const CallButtonSoumyaImageURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/talk-to-kashmir-expert.webp";

        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };



        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL =
                ApiURL.Kashmir_Group_Tour_Packages_Kolkata_With_Tulip + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img
                            src={packageTopBannerImageURL}
                            className="mobile-image2"
                            alt="..."
                        />
                    </div>
                </div>

                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Tulip Festival Special Kashmir Group Tour from Kolkata by Train</strong> - BEST DEAL 2025</center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Tulip Festival Special Kashmir Group Tour from Kolkata by Train</strong> - BEST DEAL 2025</center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                        <a href="tel:+91-91470-61467"><img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." /></a>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                Destinations:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Amritsar
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Wagah Border
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Katra
                                                </a>{" "}

                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Vaishno Devi
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Sonmarg
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam
                                                </a>
                                            </div>
                                            {/* <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div> */}
                                        </div>
                                        <br />
                                        <p className="p-2">
                                            <p>If you are looking for a memorable <strong>Kashmir Tour Packages during Tulip Festival</strong> you have come to the right place. Like every year, Adorable Vacation - The <strong>Best DMC of Kashmir</strong> has come up with <strong>Tulip Festival Special Kashmir Group Tour from Kolkata</strong>. This is all inclusive package with <b>AC 3 Tire Train Tickets</b>, <b>3 Star Hotels</b>, All Meals, All Sightseeing, 1 Night Dal Lake Houseboat Stay, Shikara Ride etc. Book this <a href="/kashmir-tulip-garden-festival-package-tour/" target="_blank"><strong>Kashmir Tulip Festival Tour Package</strong></a> and see the Heaven on Earth - Jannat-E-Kashmir. </p>
                                        </p>
                                        <hr />
                                    </div>
                                    <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>TULIP SPL KASHMIR GROUP TOUR PACKAGES FROM KOLKATA BY TRAIN</strong> IN APRIL 2025? <br /><br />
                                        FOR BOOKING <strong>TULIP FESTIVAL SPECIAL KASHMIR GROUP TOUR</strong> <a href="tel:+91-9147061467"> ☎ CALL +91-91470-61467</a> <br /><br /></h2>
                                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>TULIP SPL KASHMIR GROUP TOUR PACKAGES FROM KOLKATA BY TRAIN</strong> IN APRIL 2025? <br /><br />
                                        FOR BOOKING <strong>TULIP FESTIVAL SPECIAL KASHMIR GROUP TOUR</strong> EX-KOLKATA <a href="tel:+91-9147061467">  ☎ CALL +91-91470-61467</a> <br /><br /></h3>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> START YOUR <strong>KASHMIR TULIP FESTIVAL PACKAGES FROM KOLKATA IN GROUP</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> START YOUR <strong>KASHMIR TULIP FESTIVAL PACKAGES FROM KOLKATA IN GROUP</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Are you ready to experience the beauty of a spectacular flower show? Look no further than the Kashmir Tulip Garden, which hosts the annual <a href="/kashmir-tulip-garden-festival-package-tour/" target='_blank'><strong>Kashmir Tulip Festival Package Tour </strong></a>. This incredible event is filled with vibrant colors and is a once-in-a-lifetime experience you won’t want to miss. Our specially designed <strong>Tulip Spl Kashmir Group Tour Packages</strong> let you see this natural wonder in all its beauty. Let’s take a closer look at what makes this journey so special. <br /><br />

                                        The <a href="/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg/" target='_blank'><strong>Kashmir Package Tour From Kolkata</strong></a> takes place in the famous Kashmir Tulip Garden, located in the breathtaking Jammu and Kashmir region. This stunning garden is set at the base of the towering Zabarwan mountain range and is next to the renowned Botanical Garden of Kashmir. Covering a vast 74 acres, the garden is a peaceful and scenic paradise. It's also conveniently close to the beautiful Dal Lake, making it a must-see destination for nature lovers and tourists from all over the world. <br /><br />

                                        The <strong>Kashmir Tulip Festival 2025</strong> is a short but magical event that runs for just 20 days. During this time, over 1.5 million tulips will be in full bloom, creating a colorful and fragrant wonderland. It’s a visual feast you won’t forget, with every corner of the garden bursting with vibrant colors. <br /><br />

                                        The garden was originally called Siraj Bagh and was renamed the <b>Indira Gandhi Memorial Tulip Garden in 2007</b>. It was created by the floriculture department to help promote <b>Kashmir tourism</b>. Since then, it has become one of the most popular tourist attractions in the region. The garden features rows and rows of tulips in many different varieties, along with 70 other types of flowers, including daffodils and hyacinths. Many of the tulip bulbs are imported from Amsterdam, giving the garden an international touch. The garden also has seven terraces, each with pathways, fountains, and plenty of space to relax and enjoy the surroundings. <br /><br />

                                        If you want to experience the magic of Kashmir in April, Adorable Vacation offers special <strong>Tulip Festival Special Kashmir Group Tour</strong> starting from Kolkata by to and from AC 3 Tier Train Journey. This tour gives you a guided experience to make sure you don’t miss any of the highlights. This <strong>Kashmir Group Tour Packages from Kolkata</strong> includes train tickets, all meals, all sightseeing, houseboat stay, entry tickets, and a tour of the tulip garden. You’ll also get to visit other beautiful places in Kashmir, making your <strong>Tulip Spl Kolkata to Kashmir Group Tour</strong> even more unforgettable. <br /><br />

                                        The <strong>Tulip Festival Spl Kashmir Group Tour Packages</strong> are your chance to witness the stunning beauty of the tulips and explore the rich culture of Kashmir. During the festival, you can also experience the local handicrafts, cuisine, and music, adding even more to your journey. <br /><br />

                                        Don’t miss out on this incredible experience! Book your <a href='/kashmir-tulip-garden-festival-package-tour/' target='_blank'><strong>Kashmir Tulip Special Packages from Kolkata</strong></a> today and get ready to immerse yourself in the vibrant colors and natural beauty of Jammu and Kashmir. It's an experience you will cherish forever! <br /><br /><br /></p>

                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={brandImageURL} alt="Sightseeing 1" />
                                                   
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt=" kashmir tour package" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>KASHMIR GROUP TOUR PACKAGES FROM KOLKATA</strong> : 12N | 13D</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION FOR <strong>KASHMIR GROUP TOUR PACKAGES FROM KOLKATA</strong> : 12N | 13D</h4>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN 12N/13D <strong className='tomato'>KASHMIR GROUP TOUR PACKAGES FROM KOLKATA</strong> : AMRITSAR  [1N] | KATRA [2N] | VAISHNO DEVI | SRINAGAR [3N] | GULMARG | SONMARG | PAHALGAM [2N] </h4><br />
                                    <h4>PICKUP POINT FOR <strong>KASHMIR TULIP GARDEN FESTIVAL TRIP</strong> : HOWRAH STATION </h4><br />
                                    <h4>DROP POINT FOR <strong>TULIP SPL KASHMIR GROUP TOUR</strong> : KOLKATA STATION</h4>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Pickup Location</strong></td>
                                                    <td>HOWRAH STATION [HWH-AMRITSAR MAIL]</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Drop Location</strong></td>
                                                    <td>KOLKATA STATION [KOAA-JAMMU TAWAI EXP]</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Kashmir Tulip Festival Package </strong>Duration</td>
                                                    <td>12 Nights | 13 Days</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Kashmir Tulip Festival Packages Cost </strong></td>
                                                    <td>Rs. 25,900/P</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                    <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                    <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* start short itinerary */}

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DAY WISE SUMMERY FOR <strong>TULIP FESTIVAL SPL KASHMIR GROUP TOUR PACKAGES FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DAY WISE SUMMERY FOR <strong>TULIP FESTIVAL SPL KASHMIR GROUP TOUR PACKAGES FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                            <span>
                                                                DEPARTURE FROM HOWRAH STATION
                                                            </span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                            <span>OVERNIGHT IN TRAIN</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                            <span>ARRIVES AT AMRITSAR JN. VISIT WAGAH BORDER</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                            <span>GOLDEN TEMPLE EXCURSION AND TRANSFER TO KATRA</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                            <span>VAISHNODEVI DARSHAN</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                            <span>KATRA TO SRINAGAR</span></a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                            <span>SRINAGAR TO SONMARG </span></a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                            <span>DAY EXCURSION TO GULMARG</span></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                                                            <span>SRINAGAR TO PAHALGAM VIA LOCAL SIGHTSEEING</span></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                                                            <span>PAHALGAM LOCAL SIGHTSEEING (INCLUDED)</span></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <a href="#day11" style={{ color: 'black' }}><b>DAY 11 → </b>
                                                            <span>DEPART JAMMU TAWI EXPRESS</span></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <a href="#day12" style={{ color: 'black' }}><b>DAY 12 → </b>
                                                            <span>OVERNIGHT IN TRAIN</span></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <a href="#day13" style={{ color: 'black' }}><b>DAY 13 → </b>
                                                            <span>ARRIVE IN KOLKATA STATION</span></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end Short description--> */}
                            <br /><br /><br />

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>INCLUSIONS : <strong>KOLKATA TO KASHMIR TULIP FESTIVAL TOUR PACKAGES IN GROUP</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>INCLUSIONS : <strong>KOLKATA TO KASHMIR TULIP FESTIVAL TOUR PACKAGES IN GROUP</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Return Train fare in 3 Tier Ac Class
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Assistance upon arrival at Amritsar
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Traditional welcome in the Hotel with Welcome Drink ( Hot Tea / Coffee/ Kahwa) on arrival
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            01 Night Accommodation in Amritsar– Hotel as per the Itinerary with Breakfast, Lunch and Dinner
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            02 Night Accommodation in Katra– Hotel as per the Itinerary with Breakfast, Lunch and Dinner
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            02 Night Accommodation in Srinagar– Hotel as per the Itinerary with Breakfast, Lunch and Dinner
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            2 Night Accommodation in Pahalgam as per the Itinerary with Breakfast, Lunch, and Dinner
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            01 Night Accommodation in Srinagar– Houseboat as per the Itinerary with Breakfast, Lunch, and Dinner
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            60 Minutes Boat Ride (SHIKARA) on world famous Dal Lake – Srinagar
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Chandanwari / Aru / Betaab Valley Sightseeing at Pahalgam
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            All toll taxes & parking charges
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* distance */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES TO NOTE DURING <strong>KASHMIR TULIP FESTIVAL GROUP PACKAGE FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES TO NOTE DURING <strong>KASHMIR TULIP FESTIVAL GROUP PACKAGE FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><th>FROM</th><th>TO</th><th>DISTANCE</th></tr></thead>
                                            <tbody>
                                                <tr><td>Kolkata</td><td>Srinagar</td><td>46 hr (2,343.4 km) via NH 44</td></tr>
                                                <tr><td>Jammu</td><td>Srinagar</td><td>8 hr 14 min (266.5 km) via NH 44</td></tr>
                                                <tr><td>Srinagar</td><td>Gulmarg</td><td>1 hr 30 min (49.5 km) via Srinagar - Tangmarg Rd</td></tr>
                                                <tr><td>Srinagar</td><td>Sonmarg</td><td>2 hr 11 min (79.4 km) via NH1</td></tr>
                                                <tr><td>Srinagar</td><td>Pahalgam</td><td>2 hr 11 min (89.6 km) via Srinagar - Kanyakumari Hwy</td></tr>
                                                <tr><td>Srinagar</td><td>Dras</td><td>4 hr 2 min (141.2 km) via NH1</td></tr>
                                                <tr><td>Srinagar</td><td>Kargil</td><td>5 hr 19 min (202.1 km) via NH1</td></tr>
                                                <tr><td>Srinagar</td><td>Leh</td><td>9 hr 41 min (418.5 km) via NH1</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORE KASHMIR’S LOCAL MARKETS DURING <strong>KASHMIR TULIP FESTIVAL TOUR PACKAGES</strong> WITH ADORABLE VACATION</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORE KASHMIR’S LOCAL MARKETS DURING <strong>KASHMIR TULIP FESTIVAL TOUR PACKAGES</strong> WITH ADORABLE VACATION</h4>
                                </div>
                                <div className="card-body">

                                    <p>If you’re booking a <strong>Kashmir Tulip Festival package tour from Kolkata</strong>, you’re in for an unforgettable experience. While the <strong>Kashmir Tulip Garden Festival 2025</strong> offers a breathtaking display of tulips, don’t miss the opportunity to explore the vibrant local markets of Srinagar. These markets are the perfect way to immerse yourself in the culture, crafts, and cuisine of Kashmir. Here’s a guide to the best local markets to visit during your <strong>Srinagar Tulip Garden tour</strong>. <br /><br /></p>

                                    <h4 className='dark-blue'>Lal Chowk Markets : The Heart of Srinagar’s Culture</h4>
                                    <p>Start your <strong>Kashmir Tulip Festival tour package</strong> with a visit to Lal Chowk, Srinagar’s bustling city center. This vibrant market is known for its wide variety of Kashmiri handicrafts, from Pashmina shawls to traditional pherans and papier-mâché items. It’s the perfect place to take home authentic Kashmir souvenirs, and don’t forget to haggle for the best prices! <br /><br /></p>

                                    <h4 className='dark-blue'>Polo View Market : A Peaceful Shopping Experience</h4>
                                    <p>For a more relaxed experience, head to Polo View Market, where you can shop for beautiful Kashmiri carpets, traditional jewelry, and aromatic spices. This charming market offers a perfect mix of shopping and sightseeing, as you can enjoy the lovely views of Dal Lake while browsing the stalls. <br /><br /></p>

                                    <h4 className='dark-blue'>Raghunath Bazaar : Authentic Kashmiri Goods</h4>
                                    <p>For a deeper dive into Kashmiri culture, Raghunath Bazaar is the place to be. This market is filled with local spices, dried fruits, handwoven rugs, and intricate wooden artifacts. It’s an excellent spot to pick up authentic Kashmir-made products that reflect the region’s rich craftsmanship. <br /><br /></p>

                                    <h4 className='dark-blue'>Residency Road Market : Savor the Local Cuisine</h4>
                                    <p>A visit to Residency Road Market is a must for food lovers. During your <strong>Kashmir Tulip Festival tour</strong>, make sure to stop by food stalls offering Kashmiri specialties like Rogan Josh, Yakhni, and the famous Wazwan feast. Enjoy these delicious dishes while soaking in the lively ambiance of this popular market. <br /><br /></p>

                                    <h4 className='dark-blue'>Floating Market at Dal Lake : A Unique Shopping Experience</h4>
                                    <p>Add a magical twist to your <strong>Kashmir Tulip Festival tour packages</strong> with a visit to the Floating Market on Dal Lake. A shikara ride will take you to vendors who sell everything from flowers and fruits to handcrafted items right from their boats. The serene lake setting makes this a one-of-a-kind shopping experience. <br /><br /></p>

                                    <p>Exploring Kashmir’s local markets is the perfect complement to the stunning <strong>Kashmir Tulip Garden Festival</strong>. The markets offer a wonderful mix of vibrant colors, local crafts, delicious food, and breathtaking views. By booking a <strong>Kashmir Tulip Festival 2025 package tour</strong>, you’ll be able to seamlessly enjoy both the Tulip Festival and the charm of Kashmir’s bustling markets, making it a truly unforgettable experience. <br /><br />

                                        Book your <strong>Kashmir Tulip Festival package tour</strong> now and get ready to experience the best of Kashmir and its unique local markets! <br /><br /></p>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="kashmir package tour booking from srinagar" />
                                                    <img src={sightseen8ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                    <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TULIP FESTIVAL</strong> <b>2025: DATES, TIMINGS & TICKET INFORMATION</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TULIP FESTIVAL</strong> <b>2025: DATES, TIMINGS & TICKET INFORMATION</b></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>HIGHLIGHTS</th>
                                                    <th>TULIP FESTIVAL DETAILS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Festival Dates</td>
                                                    <td>March 19 - April 20, 2025</td>
                                                </tr>
                                                <tr>
                                                    <td>Festival Location</td>
                                                    <td>Indira Gandhi Memorial Tulip Garden, Srinagar, J&K</td>
                                                </tr>
                                                <tr>
                                                    <td>Festival Timings</td>
                                                    <td>10:00 AM - 7:30 PM Daily</td>
                                                </tr>
                                                <tr>
                                                    <td>Ticket Fees</td>
                                                    <td>INR 50 per adult, INR 25 per child</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>COST OF <strong>KASHMIR TULIP GARDEN TOUR PACKAGE</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>COST OF <strong>KASHMIR TULIP GARDEN TOUR PACKAGE</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>TOUR DURATION</th>
                                                    <th>2 PAX</th>
                                                    <th>4 PAX</th>
                                                    <th>6 PAX</th>
                                                    <th>8 PAX</th>
                                                    <th>BOOK NOW</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>4N/5D</td>
                                                    <td>₹ 20,500/-</td>
                                                    <td>₹ 18,300/-</td>
                                                    <td>₹ 16,600/-</td>
                                                    <td>₹ 16,400/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5N/6D</td>
                                                    <td>₹ 24,700/-</td>
                                                    <td>₹ 22,000/-</td>
                                                    <td>₹ 19,800/-</td>
                                                    <td>₹ 21,800/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6N/7D</td>
                                                    <td>₹ 30,900/-</td>
                                                    <td>₹ 27,400/-</td>
                                                    <td>₹ 24,700/-</td>
                                                    <td>₹ 24,500/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7N/8D</td>
                                                    <td>₹ 35,600/-</td>
                                                    <td>₹ 30,800/-</td>
                                                    <td>₹ 28,300/-</td>
                                                    <td>₹ 28,100/-</td>
                                                    <td><a href="tel:9147061467" class="btn btn-success">Call Now</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br /><br /> */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPERIENCE THE MAGNIFICENT HUES OF JANNAT-E-KASHMIR WITH <strong>KASHMIR TULIP FESTIVAL GROUP TOUR FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPERIENCE THE MAGNIFICENT HUES OF JANNAT-E-KASHMIR WITH <strong>KASHMIR TULIP FESTIVAL GROUP TOUR FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>Adorable Vacation</b> is excited to announce the launch of a <strong>Kashmir Group Tour from Kolkata by Train</strong> on 3rd April 2025. This specially curated <strong>Tulip Festival Special Kashmir Group Tour</strong> is designed for travelers who want to experience the vibrant beauty of the Kashmir Tulip Festival in full bloom. The 12N/13D <strong>Kashmir Group Tour from Kolkata</strong> offers an affordable yet luxurious way to explore the breathtaking landscapes of Kashmir while experiencing its cultural richness. <br /><br />

                                        Our <a href="/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg/" target='_blank'><strong>Kashmir Package Tour from Kolkata</strong></a> is a complete package that includes everything you need for a seamless and memorable journey. From Return 3AC Tier train tickets to a dedicated tour manager who will accompany you throughout the trip, we've got every detail covered. You'll enjoy traditional welcome drinks upon arrival, all-inclusive meals, and a stay at a beautiful houseboat on Dal Lake, along with a complimentary Shikhara ride. The tour also includes visits to iconic destinations such as the Wagah Border, the Golden Temple, and Vaishnodevi Darshan. <br /><br />

                                        In addition to these highlights, our <strong>Tulip Spl Kashmir Group Tour Packages</strong> cover all major sightseeing spots in Kashmir, including Gulmarg, Sonmarg, and Pahalgam. You’ll have the chance to witness the stunning Tulip Festival firsthand, where millions of tulips bloom across the famous Kashmir Tulip Garden, offering a visual feast. Whether you're a nature lover, adventure enthusiast, or culture seeker, this tour offers the perfect blend of everything that makes Kashmir known as "Paradise on Earth." <br /><br />

                                        Designed to fit an affordable budget without compromising on quality, this <strong>Kashmir Group Tour Packages from Kolkata by Train</strong> is perfect for those who want to experience the magic of Kashmir in a hassle-free, all-inclusive package. Join us on this unforgettable journey and immerse yourself in the beauty and serenity of Kashmir. Book your spot today! <br /><br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO REACH KASHMIR TULIP GARDEN FESTIVAL, 2025</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH KASHMIR TULIP GARDEN FESTIVAL, 2025</h4>
                                </div>
                                <div className="card-body">
                                    <p>Adorable Vacation is offering an incredible opportunity to experience the magic of <strong>Kashmir during the Tulip Festival</strong> with a specially designed <strong>Kashmir Group Tour from Kolkata</strong>. This budget-friendly, hassle-free tour includes everything you need to explore the natural beauty of Kashmir and the vibrant Tulip Festival in full bloom, making it the perfect option for travelers looking to visit this paradise on Earth without breaking the bank. <br /><br />

                                        The journey begins on 3rd April 2025, when guests will board the 13005 UP Howrah - Amritsar Mail train at Howrah Station at 19:15 hrs. The train is an AC 3 Tier service, ensuring a comfortable and restful journey. You’ll travel overnight and reach Amritsar at 08:40 hrs on 5th April, marking the first major stop of your journey before heading towards Kashmir. <br /><br />

                                        Once you arrive, you'll be transferred to your accommodation and begin the exciting exploration of Kashmir’s Tulip Festival. This is the perfect time to witness the world-famous Tulip Garden in full bloom, with millions of tulips creating a vibrant sea of colors. You'll also explore the scenic beauty of Gulmarg, Sonmarg, Pahalgam, and other famous spots while enjoying the traditional Kashmiri culture and cuisine. <br /><br />

                                        Throughout your tour, Adorable Vacation provides a dedicated tour manager to ensure a smooth and enjoyable experience. All meals, sightseeing, and a relaxing stay at a houseboat on Dal Lake are included, along with a complimentary Shikhara ride to make your Kashmir visit even more memorable. <br /><br />

                                        At the end of the tour, on 13th April, guests will board the Jammu Tawai Express at 20:30 hrs for the return journey. The train will depart from Jammu and bring you back to Kolkata, where you'll arrive on 15th April at 15:40 hrs. <br /><br /><br /></p>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="Sightseeing 4" />
                                                    <img src={sightseen11ImageURL} alt="Sightseeing 5" />
                                                    <img src={sightseen12ImageURL} alt="Sightseeing 6" />

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TRAIN ADORABLE VACATION PROVIDES FOR  <strong>KASHMIR TULIP GARDEN GROUP TOUR FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TRAIN ADORABLE VACATION PROVIDES FOR  <strong>KASHMIR TULIP GARDEN GROUP TOUR FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><th><strong>Train No</strong></th><th><strong>Departure From</strong></th><th><strong>Departure Time</strong></th><th><strong>Arrival Stn</strong></th><th><strong>Arrival Time</strong></th><th><strong>Travel Time</strong></th></tr></thead>
                                            <tbody>
                                                <tr><td>13005</td><td>Howrah</td><td>19:15</td><td>Amritsar</td><td>08:40</td><td>37 h 25 m</td></tr>
                                                <tr><td colspan="3"></td></tr>
                                                <tr><td>13152</td><td>Jammu Tawi</td><td>20:30</td><td>Kolkata</td><td>15:40</td><td>40 h 10 m</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY CHOOSE THIS <strong>KASHMIR GROUP TOUR</strong> FROM KOLKATA WITH ADORABLE VACATION?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY CHOOSE THIS <strong>KASHMIR GROUP TOUR</strong> FROM KOLKATA WITH ADORABLE VACATION?</h4>
                                </div>
                                <div className="card-body">
                                    <p>This <strong>Tulip Festival Special Kashmir Group Tour</strong> offers a perfect blend of affordability and comfort. The <strong>Kashmir Group Tour Packages from Kolkata</strong> by Train ensure a stress-free travel experience, allowing you to explore the beauty of Kashmir without the hassle of managing logistics. Whether you are traveling alone or with family, this package makes it easy to discover the splendor of Kashmir without stretching your budget. <br /><br />

                                        With AC 3 Tier Train tickets, all-inclusive meals, sightseeing, and expert guidance throughout the tour, Adorable Vacation has designed this package to make your Kashmir trip affordable yet unforgettable. It’s the best way for travelers who’ve always dreamed of visiting Kashmir to turn that dream into reality - without any financial burden.
                                        <br /><br />

                                        So, don’t miss the opportunity to experience the enchanting Tulip Festival in Kashmir. Book your spot on this <strong>Kashmir Group Tour from Kolkata</strong> today and prepare for a once-in-a-lifetime adventure!  <br /></p>
                                        <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> TULIP VARIETIES YOU CAN EXPLORE AT THE <strong>KASHMIR TULIP FESTIVAL 2025</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TULIP VARIETIES YOU CAN EXPLORE AT THE <strong>KASHMIR TULIP FESTIVAL 2025</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Welcome to the captivating world of the <strong>Kashmir Tulip Festival 2025</strong>! This annual event is a breathtaking celebration of nature, where a wide array of tulip varieties bloom amidst the stunning landscapes of Kashmir. If you're planning to attend, consider booking the exclusive <strong>Kashmir Tulip Garden Tour ex Kolkata by Train</strong> for a truly immersive experience. <br /><br />

                                        At the festival, you'll be treated to a kaleidoscope of colors as over 1.5 million tulips come to life in a spectacular display. From bold reds to soft pastels, and from classic shapes to exotic hybrids, the tulips at the <strong>Kashmir Tulip Festival</strong> are a visual feast for flower lovers. Whether you're a gardening enthusiast or simply a traveler in search of natural beauty, this festival promises an unforgettable experience. <br /><br />

                                        Join us in discovering the unique varieties of tulips that make this festival one of the most anticipated floral events of the year! Our <strong>Kashmir Tulip Festival Packages ex Kolkata</strong> provide the best way to experience this magnificent event in comfort. Don't miss out on the opportunity to be part of this vibrant celebration. <br /><br />

                                        Book your spot today for the <strong>Kolkata to Kashmir Tulip Festival Tour Packages</strong> and embark on an unforgettable journey to witness the beauty of the <strong>Kashmir Tulip Garden Tour in Group ex Kolkata</strong>. <br /><br /><br /></p>

                                    <li><b>Double Late 'Blue Spectacle' Tulips:</b> Captivating violet-blue blooms create a regal centerpiece in your garden.<br /><br /></li>
                                    <li><b>Lily-flowered 'West Point' Tulips:</b> Brilliant golden-yellow hues add grace and sophistication to any garden bed.<br /><br /></li>
                                    <li><b>Fosteriana 'Pink Impression' Tulips:</b> Large, cup-shaped flowers in soft pink evoke a dreamy, serene atmosphere.<br /><br /></li>
                                    <li><b>Greigii 'Pinocchio' Tulips:</b> Bi-colored petals in shades of red and white bring a playful and whimsical touch.<br /><br /></li>
                                    <li><b>Darwin Hybrid 'Daydream' Tulips:</b> 'Daydream' stands out with timeless elegance, featuring soft apricot-pink petals.<br /><br /></li>
                                    <li><b>Viridiflora 'Artist' Tulips:</b> 'Artist' showcases a captivating blend of red, pink, and green, adding an artistic flair to your garden.<br /><br /></li>
                                    <li><b>Parrot 'Ice Cream' Tulips:</b> Exotic, fringed petals in white and green resemble delicate ice cream cones, adding a unique touch.<br /><br /></li>
                                    <li><b>Triumph 'Gavota' Tulips:</b> Radiant red petals make a bold statement, infusing energy and vibrancy into your garden.<br /><br /></li>
                                    <li><b>Rembrandt Tulips:</b> 'Rem's Favorite' and 'Marilyn' pay homage to the artistic Rembrandt style with intricate and beautiful color patterns.<br /><br /></li> <li><b>Double Early Peony Tulips:</b> 'Abigail' in creamy white and 'Pink Star' in soft pink create an elegant and regal atmosphere.<br /><br /></li>
                                    <li><b>Fosteriana Kaufmanniana Tulips:</b> 'Stresa' in soft pink and 'Ancilla' in bright yellow bring a sense of serenity to your garden.<br /><br /></li>
                                    <li><b>Parrot 'Rococo' Tulips:</b> Fringed, multi-colored petals in pink, green, and white add an exotic and unique beauty.<br /><br /></li>
                                    <li><b>Fringed 'Shirley' Tulips:</b> Delicate fringes on pastel pink and white petals create an alluring and soft appearance.<br /><br /></li>
                                    <li><b>Greigii 'Toronto' Tulips:</b> Vibrant red blooms paired with mottled foliage bring a playful and charming element to your garden.<br /><br /></li>
                                    <li><b>Viridiflora 'Greenland' Tulips:</b> Mesmerizing shades of green and pink create a sophisticated and intriguing look.<br /><br /></li>
                                    <li><b>Kaufmanniana 'Chopin' Tulips:</b> Distinctive white blooms with a subtle blush of pink create a sense of purity and musicality.<br /><br /></li>
                                    <li><b>Triumph 'Menton' Tulips:</b> Elegant cup-shaped blooms in soft apricot-pink bring a whimsical, romantic touch to your garden.<br /><br /></li>
                                    <li><b>Parrot 'Blue Parrot' Tulips:</b> Vivid blue and violet hues, combined with fringed, feather-like petals, create a dramatic focal point.<br /><br /></li>
                                    <li><b>Kaufmanniana 'Johann Strauss' Tulips:</b> Bright yellow petals with red streaks offer a cheerful, lively touch.<br /><br /></li>
                                    <li><b>Parrot 'Carnaval de Nice' Tulips:</b> A festive mix of red, white, and green creates a celebration of color and joy.<br /><br /><br /></li>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen13ImageURL} alt="Sightseeing 4" />
                                                    <img src={sightseen14ImageURL} alt="Sightseeing 5" />
                                                    <img src={sightseen15ImageURL} alt="Sightseeing 6" />

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />  <br />
                                    <li><b>Single Early Tulips: </b>'Red Emperor' and 'Apricot Beauty' bring vibrant early pops of color to the garden.<br /><br /></li>
                                    <li><b>Triumph Tulips:</b> Admire the rich purple petals of 'Negrita' and the brilliant yellow blooms of 'Golden Apeldoorn'.<br /><br /></li>
                                    <li><b>Darwin Hybrid Tulips:</b> Look out for the intense red of 'Oxford' and the striking scarlet hues of 'Apeldoorn Elite'.<br /><br /></li>
                                    <li><b>Lily-flowered Tulips:</b> 'Ballerina' offers a beautiful blend of orange and red, while 'White Triumphator' delights with pristine white blooms.<br /><br /></li> <li><b>Double Late Tulips:</b> Marvel at the soft pink beauty of 'Angelique' and the deep maroon blooms of 'Black Hero'.<br /><br /></li>
                                    <li><b>Fosteriana Tulips:</b> Bold and vivid, 'Purissima' and 'Red Emperor' stand out with their striking colors.<br /><br /></li>
                                    <li><b>Greigii Tulips:</b> Enjoy the playful apricot-pink flowers of 'Casa Grande' and the vibrant red blooms of 'Toronto'.<br /><br /></li>
                                    <li><b>Parrot Tulips:</b> 'Estella Rijnveld' and 'Flaming Parrot' captivate with their uniquely feathered petals.<br /><br /></li>
                                    <li><b>Viridiflora Tulips:</b> 'Golden Artist' and 'China Town' showcase stunning color patterns with hints of green streaks.<br /><br /></li>
                                    <li><b>Parrot 'Texas Flame' Tulips:</b> Spectacular hues of fiery red and orange create a dramatic effect, perfect for striking displays.<br /><br /></li>
                                    <li><b>Double Early 'Monte Carlo' Tulips:</b> Soft yellow, peony-like blooms bring refinement, elegance, and sophistication.<br /><br /></li>
                                    <li><b>Lily-flowered 'Aladdin' Tulips:</b> A charming blend of red and white adds an enchanting, magical touch to your garden.<br /><br /></li>
                                    <li><b>Fosteriana 'White Emperor' Tulips:</b> Dazzling pure white blooms stand out beautifully against the greenery, creating a serene atmosphere.<br /><br /></li> <li><b>Viridiflora 'Doll's Minuet' Tulips:</b> An ethereal blend of pink and green creates a dreamy, enchanting atmosphere in your garden.<br /><br /></li>
                                    <li><b>Darwin Hybrid 'Golden Apeldoorn' Tulips:</b> Regal and timeless, with large, golden-yellow blooms that symbolize the beauty of spring.<br /><br /></li> <li><b>Lily-flowered 'White Triumphator' Tulips:</b> Pristine white, pointed petals create a sense of purity, simplicity, and elegance.<br /><br /></li>
                                    <li><b>Miniature Tulips:</b> 'Little Beauty' in deep pink and 'Tarda' in cheerful yellow bring a playful charm to any space.<br /><br /></li>
                                    <li><b>Greigii 'Red Riding Hood' Tulips:</b> Bold and dramatic, 'Red Riding Hood' adds intensity with its scarlet-red blooms.<br /><br /></li>
                                    <li><b>Fringed Tulips:</b> 'Fringed Beauty' in lilac-pink and 'Fancy Frills' in white captivate with their delicately fringed edges.<br /><br /></li>
                                    <li><b>Greigii 'Oratorio' Tulips:</b> The delicate pink and white petals of 'Oratorio' add a touch of refinement and grace.<br /><br /></li>
                                    <li><b>Triumph 'Queen of the Night' Tulips:</b> With dramatic, almost black blooms, these tulips bring an air of mystery and elegance to the garden.<br /><br /></li>
                                    <li><b>Double Late 'Black Hero' Tulips:</b> Radiant deep maroon, peony-like blooms stand out as heroes in the garden, offering a bold contrast.<br /><br /></li> <li><b>Fosteriana 'Purissima' Tulips:</b> Graceful ivory-white blooms exude chic and sophisticated charm, perfect for refined gardens.<br /><br /></li>
                                    <li><b>Greigii 'Red Riding Hood' Tulips:</b> Dazzling scarlet-red blooms, coupled with unique foliage, add a playful and eye-catching element.<br /><br /></li> <li><b>Fringed 'Burgundy Lace' Tulips:</b> Delicate fringes on deep burgundy petals bring mystery, allure, and dramatic beauty.<br /><br /></li>
                                    <li><b>Parrot 'Pretty Princess' Tulips:</b> Exquisite fringed pink and white petals add a royal touch of delicacy and elegance.<br /><br /></li>
                                    <li><b>Triumph 'Prinses Irene' Tulips:</b> A radiant combination of orange and purple hues brings warmth, sophistication, and charm.<br /><br /></li>
                                    <li><b>Parrot 'Green Wave' Tulips:</b> Whimsical, fringed green and white petals add movement, energy, and vibrancy to your garden.<br /><br /></li>
                                    <li><b>Viridiflora 'Flaming Spring Green' Tulips:</b> A captivating blend of green and red on petals symbolizes the transition from winter to spring.<br /><br /></li>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP ATTRACTIONS TO EXPLORE DURING <strong>KASHMIR TULIP GARDEN FESTIVAL GROUP TOUR FROM KOLKATA</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP ATTRACTIONS TO EXPLORE DURING <strong>KASHMIR TULIP GARDEN FESTIVAL GROUP TOUR FROM KOLKATA</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <h4 className='dark-blue'>Indira Gandhi Memorial Tulip Garden (Siraj Bagh)</h4>
                                    <p>The heart of the <strong>Kashmir Tulip Festival 2025</strong>, the Indira Gandhi Memorial Tulip Garden is the largest of its kind in Asia. With over a million tulips in full bloom, the garden is a feast for the eyes. It boasts a spectacular array of tulip varieties, from bright reds and oranges to delicate pinks and whites. Stroll through the meticulously manicured beds and enjoy the stunning display of colors, making it a perfect spot for nature lovers and photography enthusiasts. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Shankaracharya Temple</h4>
                                    <p>Perched atop a hill, Shankaracharya Temple offers a panoramic view of Srinagar and the surrounding valleys. This historic temple dedicated to Lord Shiva is an important pilgrimage site, and the journey to the top provides an opportunity to enjoy breathtaking views of the city, Dal Lake, and the snow-capped mountains. It’s a must-visit for spiritual seekers and those wanting a scenic hike during their <strong>Tulip Spl Kashmir Group Tour</strong>. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Dal Lake & Shikara Ride</h4>
                                    <p>A Shikara ride on Dal Lake is one of the most iconic experiences of any trip to Kashmir. Glide across the calm waters of the lake, surrounded by floating gardens and traditional houseboats. The view of the surrounding snow-capped mountains adds to the charm. As part of your <strong>Kashmir Group Tour Packages from Kolkata by Train</strong>, you can also visit the Mughal Gardens along the lakeshore, such as Shalimar Bagh and Nishat Bagh, both of which are architectural masterpieces. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Shalimar Bagh & Nishat Bagh</h4>
                                    <p>These Mughal gardens are an essential part of any Kashmir Tulip Garden Festival tour. Shalimar Bagh is famous for its terraced lawns, cascading fountains, and classical Mughal design, while Nishat Bagh is known for its wide vistas and charming views over Dal Lake. Both gardens, with their lush greenery and water features, are perfect spots to unwind after exploring the vibrant tulip displays. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Pari Mahal</h4>
                                    <p>Known as the "Palace of Fairies," Pari Mahal is a stunning Mughal ruin located on the western edge of Dal Lake. With its dramatic backdrop of the Zabarwan Range, it offers some of the best views of the lake and Srinagar. The ancient monument, with its terraced gardens and grand architecture, is a serene and picturesque spot for relaxation and photography. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Hazratbal Shrine</h4>
                                    <p>For those interested in Kashmir’s rich Muslim heritage, Hazratbal Shrine is an important pilgrimage site. Situated on the northern shores of Dal Lake, the shrine is known for its pristine white marble structure and the holy relic it houses. Visiting the shrine provides a spiritual experience along with a peaceful ambiance, perfect for contemplation. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Jamia Masjid</h4>
                                    <p>This iconic mosque in Srinagar is one of the largest and most beautiful in Kashmir. The Jamia Masjid, built in Indo-Saracenic style, is known for its wooden architecture, intricately carved pillars, and peaceful atmosphere. Located in the heart of Srinagar, it’s a perfect place to experience the region's architectural heritage. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Chashme Shahi</h4>
                                    <p>Another gem in the Mughal Gardens of Kashmir, Chashme Shahi is known for its natural spring and terraced layout. It’s a tranquil spot to relax and enjoy the views of the mountains and the gardens around it. The garden’s peaceful environment makes it a perfect place to unwind after a busy day of sightseeing during your <strong>Kashmir Tulip Festival Package Tour 2025</strong>. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Kashmir Handicraft Markets</h4>
                                    <p>No visit to Kashmir is complete without exploring its vibrant handicraft markets. During the <strong>Kashmir Tulip Garden Festival</strong>, you can shop for exquisite Pashmina shawls, traditional Kashmiri carpets, papier-mâché items, and beautiful wooden artifacts. Lal Chowk, Polo View Market, and Raghunath Bazaar in Srinagar are the best places to shop for souvenirs and immerse yourself in Kashmiri culture. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Sonmarg</h4>
                                    <p>For those looking to explore beyond Srinagar, Sonmarg is a must-visit destination. Located around 80 kilometers from Srinagar, Sonmarg is known for its lush meadows, alpine lakes, and majestic views of snow-covered peaks. It’s a great place for trekking, picnics, and photography. As part of your <strong>Kashmir Tulip Garden Festival package</strong>, a visit to Sonmarg offers a refreshing escape into nature. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Gulmarg</h4>
                                    <p>Gulmarg, a popular hill station and skiing destination, is also known for its stunning meadows and alpine beauty. Just 51 kilometers from Srinagar, it’s a great option for a day trip. Whether you’re a nature lover or an adventure seeker, Gulmarg offers something for everyone, including the famous Gulmarg Gondola, which offers panoramic views of the snow-capped mountains. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Betaab Valley</h4>
                                    <p>Located in Pahalgam, Betaab Valley is a picturesque haven for nature lovers and adventure seekers. Known for its lush greenery, crystal-clear streams, and breathtaking landscapes, it’s a great place to relax and enjoy the natural beauty of Kashmir. <br /><br /><br /></p>

                                    <h4 className='dark-blue'>Events at the Tulip Festival in Kashmir</h4>
                                    <p>During your <strong>Kashmir Tulip Garden Festival tour</strong>, you’ll also get the chance to experience cultural events that showcase the local traditions. Attend Kashmiri music and dance performances, explore handicraft exhibitions, and savor delicious local delicacies like Rogan Josh, Yakhni, and the famous Wazwan feast. These activities add a unique cultural touch to your trip. <br /><br /><br /></p>


                                    <h4 className='dark-blue'>Plan Your Kashmir Tulip Festival Package Now!</h4>
                                    <p>Whether you're coming for the tulips, the serene lakes, the lush gardens, or the vibrant culture, the <strong>Kashmir Tulip Festival 2025</strong> offers an unforgettable experience. Book your <strong>Kashmir Tulip Garden Festival package today</strong> and explore the best that Kashmir has to offer in one remarkable tour. <br /><br /><br /></p>


                                    {/* <table style={{ width: '100%', textAlign: 'center' }}>
                                          <tbody>
                                              <tr>
                                                  <td className="image-cell" style={{ textAlign: 'center' }}>
                                                      <img
                                                          src={flight1ImageURL}
                                                          alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                          style={{ margin: '10px' }}
                                                      />
                                                      <img
                                                          src={flight2ImageURL}
                                                          alt="Bhutan Guwahati travel packages"
                                                          style={{ margin: '10px' }}
                                                      />
                                                      <img
                                                          src={flight3ImageURL}
                                                          alt="travel bhutan with best b2b dmc - adorable vacation"
                                                          style={{ margin: '10px' }}
                                                      />
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table> */}
                                </div>
                            </div>
                            <br /><br />

                            {/* <!--By Itinerary--> */}
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DAY WISE <span className='tomato'><strong>KASHMIR GROUP TOUR PACKAGE ITINERARY FROM KOLKATA</strong></span>
                                        </h3>
                                        <br/><br/>
                                        <h4>12N/13D <strong>Kashmir Group Tour Itinerary for Tulip Festival starting from Kolkata</strong></h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: DEPARTURE FROM HOWRAH STATION</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Meet & Greet at Howrah Station to board on train towards Amritsar Jn. from Howrah Station. Your <strong>Tulip Festival Special Kashmir Group Tour starts</strong>. Overnight in Train.<br /><br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: OVERNIGHT IN TRAIN</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                Overnight in Train Journey. <br /><br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: ARRIVES AT AMRITSAR JN. VISIT WAGAH BORDER</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Arrive in Amritsar, the heart of Punjab and a city known for its rich history and vibrant culture. Check-in at your hotel and freshen up. Head out to explore some local attractions like the Jallianwala Bagh, a historical garden that commemorates the tragic massacre of 1919, and the Partition Museum, which tells the story of India’s partition. After lunch, proceed to the Wagah Border, located about 30 km from Amritsar, to witness the famous Wagah Border Ceremony, a daily military practice that symbolizes the rivalry, cooperation, and respect between India and Pakistan. Return to Amritsar city. You can explore local markets for some shopping or enjoy a delicious Punjabi dinner at one of the famous dhabas. Overnight Stay in Amritsar. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="wagah-border" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="wagah-border" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: GOLDEN TEMPLE EXCURSION AND TRANSFER TO KATRA </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your day early with a visit to the Golden Temple (Harmandir Sahib), the holiest shrine of Sikhism. Experience the serene atmosphere, listen to the sacred hymns, and take part in the community meal (Langar). After the visit, you can explore the surrounding areas like the Guru Ka Langar Hall, Akal Takht, and the Central Sikh Museum. Enjoy lunch in Amritsar and then check out from your hotel. Begin your road journey to Katra, the base camp for pilgrims visiting Vaishno Devi. The distance from Amritsar to Katra is approximately 260 km, and it will take around 5-6 hours. Arrive in Katra, check in to your hotel, and relax after a long journey. Overnight: Stay in Katra. <br /><br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="golden temple tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="golden temple tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: VAISHNODEVI DARSHAN</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, start your Holy Journey to Mata Vaishnodevi Darshan. Vaishno Devi temple is one of the holiest Hindu temples dedicated to Shakti and is the second most visited religious shrine in India after Tirupati Balaji Temple. Trek to Mata Vaishno Devi shrine (14 km), ponies can also be arranged by yourself (at your own cost).
                                                    After Darshan return back to Katra. Overnight at Katra.<br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="vaishno devi" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="vaishno devi" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: KATRA TO SRINAGAR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>Early Morning we will leave for Srinagar after check-Out to the hotel and refreshment. You will go to Dal Lake and you will be provided a shikara ride in world famous Dal Lake. Enroute visiting handloom centre famous for production of Pashmina/Shahtoosh shawls & world over reckoned Carpets. <br /><br />

                                                    In the evening, enjoy the music of the running water of riverlidder and spend a few hours in the eveningon the back of the river.<br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="srinagar kashmir 1" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="srinagar kashmir" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: SRINAGAR TO SONMARG</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, we will drive to the majestic mountain resort of Sonmarg. The route towards Sonmarg takes us on a picturesque countryside drive where we will come across rolling hills, green forests, clear water streams, and blooming rice fields. <br /><br />

                                                    We will zoom past the town of Kangan which handles all the local needs of this region. From Kangan, we will drive towards Mammar, famous for its trout farm before we head towards Sonmarg. We will once again alight from our vehicles at the tourist spot of Gagangir. The spot is built in the middle of the river Sindh looks like an island with snow glaciers covering one or the other spot. In Sonmarg we will take a 3-hour walk or horseback trip/local Union cab to the Thajiwas glacier (on direct payment Basis). Overnight stay in Srinagar at a hotel. <br /><br /><br /></p>
                                                    <div className="content-desktop">
                                                    <center><img src={navDay7ImageURL} alt="srinagar kashmir 1" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="srinagar kashmir" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day8"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 08: EXCURSION TO GULMARG</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After a leisurely breakfast, drive to Gulmarg via Bye-Pass. Gulmarg, called the “Meadowof Flowers”. Gulmarg is at an altitude of 8700 – ft. Gulmarg also referred to as Meadow of Flowers is a famous Ski Resort & the world’s highest 18 hole Golf Course. The place was prominent during British time.Gulmarg's legendary beauty, prime
                                                    location and proximity to Srinagar naturally make it one of the premier hill resorts in the country. Originally called ‘Gaurimarg’ by shepherds, its present name was given in the 16th century by Sultan Yusuf Shah, who was inspired by the sight of its grassy slopes emblazoned with wild flowers. Gulmarg was a favorite hauntof Emperor
                                                    Jehangirwho once collected 21 different varietiesof flowers from here. Today Gulmarg is not merely a mountain resort of exceptional beauty- it also has the highestgreen golf coursein the world, at an altitude of 2,650 m, and is the country'spremier ski resort in the winter. On Arrival in Gulmarg, check in to the pre-arranged Hotel. Rest of
                                                    the dayfree at leisure. <br /><br />

                                                    One Can go for the Gondola Ride up to 1st or 2nd Phase. The Gulmarg Gondola is a tourist attraction in itself. It is one of the highest cable cars in the world, reaching 3,979 metres. The two-stage ropeway ferries about 600 people per hour to and from the gondola main station in Gulmarg to the shoulder of nearby Mt. Affarwat Summit
                                                    (4,200 m (13,780 ft)). There are also countless off-piste possibilities through the beautiful Khilanmarg meadows on the foot of Mount Affarwat, which offer a scenic view of Nanga Parbat. The Affarwat ridge at 3850+metres offers an avalanche controlled ski area that offers a wide field of snow to descend 800 m in approximately 3 km of
                                                    skiing, and is for advanced skiers only. Due to Gulmarg's steep terrain, the region is popular amongstadvanced and extreme skiers from aroundthe world and has been visited by a number of ski professionals and featured in a number of ski films also. <br /><br />

                                                    Dinner & Overnightstay at Srinagar. <br /><br /><br /></p>
                                                    <div className="content-desktop">
                                                    <center><img src={navDay8ImageURL} alt="srinagar kashmir 1" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="srinagar kashmir" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day9"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 09: EXCURSION TO GULMARG</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast leave for Pahalgam 2440 Mtrs (vale of Kashmir). On the way visist srinagar local sightseeing, Take a visit to Shankaracharya Temple, It is believed that it was the place where the great philosopher and saint Shankaracharya stayed when he visited Kashmir ten centuries ago to revive Sanathan Dharma. The original ceiling was dome- shaped and the brick roof is not more than a century old. We took a visit to Mughal Gardens, Nishat Garden known as "Garden of pleasure" builtin 16th century (1633)by Mughal King Asif Khan. Then visit Shalimar Garden known as ‘Garden of love’ built in 16th century (1619) by Mughal King Jahangir for his beloved wife Noor Jahan or (Mehr-un-Nissa & Chashmashahi ,The Royal Spring). The Chashmashahi Mughal garden in Srinagar had been loved by emperors and picnickers alike.
                                                    <br /><br />

                                                    It is a 4 hours journey on the way.On the way visit Saffron fields, Avantipur ruins and Anantnag sulpher spring. Bat villageand apple orchids. Pahalgam is the most famous place for the Indian Film Industry. Enjoy the natural charm of the valley. In the evening,enjoy the music of the running water of river lidder and spend a few hours in
                                                    the evening on the back of the river. Overnight at Pahalgam. <br /><br /><br /></p>
                                                    <div className="content-desktop">
                                                    <center><img src={navDay9ImageURL} alt="pahalgam kashmir 2" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="pahalgam kashmir 2" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day10"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 10: PAHALGAM LOCAL SIGHTSEEING (INCLUDED)</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After a leisurely breakfast, the entire day is free for you to explore and enjoy the breathtaking beauty of Pahalgam. As part of your <strong>Kashmir Tulip Festival Package Tour</strong>, you can visit some of the most scenic spots in and around Pahalgam. Here are some must-visit attractions included in your <strong>Tulip Festival Special Kashmir Tour 2025</strong>:<br /><br /></p>

                                                <h4>Aru Valley</h4>
                                                <p>Located about 12 km from Pahalgam, Aru Valley is a serene destination known for its stunning meadows, crystal-clear lakes, and snow-capped mountains. This peaceful village, situated on the left bank of the Aru River, is a popular base camp for treks to Kolahoi Glacier and Tarsar Lake. The scenic beauty here is perfect for photography, sightseeing, and nature walks. Visitors can also enjoy activities such as fishing, horse riding, and trekking. Aru Valley is the ideal spot for nature lovers and adventure enthusiasts alike. <br /><br /></p>

                                                <h4>Betaab Valley</h4>
                                                <p>At a distance of about 15 km from Pahalgam, Betaab Valley is one of Kashmir’s most picturesque locations. Famous for its lush green meadows, dense forests, and snow-covered mountains, Betaab Valley was named after the Bollywood hit film Betaab. This valley is not only a popular tourist destination but also a favorite camping site for trekkers. The crystal-clear stream flowing down from the snowy peaks is a highlight, and you can also indulge in nature walks, photography, and picnicking. <br /><br /></p>

                                                <h4>Chandanwari</h4>
                                                <p>Located 16 km from Pahalgam, Chandanwari is the starting point for the Amarnath Yatra pilgrimage. It is famous for its snow sledding on a snow bridge, which makes for a thrilling experience. You can hire a taxi from Pahalgam for a round-trip journey to Chandanwari. Many visitors enjoy having a cup of tea at the local dhabas or engage in playful snow fights. The picturesque location has been featured in several Bollywood films, making it an attractive spot for movie buffs and nature lovers alike. <br /><br /></p>

                                                <h4>Optional: Baisaran Valley / Mini Switzerland</h4>
                                                <p>For those looking for an additional adventure, you can hire ponies directly from Pahalgam to visit Baisaran Valley, often referred to as Mini Switzerland. Surrounded by pine forests and snow-clad mountains, Baisaran Valley is a beautiful meadow that offers panoramic views of the surrounding landscape. This area is also famous for its film shoots and offers ample opportunities for hiking, photography, and enjoying the serenity of Kashmir. <br /><br />

                                                    In the evening, return to your hotel in Pahalgam for dinner and a restful overnight stay. Whether you're enjoying the calm of Aru Valley, the beauty of Betaab Valley, or the adventure of Chandanwari, your <strong>Kashmir Tulip Garden Festival tour</strong> promises a mix of natural beauty and cultural exploration. <br /><br />

                                                    As part of your <strong>Kashmir Tulip Festival Package Tour</strong>, Pahalgam provides the perfect opportunity to immerse yourself in the breathtaking landscapes of Kashmir, enhancing your experience of the <strong>Kashmir Tulip Garden Festival 2025</strong>. <br /><br /></p>
                                                    <div className="content-desktop">
                                                    <center><img src={navDay10ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day11"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 11: DEPART JAMMU TAWI</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>ensures a punctual transfer to Jammu Tawi to catch the Train for Kolkata.<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day12"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 12: OVERNIGHT IN TRAIN</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>Overnight in Train<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day13"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 13: ARRIVE IN HOWRAH</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>Tour concludes. After reaching Kolkata, before leaving the Station bid good bye to all the fellow travellers you’ve met during the tour. Cherish the memories forever. <br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">{<InclusionExclusion />}</div>
                                </div>
                            </div>
                  
                            <br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <center><a href='tel:9147061467'><img src={CallButtonSoumyaImageURL} alt="kashmir expert" /></a></center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />

                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >KASHMIR TULIP FESTIVAL AND KASHMIR GROUP TOURS RELETED QUESTIONS</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        <h4> Q. WHAT IS THE SIGNIFICANCE OF THE KASHMIR TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Discover the enchanting beauty of Kashmir through the mesmerizing <strong>Kashmir Tulip Festival 2025</strong>. This annual event, also known as the Tulip Festival in Kashmir, takes place in the scenic Indira Gandhi Memorial Tulip Garden, situated in Srinagar. Blooming in April, this festival showcases the vibrant hues of tulips against the majestic backdrop of the Zabarwan Range.

                                                        Immerse yourself in a kaleidoscope of colors as you explore one of Asia's largest tulip gardens. The festival not only celebrates the natural beauty of the region but also promotes tourism, making it an ideal time to witness the breathtaking landscapes of Kashmir.

                                                        This <strong>Tulip Festival Special Kashmir Tour</strong> is not just an event; it’s an experience that captivates your senses and leaves you with lasting memories. Plan your <strong>Kolkata to Kashmir Tulip Festival Tour Packages</strong> today and embrace the beauty that nature unfolds in this picturesque destination.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        <h4>Q. IS THERE AN ENTRANCE FEE FOR THE TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        For seamless entry, domestic visitors can obtain tickets at just INR 50 per adult and INR 25 per child. International travelers can access this enchanting paradise for INR 150 (including GST) per person.<br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        <h4>Q. ARE THERE ANY SPECIFIC DATES FOR THE TULIP FESTIVAL IN 2025?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Discover the optimal time for a visit to the Tulip Garden in Kashmir! The Tulip Garden in Srinagar opens its gates in March 2025, and you can conveniently book your tickets online. The prime time to explore the enchanting Tulip Garden is in early April, when the tulips are at their peak, showcasing a breathtaking array of colors. The tulips bloom for a brief period, not lasting beyond 20 days, so it’s important to plan your visit accordingly to witness the stunning beauty of the garden in full glory during this time.<br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        <h4>Q. WHY IS INDIRA GANDHI MEMORIAL TULIP GARDEN FAMOUS?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Formerly known as the Model Floriculture Centre, the garden was unveiled to the public in 2007 with the goal of enhancing tourism and floriculture in the Kashmir Valley. With over 68 diverse tulip varieties, totaling in the hundreds of thousands, the garden showcases a breathtaking display of color. Additionally, visitors can marvel at various species of daffodils, hyacinths, and ranunculus, contributing to the garden’s allure as a vibrant and diverse floral paradise. <br /><br /></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        <h4>Q. WHEN DO TULIPS BLOOM IN KASHMIR?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The tulips in Kashmir grace the landscape from late March to mid-May, with their peak bloom occurring in mid-April. For the most spectacular views, plan your visit during this period. The annual <strong>Kashmir Tulip Festival Tour</strong> offers a fantastic opportunity to witness the tulips in their full glory. Stay updated with the latest information to ensure your visit coincides with the peak bloom, creating unforgettable memories amid the picturesque tulip fields. <br /><br /></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        <h4>Q. WHAT IS INCLUDED IN THE <strong>KASHMIR TULIP FESTIVAL SPECIAL KASHMIR GROUP TOUR</strong>?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The <strong>Kashmir Tulip Festival Special Kashmir Tour</strong> includes everything you need for a seamless and unforgettable experience. The package covers return 3AC Tier train tickets from Howrah Station to Kashmir, accommodation, all meals, a guided tour of the Tulip Garden, Shikara ride on Dal Lake, and sightseeing across iconic locations such as Gulmarg, Pahalgam, and Sonmarg. It's the perfect opportunity for travelers from Kolkata to enjoy the Tulip Festival in Kashmir with ease.<br /><br /></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. HOW CAN I TRAVEL FROM KOLKATA TO KASHMIR FOR THE TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Adorable Vacation offers the best way to travel from Kolkata to Kashmir for the Tulip Festival. The Kashmir Tour, taking travelers on a comfortable journey in 3AC Tier. The train will return to Kolkata after an exciting and immersive tour of Kashmir’s most famous tulip gardens and other breathtaking locations. With train travel included, this is a budget-friendly and hassle-free option to explore the beauty of Kashmir.<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. ARE THERE OTHER SIGHTSEEING OPTIONS IN KASHMIR APART FROM THE TULIP GARDEN?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Absolutely! Kashmir is rich in cultural and natural attractions. While visiting for <strong>Kashmir Tulip Garden tour</strong>, you'll also experience the splendor of places like Dal Lake, Shankaracharya Temple, the Mughal Gardens, Gulmarg, Sonmarg, and the famous Betaab Valley. You can also enjoy local cultural performances, visit traditional markets for handicrafts, and take part in spiritual experiences like the Vaishno Devi Darshan. The <strong>Kashmir Tulip Festival Packages ex Kolkata</strong> ensure a well-rounded Kashmir experience.<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. HOW LONG IS THE KASHMIR TULIP FESTIVAL SPECIAL GROUP TOUR?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The <strong>Kashmir Tulip Festival Special Tour Packages</strong> lasts for 12 nights and 13 days. The package includes everything you need, from travel to sightseeing and accommodation, ensuring you have plenty of time to explore Kashmir and enjoy the Tulip Festival at its peak.<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. WHAT IS THE BEST TIME TO VISIT KASHMIR FOR THE TULIP FESTIVAL?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The best time to visit Kashmir for the Tulip Festival is in early April, when the tulips are in full bloom and the entire garden is a riot of color. However, it's important to note that the Tulip Festival lasts for just 20 days, so make sure to plan your visit during the prime bloom period for the most spectacular views.<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. IS THE TULIP FESTIVAL ACCESSIBLE FOR SENIOR CITIZENS AND CHILDREN?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, the <strong>Kashmir Tulip Garden Tour in April 2025</strong> is designed to be accessible for travelers of all ages, including senior citizens and children. The train journey is comfortable and offers a relaxing travel experience. Additionally, most of the key attractions in Kashmir, including the Tulip Garden and Dal Lake, are accessible with minimal walking, making them ideal for senior citizens. Children can also enjoy the beauty and fun activities at the festival, making it a family-friendly experience.<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. CAN I EXTEND MY STAY IN KASHMIR AFTER THE TULIP FESTIVAL TOUR?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, if you wish to extend your stay and explore more of Kashmir, you can easily do so. Adorable Vacation can help you customize your trip, providing you with additional accommodation options and guided tours for a more in-depth experience. Just let us know your preferences, and we’ll arrange everything for you.<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        <h4>Q. HOW DO I BOOK A <strong>KASHMIR TULIP FESTIVAL SPECIAL GROUP TOUR</strong>?</h4>
                                                    </button>
                                                </div>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Booking your <strong>Kashmir Tulip Festival Special Tour Packages</strong> is simple! Visit our website or contact our customer service team to book your package. Our team will assist you in choosing the best dates and arrangements to ensure a seamless and memorable Kashmir experience. Don't miss out on this opportunity to be part of the spectacular <strong>Kashmir Tulip Garden Tour</strong>!<br /><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>
            </>
        );
    }
}
export default Kashmir_12N_13D_Tulip_Group_Tour_Kolkata_Package;