import React, { Component } from 'react';

class InclusionExclusion_7N_8D_nagpur extends Component {
    render() {
        return (
            <>
            <h4 className='tomato'><u>INCLUSION: <strong>NAGPUR TO BHUTAN TOUR PACKAGES</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Pick up & Drop at Bagdogra Airport
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;3* Hotel Accommodation [TCB Approves]
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Both Way transfers</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All Sightseeing (SIC BASIS)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Online E-Permit assistance.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Unlimited Breakfast, Lunch, Dinner in Buffet</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sustainable Development Fees (SDF) Rs. 1,200/Adult/Night</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;1 Day *Special Lunch LM Paro in 5 Star Property</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One bottle of Mineral Water Per Head Per Day</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Professional English-Speaking Guide</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;24/7 Guest Support during trip from Bhutan & India Office</li>
                </ul><br/><br/><br/>
                <h4 className='tomato'><u>EXCLUSION: <strong>BHUTAN TOUR PACKAGES FROM NAGPUR</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Air Fare</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Snacks, Lunch or any other meal</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Monument Entrance Fees</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Laundry, Tips, Phone Call or any such Personal Expense</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any item which is not mentioned in the "Inclusion" Section</li>
                    
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_7N_8D_nagpur;