import React, { Component } from "react";
import ReactDOM from "react-dom";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

class ImageCarouselModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            currentIndex: 0, // Tracks the currently displayed image
        };
        this.modalRef = React.createRef();
    }

    toggleModal = (index = 0) => {
        this.setState({
            showModal: !this.state.showModal,
            currentIndex: index, // Set the initial slide index
        });
    };

    handleClickOutside = (event) => {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.setState({ showModal: false });
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    renderModalContent = () => {
        const { images } = this.props;
        const { currentIndex } = this.state;

        return (
            <div
                className={`image-popup-modal modal d-flex align-items-center justify-content-center ${this.state.showModal ? "show" : ""
                    }`}
                style={{
                    display: this.state.showModal ? "block" : "none",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    zIndex: 1050,
                }}
            >
                <div
                    className="image-popup-dialog modal-dialog"
                    style={{
                        maxWidth: "900px", // Set a max width for the modal
                        width: "100%", // Make sure it uses available width
                        margin: "auto", // Center the modal
                    }}
                >
                    <div className="modal-content" ref={this.modalRef} style={{ width: "100%" }}>
                        {/* Carousel Component */}
                        <Carousel
                            activeIndex={currentIndex}
                            onSelect={(selectedIndex) => {
                                // Update currentIndex when a new slide is selected
                                this.setState({ currentIndex: selectedIndex });
                            }}
                            interval={3000} // Auto-slide interval: 3 seconds
                            fade // Smooth fade transition
                            indicators // Display navigation dots
                            controls // Display navigation buttons
                        >
                            {images.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block mx-auto"
                                        src={image}
                                        alt={`Slide ${index + 1}`}
                                        style={{
                                            width: "100%", // Make sure the image fills the width of the carousel
                                            maxWidth: "100%", // Ensures image fits within the container
                                            height: "auto", // Allow height to scale proportionally
                                            objectFit: "contain", // Maintain aspect ratio
                                        }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { sliderImageURL, alternateText } = this.props;

        return (
            <>
                {/* Thumbnail Image */}
                <div
                    className="image-wrapper2"
                    onClick={() => this.toggleModal(this.props.index)}
                    style={{ cursor: "pointer" }}
                >
                    <img src={sliderImageURL} alt={alternateText} />
                </div>

                {/* Render Modal using React Portal */}
                {this.state.showModal &&
                    ReactDOM.createPortal(
                        this.renderModalContent(),
                        document.body // Render the modal at the top of the DOM tree
                    )}
            </>
        );
    }
}

export default ImageCarouselModal;