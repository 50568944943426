import React, { Component } from 'react';
import Slider from "react-slick";
import ApiURL from "../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

class BlogExplorer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DestinationData: []
        }
    }

    componentDidMount() {
        const destinationDataList = [
            {
                ImageName: "Bhutan1.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/bhutan-blog"
            },
            {
                ImageName: "Bhutan2.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
            {
                ImageName: "Bhutan3.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
            {
                ImageName: "Bhutan4.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
            {
                ImageName: "Bhutan5.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
        ];

        this.setState({ DestinationData: destinationDataList })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const destinationSliderList = this.state.DestinationData;
        const MyView = destinationSliderList.map((sliderItem, i) => {
            console.log(sliderItem);
            const sliderImageURL = ApiURL.BlogExplorerImageURL + "/" + sliderItem.ImageName;
            return (
                <div className="cards-wrapper" key={i}>
                    <div className="card">
                        <div className="image-wrapper">
                            <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{sliderItem.Title}</h5>
                            <p className="card-text">{sliderItem.Description}</p>
                            <div className="card-footer">
                                
                                <Link to={sliderItem.BookNowURL} target='_blank' className="btn-Warning btn-sm float-end">
                                   Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid custom-container" style={{ paddingTop: '110px' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2>Bhutan <span>Explorer</span></h2>
                                <h4>International Tour</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner-two">
                                <div className="carousel-item active">
                                    <Slider {...settings}>
                                        {MyView}
                                    </Slider>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </>
        );
    }
}

export default BlogExplorer;